import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_day_atom } from '../../../assets/model/detail/store';
import moment from 'moment';
import { fetchGameList } from '../../../utils/api';
import _ from 'lodash';
import DepthChartContainer from '../../../components/depth/DepthChartContainer';
import ad1 from '../../../assets/image/ad/ad1.png';
import ad2 from '../../../assets/image/ad/ad2.png';
import ad3 from '../../../assets/image/ad/ad3.png';
import ad4 from '../../../assets/image/ad/ad4.png';
import { Grid } from '@mui/material';

function TeamRosterDepthBox() {
    const gameDay = useAtomValue(game_day_atom);
    const [gameList, setGameList] = useState(new Map());

    useEffect(() => {
        const gameDaySeason = moment(gameDay).format('YYYY');
        const gameDayString = moment(gameDay).format('YYYY-MM-DD');

        fetchGameList({
            gameDay: gameDayString,
            gameTypeCodes: 4201,
            seasons: gameDaySeason
        }).then((response) => {
            const sortedGameList = _.sortBy(response['data']['RECORD'], ['gameStatus']);
            const groupedDate = _.groupBy(sortedGameList, (item, idx) => item['awayTeamId']+"_"+item['homeTeamId']);

            const groupedMap = new Map();
            _.forEach(groupedDate, (item, idx) => {
                groupedMap.set(idx, item);
            });

            setGameList((prev) => {
                return groupedMap;
            });
        })
    }, [gameDay]);

    const randomAd = () => {
        const randomNum = Math.ceil(Math.random()*(4));
        if(randomNum === 1) {
            return <img src={ad1} width='100%' height='100%' alt="ad1"/>
        } else if(randomNum === 2) {
            return <img src={ad2} width='100%' height='100%' alt="ad2"/>
        } else if(randomNum === 3) {
            return <img src={ad3} width='100%' height='100%' alt="ad3"/>
        } else {
            return <img src={ad4} width='100%' height='100%' alt="ad4"/>
        }
    }

    const rosterDepth = (start, end) => {        
        if(gameList.size === 0) {
            return <>뎁스 정보가 없습니다.</>;
        } else {
            const result = [];
            const gameListKeys = [];
            gameList.forEach((value, key, map) => {
                gameListKeys.push(key);
            })
            // const gameListKeys = gameList.keys().toArray();

            for(let i=start; i < end; i++) {
                const key = gameListKeys[i];
                result.push(
                <div className="depth_wrap" key={i}>
                {
                    gameList.get(key) ? 
                    <DepthChartContainer gameInfoArr={gameList.get(key)}/> :
                    <>{randomAd()}</>
                }                
                </div>
                )
            }

            return result
        }
    }

    if(gameList.size === 0) {
        return (
        <>뎁스 정보가 없습니다.</>
        )
    }

    return (
    <>
    <Grid className="container" sx={{display: {xs: 'none', md: 'block'}}}>
        <div className="team_roster_depth">
            <div className="team_depth_row">
                {rosterDepth(0, 3)}
            </div>
            <div className="team_depth_row">
                {rosterDepth(3, 6)}
            </div>
        </div>
    </Grid>
    </>
    )
}

export default TeamRosterDepthBox;