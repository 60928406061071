import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPwPage = () => {
    const [memberLoginId, setMemberLoginId] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    // validation 관리용 state
    const [validMemberLoginId, setValidMemberLoginId] = useState(false);
    const [validEmailAddress, setValidEmailAddress] = useState(false);

    const [messageMemberLoginId, setMessageMemberLoginId] = useState("");
    const [messageEmailAddress, setMessageEmailAddress] = useState("");

    const navigate = useNavigate();

    const moveToLogin = () => {
        navigate('/login');
    }

    const saveMemberLoginId = event => {
        setMemberLoginId(event.target.value.trim());
    };

    const saveEmailAddress = event => {
        setEmailAddress(event.target.value.trim());
    };

    const checkSpacebar = event => {
        if(event.keyCode === 32){
            event.preventDefault();
        }
    }

    const validationReset = () => {
        setValidMemberLoginId(false);
        setValidEmailAddress(false);
        setMessageMemberLoginId("");
        setMessageEmailAddress("");
    }

    const validationCheck = () => {
        validationReset();
        let checkResult = true;

        if(memberLoginId === ""){
            setValidMemberLoginId(true);
            setMessageMemberLoginId("∙ 아이디: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 아이디: 영문 소문자로 시작하는 5~20자의 영문 소문자, 숫자, 특수문자(-, _)
            const idRegex = /^[a-z]+[a-z0-9_-]{4,19}$/;

            if(!idRegex.test(memberLoginId)){
                setValidMemberLoginId(true);
                setMessageMemberLoginId("∙ 아이디: 영문 소문자로 시작하는 5~20자의 영문 소문자, 숫자, 특수문자(-, _) 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(emailAddress === ""){
            setValidEmailAddress(true);
            setMessageEmailAddress("∙ 이메일: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 이메일: 이메일 형식 체크
            const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

            if(!emailRegex.test(emailAddress)){
                setValidEmailAddress(true);
                setMessageEmailAddress("∙ 이메일: 올바른 이메일인지 확인해주세요.");
                checkResult = false;
            }
        }

        return checkResult;
    }

    const onSendEmail = () => {
        if(validationCheck()){
            checkUserEmailAndId()
            .then((res) => {
                let result = res.checkUserEmailAndId;

                if(result === "SUCCESS"){
                    navigate('/login');
                    alert("해당 이메일로 임시 비밀번호를 발송했습니다.");
                } else if(result === "FAILURE") {
                    alert("아이디와 이메일이 올바른지 확인해주시기 바랍니다.");
                } else {
                    navigate('/error');
                }

            });
        }
    };

    const checkUserEmailAndId = () =>
        new Promise((resolve) => {
            const params = {
                memberLoginId: memberLoginId,
                emailAddress: emailAddress
            };

            axios.post(`${process.env.REACT_APP_API_URL}/member/checkUserEmailAndId`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
        });

    return (
        <div className="container">
            <div id="joinBox">
                <div id="topBtnWrap">
                    <button className="funcBtn" id="cancleBtn" onClick={moveToLogin}>취소</button>
                </div>
                <form>
                    <div>
                        <div className="label">
                            아이디
                        </div>
                        <input type="text" className={validMemberLoginId ? "warning" : ""}
                            name="memberLoginId" value={memberLoginId} maxLength="20" onChange={saveMemberLoginId} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            이메일
                        </div>
                        <input type="text" className={validEmailAddress ? "warning" : ""}
                            name="emailAddress" value={emailAddress} onChange={saveEmailAddress} onKeyDown={checkSpacebar} />
                    </div>
                    <div id="warningMessage">
                        <div>
                            {messageMemberLoginId}
                        </div>
                        <div>
                            {messageEmailAddress}
                        </div>
                    </div>
                </form>

                <button id="joinBtn" onClick={onSendEmail}>
                    임시 비밀번호 발급
                </button>
            </div>
        </div>
    );
};

export default ForgotPwPage;