import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchPitcherRecord } from '../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../utils/flat';
import IndicatorChart from '../../components/chart/IndicatorChart';

function TeamPitchingDigestBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [teamPitchingDigest, setTeamPitchingDigest] = useState({});
    const statObj = [
        {stat: 'era', sort: 'desc', name: 'ERA'},
        {stat: 'whip', sort: 'desc', name: 'WHIP'},
        {stat: 'avg', sort: 'desc', name: '피안타율'},
        {stat: 'obp', sort: 'desc', name: 'OBP'},
        {stat: 'slg', sort: 'desc', name: 'SLG'},
        {stat: 'ops', sort: 'desc', name: 'OPS'},
        {stat: 'hr9', sort: 'desc', name: 'HR/9'},
        {stat: 'bb9', sort: 'desc', name: 'BB/9'},
        {stat: 'k9', sort: 'asc', name: 'K/9'},
        {stat: 'qs', sort: 'asc', name: '퀄리티스타트'},
        {stat: 'qsPlus', sort: 'asc', name: '퀄리티스타트+'},
        {stat: 'erPerGame', sort: 'desc', name: '경기당 자책점'},
    ];

    useEffect(() => {
        const teamId = params['teamId'];
        const teamParam = {
            seasons: season,
            gameTypeCodes: 4201,
            groupingColumn: 'team'
        }

        const teamPitchingPromise = fetchPitcherRecord(teamParam);

        teamPitchingPromise.then((response) => {
            const teamDigestRecord = loopFlatObject(response['data']['RECORD']);
            const calcedRecord = rankingCalc(teamId, teamDigestRecord);
            const idxData = _.keyBy(calcedRecord, 'stat');
            setTeamPitchingDigest(idxData);
        })
    }, []);

    function rankingCalc(teamId, digestRecord) {
        let tmpArr = [];
        _.forEach(statObj, (item, idx) => {
            const {stat} = item;

            let sortedData = _.sortBy(digestRecord, [(o) => {return o[stat]*1 }]);
            if(item['sort'] === 'asc') {
                sortedData.reverse();
            }

            const statRanking = _.findIndex(sortedData, (data) => data['pitcherTeamId'] === teamId);
            const statObj = _.find(sortedData, (data) => data['pitcherTeamId'] === teamId);

            tmpArr.push({stat: stat, value: statObj[stat], rank: statRanking+1, name: item['name']});
        })

        return tmpArr;
    }

    return (
    <>
    {
    Object.keys(teamPitchingDigest).length === 0
    ?
    <></>
    :
    <div className="trackmanArea">
        <div className="teamTitle">{'팀 투구 그래프'}</div>
        <div style={{paddingTop: '5px'}}>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamPitchingDigest['era']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['whip']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['avg']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamPitchingDigest['obp']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['slg']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['ops']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamPitchingDigest['hr9']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['bb9']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['k9']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamPitchingDigest['qs']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['qsPlus']}/></div>
                <div><IndicatorChart data={teamPitchingDigest['erPerGame']}/></div>
            </div>
        </div>
    </div>
    }
    </>
    )
}

export default TeamPitchingDigestBox;