import React, { useEffect, useState } from 'react';
import { fetchBatterRecord, fetchBatterRecordDetail, fetchUniqPlayerRoster } from '../../../utils/api';
import moment from 'moment';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';

function WeeklyBatterRecord(props) {    
    const {fromDate, toDate, teamId} = props;
    const [statRecord, setStatRecord] = useState([]);

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const commonParam = {
            gameTypeCodes: 4201,
            groupingColumn: 'seasonPlayer',
            batterTeamId: teamId, 
            seasons
        }

        const beforeParam = {
            ...commonParam, 
            fromDate: moment(fromDate).add(-7, 'd').format('YYYY-MM-DD'),
            toDate: moment(toDate).add(-7, 'd').format('YYYY-MM-DD'),
        }

        const param = {
            ...commonParam, 
            fromDate, toDate
        }

        const rosterParam = {
            gameTypeCodes: 4201,
            seasons, teamIds: teamId,
            fromDate: moment(fromDate).add(-7, 'd').format('YYYY-MM-DD'),
            toDate
        }

        const beforePromise = fetchBatterRecord(beforeParam);
        const recordPromise = fetchBatterRecord(param);
        const uniqRosterPromise = fetchUniqPlayerRoster(rosterParam)

        Promise.all([
            beforePromise,
            recordPromise,
            uniqRosterPromise
        ]).then((response) => {
            const beforeBatterRecord = _.keyBy(batterDataSetting(response[0]['data']['RECORD']), 'batterId');
            const batterRecord = _.keyBy(batterDataSetting(response[1]['data']['RECORD']), 'batterId');

            const batterRoster = _.filter(response[2]['data']['RECORD'], item => [5101, 5103].indexOf(item['rosterTypeCode']*1) > -1 );

            const batterRecordArr = [];
            _.forEach(batterRoster, (item, idx) => {
                const tmpObj = {};

                const {playerName, playerId} = item;

                tmpObj['batterName'] = playerName;
                tmpObj['batterId'] = playerId;

                const playerBeforeRecord = beforeBatterRecord[playerId] || {};
                const playerRecord = batterRecord[playerId] || {};

                const compareStat = statCompare(playerBeforeRecord, playerRecord);
                
                batterRecordArr.push({
                    ...tmpObj, ...compareStat
                });
            });

            const tpaFilterData = _.filter(batterRecordArr, item => item['afterTpa'] >= 10);
            setStatRecord(_.sortBy(tpaFilterData, ['ops_after']).reverse());
        })
    }, [fromDate, teamId])

    const compareStatArr = ['avg', 'ops', 'slg', 'obp', 'bbRate', 'kRate'];
    const statCompare = (before, after) => {
        const tmpObj = {};

        if(after) {
            tmpObj['afterTpa'] = after['basicStat_tpa'];
        } else {
            tmpObj['afterTpa'] = 0;
        }

        _.forEach(compareStatArr, (item) => {
            if(!_.isEmpty(before) && !_.isEmpty(after)) {
                tmpObj[item+"_compare"] = after[item] - before[item];
                tmpObj[item+"_after"] = after[item];
                tmpObj[item+"_before"] = before[item];
                tmpObj['empty'] = false;
            } else if(_.isEmpty(before) && !_.isEmpty(after)) { // before empty
                tmpObj[item+"_compare"] = 'NEW';
                tmpObj[item+"_after"] = after[item]
                tmpObj[item+"_before"] = '-';
                tmpObj['empty'] = true;
            } else if(_.isEmpty(after) && !_.isEmpty(before)) { // after empty
                tmpObj[item+"_compare"] = 'X';
                tmpObj[item+"_after"] = '-';
                tmpObj[item+"_before"] = before[item];
                tmpObj['empty'] = true;
            } else {
                tmpObj[item+"_compare"] = '_';
                tmpObj[item+"_after"] = '_';
                tmpObj[item+"_before"] = '-';
                tmpObj['empty'] = true;
            }
        })

        return tmpObj;
    }

    const batterDataSetting = (data) => {
        return _.filter(loopFlatObject(data), item => item['basicStat_gameCnt'] > 0 ); 
    }

    return (
    <>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <div className="title">타자 주간 기록 변화</div>
        <div className="stat_comment">기준 : 10타석 이상</div>
    </div>
    <div className="content_table">
    {
    statRecord.length !== 0 
    ?
    <table className="hiball_table">
        <thead>
            <tr>
                <th>선수</th>
                <th>지난주AVG</th>
                <th>증감</th>
                <th>이번주AVG</th>
                <th>지난주OPS</th>
                <th>증감</th>
                <th>이번주OPS</th>
            </tr>
        </thead>
        <tbody>
        {
            _.map(statRecord, (item, idx) => (
                <tr key={item['batterId']}>
                    <td>{item['batterName']}</td>
                    <td>{item['avg_before']}</td>
                    {
                    !item['empty']
                    ?
                    (
                        item['avg_compare'] > 0 
                        ?
                        <td className="red_color">{(item['avg_compare']).toFixed(3)}</td>
                        :
                        <td className="blue_color">{(item['avg_compare']).toFixed(3)}</td>
                    )
                    :
                    <td>{item['avg_compare']}</td>
                    }
                    <td>{item['avg_after']}</td>
                    <td>{item['ops_before']}</td>
                    {
                    !item['empty']
                    ?
                    (
                        item['ops_compare'] > 0 
                        ?
                        <td className="red_color">{(item['ops_compare']).toFixed(3)}</td>
                        :
                        <td className="blue_color">{(item['ops_compare']).toFixed(3)}</td>
                    )
                    :
                    <td>{item['ops_compare']}</td>
                    }
                    <td>{item['ops_after']}</td>
                </tr>
            ))
        }
        </tbody>
    </table>
    :
    <></>
    }
    </div>
    </>
    )
}

export default WeeklyBatterRecord;