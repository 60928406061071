import React from 'react';
import PitchLogsFilter from '../../components/filter/pitch-logs-filter';
import PitchLogsRecord from './pitch-logs-record';

function PitchLogsContainer() {
    return (
    <div>
        <PitchLogsFilter/>
        <PitchLogsRecord/>
    </div>
    )
}

export default PitchLogsContainer;