import React, { useState } from 'react';
// import Logo from '../../assets/image/common/logo_white.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { decodeToken } from "../../utils/decodeToken";
import PlayerSearchBox from '../search/PlayerSearchBox';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { createTheme, alpha, ThemeProvider } from '@mui/material/styles';

const hiballBase = '#fff';
const hiballMain = alpha(hiballBase, 0.7);

const theme = createTheme({
    palette: {
        hiball: {
            main: hiballMain
        }
    },
});

function Header() {
    let token = JSON.parse(localStorage.getItem("accessToken"));
    let decodedToken = decodeToken();

    const { onLogout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
    <ThemeProvider theme={theme}>
        <Box sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'flex-end',
            backgroundColor: '#EAEAEA'
        }}>
            <div className='container'>
                <div id="auth_menu">
                {
                token ? (
                    <div className="user_wrap">
                        <span>{decodedToken.nickname} 님</span>
                        <br/>
                        <button id="profileBtn" type="button" onClick={() => navigate('/profile')}>
                        프로필
                        </button>
                        <button id="logoutBtn" type="button" onClick={onLogout}>
                        로그아웃
                        </button>
                    </div>
                ) : (
                    <div className="user_wrap">
                        <button id="joinBtn" type="button" onClick={() => navigate('/join/policy')}>
                        회원가입
                        </button>
                        <button id="loginBtn" type="button" onClick={() => navigate('/login')}>
                        로그인
                        </button>
                    </div>
                )
                }
                </div>
            </div>
        </Box>
        <AppBar position='static' color="hiball">
            <Container maxWidth="lg" disableGutters>
                <Toolbar disableGutters>
                <Typography
                    variant="h6"
                    noWrap
                    component={Link}
                    to="/"
                    // href="#app-bar-with-responsive-menu"
                    sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                >
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Hiball" id="hiballLogo"/>
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                    >
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/todaygame">오늘의 경기</MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/gameschedule">경기일정/결과</MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/ranking">순위</MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/record/team/">팀</MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/record/player/">선수</MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/todaygame">Logs</MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/board">게시판</MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} component={Link} to="/weekly">주간기록</MenuItem>
                    </Menu>
                </Box>
                <Box sx={{display: { xs: 'flex', md: 'none' }, alignItems: 'center'}}>
                    <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                        mr: 2,
                        // display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Hiball" id="hiballLogo"/>
                    </Typography>
                </Box>
                <Box sx={{ 
                        flexGrow: 1,
                        display: { xs: 'flex', md: 'none' }
                    }}>
                        <div id="top_menu">
                            <div id='search_bar'>
                                <PlayerSearchBox/>
                            </div>
                        </div>
                    </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <div id="top_menu">
                        <div className="gap"></div>
                        <div id="navigation">
                            <Link to="/todaygame" className={pathname.indexOf('/todaygame') > -1 ? 'active' : ''}>오늘의 경기</Link>
                            <Link to="/gameschedule/main" className={pathname.indexOf('/gameschedule') > -1 ? 'active' : ''}>경기일정/결과</Link>
                            <Link to="/ranking/team" className={pathname.indexOf('/ranking') > -1 ? 'active' : ''}>순위</Link>
                            <Link to="/record/team/batting/basic" className={pathname.indexOf('/record/team/') > -1 ? 'active' : ''}>팀</Link>
                            <Link to="/record/player/batting/basic" className={pathname.indexOf('/record/player/') > -1 ? 'active' : ''}>선수</Link>
                            <Link to="/logs" className={pathname.indexOf('/logs') > -1 ? 'active' : ''}>Logs</Link>
                            <Link to="/board" className={pathname.indexOf('/board') > -1 ? 'active' : ''}>게시판</Link>
                            <Link to="/weekly" className={pathname.indexOf('/weekly') > -1 ? 'active' : ''}>Weekly</Link>
                        </div>
                        <div className="gap"></div>
                        <div id='search_bar'>
                            <PlayerSearchBox/>
                        </div>
                    </div>
                </Box>
                </Toolbar>
            </Container>
        </AppBar>
    </ThemeProvider>
    )
}

export default Header;