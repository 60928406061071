export default function pitchingColumnCalc(statType, sortColumn, direction) {
    if(statType === "basic") {
        switch (sortColumn) {
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'statCalced_stat_gameCnt':
            case 'statCalced_stat_tpa':
            case 'statCalced_ip':
            case 'stat_blown':
            case 'stat_pitchingCnt':
            case 'stat_starter':
            case 'stat_substitute':
            case 'win':
            case 'lose':
            case 'save':
            case 'hold':
            case 'stat_r':
            case 'stat_er':
            case 'stat_tpa':
            case 'stat_ab':
            case 'stat_hit':
            case 'stat_b1b':
            case 'stat_b2b':
            case 'stat_b3b':
            case 'stat_hr':
            case 'stat_xh':
            case 'stat_so':
            case 'stat_bbAddIbb':
            case 'stat_ibb':
            case 'stat_hbp':
            case 'stat_sf':
            case 'stat_sb':
            case 'statCalced_winRate':
            case 'statCalced_era':
            case 'statCalced_fip':
            case 'statCalced_whip':
            case 'stat_wpa':
            case 'statCalced_totalWar':
            case 'statCalced_bWar':
            case 'statCalced_pitcherWAR': 
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                }
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "detail") {
        switch (sortColumn) {
            case 'statCalced_stat_gameCnt':
            case 'statCalced_ip':
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'statCalced_totalWar':
            case 'statCalced_bWar':
            case 'statCalced_pitcherWAR':
            case 'statCalced_era':
            case 'statCalced_fip':
            case 'statCalced_eraSubFip':
            case 'statCalced_kRate':
            case 'statCalced_bbRate':
            case 'statCalced_kBbRate':
            case 'statCalced_hit9':
            case 'statCalced_hr9':
            case 'statCalced_k9':
            case 'statCalced_bb9':
            case 'statCalced_stat_lookingSo':
            case 'statCalced_lookingSoRate':
            case 'statCalced_stat_swingSo':
            case 'statCalced_swingSoRate':
            case 'statCalced_babip':
            case 'statCalced_lobRate':
            case 'statCalced_pDivip':
            case 'statCalced_gDivIp':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "tpa") {
        switch (sortColumn) {
            case 'statCalced_ip':
            case 'statCalced_stat_pitchingCnt':
            case 'statCalced_pDivPa':
            case 'statCalced_strikeRate':
            case 'statCalced_ballRate':
            case 'statCalced_zoneStrikeRate':
            case 'statCalced_zoneBallRate':
            case 'statCalced_strikeStandRate':
            case 'statCalced_strikeSwingRate':
            case 'statCalced_strikeFoulRate':
            case 'statCalced_strikeInplayRate':
            case 'statCalced_firstStrikeRate':
            case 'statCalced_swStr':
            case 'statCalced_oStandRate':
            case 'statCalced_zStandRate':
            case 'statCalced_standRate':
            case 'statCalced_oSwingRate':
            case 'statCalced_zSwingRate':
            case 'statCalced_swingRate':
            case 'statCalced_oContactRate':
            case 'statCalced_zContactRate':
            case 'statCalced_contactRate':
            case 'statCalced_whiff':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "hit") {
        switch(sortColumn) {
            case 'statCalced_ip':
            case 'statCalced_stat_tpa':
            case 'statCalced_stat_hit':
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'statCalced_babip':
            case 'statCalced_stat_groundBall':
            case 'statCalced_stat_flyBall':
            case 'statCalced_stat_lineDriveBall':
            case 'statCalced_gdDivFly':
            case 'statCalced_gdRate':
            case 'statCalced_flyRate':
            case 'statCalced_lineRate':
            case 'statCalced_stat_hr':
            case 'statCalced_stat_xh':
            case 'statCalced_xhPerPa':
            case 'statCalced_xhPerAb':
            case 'statCalced_xhPerHit':
            case 'statCalced_paPerHr':
            case 'statCalced_abPerHr':
            case 'statCalced_hrPerFly':
            case 'statCalced_hrPerFlyOut':
            case 'statCalced_stat_oneRbiHr':
            case 'statCalced_stat_twoRbiHr':
            case 'statCalced_stat_threeRbiHr':
            case 'statCalced_stat_fourRbiHr':
            case 'statCalced_stat_rightPitcherHr':
            case 'statCalced_stat_leftPitcherHr':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    } else if(statType === "clutch") {
        switch(sortColumn) {
            case 'statCalced_stat_tpa':
            case 'statCalced_stat_scoringPosTpa':
            case 'statCalced_scoringPaRate':
            case 'statCalced_scoringAvg':
            case 'statCalced_stat_rbi':
            case 'statCalced_pureRbi':
            case 'statCalced_scoringHitRate':
            case 'statCalced_clutchHit':
            case 'statCalced_scoringHrRate':
            case 'statCalced_clutchHr':
            case 'statCalced_scoringSoRate':
            case 'statCalced_clutchK':
            case 'statCalced_scoringBbRate':
            case 'statCalced_clutchBb':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    } else if(statType === "starter") {
        switch (sortColumn) {
            case 'stat_gameCnt':
            case 'statCalced_ip':
            case 'statCalced_totalWar':
            case 'statCalced_bWar':
            case 'statCalced_pitcherWAR':
            case 'stat_wpa':
            case 'statCalced_era':
            case 'stat_win':
            case 'stat_lose':
            case 'stat_cg':
            case 'stat_shutOut':
            case 'stat_over5Inn':
            case 'stat_over6Inn':
            case 'stat_over7Inn':
            case 'stat_over8Inn':
            case 'stat_qs':
            case 'statCalced_qsRate':
            case 'stat_qsPlus':
            case 'statCalced_qsPlusRate':
            case 'stat_qk':
            case 'stat_tl':
            case 'stat_cw':
            case 'stat_pr':
            case 'stat_prs':
            case 'stat_bw':
            case 'stat_bl':
            case 'statCalced_gDivIp':
            case 'statCalced_gDivPitch':
            case 'stat_teamWin':
            case 'stat_teamLose':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "relief") {
        switch (sortColumn) {
            case 'stat_gameCnt':
            case 'statCalced_ip':
            case 'statCalced_totalWar':
            case 'statCalced_bWar':
            case 'statCalced_pitcherWAR':
            case 'stat_wpa':
            case 'statCalced_era':
            case 'statCalced_ifFip':
            case 'statCalced_fip':
            case 'stat_win':
            case 'stat_lose':
            case 'stat_hold':
            case 'stat_save':
            case 'stat_blown':
            case 'statCalced_savePlusRate':
            case 'statCalced_saveRate':
            case 'straightPitchDay2':
            case 'straightPitchDay3':
            case 'stat_under1Inn':
            case 'stat_under2Inn':
            case 'stat_under3Inn':
            case 'stat_over3Inn':
            case 'stat_ip1Inn':
            case 'stat_ip2Inn':
            case 'stat_ip3Inn':
            case 'stat_ip4Inn':
            case 'stat_toughHold':
            case 'stat_toughSave':
            case 'stat_toughBlown':
            case 'statCalced_toughSaveRate':
            case 'stat_ir':
            case 'stat_ira':
            case 'statCalced_irsPer':
            case 'stat_pr':
            case 'stat_prs':
            case 'statCalced_inLi':
            case 'statCalced_pLi':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "ballspeed") {
        switch (sortColumn) {
            case 'totalCnt':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            case '6116_cnt':
            case '6101_cnt':
            case '6117_cnt':
            case '6108_cnt':
            case '6111_cnt':
            case '6103_cnt':
            case '6107_cnt':
            case '6119_cnt':
            case '6114_cnt':            
            case '6106_cnt':
            case '6112_cnt':
            case '6116_rate':
            case '6101_rate':
            case '6117_rate':
            case '6108_rate':
            case '6111_rate':
            case '6103_rate':
            case '6107_rate':
            case '6119_rate':
            case '6114_rate':
            case '6106_rate':
            case '6112_rate':
                return (a, b) => {
                    if(direction === 'ASC') {
                        if(a[sortColumn] === "-") return 1;
                        if(b[sortColumn] === "-") return -1;
                        if(a[sortColumn] === b[sortColumn]) return 0;    
                    } else {
                        if(a[sortColumn] === "-") return -1;
                        if(b[sortColumn] === "-") return 1;
                        if(a[sortColumn] === b[sortColumn]) return 0;
                    }
                    return a[sortColumn] - b[sortColumn];
                }
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }
}