import { Tooltip } from '@mui/material';
import React from 'react';

export function tooltipRender(props) {
    const {column, sortDirection} = props;
    const {key, tooltip, name} = column;
    return (
        <>
        {
        sortDirection === 'DESC'
        ?
        <Tooltip title={tooltip} placement='top'>
            <span id={key}>{name}▼</span>
        </Tooltip>
        :
        (
        sortDirection === 'ASC'
        ?
        <Tooltip title={tooltip} placement='top'>
            <span id={key}>{name}▲</span>
        </Tooltip>
        :
        <Tooltip title={tooltip} placement='top'>
            <span id={key}>{name}</span>
        </Tooltip>
        )
        }
        </>
    )
}