import React, { useState } from 'react';
import PitcherBasicRecordBox from './pitcher-basic-record-box';
import PitcherSituation1RecordBox from './pitcher-situation1-record-box';
import PitcherSituation2RecordBox from './pitcher-situation2-record-box';
import PitcherDateRecordBox from './pitcher-date-record-box';


function PitcherRecordBox() {
    const [subId, setSubId] = useState("pitcher_basic");

    const subMenuEventHandler = (e) => {
        const id = e.currentTarget.id;
        setSubId(id);
    }

    const SubTabContent = function() {
        switch(subId) {
            case 'pitcher_basic':
                return <PitcherBasicRecordBox />
            case 'pitcher_date':
                return <PitcherDateRecordBox />
            case 'pitcher_situation1':
                return <PitcherSituation1RecordBox />;
            case 'pitcher_situation2':
                return <PitcherSituation2RecordBox />;
            default:
                return <></>
        }
    }

    return (
    <>
    <div id="player_tab_btn_area">
        <div id="pitcher_basic"
        className={"btn "+(subId === 'pitcher_basic' && 'active')}
        onClick={subMenuEventHandler}
        >기본</div>
        <div id="pitcher_date"
        className={"btn "+(subId === 'pitcher_date' && 'active')}
        onClick={subMenuEventHandler}
        >날짜별</div>
        <div id="pitcher_situation1"
        className={"btn "+(subId === 'pitcher_situation1' && 'active')}
        onClick={subMenuEventHandler}
        >상황1</div>
        <div id="pitcher_situation2"
        className={"btn "+(subId === 'pitcher_situation2' && 'active')}
        onClick={subMenuEventHandler}
        >상황2</div>
        <div id="pitcher_chart"
        className={"btn "+(subId === 'pitcher_chart' && 'active')}
        onClick={subMenuEventHandler}
        >차트</div>
    </div>
    <div id="player_tab_content">
        <SubTabContent />
    </div>
    </>
    )
}

export default PitcherRecordBox;