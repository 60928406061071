import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchRosterInfo } from '../../utils/api';
import positionTypeCode from '../../utils/code/positionTypeCode';
import { Box } from '@mui/material';
const positionArr = positionTypeCode();

function LineupChartContainer(props) {
    const {gameInfoArr} = props;

    const [rosterObj, setRosterObj] = useState({
        roster: [],
        gameInfo: {},
        lineUpYn: 'N'
    });

    useEffect(() => {
        if(gameInfoArr.length !== 0) {
            let sortedData = _.sortBy(gameInfoArr, ['doubleHeaderYn']);
            const gameInfo = sortedData[0];

            let commonParam = {
                gameTypeCodes: 4201
            };

            if(sortedData.length > 1) {
                sortedData = [sortedData[1]];
            }

            const promiseArr = [];
            _.forEach(sortedData, (item, idx) => {
                if(gameInfo['lineUpYn'] === 'Y') {
                    commonParam['season'] = item['season'];

                    const rosterInfoParam = {
                        ...commonParam, gameInfoIds: item['gameInfoId']
                    }
    
                    const rosterInfoPromise = fetchRosterInfo(rosterInfoParam);
                    promiseArr.push(rosterInfoPromise);
                } else {
                    let awayTeamParam = {...commonParam,
                        season: moment(item['awayPreGameDay']).format('YYYY'),
                        gameInfoIds: item['awayPreGameInfoId']
                    }

                    let homeTeamParam = {...commonParam,
                        season: moment(item['homePreGameDay']).format('YYYY'),
                        gameInfoIds: item['homePreGameInfoId']
                    }

                    const awayRosterInfoPromise = fetchRosterInfo(awayTeamParam);
                    const homeRosterInfoPromise = fetchRosterInfo(homeTeamParam);

                    promiseArr.push(awayRosterInfoPromise);
                    promiseArr.push(homeRosterInfoPromise);
                }
            });

            Promise.all(promiseArr)
            .then((response) => {
                const tmpArr = [];
                _.forEach(response, (item ,idx) => {
                    tmpArr.push(item['data']['RECORD']);
                });

                const rosterPlayerArr = _.flatten(tmpArr);

                let setObj = new Set(rosterPlayerArr.map(JSON.stringify));
                let uniqPlayerArr = Array.from(setObj).map(JSON.parse);
                const rosterInfo = rosterCalc(uniqPlayerArr, gameInfo);

                const tmpObj = {
                    roster: rosterInfo,
                    gameInfo,
                    lineUpYn: gameInfo['lineUpYn']
                }
    
                setRosterObj(tmpObj)
            })
        }  
    }, [gameInfoArr]);

    if(Object.keys(rosterObj['roster']).length === 0) {
        return <>라인업 정보가 없습니다.</>;
    }

    function rosterCalc(info, gameInfo) {
        const {awayTeamId, homeTeamId} = gameInfo;

        const starterPitcher = _.filter(info, (item, idx) => {
            return item['rosterTypeCode'] === '5102'
        });

        const filteredData = _.sortBy(_.filter(info, (item, idx) => {
            return (item['battingOrder'] !== '0') && item['rosterTypeCode'] === '5101';
        }), ['battingOrder']);

        const flatArr = _.flattenDeep([starterPitcher, filteredData]);
        const rosterGroupedData = _.groupBy(flatArr, (item, idx) => {
            return item['teamId'];
        });

        let battingOrderArr = [];
        for(let i=0; i < 10; i++) {
            battingOrderArr.push(
                {
                    awayTeamPlayer: rosterGroupedData[awayTeamId][i],
                    homeTeamPlayer: rosterGroupedData[homeTeamId][i]
                }
            )
        }

        return battingOrderArr;
    }

    const positionRenderer = (code) => {
        return positionArr.find(item => item['positionTypeCode'] === code*1)['shortName'];
    }

    return (
    <Box
    sx={{
        border: '1px solid black',
        width: {xs: '49%', md: '19%'}
    }}
    >
    <table className="main_lineup_table">
        <colgroup>
            <col></col>
            <col></col>
            <col width="10%"></col>
        </colgroup>
        <thead>
            <tr>
                <th colSpan={2} className={'font_color_'+rosterObj['gameInfo']['awayTeamId']}>
                    {rosterObj['gameInfo']['awayTeamName']}
                </th>
                <th></th>
                <th colSpan={2} className={'font_color_'+rosterObj['gameInfo']['homeTeamId']}>
                    {rosterObj['gameInfo']['homeTeamName']}
                </th>
            </tr>
        </thead>
        <tbody>
        {
        _.map(rosterObj['roster'], (item, idx) => (
            <tr key={item['homeTeamPlayer']['playerId']+"/"+item['awayTeamPlayer']['playerId']}>
                <td>{positionRenderer(item['awayTeamPlayer']['positionTypeCode'])}</td>
                <td>{item['awayTeamPlayer']['playerName']}</td>
                <td>{idx !== 0 && idx}</td>
                <td>{positionRenderer(item['homeTeamPlayer']['positionTypeCode'])} </td>
                <td>{item['homeTeamPlayer']['playerName']}</td>
            </tr>
        ))
        }
        </tbody>
    </table>
    </Box>
    )
}

export default LineupChartContainer;