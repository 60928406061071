import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchBatterRecord, fetchTeamRanking } from '../../../utils/api';
import arrow_big_prev from '../../../assets/image/main/arrow_big_prev.png';
import arrow_big_next from '../../../assets/image/main/arrow_big_next.png';
import {flatObject, loopFlatObject} from '../../../utils/flat';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';

function HomeHitterRanking(props) {
    const [hitterRanking, setHitterRanking] = useState([]);
    const gameDay = props['lastGameDay'];
    const gameDaySeason = moment(gameDay).format('YYYY');
    const navigate = useNavigate();

    useEffect(() => {
        if(gameDay) {
            const commonParam = {
                seasons: gameDaySeason,
                gameTypeCodes: 4201
            }

            const batterParam = { ...commonParam, groupingColumn: 'seasonPlayer' };
            const teamParam = { ...commonParam, gameDay };

            const batterRecordPromise = fetchBatterRecord(batterParam);
            const teamRecordPromise = fetchTeamRanking(teamParam);

            Promise.all([
                batterRecordPromise,
                teamRecordPromise
            ]).then((response) => {
                const rankingData = Object.values(response[0]['data']['RECORD']);
                const teamRankingData = Object.values(response[1]['data']['RECORD'])[0];
                const indexTeamData = _.keyBy(teamRankingData, 'teamId');

                let filterData = _.filter(rankingData, (item) => {
                    let teamGameCnt = indexTeamData[item['batterTeamId']]['gameCnt'];
                    return item['basicStat']['tpa'] > teamGameCnt*3.1;
                });

                let innerData = loopFlatObject(filterData);
    
                innerData.sort(function (a, b) {
                    if (a['avg']*1 > b['avg']*1) {
                        return -1;
                    }
                    if (a['avg']*1 < b['avg']*1) {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                });
                
                setHitterRanking(innerData.slice(0,10));
            });
        }
    }, [gameDay]);

    const batterPlayerInfoHandler = (e) => {
        const playerObj = e.currentTarget.id;
        const playerId = playerObj.split("/")[1];
        const season = playerObj.split("/")[0];
        navigate(`/player/batter/${playerId}?season=${season}`);
    }

    if(hitterRanking.length === 0) {
        return <div>데이터가 없습니다.</div>
    }

    return (
        <>
        <div className="title">
            <div className="arrow_prev">
                <img src={arrow_big_prev} alt="arrow_big_prev"/>
            </div>
            <div>
                HITTER <span>RANKING</span>
            </div>
            <div className="arrow_next">
                <img src={arrow_big_next} alt="arrow_big_next"/>
            </div>
        </div>
        <div className="table">
            <div className="name_wrap">
                <div className="name">
                    <span>타자순위</span>
                </div>
                <Link to="/ranking/batter" className='more'>
                    MORE +
                </Link>
            </div>
            <div className="content">
                <table>
                    <thead>
                    <tr>
                        <th>순위</th>
                        <th>팀</th>
                        <th>이름</th>
                        <th>타수</th>
                        <th>타율</th>
                        <th>OPS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                    hitterRanking.map((item, idx) => (
                        <tr key={item['batterTeamId']+"_"+item['batterId']}>
                            <td>{idx+1}</td>
                            <td>
                                <img
                                src={require(`/src/assets/image/common/team_logo/logo_${item['batterTeamId']}.png`)}
                                alt={item['batterName']}
                                />
                            </td>
                            <td >
                                <span
                                onClick={batterPlayerInfoHandler} id={item['season']+"/"+item['batterId']} 
                                style={{cursor: 'pointer'}}
                                >
                                {item['batterName']}
                                </span>
                            </td>
                            <td>{item['basicStat_ab']}</td>
                            <td>{item['avg']}</td>
                            <td>{item['ops']}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}

export default HomeHitterRanking;