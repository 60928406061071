import React from 'react';
import styled from 'styled-components';

export default function FilterBoxDiv() {
    return (
        styled.div`
            border: 2px solid #e2e1e6;
            margin: 5px 0px;
            border-radius: 5px;
            padding: 5px 0px;
        `
    )
}