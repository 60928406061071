import React, { useEffect, useState } from 'react';
import TodayTotalBox from './today-total-box';
import TodayLineUpBox from './today-lineup-box';
import TodayRosterBox from './today-roster-box';
import TodayVersusBox from './today-versus-box';
import TodayDepthBox from './today-depth-box';

function TodayGameLowerBox() {
    const [subId, setSubId] = useState('total');

    const subMenuEventHandler = (e) => {
        const id = e.currentTarget.id;
        setSubId(id);
    }

    const LowerTabContent = function() {
        switch(subId) {
            case 'lineup' : 
                return <TodayLineUpBox/>;
            case 'roster' : 
                return <TodayRosterBox/>;
            case 'depth' : 
                return <TodayDepthBox/>;
            case 'versus' : 
                return <TodayVersusBox/>;
            case 'ballcode' : 
                return <></>;
            default :
                return <TodayTotalBox/>;
        }
        
    }

    return (
    <div id='lower_tab_wrap'>
        <div id="lower_tab_area">
            <div id="lower_tab_btn_area">
                <div id="total" 
                className={"btn "+((subId === "total") && "active")}
                onClick={subMenuEventHandler}
                >전적</div>

                <div id="lineup"
                className={"btn "+((subId === "lineup") && "active")}
                onClick={subMenuEventHandler}
                >선발라인업</div>

                <div id="roster" 
                className={"btn "+((subId === "roster") && "active")}
                onClick={subMenuEventHandler}
                >로스터</div>

                <div id="depth"
                className={"btn "+((subId === "depth") && "active")}
                onClick={subMenuEventHandler}
                >뎁스</div>

                <div id="versus" 
                className={"btn "+((subId === "versus") && "active")}
                onClick={subMenuEventHandler}
                >VS</div>

                {/* <div id="ballcode" 
                className={"btn "+((subId === "ballcode") && "active")}
                onClick={subMenuEventHandler}
                >구종</div> */}
            </div>
            <div id='lower_tab_content'>
                <LowerTabContent />
            </div>
        </div>
    </div>
    )
}

export default TodayGameLowerBox;