import _ from "lodash";

function BallTypeTableComponent(props) {
    let {data} = props;

    return (
    <>
    {
    !_.isEmpty(data)
    ?
    // <table className="record_table">
    //     <thead>
    //         <tr>
    //             <th>구종</th>
    //             <th>구속</th>
    //             <th>구사율</th>
    //         </tr>
    //     </thead>
    //     <tbody>
    //         {
    //         _.map(data, (item, idx) => (
    //             <tr key={item['ballCode']}>
    //                 <td>{item['ballCodeName']}</td>
    //                 <td>{(item['ballSpeed']*1).toFixed()}</td>
    //                 <td>{(item['pitchRate']*100).toFixed(1)}</td>
    //             </tr>
    //         ))
    //         }
    //     </tbody>
    // </table>
    <table className="record_table">
        <tbody>
            <tr>
                {
                _.map(data, (item, idx) => (
                    <th key={item['ballCode']}>{item['ballCodeName']}</th>
                ))
                }
            </tr>
            <tr>
                {
                _.map(data, (item, idx) => (
                    <td key={item['ballCode']}>{(item['ballSpeed']*1).toFixed()+"km"}</td>
                ))
                }
            </tr>
            <tr>
                {
                _.map(data, (item, idx) => (
                    <td key={item['ballCode']}>{(item['pitchRate']*100).toFixed(1)+"%"}</td>
                ))
                }
            </tr>
        </tbody>
    </table>
    :
    <div>
        구종 기록없음
    </div>
    }
    </>
    )
}

export default BallTypeTableComponent;