import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { fetchTeamRecentLineupInfo } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

function WeeklyLineupInfo(props) {
    const {fromDate, toDate, teamId} = props;
    const [lineup, setLineup] = useState({});
    const [dayArr, setDayArr] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const dateArr = [];
        for(let i=0; i < 7; i++) {
            const tmpDate = moment(fromDate).add(i, 'd').format('YYYY-MM-DD');
            dateArr.push(tmpDate);
        }

        setDayArr(dateArr);

        const lineupParam = {
            gameTypeCodes: 4201,
            teamIds: teamId,
            seasons, fromDate, toDate,
            rosterTypeCodes: '5101,5102'
        }

        const teamRecentLineupPromise = fetchTeamRecentLineupInfo(lineupParam);

        // const testLineupPromise = fetchTeamRecentLineupInfo({
        //     gameTypeCodes: 4201,
        //     teamIds: 6001,
        //     seasons: 2024,
        //     toDate: '2024-08-11',
        //     fromDate: '2024-08-11',
        //     rosterTypeCodes: '5101,5102'
        // });

        // testLineupPromise.then((response) => {
        //     console.log(response);
        // })

        
        teamRecentLineupPromise.then((response) => {
            const rosterPlayer = response['data']['RECORD'];
            const groupedData = _.groupBy(rosterPlayer, item => item['gameDay']);

            const dateObj = {};
            _.forEach(dateArr, (item, idx) => {
                const innerData = groupedData[item];
                if(innerData) {

                    if(innerData.length > 10) { //double header
                        const gameInfoGroup = _.groupBy(innerData, innerItem => innerItem['gameInfoId']);
                        const gameInfoArr = []
                        
                        _.forEach(gameInfoGroup, (item) => {
                            gameInfoArr.push({
                                time: item[0]['gameStime'],
                                gameInfoId: item[0]['gameInfoId'],
                                gameDay: item[0]['gameDay']
                            })
                        })

                        const sortedData = _.sortBy(gameInfoArr, ['time']);

                        const gameInfoIdArr = [];
                        _.forEach(sortedData, (item) => {
                            const {gameInfoId} = item;
                            gameInfoIdArr.push(gameInfoGroup[gameInfoId]);
                        })
                        dateObj[item] = gameInfoIdArr;
                    } else { // one game
                        dateObj[item] = [innerData];
                    }               
                }
            })

            setLineup(dateObj);
        })

    }, [fromDate, teamId]);

    function gameInfoIdClickHandler(event) {
        const gameInfoId = event.currentTarget.id;
        navigate(`/gameschedule/result/${gameInfoId}`);
    }

    function playerClickHandler(event) {
        const playerObj = event.currentTarget.id;
        const playerId = playerObj.split('/')[0];
        const season = playerObj.split('/')[1];
        const positionTypeCode = playerObj.split('/')[2];
        if(positionTypeCode*1 === 5201) {
            navigate(`/player/pitcher/${playerId}?season=${season}`);
        } else {
            navigate(`/player/batter/${playerId}?season=${season}`);
        }
    }

    return (
    <>
    <div className="title">주간 라인업</div>
    <div style={{display: 'flex', flexDirection:'column'}}>
        <div className='record_div'>
            <div>날짜</div>
            <div>선발</div>
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
            <div>5</div>
            <div>6</div>
            <div>7</div>
            <div>8</div>
            <div>9</div>
        </div>
        {
        !_.isEmpty(lineup)
        ?
        dayArr.map((item) => (
        <div className='record_div' key={item}>
            <div>{item}</div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            {
            lineup[item] ?
            lineup[item].map((innerItem) => (
                <div key={innerItem[0]['gameInfoId']} style={{display: 'flex', flexDirection: 'row'}}>
                    <div>{innerItem[0]['playerName']}</div>
                    <div>{innerItem[1]['playerName']}</div>
                    <div>{innerItem[2]['playerName']}</div>
                    <div>{innerItem[3]['playerName']}</div>
                    <div>{innerItem[4]['playerName']}</div>
                    <div>{innerItem[5]['playerName']}</div>
                    <div>{innerItem[6]['playerName']}</div>
                    <div>{innerItem[7]['playerName']}</div>
                    <div>{innerItem[8]['playerName']}</div>
                    <div>{innerItem[9]['playerName']}</div>
                </div>
            ))
            :
            <div>경기가 없습니다.</div>
            }
            </div>
        </div>
        ))
        :
        <></>
        }
    </div>
    {/* <div>
       <table className="hiball_table">
            <colgroup>
                <col width="8%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
                <col width="9.2%"></col>
            </colgroup>
            <thead>
                <tr>
                    <th>날짜</th>
                    <th>선발</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                </tr>
            </thead>
            <tbody>
            {
            dayArr.map((item, idx) => (
                <tr key={item}>
                {
                lineup[item]
                ?
                _.map(lineup[item], (innerItem, innerIdx) => (
                    <Fragment key={innerItem['battingOrder']+"_"+innerItem['gameInfoId']}>
                    {
                    innerIdx === 0 ?
                    <>
                    <td>
                        <span
                        id={innerItem['gameInfoId']}
                        onClick={gameInfoIdClickHandler}
                        style={{color: '#1b4397', cursor: 'pointer'}}
                        >
                        {innerItem['gameDay'].slice(-5)}</span>
                    </td>
                    <td>
                        <span
                        id={innerItem['playerId']+"/"+innerItem['gameDay'].split('-')[0]+"/"+innerItem['positionTypeCode']}
                        onClick={playerClickHandler}
                        style={{color: '#1b4397', cursor: 'pointer'}}
                        >{innerItem['playerName']}</span>
                    </td>
                    </>
                    :
                    <td>
                        <span
                        id={innerItem['playerId']+"/"+innerItem['gameDay'].split('-')[0]+"/"+innerItem['positionTypeCode']}
                        onClick={playerClickHandler}
                        style={{color: '#1b4397', cursor: 'pointer'}}
                        >{innerItem['playerName']}</span>
                    </td>
                    }
                    </Fragment>
                ))
                :
                <>
                <td>{item.slice(-5)}</td>
                <td colSpan={10}>경기 없음</td>
                </>
                }
                </tr>
            ))
            }
            </tbody>
        </table>
    </div> */}
    </>
    )
}

export default WeeklyLineupInfo;