import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchPitcherRecord } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';

function PitcherDateRecordBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [pitcherRecord, setPitcherRecord] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const playerId = params['playerId'];
        const recordParam = {
            pitcherIds: playerId,
            gameTypeCodes: 4201,
            groupingColumn: 'game',
            seasons: season
        }

        const pitcherRecordPromise = fetchPitcherRecord(recordParam)

        pitcherRecordPromise.then((response) => {
            const pitcherData = loopFlatObject(response['data']['RECORD']);
            setPitcherRecord(pitcherData);
        })
    }, []);

    function gameInfoIdClickHandler(event) {
        const gameInfoId = event.currentTarget.id;
        navigate(`/gameschedule/result/${gameInfoId}`);
    }

    const gameResultRenderer = (item) => {
        const {win, lose, hold, save} = item;

        if(win*1 === 1) {
            return <span className="color_red">승</span>
        } else if(lose*1 === 1) {
            return <span className="color_blue">패</span>
        } else if(hold*1 === 1) {
            return <span className="color_darkgray">홀</span>
        } else if(save*1 === 1) {
            return <span className="color_darkgray">세</span>
        } else {
            return <span>-</span>
        }
    }

    return (
        <div id="player_basic_container">
        <div className="table_title">
            날짜별 기록
        </div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>날짜</th>
                        <th>상대팀</th>
                        <th>타자수</th>
                        <th>이닝</th>
                        <th>투구수</th>
                        <th>결과</th>
                        <th>실점</th>
                        <th>자책</th>
                        <th>피안타</th>
                        <th>피2루타</th>
                        <th>피3루타</th>
                        <th>피홈런</th>
                        <th>탈삼진</th>
                        <th>볼넷</th>
                        <th>사구</th>
                        <th>평균자책점</th>
                        <th>WHIP</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(pitcherRecord, (item, idx) => (
                    <tr key={item['gameInfoId']}>
                        <td>
                            <span
                            id={item['gameInfoId']}
                            onClick={gameInfoIdClickHandler}
                            className="link"
                            >
                            {item['gameDay']}
                            </span>
                        </td>
                        <td>{item['batterTeamName']}</td>
                        <td>{item['basicStat_tpa']}</td>
                        <td>{item['ip']}</td>
                        <td>{item['basicStat_pitchingCnt']}</td>
                        <td>{gameResultRenderer(item)}</td>
                        <td>{item['basicStat_r']}</td>
                        <td>{item['basicStat_er']}</td>
                        <td>{item['basicStat_hit']}</td>
                        <td>{item['basicStat_b2b']}</td>
                        <td>{item['basicStat_b3b']}</td>
                        <td>{item['basicStat_hr']}</td>
                        <td>{item['basicStat_so']}</td>
                        <td>{item['basicStat_bb']+item['basicStat_ibb']+item['basicStat_hbp']}</td>
                        <td>{item['basicStat_hbp']}</td>
                        <td>{item['era']}</td>
                        <td>{item['whip']}</td>
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default PitcherDateRecordBox;