import axios from 'axios';

function fetchGameList(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/schedule`, param);
}

function fetchBatterRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/batter/record`, param)
}

function fetchBatterRecordDetail(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/batter/record-detail`, param)
}

function fetchBatterRecordTpa(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/batter/record-tpa`, param)
}

function fetchBatterSituationRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/batter/situation`, param)
}

function fetchPitcherRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/pitcher/record`, param)
}

function fetchPitcherRecordDetail(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/pitcher/record-detail`, param)
}

function fetchPitcherRecordTpa(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/pitcher/record-tpa`, param)
}

function fetchPitcherSituationRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/pitcher/situation`, param)
}

function fetchFielderRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/fielder/record`, param)
}

function fetchCatcherRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/catcher/record`, param)
}

function fetchInfielderRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/infielder/record`, param)
}

function fetchOutfielderRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/outfielder/record`, param)
}

function fetchRunnerRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/runner/record`, param)
}

function fetchPlayerInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/info`, param)
}

function fetchPlayerAllInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/allInfo`, param)
}

function fetchUniqPlayerRoster(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/distinct-roster`, param)
}

function fetchTeamInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/info`, param);
}

function fetchTeamDepth(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/depth`, param)
}

function fetchTeamMatchUpRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/matchup-record`, param)
}

function fetchTeamRanking(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/ranking`, param) 
}

function fetchTeamRecord(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/record`, param)
}

function fetchTeamWinRate(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/win-rate`, param)
}

function fetchTeamWinResult(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/win-result`, param)
}

function fetchRosterInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/roster`, param)
}

function fetchBatterBallType(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/batter/pitch-record`, param)
}

function fetchPitcherBallType(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/pitcher/pitch-record`, param)
}

function fetchStraightPitch(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/player/pitcher/straight-pitch`, param);
}

function fetchScoreBoardInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/scoreBoard`, param)
}

function fetchGameBatterResultInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/batter-result`, param);
}

function fetchGameBatterInningResultInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/batter-result-inning`, param);
}

function fetchGameBatterTableResultInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/batter-result-table`, param);
}

function fetchGameRunnerInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/runner-result`, param);
}

function fetchGamePitcherResultInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/pitcher-result`, param);
}

function fetchGameWpaResult(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/wpa-result`, param);
}

function fetchGameWpaTpaResult(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/wpa-tpaResult`, param);
}

function fetchPitchByPitch(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/pitchByPitch`, param);
}

function fetchPitchLog(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/pitchLog`, param);
}

function fetchTeamRecentLineupInfo(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/team/battingOrder`, param);
}

function fetchLastGameDay(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/lastGameDay`, param);
}

function fetchBaGameDay(param) {
    return axios.post(`${process.env.REACT_APP_API_URL}/game/baGameDay`, param);
}

export {
    fetchGameList,
    fetchBatterRecord,
    fetchBatterRecordDetail,
    fetchBatterRecordTpa,
    fetchBatterSituationRecord,
    fetchPitcherRecord,
    fetchPitcherRecordDetail,
    fetchPitcherRecordTpa,
    fetchPitcherSituationRecord,
    fetchFielderRecord,
    fetchCatcherRecord,
    fetchInfielderRecord,
    fetchOutfielderRecord,
    fetchRunnerRecord,
    fetchPlayerInfo,
    fetchPlayerAllInfo,
    fetchUniqPlayerRoster,
    fetchTeamInfo,
    fetchTeamDepth,
    fetchTeamMatchUpRecord,
    fetchTeamRanking,
    fetchTeamRecord,
    fetchTeamWinRate,
    fetchTeamWinResult,
    fetchRosterInfo,
    fetchBatterBallType,
    fetchPitcherBallType,
    fetchStraightPitch,
    fetchScoreBoardInfo,
    fetchGameBatterResultInfo,
    fetchGameBatterInningResultInfo,
    fetchGameBatterTableResultInfo,
    fetchGameRunnerInfo,
    fetchGamePitcherResultInfo,
    fetchGameWpaResult,
    fetchGameWpaTpaResult,
    fetchPitchByPitch,
    fetchPitchLog,
    fetchTeamRecentLineupInfo,
    fetchLastGameDay,
    fetchBaGameDay
}