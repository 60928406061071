import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { fetchPitcherRecord, fetchPitcherRecordDetail, fetchUniqPlayerRoster } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';

function WeeklyPitcherRecord(props) {
    const {fromDate, toDate, teamId} = props;
    const [statRecord, setStatRecord] = useState([]);

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const commonParam = {
            gameTypeCodes: 4201,
            groupingColumn: 'seasonPlayer',
            pitcherTeamIds: teamId, 
            seasons
        }

        const beforeParam = {
            ...commonParam, 
            fromDate: moment(fromDate).add(-7, 'd').format('YYYY-MM-DD'),
            toDate: moment(toDate).add(-7, 'd').format('YYYY-MM-DD'),
        }

        const param = {
            ...commonParam, 
            fromDate, toDate
        }

        const rosterParam = {
            gameTypeCodes: 4201,
            seasons, teamIds: teamId,
            fromDate: moment(fromDate).add(-7, 'd').format('YYYY-MM-DD'),
            toDate
        }

        const beforePromise = fetchPitcherRecord(beforeParam);
        const recordPromise = fetchPitcherRecord(param);
        const uniqRosterPromise = fetchUniqPlayerRoster(rosterParam);

        Promise.all([
            beforePromise,
            recordPromise,
            uniqRosterPromise
        ]).then((response) => {
            const beforePitcherRecord = _.keyBy(pitcherDataSetting(response[0]['data']['RECORD']), 'pitcherId');
            const pitcherRecord = _.keyBy(pitcherDataSetting(response[1]['data']['RECORD']), 'pitcherId');

            const pitcherRoster = _.filter(response[2]['data']['RECORD'], item => [5102, 5104].indexOf(item['rosterTypeCode']*1) > -1 );
            const pitcherRecordArr = [];
            _.forEach(pitcherRoster, (item, idx) => {
                const tmpObj = {};

                const {playerName, playerId} = item;

                tmpObj['pitcherName'] = playerName;
                tmpObj['pitcherId'] = playerId;

                const playerBeforeRecord = beforePitcherRecord[playerId] || {};
                const playerRecord = pitcherRecord[playerId] || {};

                const compareStat = statCompare(playerBeforeRecord, playerRecord);

                pitcherRecordArr.push({
                    ...tmpObj, ...compareStat
                });
            });

            const tpaFilterData = _.filter(pitcherRecordArr, item => item['afterGameCnt'] > 0);
            setStatRecord(_.sortBy(tpaFilterData, item => item['era_after']*1 ));
        });
    }, [fromDate, teamId]);

    const compareStatArr = ['era', 'whip'];
    const statCompare = (before, after) => {
        const tmpObj = {};

        if(after) {
            tmpObj['afterGameCnt'] = after['basicStat_gameCnt'];
        } else {
            tmpObj['afterGameCnt'] = 0;
        }

        _.forEach(compareStatArr, (item) => {
            if(!_.isEmpty(before) && !_.isEmpty(after)) {
                tmpObj[item+"_compare"] = after[item] - before[item];
                tmpObj[item+"_after"] = after[item];
                tmpObj[item+"_before"] = before[item];
                tmpObj['empty'] = false;
            } else if(_.isEmpty(before) && !_.isEmpty(after)) { // before empty
                tmpObj[item+"_compare"] = 'NEW';
                tmpObj[item+"_after"] = after[item]
                tmpObj[item+"_before"] = '-';
                tmpObj['empty'] = true;
            } else if(_.isEmpty(after) && !_.isEmpty(before)) { // after empty
                tmpObj[item+"_compare"] = 'X';
                tmpObj[item+"_after"] = '-';
                tmpObj[item+"_before"] = before[item];
                tmpObj['empty'] = true;
            } else {
                tmpObj[item+"_compare"] = '_';
                tmpObj[item+"_after"] = '_';
                tmpObj[item+"_before"] = '-';
                tmpObj['empty'] = true;
            }
        })

        return tmpObj;
    }

    const pitcherDataSetting = (data) => {
        return _.filter(loopFlatObject(data), item => item['basicStat_gameCnt'] > 0);
    }

    return (
    <>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <div className="title">투수 주간 기록 변화</div>
        <div className="stat_comment">기준 : 1경기 이상</div>
    </div>
    <div className='content_table'>
    {
    statRecord.length !== 0 
    ?
    <table className="hiball_table">
        <thead>
            <tr>
                <th>선수</th>
                <th>지난주ERA</th>
                <th>증감</th>
                <th>이번주ERA</th>
                <th>지난주WHIP</th>
                <th>증감</th>
                <th>이번주WHIP</th>
            </tr>
        </thead>
        <tbody>
        {
            _.map(statRecord, (item, idx) => (
                <tr key={item['pitcherId']}>
                    <td>{item['pitcherName']}</td>
                    <td>{item['era_before']}</td>
                    {
                    !item['empty']
                    ?
                    (
                        item['era_compare'] > 0 
                        ?
                        <td className="red_color">{(item['era_compare']).toFixed(2)}</td>
                        :
                        <td className="blue_color">{(item['era_compare']).toFixed(2)}</td>
                    )
                    :
                    <td>{item['era_compare']}</td>
                    }
                    <td>{item['era_after']}</td>
                    <td>{item['whip_before']}</td>
                    {
                    !item['empty']
                    ?
                    (
                        item['whip_compare'] > 0 
                        ?
                        <td className="red_color">{(item['whip_compare']).toFixed(2)}</td>
                        :
                        <td className="blue_color">{(item['whip_compare']).toFixed(2)}</td>
                    )
                    :
                    <td>{item['whip_compare']}</td>
                    }
                    <td>{item['whip_after']}</td>
                </tr>
            ))
        }
        </tbody>
    </table>
    :
    <></>
    }
    </div>
    </>
    )
}

export default WeeklyPitcherRecord;