import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_today_atom, game_info_obj_atom } from '../../../../assets/model/detail/store';
import moment from 'moment';
import { fetchRosterInfo, fetchTeamMatchUpRecord } from '../../../../utils/api';
import { flatObject, loopFlatObject } from '../../../../utils/flat';
import VsMatrixTableComponent from '../../../../components/table/VsMatrixTableComponent';
import _ from 'lodash';
import icon_away from '../../../../assets/image/common/icon_away.png';
import icon_home from '../../../../assets/image/common/icon_home.png';

function TodayVersusBox() {
    const gameDay = useAtomValue(game_today_atom);
    const gameInfo = useAtomValue(game_info_obj_atom);

    const [awayRecord, setAwayRecord] = useState({});
    const [homeRecord, setHomeRecord] = useState({});
    const [batterRoster, setBatterRoster] = useState([]);
    const [pitcherRoster, setPitcherRoster] = useState([]);

    useEffect(() => {
        if(!_.isEmpty(gameInfo)) {
            const season = moment(gameDay).format('YYYY');
            const rosterParam = {
                gameInfoIds: gameInfo['gameInfoId'],
                gameTypeCodes: 4201
            };

            const rosterInfoPromise = fetchRosterInfo({
                ...rosterParam
            });

            rosterInfoPromise.then((value) => {
                const rosterInfo = value['data']['RECORD'];

                if(rosterInfo.length !== 0) {
                    const {awayTeamId, homeTeamId} = gameInfo;
                    const batterRosterRecord = rosterDivideFunc(rosterInfo, awayTeamId, homeTeamId, 'batter');
                    const pitcherRosterRecord = rosterDivideFunc(rosterInfo, awayTeamId, homeTeamId, 'pitcher');
                    setBatterRoster(batterRosterRecord);
                    setPitcherRoster(pitcherRosterRecord);

                    const commonParam = {
                        seasons: season,
                        gameTypeCodes: 4201,
                        groupingColumn: 'vsPitcher'
                    }

                    const awayTeamParam = {
                        ...commonParam,
                        teamId: awayTeamId,
                        oppTeamId: homeTeamId
                    }

                    const homeTeamParam = {
                        ...commonParam,
                        teamId: homeTeamId,
                        oppTeamId: awayTeamId
                    }

                    const awayPromise = fetchTeamMatchUpRecord(awayTeamParam);
                    const homePromise = fetchTeamMatchUpRecord(homeTeamParam);

                    Promise.all([
                        awayPromise, homePromise
                    ]).then((response) => {
                        setAwayRecord(loopFlatObject(response[0]['data']['RECORD']));
                        setHomeRecord(loopFlatObject(response[1]['data']['RECORD']));
                    });
                } else {
                    setBatterRoster([]);
                    setPitcherRoster([]);
                    setAwayRecord([]);
                    setHomeRecord([]);
                }
            })
        }
    }, [gameInfo])

    const rosterDivideFunc = function(data, awayTeamId, homeTeamId, type) {
        let tmpArr = {};
        tmpArr[awayTeamId] = [];
        tmpArr[homeTeamId] = [];

        let playerTypeArr = (type == 'batter') ? [5101, 5103] : [5102, 5104];

        data.map((item, idx) => {
            if(item['teamId'] == awayTeamId && (playerTypeArr.indexOf(item['rosterTypeCode']*1) > -1)) {
                tmpArr[awayTeamId].push(item);
            } else if(item['teamId'] == homeTeamId && (playerTypeArr.indexOf(item['rosterTypeCode']*1) > -1)) {
                tmpArr[homeTeamId].push(item);
            }
        });

        return tmpArr;
    }
    
    return (
    <div id="lower_tab5" className="tab">
        <div className="head">
            <div className="team_away">
                <div className={`team_icon bg_color_${gameInfo['awayTeamId']}`}>
                    <img src={icon_away} alt="icon_away" />
                </div>
                <div className="team_logo_wrap">
                    <div className="team_logo">
                        <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfo['awayTeamId']}_l.png`)}/>
                    </div>
                </div>
            </div>
            <div className="vs_text">
                VS
            </div>
            <div className="team_home">
                <div className="team_logo_wrap">
                    <div className="team_logo">
                        <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfo['homeTeamId']}_l.png`)}/>
                    </div>
                </div>
                <div className={`team_icon bg_color_${gameInfo['homeTeamId']}`}>
                    <img src={icon_home} alt="icon_home"/>
                </div>
            </div>
        </div>
        <div className="body">
            <div className="team_away">
                <div className="matrix_content">
                    <VsMatrixTableComponent
                    gameInfo={gameInfo}
                    teamRecord={awayRecord}
                    batterTeam={'away'}
                    pitcherTeam={'home'}
                    batterRoster={batterRoster}
                    pitcherRoster={pitcherRoster}
                    />
                </div>
            </div>
            <div className="team_home">
                <div className="matrix_content">
                    <VsMatrixTableComponent
                    gameInfo={gameInfo}
                    teamRecord={homeRecord}
                    batterTeam={'home'}
                    pitcherTeam={'away'}
                    batterRoster={batterRoster}
                    pitcherRoster={pitcherRoster}
                    />
                </div>
            </div>
        </div>
    </div>
    )
}

export default TodayVersusBox;