import _ from "lodash"

export default function statCalc(dataArr) {
    return {
        ...dataCalc(dataArr)
    }
}

function dataCalc(data) {
    let abTotal = 0;
    let b1bTotal = 0;
    let b2bTotal = 0;
    let b3bTotal = 0;
    let hrTotal = 0;
    let bbTotal = 0;
    let hbpTotal = 0;
    let ibbTotal = 0;
    let soTotal = 0;
    let tpaTotal = 0;
    let sfTotal = 0;

    _.forEach(data, (item, idx) => {
        let {
            ab, b1b, b2b, b3b, bb, hr,
            hbp, ibb, so, tpa, sfout
            // sf
        } = item;

        abTotal += ab*1;
        b1bTotal += b1b*1;
        b2bTotal += b2b*1;
        b3bTotal += b3b*1;
        hrTotal += hr*1;
        bbTotal += bb*1;
        hbpTotal += hbp*1;
        ibbTotal += ibb*1;
        soTotal += so*1;
        tpaTotal += tpa*1;
        sfTotal += sfout*1;
    });

    let hitTotal = b1bTotal+b2bTotal+b3bTotal+hrTotal;
    let obpSon = hitTotal + bbTotal + hbpTotal;
    let obpMom = abTotal+bbTotal+hbpTotal+sfTotal;

    let slgSon = b1bTotal+(2*b2bTotal)+(3*b3bTotal)+(4*hrTotal);

    let obp = (obpMom !== 0) ? obpSon/obpMom : 0;
    
    let slg = (abTotal !== 0) ? slgSon/abTotal : 0;
    let avg = (abTotal !== 0) ? hitTotal/abTotal : 0;

    return {
        slg: statString(slg),
        obp: statString(obp), 
        ops: statString(statString(slg)*1+statString(obp)*1),
        avg: statString(avg)
    }
}

function statString(stat) {
    let statStringInit = '';

    if(stat*1 === 0) {
        statStringInit = '.000';
    } else if(Number.isInteger(stat)) { // integer
        statStringInit = stat+".000";
    } else if(stat > 0 && stat < 1)  {
        let basicStat = (stat*1).toPrecision(3);
        statStringInit = basicStat.slice(1,5);
    } else if(stat > 1){ 
        let basicStat = (stat*1).toPrecision(4);
        statStringInit = basicStat;
    }

    return statStringInit;
}