import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { fetchTeamRanking, fetchTeamRecord } from '../../../utils/api';
import weekDaysCode from '../../../utils/code/weekDaysCode';
import { Skeleton, Stack } from '@mui/material';
const weekDayArr = weekDaysCode();


function TeamVersusRecordBox(props) {
    const {statType, gameInfoObj} = props;
    const [awayRecord, setAwayRecord] = useState({});
    const [homeRecord, setHomeRecord] = useState({});
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        if(statType && !_.isEmpty(gameInfoObj)) {
            setIsLoader(true);
            const {awayTeamId, homeTeamId} = gameInfoObj;
            const commonParam = {
                seasons: gameInfoObj['season'],
                gameTypeCodes: 4201
            }

            if(statType === 'season') {
                const teamRaningParam = {...commonParam, gameDay: gameInfoObj['gameDay']};
                const teamRankingPromise = fetchTeamRanking(teamRaningParam);

                Promise.all([
                    teamRankingPromise
                ]).then((response) => {
                    const teamRanking = Object.values(response[0]['data']['RECORD'])[0];
                    const awayTeamRanking = teamRanking.find(item => item['teamId'] === awayTeamId);
                    const homeTeamRanking = teamRanking.find(item => item['teamId'] === homeTeamId);

                    setAwayRecord(awayTeamRanking);
                    setHomeRecord(homeTeamRanking);
                    setIsLoader(false);
                })
            } else {
                let awayParam = null;
                let homeParam = null;

                if(statType === 'relative') {
                    awayParam = {...commonParam, teamId: awayTeamId, oppTeamId: homeTeamId};
                    homeParam = {...commonParam, teamId: homeTeamId, oppTeamId: awayTeamId};
                } else if(statType === 'day') {
                    awayParam = {...commonParam, teamId: awayTeamId, weekDays: gameInfoObj['weekDay']};
                    homeParam = {...commonParam, teamId: homeTeamId, weekDays: gameInfoObj['weekDay']};
                } else if(statType === 'recent') {
                    awayParam = {...commonParam, teamId: awayTeamId, limitEnd: 10};
                    homeParam = {...commonParam, teamId: homeTeamId, limitEnd: 10};
                } else {
                    awayParam = {...commonParam, teamId: awayTeamId, stadiums: gameInfoObj['stadiumsId']};
                    homeParam = {...commonParam, teamId: homeTeamId, stadiums: gameInfoObj['stadiumsId']};
                } 

                const awayVsRecord = fetchTeamRecord(awayParam);
                const homeVsRecord = fetchTeamRecord(homeParam);

                Promise.all([
                    awayVsRecord, homeVsRecord
                ]).then((response) => {
                    const awayTeamRanking = response[0]['data']['RECORD'][0];
                    const homeTeamRanking = response[1]['data']['RECORD'][0];
                    setAwayRecord(awayTeamRanking);
                    setHomeRecord(homeTeamRanking);
                    setIsLoader(false);
                })
            }
        } else {
            setIsLoader(true);
        }
    }, [props]);

    const titleRenderer = () => {
        if(statType === 'season') {
            return <>시즌</>;
        } else if(statType === 'relative') {
            return <>상대</>;
        } else if(statType === 'day') {            
            const day = weekDayArr.find((item) => item['weekDay'] === gameInfoObj['weekDay']*1)['weekDayName'];
            return <>{day}요일</>;
        } else if(statType === 'stadium') {
            return <>{gameInfoObj['stadiumsName']}</>;
        } else if(statType === 'recent') {
            return <>10경기</>;
        }
    }

    return (
    <>
    {
    isLoader
    ?
    <div>
        <Stack spacing={1}>
            <Skeleton variant='rounded' width={563} height={42} animation="wave"/>
        </Stack>
    </div>
    :
    <>
    <div className="team_title">
        {titleRenderer()}
    </div>
    <div className="team_away">
    {
        !_.isEmpty(awayRecord) ?
        <>
        <div className="team_score">
            <span>{awayRecord['win']}</span>승
            <span>{awayRecord['lose']}</span>패
            <span>{awayRecord['draw']}</span>무
        </div>
        </>
        :
        <>
        <div className="team_score">
            <span>0</span>승
            <span>0</span>패
            <span>0</span>무
        </div>
        </>
    }
    </div>
    <div className="team_home">
    {
        !_.isEmpty(homeRecord) ?
        <>
        <div className="team_score">
            <span>{homeRecord['win']}</span>승
            <span>{homeRecord['lose']}</span>패
            <span>{homeRecord['draw']}</span>무
        </div>
        </>
        :
        <>
        <div className="team_score">
            <span>0</span>승
            <span>0</span>패
            <span>0</span>무
        </div>
        </>
    }   
    </div>
    </>
    }
    </>
    )
}

export default TeamVersusRecordBox;