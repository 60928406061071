import React, { Fragment, useEffect, useState } from 'react';
import icon_calendar from '../../../assets/image/common/icon_calendar.png';
import arrow_black from '../../../assets/image/common/arrow_black.png';
import up_arrow from '../../../assets/image/common/up_arrow.png';
import down_arrow from '../../../assets/image/common/down_arrow.png';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import "moment/locale/ko";
import HomeScheduleGameBox from './home-schedule-game-box';
import { atom, useAtom } from 'jotai';
import { game_day_atom } from '../../../assets/model/detail/store';
import { fetchBaGameDay, fetchGameList, fetchLastGameDay } from '../../../utils/api';
import _ from 'lodash';

function HomeScheduleBox() {
    const [date, setDate] = useAtom(game_day_atom);
    const [isOpen, setIsOpen] = useState(false);
    const mark_atom = atom([]);
    const [mark, setMark] = useState(mark_atom);
    const [gameArr, setGameArr] = useState([]);

    useEffect(() => {
        moment.locale("ko");
        const todayGameSeason = moment(date).format('YYYY');

        const todayGameListPromise = fetchGameList({
            seasons: todayGameSeason,
            gameDay: date,
            gameTypeCodes: 4201
        });

        const lastGameDayPromise = fetchLastGameDay({
            seasons: [todayGameSeason, todayGameSeason-1].toString(),
            gameTypeCodes: 4201
        })

        Promise.all([
            todayGameListPromise, 
            lastGameDayPromise
        ]).then((response) => {
            const todayGameArr = response[0]['data']['RECORD'];
            setGameArr(todayGameArr);

            if(todayGameArr.length === 0) {
                const {lastGameDay} = response[1]['data']['RECORD'][0];
                setDate(lastGameDay);
                monthGameListFetch(lastGameDay);
            } else {
                monthGameListFetch(date);
            }
        })
    }, [])

    const monthGameListFetch = (selectedDate) => {
        const lastGameMonth = moment(selectedDate).format('MM');
        const lastGameSeason = moment(selectedDate).format('YYYY');

        fetchGameList({
            seasons: lastGameSeason,
            months: lastGameMonth,
            gameTypeCodes: 4201
        }).then((data) => {
            const monthGameList = data['data']['RECORD'];
            const gameList = _.groupBy(monthGameList, (item) => item['gameDay']);
            setMark(gameList);
        })
    }

    const handleToggleCalendar = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const onActiveStartDateChange = (event) => {
        const {action, view, value, activeStartDate} = event;
        if(view === 'month') {
            monthGameListFetch(activeStartDate);
        }
    }

    const arrowClickEventHandler = (event) => {
        const id = event.currentTarget.id;

        fetchBaGameDay({ gameDay: moment(date).format("YYYY-MM-DD") })
        .then((response) => {
            const {nextGameDay, prevGameDay} = response['data']['RECORD'][0];
            if(id === 'up_arrow') {
                gameDayNavigate(prevGameDay);
            } else {
                gameDayNavigate(nextGameDay);
            }
        })
    }

    const gameDayNavigate = (date) => {
        setDate(date);
    }

    return (
        <>
        <div id="schedule_container">
            <div id="schedule_calendar">
                <div className="body">
                    <img src={up_arrow} alt="up_arrow" id="up_arrow" className="arrow" 
                    onClick={arrowClickEventHandler}/>
                    <span onClick={handleToggleCalendar}>
                    {
                    date === moment(new Date()).format('YYYY-MM-DD') &&
                    gameArr.length === 0 
                    ?
                    ''
                    :
                    (date && moment(date).format('M/D')+" "+moment(date).format('dd'))
                    }
                    </span>
                    <img src={down_arrow} alt="down_arrow" id="down_arrow" className="arrow"
                    onClick={arrowClickEventHandler}/>
                </div>
                {/* <div className="btn">
                    <img src={icon_calendar} alt="calendar" />
                    <span>달력</span>
                    <img src={arrow_black} alt="arrow_black" />
                </div> */}
                <div style={{position: 'absolute'}}>
                {
                    (isOpen) && (
                        <Calendar
                        onChange={(prevDate) => {
                            return setDate(moment(prevDate).format("YYYY-MM-DD"));
                        }}
                        value={date}
                        onClickDay={(value, event) => setIsOpen(!isOpen)}
                        formatDay={(locale, date) => moment(date).format("D")}
                        calendarType="gregory"
                        onActiveStartDateChange={onActiveStartDateChange}
                        tileContent={({date, view}) => {
                            if(view === 'month') {
                                let html = [];
                                let calcDate = moment(date).format("YYYY-MM-DD");

                                if(calcDate in mark) {
                                    let gameStart = _.filter(mark[calcDate], (item) => item['ingStatus'] !== 'CANCEL').length;
                                    html.push(<div key={calcDate} style={{color: '#2184b2'}}>{gameStart}</div>)
                                }

                                return <div>{html}</div>
                            }
                        }}
                        tileDisabled={({date, view}) => {
                            if(view === 'month') {
                                let calcDate = moment(date).format("YYYY-MM-DD");
                                return !mark[calcDate];
                            }
                        }}
                        />
                    )
                }
                </div>
            </div>
            <HomeScheduleGameBox/>
        </div>
        </>
    )
}

export default HomeScheduleBox;