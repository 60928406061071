import React, { useState, useRef } from "react";
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import RadioFavTeam from '../../components/user/RadioFavTeam';
import user_icon from '../../assets/image/common/user_icon_default.png';

const JoinPage = () => {
    const {userPolicy, policyAgreeYn}  = useOutletContext();

    const inputEmailRef = useRef(null);
    const inputAuthenticationNumberRef = useRef(null);

    const [memberLoginId, setMemberLoginId] = useState('');
    const [encContent, setEncContent] = useState('');
    const [memberName, setMemberName] = useState('');
    const [nickName, setNickName] = useState('');
    const [birthYmd, setBirthYmd] = useState('');
    const [gender, setGender] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    // const [mobilePhone, setMobilePhone] = useState('');
    const [favTeamCode, setFavTeamCode] = useState("9999");

    // validation 관리용 state
    const [validMemberLoginId, setValidMemberLoginId] = useState(false);
    const [validEncContent, setValidEncContent] = useState(false);
    const [validMemberName, setValidMemberName] = useState(false);
    const [validNickName, setValidNickName] = useState(false);
    const [validBirthYmd, setValidBirthYmd] = useState(false);
    const [validGender, setValidGender] = useState(false);
    const [validEmailAddress, setValidEmailAddress] = useState(false);
    // const [validMobilePhone, setValidMobilePhone] = useState(false);

    const [messageMemberLoginId, setMessageMemberLoginId] = useState("");
    const [messageEncContent, setMessageEncContent] = useState("");
    const [messageMemberName, setMessageMemberName] = useState("");
    const [messageNickName, setMessageNickName] = useState("");
    const [messageBirthYmd, setMessageBirthYmd] = useState("");
    const [messageGender, setMessageGender] = useState("");
    const [messageEmailAddress, setMessageEmailAddress] = useState("");
    // const [messageMobilePhone, setMessageMobilePhone] = useState("");

    // 이메일 인증용
    const [authenticationNumber, setAuthenticationNumber] = useState('');
    const [authenticationFlag, setAuthenticationFlag] = useState(false);
    const [validConfirmEmail, setValidConfirmEmail] = useState(false);
    const [messageEmailAuthentication, setMessageEmailAuthentication] = useState("");

    const favTeams = [
        {teamCode: '1001', teamName: "롯데"},
        {teamCode: '2001', teamName: "한화"},
        {teamCode: '3001', teamName: "LG"},
        {teamCode: '4001', teamName: "NC"},
        {teamCode: '5001', teamName: "SSG"},
        {teamCode: '6001', teamName: "기아"},
        {teamCode: '7001', teamName: "키움"},
        {teamCode: '8001', teamName: "두산"},
        {teamCode: '9001', teamName: "삼성"},
        {teamCode: '10001', teamName: "KT"}
    ];

    const navigate = useNavigate();

    const onCheckEmail = () => {
        if(emailAddress === ""){
            inputEmailRef.current.focus();
        } else {
            createAuthenticationNumber()
            .then((res) => {
                let result = res.data.createAuthenticationNumber;

                if(result === "SUCCESS"){
                    alert("메일을 발송했습니다.");
                } else {
                    navigate('/error');
                }
            });
        }
    }

    const onCheckAuthenticationNumber = () => {
        if(emailAddress === ""){
            inputEmailRef.current.focus();
        } else if(authenticationNumber === "") {
            inputAuthenticationNumberRef.current.focus();
        } else {
            confirmAuthenticationNumber()
            .then((res) => {
                let result = res.data.confirmAuthenticationNumber;
                let confirmResult = res.data.emailAuthentication;

                if(result === "SUCCESS"){
                    if(confirmResult === "CONFIRMED"){
                        alert("인증되었습니다.");
                        setAuthenticationFlag(true);

                        setValidConfirmEmail(false);
                        setMessageEmailAuthentication("");
                    } else if(confirmResult === "DENIED") {
                        alert("인증번호가 다릅니다.");
                    } else if(confirmResult === "EMPTY") {
                        alert("인증번호를 먼저 발급해주세요.");
                    } else {
                        navigate('/error');
                    }
                } else {
                    navigate('/error');
                }
            });
        }
    }

    const onJoin = () => {
        selectUserCountById()
        .then((res) => {
            let numberOfDuplicateId = res.data.selectUserCountById;

            if(validationCheck(numberOfDuplicateId)){
                insertUser()
                .then((res) => {
                    let result = res.data.insertUser;

                    if(result === "Success"){
                        navigate('/login');
                        alert("회원가입이 완료되었습니다.");
                    } else if(result === "Duplicate") {
                        alert("탈퇴 후 6개월간 동일한 이메일로 재가입이 불가능합니다.\n(관리자에게 문의 바랍니다.)");
                    } else {
                        navigate('/error');
                    }
                });
            }
        });
    };

    const validationReset = () => {
        setValidMemberLoginId(false);
        setValidEncContent(false);
        setValidMemberName(false);
        setValidNickName(false);
        setValidBirthYmd(false);
        setValidGender(false);
        setValidEmailAddress(false);
        // setValidMobilePhone(false);
        setValidConfirmEmail(false);

        setMessageMemberLoginId("");
        setMessageEncContent("");
        setMessageMemberName("");
        setMessageNickName("");
        setMessageBirthYmd("");
        setMessageGender("");
        setMessageEmailAddress("");
        // setMessageMobilePhone("");
        setMessageEmailAuthentication("");
    }

    const validationCheck = (numberOfDuplicateId) => {
        validationReset();
        let checkResult = true;

        if(memberLoginId === ""){
            setValidMemberLoginId(true);
            setMessageMemberLoginId("∙ 아이디: 필수 정보입니다.");
            checkResult = false;
        } else {
            if(numberOfDuplicateId > 0){
                setValidMemberLoginId(true);
                setMessageMemberLoginId("∙ 아이디: 사용하실 수 없는 아이디 입니다. (중복)");
                checkResult = false;
            }

            // 아이디: 영문 소문자로 시작하는 5~20자의 영문 소문자, 숫자, 특수문자(-, _)
            const idRegex = /^[a-z]+[a-z0-9_-]{4,19}$/;

            if(!idRegex.test(memberLoginId)){
                setValidMemberLoginId(true);
                setMessageMemberLoginId("∙ 아이디: 영문 소문자로 시작하는 5~20자의 영문 소문자, 숫자, 특수문자(-, _) 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(encContent === ""){
            setValidEncContent(true);
            setMessageEncContent("∙ 비밀번호: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 비밀번호: 8~16자의 영문 소문자, 숫자, 특수문자
            const passwordRegex = /^(?=.*[a-z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

            if(!passwordRegex.test(encContent)){
                setValidEncContent(true);
                setMessageEncContent("∙ 비밀번호: 8~16자의 영문 소문자, 숫자, 특수문자 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(memberName === ""){
            setValidMemberName(true);
            setMessageMemberName("∙ 이름: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 이름: 2~12자의 한글, 영문 소문자
            const nameRegex = /^[a-z가-힣]{2,12}$/;

            if(!nameRegex.test(memberName)){
                setValidMemberName(true);
                setMessageMemberName("∙ 이름: 2~12자의 한글, 영문 소문자 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(nickName === ""){
            setValidNickName(true);
            setMessageNickName("∙ 닉네임: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 닉네임: 2~12자의 한글, 영문 소문자, 숫자
            const nickNameRegex = /^[a-z0-9가-힣]{2,12}$/;

            if(!nickNameRegex.test(nickName)){
                setValidNickName(true);
                setMessageNickName("∙ 닉네임: 2~12자의 한글, 영문 소문자, 숫자 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(birthYmd === ""){
            setValidBirthYmd(true);
            setMessageBirthYmd("∙ 생년월일: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 생년월일: 날짜 형식 체크
            const dateRegex = /^(19[0-9][0-9]|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

            if(!dateRegex.test(birthYmd)){
                setValidBirthYmd(true);
                setMessageBirthYmd("∙ 생년월일: 올바른 생년월일인지 확인해주세요.");
                checkResult = false;
            }

            // 생년월일: 8자의 숫자
            const birthRegex = /^[0-9]{8}$/;

            if(!birthRegex.test(birthYmd)){
                setValidBirthYmd(true);
                setMessageBirthYmd("∙ 생년월일: 8자의 숫자로 입력해주세요.");
                checkResult = false;
            }
        }

        if(gender === ""){
            setValidGender(true);
            setMessageGender("∙ 성별: 필수 정보입니다.");
            checkResult = false;
        }

        if(emailAddress === ""){
            setValidEmailAddress(true);
            setMessageEmailAddress("∙ 이메일: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 이메일: 이메일 형식 체크
            const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

            if(!emailRegex.test(emailAddress)){
                setValidEmailAddress(true);
                setMessageEmailAddress("∙ 이메일: 올바른 이메일인지 확인해주세요.");
                checkResult = false;
            }
        }

        if(authenticationFlag === false){
            setValidConfirmEmail(true);
            setMessageEmailAuthentication("∙ 이메일: 인증번호 입력 및 확인절차를 진행해주세요.");
            checkResult = false;
        }

        // if(mobilePhone === ""){
        //     setValidMobilePhone(true);
        //     setMessageMobilePhone("∙ 휴대폰: 필수 정보입니다.");
        //     checkResult = false;
        // } else {
        //     // 휴대폰: 휴대폰 번호 형식 체크
        //     const mobileRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        //     if(!mobileRegex.test(mobilePhone)){
        //         setValidMobilePhone(true);
        //         setMessageMobilePhone("∙ 휴대폰: 올바른 휴대폰 번호인지 확인해주세요.");
        //         checkResult = false;
        //     }
        // }

        return checkResult;
    }

    const saveMemberLoginId = event => {
        setMemberLoginId(event.target.value.trim());
    };

    const saveEncContent = event => {
        setEncContent(event.target.value.trim());
    };

    const saveMemberName = event => {
        setMemberName(event.target.value.trim());
    };

    const saveNickName = event => {
        setNickName(event.target.value.trim());
    };

    const saveBirthYmd = event => {
        setBirthYmd(event.target.value.trim());
    };

    const saveGender = event => {
        setGender(event.target.value);
    };

    const saveEmailAddress = event => {
        setEmailAddress(event.target.value.trim());
    };

    const saveAuthenticationNumber = event => {
        setAuthenticationNumber(event.target.value.trim());
    };

    // const saveMobilePhone = event => {
    //     setMobilePhone(event.target.value.trim());
    // };

    const saveFavTeamCode = event => {
        setFavTeamCode(event.target.value);
    };

    const checkSpacebar = event => {
        if(event.keyCode === 32){
            event.preventDefault();
        }
    }

    const insertUser = () =>
    new Promise((resolve) => {
        let paramFavTeamCode = favTeamCode;
        if(paramFavTeamCode === "9999") paramFavTeamCode = 0;

        let policyId = userPolicy.policyId;

        // let paramMobilePhone = mobilePhone.replace(/-/g,'');

        const params = {
            memberLoginId: memberLoginId,
            encContent: encContent,
            memberName: memberName,
            nickName: nickName,
            birthYmd: birthYmd,
            sex: gender,
            emailAddress: emailAddress,
            // mobilePhone: paramMobilePhone,
            favTeamCode: paramFavTeamCode,
            policyId: policyId,
            policyAgreeYn: policyAgreeYn
        };

        axios.post(`${process.env.REACT_APP_API_URL}/member/insertUser`, params).then((Response)=>{
            // console.log(Response);

            resolve(Response);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    const createAuthenticationNumber = () =>
    new Promise((resolve) => {
        const params = {
            emailAddress: emailAddress
        };

        axios.post(`${process.env.REACT_APP_API_URL}/member/createAuthenticationNumber`, params).then((Response)=>{
            // console.log(Response);

            resolve(Response);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    const confirmAuthenticationNumber = () =>
    new Promise((resolve) => {
        const params = {
            emailAddress: emailAddress,
            authenticationNumber: authenticationNumber,
            authenticationNumberType: "joinKey"
        };

        axios.post(`${process.env.REACT_APP_API_URL}/member/confirmAuthenticationNumber`, params).then((Response)=>{
            // console.log(Response);

            resolve(Response);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    const selectUserCountById = () =>
    new Promise((resolve) => {
        const params = {
            memberLoginId: memberLoginId
        };

        axios.post(`${process.env.REACT_APP_API_URL}/member/selectUserCountById`, params).then((Response)=>{
            // console.log(Response);

            resolve(Response);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    return (
        policyAgreeYn === "Y"
        ?
        <div className="container">
            <div id="joinBox">
                <form>
                    <div>
                        <div className="label">
                            아이디
                        </div>
                        <input type="text" className={validMemberLoginId ? "warning" : ""}
                            name="memberLoginId" value={memberLoginId} maxLength="20" onChange={saveMemberLoginId} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            비밀번호
                        </div>
                        <input type="password" className={validEncContent ? "warning" : ""}
                            name="encContent" value={encContent} maxLength="16" onChange={saveEncContent} onKeyDown={checkSpacebar} autoComplete="off" />
                    </div>
                    <div>
                        <div className="label">
                            이름
                        </div>
                        <input type="text" className={validMemberName ? "warning" : ""}
                            name="memberName" value={memberName} maxLength="12" onChange={saveMemberName} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            닉네임
                        </div>
                        <input type="text" className={validNickName ? "warning" : ""}
                            name="nickName" value={nickName} maxLength="12" onChange={saveNickName} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            생년월일
                        </div>
                        <input type="text" className={validBirthYmd ? "warning" : ""}
                            name="birthYmd" placeholder="생년월일 8자리" value={birthYmd} maxLength="8" onChange={saveBirthYmd} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            성별
                        </div>
                        <div id="radioWrap" className={validGender ? "warning" : ""}>
                            <label>
                                <input type="radio" name="gender" value="남" onChange={saveGender} checked={gender === "남"} />
                                남
                            </label>
                            <label>
                                <input type="radio" name="gender" value="여" onChange={saveGender} />
                                여
                            </label>
                            <label>
                                <input type="radio" name="gender" value="선택안함" onChange={saveGender} />
                                선택안함
                            </label>
                        </div>
                    </div>
                    <div>
                        <div className="label">
                            이메일
                        </div>
                        <input type="text" className={validEmailAddress ? "warning" : ""} ref={inputEmailRef}
                            name="emailAddress2" value={emailAddress} onChange={saveEmailAddress} onKeyDown={checkSpacebar} />

                        <button type="button" id="checkEmailBtn" onClick={onCheckEmail}>
                            이메일 인증
                        </button>
                    </div>
                    <div>
                        <div className="label">
                            이메일<br />인증번호
                        </div>
                        <input type="password" className={validConfirmEmail ? "warning" : ""}  ref={inputAuthenticationNumberRef}
                            name="authenticationNumber" value={authenticationNumber} onChange={saveAuthenticationNumber} onKeyDown={checkSpacebar} />

                        <button type="button" id="checkAuthenticationNumberBtn" onClick={onCheckAuthenticationNumber}>
                            확인
                        </button>
                    </div>
                    {/* <div>
                        <div className="label">
                            휴대폰
                        </div>
                        <input type="text" className={validMobilePhone ? "warning" : ""}
                            name="mobilePhone" value={mobilePhone} onChange={saveMobilePhone} onKeyDown={checkSpacebar} />
                    </div> */}
                    <div id="warningMessage">
                        <div>
                            {messageMemberLoginId}
                        </div>
                        <div>
                            {messageEncContent}
                        </div>
                        <div>
                            {messageMemberName}
                        </div>
                        <div>
                            {messageNickName}
                        </div>
                        <div>
                            {messageBirthYmd}
                        </div>
                        <div>
                            {messageGender}
                        </div>
                        <div>
                            {messageEmailAddress}
                        </div>
                        <div>
                            {messageEmailAuthentication}
                        </div>
                        {/* <div>
                            {messageMobilePhone}
                        </div> */}
                    </div>
                    <div id="favTeamLabel">
                        <div className="label">
                            응원팀
                        </div>
                        <div id="favTeamWrap">
                            <div id="favTeamNone">
                                <div>
                                    <input type="radio" name="favTeamCode" value="9999" onChange={saveFavTeamCode} checked={favTeamCode === "9999"} />
                                    없음
                                </div>
                                <div>
                                    <img src={user_icon} alt="userIcon" id="uesrIcon" />
                                </div>
                            </div>
                            <div id="favTeamList">
                                {
                                _.map(favTeams, (item, idx) => (
                                    <RadioFavTeam key={idx} teamCode={item.teamCode} teamName={item.teamName} action={saveFavTeamCode} />
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </form>

                <button type="button" id="joinBtn" onClick={onJoin}>
                    JOIN
                </button>
            </div>
        </div>
        :
        <div className="container">
            <div id="errorWrap">
                <h1>올바르지 않은 접근입니다.</h1>
            </div>
        </div>
    );
};

export default JoinPage;