import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGameBatterInningResultInfo, fetchGameList, fetchGamePitcherResultInfo, fetchGameRunnerInfo, fetchScoreBoardInfo } from '../../../utils/api';
import _ from 'lodash';
import { flatObject, loopFlatObject } from '../../../utils/flat';

function GameResultBox() {
    const params = useParams();
    const [gameInfo, setGameInfo] = useState({}); 

    useEffect(() => {
        const gameInfoId = params['gameInfoId'];

        const gameParam = {
            gameInfoIds: gameInfoId,
            gameTypeCodes: 4201
        }

        const gameInfoPromise = fetchGameList(gameParam);
        const scoreboardPromise = fetchScoreBoardInfo(gameParam);
        const runnerResultInfoPromise = fetchGameRunnerInfo(gameParam);
        const batterResultByInningPromise = fetchGameBatterInningResultInfo(gameParam);

        Promise.all([
            gameInfoPromise,
            scoreboardPromise,
            batterResultByInningPromise,
            runnerResultInfoPromise
        ]).then((response) => {
            const gameInfo = response[0]['data']['RECORD'][0];
            const hitResult = _.keyBy(response[1]['data']['HITRESULT'], 'batterTeamId');
            const errorInfo = _.keyBy(response[1]['data']['ERROR'], 'HOME_OR_AWAY');
            const runnerInfo = _.groupBy(response[3]['data']['RECORD'], 'inningTb'); // 0 | 1

            const batterInfo = _.groupBy(loopFlatObject(response[2]['data']['RECORD']), 'hitResultCode');
            const homerunTeamInfo = _.groupBy(batterInfo['6613'], 'batter_batterTeamId');

            const {awayTeamId, homeTeamId} = gameInfo;

            gameInfo['awayTeamHit'] = hitResult[awayTeamId]['hit'];
            gameInfo['awayTeamBb'] = hitResult[awayTeamId]['bb'];
            gameInfo['awayTeamHr'] = homerunTeamInfo[awayTeamId] ? homerunTeamInfo[awayTeamId].length : 0;
            gameInfo['awayTeamError'] = (errorInfo['AWAY']) ? errorInfo['AWAY']['error'] : 0;
            gameInfo['awayTeamRunner'] = (runnerInfo[0]) ? runnerInfo[0].length : 0;

            gameInfo['homeTeamHit'] = hitResult[homeTeamId]['hit'];
            gameInfo['homeTeamBb'] = hitResult[homeTeamId]['bb'];
            gameInfo['homeTeamHr'] = homerunTeamInfo[homeTeamId] ? homerunTeamInfo[homeTeamId].length : 0;
            gameInfo['homeTeamError'] = (errorInfo['HOME']) ? errorInfo['HOME']['error'] : 0;
            gameInfo['homeTeamRunner'] = (runnerInfo[1]) ? runnerInfo[1].length : 0;

            setGameInfo(gameInfo);
        })
    }, [])

    return (
    <>
        <table id="game_result_table">
        <thead>
            <tr>
                <th>{gameInfo['awayTeamName']}</th>
                <th>VS</th>
                <th>{gameInfo['homeTeamName']}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{gameInfo['awayTeamHit']}</td>
                <td>안타</td>
                <td>{gameInfo['homeTeamHit']}</td>
            </tr>
            <tr>
                <td>{gameInfo['awayTeamHr']}</td>
                <td>홈런</td>
                <td>{gameInfo['homeTeamHr']}</td>
            </tr>
            <tr>
                <td>{gameInfo['awayTeamRunner']}</td>
                <td>도루</td>
                <td>{gameInfo['homeTeamRunner']}</td>
            </tr>
            <tr>
                <td>{gameInfo['awayTeamBb']}</td>
                <td>4사구</td>
                <td>{gameInfo['homeTeamBb']}</td>
            </tr>
            <tr>
                <td>{gameInfo['awayTeamError']}</td>
                <td>실책</td>
                <td>{gameInfo['homeTeamError']}</td>
            </tr>
        </tbody>
        </table>
    </>
    )
}

export default GameResultBox;