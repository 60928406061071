import React, { useEffect, useRef, useState } from 'react';
import arrow_blue from '../../../assets/image/common/arrow_blue.png';
import arrow_white from '../../../assets/image/common/arrow_white.png';
import arrow_down from '../../../assets/image/common/arrow_down_blue.png';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { game_day_atom, game_info_id_atom, game_info_obj_atom } from '../../../assets/model/detail/store';
import moment from 'moment';
import { fetchGameList } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function HomeScheduleGameBox() {
    const gameDay = useAtomValue(game_day_atom);
    const [gameList, setGameList] = useState([]);
    const [gameInfoId, setGameInfoId] = useAtom(game_info_id_atom);
    const setGameInfoObj = useSetAtom(game_info_obj_atom);
    const navigate = useNavigate();

    const [isDrag, setIsDrag] = useState(false); //드레그 중인지의 상태확인
    const [startX, setStartX] = useState(); //처음 클릭한 x좌표 

    const [currentIndex, setCurrentIndex] = useState(0);
    const [indexNumber, setIndexNumber] = useState(0);
    const [totalGameList, setTotalGameList] = useState([]);
    let divBox = useRef();
    let prevRef = useRef();
    let nextRef = useRef();

    useEffect(() => {
        const gameDaySeason = moment(gameDay).format('YYYY');
        const gameDayString = moment(gameDay).format('YYYY-MM-DD');
        fetchGameList(
            {
                gameDay: gameDayString,
                gameTypeCodes: 4201,
                seasons: gameDaySeason
            }
        ).then((response) => {
            const newGameList = _.sortBy(response['data']['RECORD'], ['gameStatus']);

            if(newGameList.length > 0) {
                setGameInfoId(newGameList[0]['gameInfoId']);
                setGameInfoObj(newGameList[0]);

                setGameList(newGameList);

                // if(newGameList.length > 5) {
                //     setIndexNumber(1);
                //     setGameList(newGameList.slice(0, 5));
                // } else {
                //     setIndexNumber(0);
                //     setGameList(newGameList);
                // }

                // setCurrentIndex(0);
                // setTotalGameList(newGameList);
            }
        })
    }, [gameDay]);

    const gameSummaryClickHandler = (e) => {
        const id = e.currentTarget.id;
        const selectGameInfoObj = gameList.find((item) => item['gameInfoId'] === id);
        setGameInfoId(id);
        setGameInfoObj(selectGameInfoObj);
    }

    const gameDetailClickHandler = (e) => {
        const id = e.currentTarget.id;
        const selectGameInfoObj = gameList.find((item) => item['gameInfoId'] === id);
        const selectedGameInfoId = selectGameInfoObj['gameInfoId'];
        const selectedDay = selectGameInfoObj['gameDay'].split('-').join("");
        navigate(`/todaygame/${selectedDay}/${selectedGameInfoId}`);
    }

    const gameResultClickHandler = (e) => {
        const id = e.currentTarget.id;
        navigate(`/gameschedule/result/${id}`);
    }

    const onDragStart = e => {
        e.preventDefault();
        setIsDrag(true);
        setStartX(e.pageX + divBox.current.scrollLeft);
    };

    const onDragEnd = () => {
        setIsDrag(false);
    };

    const throttle = (func, ms) => {
        let throttled = false;
        return (...args) => {
            if (!throttled) {
                throttled = true;
                setTimeout(() => {
                    func(...args);
                    throttled = false;
                }, ms);
            }
        };
    };

    const onDragMove = e => {
        if (isDrag) {
            const { scrollWidth, clientWidth, scrollLeft } = divBox.current;
    
            divBox.current.scrollLeft = startX - e.pageX;
          
            if (scrollLeft === 0) {
                setStartX(e.pageX); //가장 왼쪽일 때, 움직이고 있는 마우스의 x좌표가 곧 startX로 설정.
            } else if (scrollWidth <= clientWidth + scrollLeft) {
                setStartX(e.pageX + scrollLeft); //가장 오른쪽일 때, 움직이고 있는 마우스의 x좌표에 현재 스크롤된 길이 scrollLeft의 합으로 설정
            }
        }
    };

    const delay = 10;
    const onThrottleDragMove = throttle(onDragMove, delay);

    return (
    <>
    {
        gameList.length !== 0 ?
        <>
            <div id="schedule_game_list"
            ref={divBox}
            onMouseDown={onDragStart}
            onMouseMove={isDrag ? onThrottleDragMove : null}
            onMouseUp={onDragEnd}
            onMouseLeave={onDragEnd}
            >
            {
                gameList.map((item) => (
                item['gameStatus']*1 != 4199
                ?
                <div key={item['gameInfoId']} className={'item '+ ((gameInfoId === item['gameInfoId']) ? 'active' : '')}>
                    <div className="body">
                        <div className="top">
                            <div className="circle_left">
                                {item['stadiumsName'] || '미정'}
                            </div>
                            <div className="circle_right">
                                { item['gamesStime'] ? item['gamesStime'].slice(0,5) : '00:00'}
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="team_away">
                                <img src={require(`/src/assets/image/common/team_logo/logo_${item['awayTeamId']}.png`)}
                                alt={item['awayTeamName']}/>
                                <div className="sp">{item['awayStartPitcherName'] || '선발'}</div>
                            </div>
                            <div style={{marginTop: '15px'}}>
                                {
                                    item['ingStatus'] === "PRE" ?
                                    <div className="game_status">경기전</div>
                                    :
                                    <div className="vs_score">
                                        {item['awayTeamScore'] || 0} : {item['homeTeamScore'] || 0}
                                        <br/>
                                    </div>
                                }
                                <div className="double_header">
                                {
                                    item['doubleHeaderYn'] !== 'N'
                                    ? 'DH'+item['doubleHeaderYn']
                                    : <br/>
                                }
                                </div>
                            </div>
                            <div className="team_home">
                                <img src={require(`/src/assets/image/common/team_logo/logo_${item['homeTeamId']}.png`)}
                                alt={item['homeTeamName']}/>
                                <div className="sp">{item['homeStartPitcherName'] || '선발'}</div>
                            </div>
                        </div>
                    </div>
                    {
                    item['ingStatus'] === 'DONE' ?
                    <div className="triple_btn">
                        <div className="summary" onClick={gameSummaryClickHandler} id={item['gameInfoId']}>
                            <span>요약</span>
                            <img src={arrow_blue} alt="arrow_blue"/>
                        </div>
                        <div className="detail" onClick={gameDetailClickHandler} id={item['gameInfoId']}>
                            <span>자세히</span>
                            <img src={arrow_white} alt="arrow_white"/>
                        </div>
                        <div className="summary" onClick={gameResultClickHandler} id={item['gameInfoId']}>
                            <span>결과</span>
                            <img src={arrow_blue} alt="arrow_white"/>
                        </div>
                    </div>
                    :
                    <div className="btn">
                        <div className="summary" onClick={gameSummaryClickHandler} id={item['gameInfoId']}>
                            <span>요약</span>
                            <img src={arrow_blue} alt="arrow_blue"/>
                        </div>
                        <div className="detail" onClick={gameDetailClickHandler} id={item['gameInfoId']}>
                            <span>자세히</span>
                            <img src={arrow_white} alt="arrow_white"/>
                        </div>
                    </div>
                    }
                </div>
                :
                <div key={item['gameInfoId']} className={'item '+ ((gameInfoId === item['gameInfoId']) ? 'active' : '')}>
                    <div className="body">
                        <div className="top">
                            <div className="circle_left">
                                {item['stadiumsName'] || '미정'}
                            </div>
                            <div className="circle_right">
                                { item['gamesStime'] ? item['gamesStime'].slice(0,5) : '00:00'}
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="team_away">
                                <img src={require(`/src/assets/image/common/team_logo/logo_${item['awayTeamId']}.png`)}
                                alt={item['awayTeamName']}/>
                                <div className="sp">{item['awayStartPitcherName'] || '선발'}</div>
                            </div>
                            <div className="game_cancel">우천취소</div>
                            <div className="team_home">
                                <img src={require(`/src/assets/image/common/team_logo/logo_${item['homeTeamId']}.png`)}
                                alt={item['homeTeamName']}/>
                                <div className="sp">{item['homeStartPitcherName'] || '선발'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="full_btn">
                        <div className="summary" onClick={gameSummaryClickHandler} id={item['gameInfoId']}>
                            <span>요약</span>
                            <img src={arrow_blue} alt="arrow_blue"/>
                        </div>
                    </div>
                </div>
                ))
            }
            </div>
        </>
        :
        <div>데이터없음</div>
    }
    </>
    )
}

export default HomeScheduleGameBox;