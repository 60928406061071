import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PlayerBattingRecord from '../../../layout/record/player/player-batting-record';
import PlayerPitchingRecord from '../../../layout/record/player/player-pitching-record';
import PlayerFieldingRecord from '../../../layout/record/player/player-fielding-record';
import { useSetAtom } from 'jotai';
import { player_batting_filter_atom, player_fielding_filter_atom, player_pitching_filter_atom } from '../../../assets/model/detail/store';

function PlayerRecordMain() {
    const navigate = useNavigate();
    const params = useParams();
    const subId = params['subId'];
    const setBattingFilterObj = useSetAtom(player_batting_filter_atom);
    const setPitchingFilterObj = useSetAtom(player_pitching_filter_atom);
    const setFieldingFilterObj = useSetAtom(player_fielding_filter_atom);

    useEffect(() => {
        return () => {
            if(subId === 'batting') {
                localStorage.removeItem('playerBattingFilter');
                setBattingFilterObj({})
            } else if(subId === 'pitching'){
                localStorage.removeItem('playerPitchingFilter');
                setPitchingFilterObj({})
            } else if(subId === 'fielding') {
                localStorage.removeItem('playerFieldingFilter');
                setFieldingFilterObj({})
            }
        }
    })

    const PlayerRecordTabContent = function() {
        switch(subId) {
            case 'batting':
                return <PlayerBattingRecord/>;
            case 'pitching':
                return <PlayerPitchingRecord/>;
            case 'fielding':
                return <PlayerFieldingRecord/>;
            default:
                return <></>;
        }
    }

    return (
    <>
    <div className="container">
        <div id="record_tab_container">
            <div id="record_tab_area">
                <div 
                className={"btn "+((subId === "batting") && "active")}
                onClick={() => navigate('/record/player/batting/basic')}
                >타격</div>
                <div
                className={"btn "+((subId === "pitching") && "active")}
                onClick={() => navigate('/record/player/pitching/basic')}
                >투구</div>
                <div
                className={"btn "+((subId === "fielding") && "active")}
                onClick={() => navigate('/record/player/fielding/basic')}
                >수비</div>
            </div>
        </div>
        <div id="record_main_container">
            <PlayerRecordTabContent/>
        </div>
    </div>
    </>
    )
}

export default PlayerRecordMain