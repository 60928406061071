function pitcherTooltip() {
    let tooltipObj = {};

    tooltipObj.tpa = '타석 = 타수 + 볼넷 + 사구 + 희생플라이 + 희생번트';
    tooltipObj.ab = '타수';
    tooltipObj.b1b = '1루타';
    tooltipObj.hr = '피홈런';
    tooltipObj.so = '삼진';
    tooltipObj.totalBb = '볼넷';
    tooltipObj.rbi = '타점';
    tooltipObj.stealCnt = '도루 성공';
    tooltipObj.failStealCnt = '도루 실패';
    tooltipObj.avg = '타율 = 안타 / 타수';
    tooltipObj.slg = '장타율 = {1루타 + (2*2루타) + (3*3루타) + (4*홈런)} / 타수';
    tooltipObj.obp = '출루율 = (안타 + 볼넷 + 사구) / (타수 + 볼넷 + 사구 + 희생 플라이)';
    tooltipObj.ops = 'OPS = 출루율 + 장타율';
    tooltipObj.kRate = '삼진비율 = 삼진 / 타석';
    tooltipObj.bbRate = '볼넷비율 = 볼넷 / 타석';

    tooltipObj.swingRate = '스윙비율 = (s스윙 + b스윙) / 투구수';
    tooltipObj.contactRate = '컨택비율 = (s컨텍 + b컨텍) / 투구수';
    tooltipObj.standRate = '스탠딩비율 = (s스탠드 + b스탠드) / 투구수';
    tooltipObj.tpaDivPitch = "타석 당 투구수 = 투구수 / 타석 "

/*****************   투수 기본  *******************/
    tooltipObj.gameCnt = "출전"
    tooltipObj.starter = "선발"
    tooltipObj.relief = "구원"
    tooltipObj.cg = "완투"
    tooltipObj.win = "승"
    tooltipObj.lose = "패"
    tooltipObj.save = "세이브"
    tooltipObj.blown = "블론 = 7이닝 3점차 이내에서 구원 등판하여 역전 당한 경우"
    tooltipObj.ip = "이닝"
    tooltipObj.pitchingCnt = "총 투구수"
    tooltipObj.r = "실점"
    tooltipObj.er  = "자책점"
    tooltipObj.tpa  = "타석"
    tooltipObj.ab  = "타수"
    tooltipObj.hit  = "안타"
    tooltipObj.b2b  = "2루타"
    tooltipObj.b3b  = "3루타"
    tooltipObj.hr  = "홈런"
    tooltipObj.xh  = "장타"
    tooltipObj.so  = "삼진"
    tooltipObj.lookingSoRate = '루킹 삼진율 = 루킹 삼진 / 삼진';
    tooltipObj.swingSoRate = '스윙 삼진율 = 스윙 삼진 / 삼진';
    tooltipObj.lookingSo = '루킹 삼진';
    tooltipObj.swingSo= '스윙 삼진';
    tooltipObj.bb  = "볼넷"
    tooltipObj.bbAddIbb  = "볼넷 (고의4구 포함)"
    tooltipObj.ibb  = "고의4구"
    tooltipObj.hbp = "몸에 맞는 볼"
    tooltipObj.sf = '희비';
    tooltipObj.sb = '희타';
    tooltipObj.winRate = "승률"
    tooltipObj.era = "평균 자책점"
    tooltipObj.fip = "FIP = ((13 * 홈런) + (3 * (볼넷 + 몸에 맞는볼))-( 2 * 삼진)) / 이닝 + FIP constant"
    tooltipObj.wpa = '투구 이벤트에서 발생한 WE 합';

    /**********************투수 상세****************************/
    tooltipObj.eraPlus = "파크 팩터를 적용한 ERA / 리그 ERA"
    tooltipObj.fipPlus = "파크 팩터를 적용한 FIP / 리그 FIP"
    tooltipObj.eraSubFip = "ERA - FIP"
    tooltipObj.hr9 = "9이닝당 홈런 허용 = (홈런 * 9) / 이닝"
    tooltipObj.hit9 = "9이닝당 안타 허용 = ((1루타+2루타+3루타+홈런) * 9) / 이닝"
    tooltipObj.bb9 = "9이닝당 볼넷 허용 = (볼넷 * 9) / 이닝" 
    tooltipObj.k9 = "9이닝당 삼진 허용 = (삼진 * 9) / 이닝" 
    tooltipObj.kBbRate = "K/BB"
    tooltipObj.kRate = "삼진비율 = 삼진 / 타석"
    tooltipObj.bbRate = "볼넷비율 = 볼넷 / 타석"
    tooltipObj.whip = "이닝당 출루 허용율 = (볼넷+피안타) / 이닝"
    tooltipObj.babip = "인플레이 타구 비율 = (안타-홈런)/(타수-삼진-홈런+희비) "
    tooltipObj.pDivip = "이닝당 평균 투구수 = 투구수 / 이닝"
    tooltipObj.gDivIp = "게임당 평균 이닝 = 이닝 / 출전"

    /**********************투수 타석**************************/

    tooltipObj.pitchingCnt = "총 투구수";
    tooltipObj.pDivPa = "타석당 투구수 = 전체 투구 / 타석"
    tooltipObj.strikeRate = "투구 중 스트라이크 비율 =  스트라이크 / 전체 투구"
    tooltipObj.ballRate = "투구중 볼 비율 = 볼 / 투구수"
    tooltipObj.zoneStrikeRate = "투구 중 스트라이크존 투구 비율 =  스트라이크존 투구 / 전체 투구"
    tooltipObj.zoneBallRate = "투구중 볼존 비율 = 볼존 투구 / 전체 투구"
    tooltipObj.strikeStandRate = "스트라이크 판정 투구 중 스탠딩 비율 = 스트라이크 스탠딩 / 스트라이크 판정 투구"
    tooltipObj.strikeSwingRate = "스트라이크 판정 투구 중 스윙 비율 = 스트라이크 스윙 / 스트라이크 판정 투구"
    tooltipObj.strikeFoulRate = "스트라이크 판정 투구 중 파울 비율 = 스트라이크 파울 / 스트라이크 판정 투구"
    tooltipObj.strikeInplayRate = "스트라이크 판정 투구 중 인플레이 비율 = 스트라이크 인플레이 / 스트라이크 판정 투구"
    tooltipObj.whiff = "전체 스윙 중 헛스윙 비율 = 헛스윙 / 전체 스윙"
    tooltipObj.swStr = "전체 투구 중 헛스윙 비율 = 헛스윙 / 전체 투구"
    tooltipObj.oStandRate = "아웃존 투구 중 스탠딩 비율 = 아웃존 스탠딩 / 아웃존 투구"
    tooltipObj.zStandRate = "스트라이크존 투구 중 스탠딩 비율 = 스트라이크존 스탠딩 / 스트라이크존 투구"
    tooltipObj.standRate = "전체 투구 중 스탠딩 비율 = 스탠딩 / 전체 투구"
    tooltipObj.oSwingRate = "아웃존 투구 중 스윙 비율 = 아웃존 스윙 / 아웃존 투구"
    tooltipObj.zSwingRate = "스트라이크존 투구 중 스윙 비율 = 스트라이크존 스윙 / 스트라이크존 투구"
    tooltipObj.swingRate = "전체 투구 중 스윙 비율 = 스윙 / 전체 투구"
    tooltipObj.oContactRate = "아웃존 스윙 중 컨택 비율 = 아웃존 컨택 / 아웃존 스윙"
    tooltipObj.zContactRate = "스트라이크존 스윙 중 컨택 비율 = 스트라이크존 컨택 / 스트라이크존 스윙"
    tooltipObj.contactRate = "전체 스윙 중 컨택 비율 = 컨택 / 전체 스윙"
    // tooltipObj.zoneRate = "스트라이크존 투구율 = 스트라이크존 투구 / 전체 투구"
    // tooltipObj.firstStrikeRate = "초구 스트라이크 비율 = 스트라이크 판정 초구/ 전체 초구"
    



    /**********************투수 타구**************************/        

    tooltipObj.go = "땅볼 아웃"
    tooltipObj.fo = "플라이 아웃 + 라인드라이브 아웃"
    tooltipObj.groundBall = "땅볼 타구"
    tooltipObj.flyBall = "플라이 타구"
    tooltipObj.lineDriveBall = "라인드라이 타구"
    tooltipObj.goDivFo = "땅볼 아웃 플라이 아웃 비율 = 땅볼 아웃 / (뜬공 아웃 + 라인드라이브 아웃)"
    tooltipObj.gdDivFly = "땅볼 플라이 비율 = 땅볼 / (뜬공 + 라인드라이브)"
    tooltipObj.gdRate = "타구 중 땅볼 비율 = 땅볼 / (땅볼 + 뜬공 + 라인드라이브)"
    tooltipObj.flyRate = "타구 중 뜬공 비율 = 뜬공 / (땅볼 + 뜬공 + 라인드라이브)"
    tooltipObj.lineRate = "타구 중 라인드라이브 비율 = 라인드라이브 / (땅볼 + 뜬공 + 라인드라이브)"
    tooltipObj.rankArate = "랭크 타구 중 A타구 비율 =  랭크 A / (랭크A + 랭크B + 랭크C)"
    tooltipObj.rankBrate = "랭크 타구 중 B타구 비율 =  랭크 B / (랭크A + 랭크B + 랭크C)"
    tooltipObj.rankCrate = "랭크 타구 중 C타구 비율 =  랭크 C / (랭크A + 랭크B + 랭크C)"

    /*********************투수 득점 생산력**************************/
    
    tooltipObj.re24Pit = '투구 이벤트에서 발생한 RE24 합';
    tooltipObj.wpa = '투구 이벤트에서 발생한 WE 합';
    tooltipObj.wpaPlus = '투구 이벤트에서 발생한 WE 플러스 합';
    tooltipObj.wpaMinus = '투구 이벤트에서 발생한 WE 마이너스 합';
    tooltipObj.pLi = '타석당 평균 Leverage Index'
    tooltipObj.inLi = '등판시 평균 Leverage Index'
    tooltipObj.exLi = '강판시 평균 Leverage Index'
    tooltipObj.wpaLi = "매타석 WPA/LI 합"
    tooltipObj.clutch = "전체 WPA/pLI - WPA/LI값"
    tooltipObj.meltDown = "MeltDown = 구원 등판 후 WPA -0.06 이하 기록 경기 수"
    tooltipObj.shutDown = "ShutDown = 구원 등판 후 WPA 0.06 이상 기록 경기 수"
    
    /*********************투수 클러치**************************/
    tooltipObj.scoringPosTpa = "득점권 타석"
    tooltipObj.scoringPosAb = "득점권 타수"
    tooltipObj.scoringPosHit = "득점권 안타"
    tooltipObj.scoringPaRate = "득점권 타석 비율 = 득점권 타석 / 타석"
    tooltipObj.scoringAvg = "득점권 타율 = 특점권 안타 / 득점권 타수"
    tooltipObj.lobRate = "잔루 처리율 = (안타 + 볼넷 + 몸에 맞는볼 - 실점) / (안타 + 볼넷 + 몸에 맞는볼 - (1.4 * HR))"
    tooltipObj.gdpCnt = "병살 기회"
    tooltipObj.gdp = "병살"
    tooltipObj.gdpRate = "병살 성공율 = 병살 / 병살 기회"
    tooltipObj.scoringHitRate = "득점권 안타 비율 = 득점권 안타 / 득점권 타석"
    tooltipObj.clutchHit = "클러치 안타 = 득점권 안타 비율 - 안타 비율"
    tooltipObj.scoringHrRate = "득점권 홈런 비율 = 득점권 홈런 / 득점권 타석"
    tooltipObj.clutchHr = "클러치 홈런 = 득점권 홈런 비율 - 홈런 비율"
    tooltipObj.scoringSoRate = "득점권 삼진 비율 = 득점권 삼진 / 득점권 타석"
    tooltipObj.clutchK = "클러치 삼진 = 득점권 삼진 비율 - 삼진 비율"
    tooltipObj.scoringBbRate = "득점권 볼넷 비율 = 득점권 볼넷 / 득점권 타석"
    tooltipObj.clutchBb = "클러치 볼넷 = 득점권 볼넷 비율 - 볼넷 비율"


    /*********************투수 선발**************************/
    tooltipObj.starterWin = "선발 승"
    tooltipObj.starterLose = "선발 패"
    tooltipObj.cg = "완투"
    tooltipObj.shutOut = "완봉"
    tooltipObj.over5Inn = "5이닝+"
    tooltipObj.over6Inn = "6이닝+"
    tooltipObj.over7Inn = "7이닝+"
    tooltipObj.over8Inn = "8이닝+"
    tooltipObj.qs = '퀄리티 스타트 = 6이닝 이상 3자책점 이하';
    tooltipObj.qsRate = '퀄리티 스타트 달성율';
    tooltipObj.qs_p = '퀄리티 스타트+ = 7이닝 이상 3자책점 이하';
    tooltipObj.qsPlusRate = '퀄리티 스타트+ 달성율';
    tooltipObj.qk = "퀵후크"
    tooltipObj.tl = "퀄리티 스타트 달성 후 패"
    tooltipObj.cw = "퀄리티 스타트 달성 실패 후 승"
    tooltipObj.prs = "남겨둔 주자 중에 득점한 주자 수"
    tooltipObj.pr = "투수가 루상에 남겨둔 주자 수"
    tooltipObj.rs = "득점 지원"
    tooltipObj.rs9 = "9이닝당 득점 지원 = (득점*9) / 이닝"
    tooltipObj.bw = "구원이 승을 날린 경우"
    tooltipObj.bl = "팀이 패배를 막아준 경우"
    tooltipObj.gDivPitch = "게임당 투구수 = 투구수/ 게임"
    tooltipObj.teamWin = "등판 시 팀승"
    tooltipObj.teamLose = "등판 시 팀패"


    /*********************투수 구원**************************/
    tooltipObj.pitcherParkWAR = "파크 팩터를 적용한 구원 WAR"
    tooltipObj.ifFip = "FIP = ((13 * 홈런) + (3 * (볼넷 + 몸에 맞는볼))-( 2 * 삼진 + 인필드플라이)) / 이닝 + ifFIP constant"
    tooltipObj.reliefWin = "구원 승"
    tooltipObj.reliefLose = "구원 패"
    tooltipObj.over1nnSave = '1이닝+SV';
    tooltipObj.blown = '블론';
    tooltipObj.hold = '홀드';
    tooltipObj.ip = '이닝';
    tooltipObj.saveRate = '세이브/(세이브 + 블론)'
    tooltipObj.savePlusRate = '(세이브 + 홀드)/(세이브 + 홀드 + 블론)'
    tooltipObj.under1Inn = "한 이닝에만 투구한 경우"
    tooltipObj.under2Inn = "두 이닝에 걸쳐 투구한 경우"
    tooltipObj.under3Inn = "세 이닝에 걸쳐 투구한 경우"
    tooltipObj.over3Inn = "3이닝 이상 투구"
    tooltipObj.ip1Inn = "1이닝 이하 투구(아웃카운트)"
    tooltipObj.ip2Inn = "1이닝 초과 2이닝 이하 투구(아웃카운트)"
    tooltipObj.ip3Inn = "2이닝 초과 3이닝 이하 투구(아웃카운트)"
    tooltipObj.ip4Inn = "3이닝 초과 투구(아웃카운트)"
    tooltipObj.toughSave = '터프(득점 차가 주자수 보다 작은 상황에서 등판) 세이브';
    tooltipObj.toughHold = '터프(득점 차가 주자수 보다 작은 상황에서 등판) 홀드';
    tooltipObj.straightPitchDay2 = '2연투';
    tooltipObj.straightPitchDay3 = '3연투 이상';
        
    tooltipObj.toughBlown = '터프(득점 차가 주자수 보다 작은 상황에서 등판) 블론';
    tooltipObj.toughSaveRate = '터프(득점 차가 주자수 보다 작은 상황에서 등판) 세이브율 = 터프 세이브 / 터프 세이브 기회';
    tooltipObj.ir = "승계 주자"
    tooltipObj.ira ="승계 주자 득점"
    tooltipObj.irsPer = "승계 주자 허용율 = 승계 주자 득점 / 승계 주자"

    /*********************투수 견제 **************************/
    tooltipObj.checkBaseOut = "견제사"
    tooltipObj.balk = "보크"
    tooltipObj.wildPitch = "폭투"
    tooltipObj.passedBall = "포일"
    tooltipObj.starterWin = "도루"
    tooltipObj.failStealCnt = "도실"
    tooltipObj.stealRate = "도루 성공율 = 도루 / (도루 + 도실)"
    tooltipObj.base1StealCnt = "2루 도루"
    tooltipObj.base1FailStealCnt = "2루 도실"
    tooltipObj.base1StealRate = "2루 도루 성공율"
    tooltipObj.base2StealCnt = "3루 도루"
    tooltipObj.base2FailStealCnt = "3루 도실"
    tooltipObj.base2StealRate = "3루 도루 성공율"
    tooltipObj.base3StealCnt = "홈 도루"
    tooltipObj.base3FailStealCnt = "홈 도실"
    tooltipObj.base3StealRate = "홈 도루 성공율"

    /*********************투수 WAR **************************/
    tooltipObj.ifFipR9 = "ifFIP + Adjustment"
    tooltipObj.drpw = "1승당 득점 (dRPW) = ([([(18 – 이닝 / 게임)*(리그 FIPR9)] + [(이닝 / 게임) * FIPR9]) / 18] + 2)*1.5"
    tooltipObj.rAA = "리그 FIPR9 - FIPR9 "
    tooltipObj.parkRaa = "파크 팩터를 적용한 9이닝당 평균 실점 = 리그 FIPR9 - parkFIPR9 "
    //tooltipObj.parkRaa = "파크 팩터를 적용한 RAAp9 = FIPR9 / 파크팩터"
    tooltipObj.wAA = "평균 실점을 1승당 승리로 나눈 값 = RAAP9 / dRPW"
    tooltipObj.pitcherWAR = "Fangraphs WAR(전체)"
    tooltipObj.reliefPitcherWar = "Fangraphs WAR(구원)"
    tooltipObj.starterPitcherWar = "Fangraphs WAR(선발)"
    tooltipObj.accmPitcherWar = "투수 WAR 합계"
    tooltipObj.parkWaa = " 파크팩터를 적용한 WPGAA = pRAAP9 / dRPW"
    tooltipObj.pitcherParkWAR = " 파크팩터를 적용한 WAR = (pWPGAA + 대체 레벨) * (이닝 / 9) * Li.Multi(Leverage Index)"
    tooltipObj.replaceMentLevel = "대체 레벨 = 0.03*(1 – 선발 / 게임) + 0.12*(선발 / 게임) "
    tooltipObj.waaAdj = "등판 시 Leverage Index = (1 + gmLI) / 2"
    tooltipObj.hWar = "한화 이글스 WAR(전체)"
    tooltipObj.reliefHWar = "한화 이글스 WAR(구원)"
    tooltipObj.starterHWar = "한화 이글스 WAR(선발)"
    tooltipObj.bWar = "Baseball Reference WAR(전체)"
    tooltipObj.reliefBWar = "Baseball Reference WAR(구원)"
    tooltipObj.starterBWar = "Baseball Reference WAR(선발)"
    tooltipObj.totalWar = "(hWAR*0.5)+(fWAR*0.5)(전체)"
    tooltipObj.reliefTotalWar = "(hWAR*0.5)+(fWAR*0.5)(구원)"
    tooltipObj.starterTotalWar = "(hWAR*0.5)+(fWAR*0.5)(선발)"
    tooltipObj.squareX = "(((평균 이닝*경기당 득점)+((18-평균 이닝)*리그 득점)/18)⋀0.285"
    tooltipObj.runScored = "9이닝당 실점⋀X"
    tooltipObj.runAllowed = "리그 평균 9이닝당 실점⋀X"
    tooltipObj.ra9 = "9이닝당 실점"
    tooltipObj.winAboveAverage = "대체 선수 승률 대비 기대 승률"
    tooltipObj.woba = '(wBB*uBB + wHBP*HBP + w1H*1B + w2H*2B + w3H*3B + wHR*HR) / (AB + BB - IBB + SF + HBP)';
    tooltipObj.wRaa = '((리그 wOBA - wOBA)/wOBA Scale)*PA';
    tooltipObj.waa = 'Wins Per Game Above Average (WPGAA)'
    tooltipObj.correction = '투수 WAR 조정을 위한 상수'

        /******  투수 구종 *******/
    tooltipObj.totalPitch = "총 투구수"
    tooltipObj['fastBallValue'] = '직구 구종가치'; //
    tooltipObj['fastBallValueDivOneHundred'] = '직구 구종가치/100';
    tooltipObj['fastBallPitchRate'] = '직구 투구%';
    tooltipObj['fastBallCnt'] = '직구 투구수';
    tooltipObj['fastBallAvgSpeed'] = '직구 평균구속';
    tooltipObj['fastBallMaxSpeed'] = '직구 최고구속';

    tooltipObj['twoSeamValue'] = '투심 구종가치';
    tooltipObj['twoSeamValueDivOneHundred'] = '투심 구종가치/100';
    tooltipObj['twoSeamPitchRate'] = '투심 투구%';
    tooltipObj['twoSeamCnt'] = '투심 투구수';
    tooltipObj['twoSeamAvgSpeed'] = '투심 평균구속';
    tooltipObj['twoSeamMaxSpeed'] = '투심 최고구속';

    tooltipObj['cutterValue'] = '커터 구종가치';
    tooltipObj['cutterValueDivOneHundred'] = '커터 구종가치/100';
    tooltipObj['cutterPitchRate'] = '커터 투구%';
    tooltipObj['cutterCnt'] = '커터 투구수';
    tooltipObj['cutterAvgSpeed'] = '커터 평균구속';
    tooltipObj['cutterMaxSpeed'] = '커터 최고구속';

    tooltipObj['curveValue'] = '커브 구종가치';
    tooltipObj['curveValueDivOneHundred'] = '커브 구종가치/100';
    tooltipObj['curvePitchRate'] = '커브 투구%';
    tooltipObj['curveCnt'] = '커브 투구수';
    tooltipObj['curveAvgSpeed'] = '커브 평균구속';
    tooltipObj['curveMaxSpeed'] = '커브 최고구속';

    tooltipObj['sliderValue'] = '슬라이더 구종가치';
    tooltipObj['sliderValueDivOneHundred'] = '슬라이더 구종가치/100';
    tooltipObj['sliderPitchRate'] = '슬라이더 투구%';
    tooltipObj['sliderCnt'] = '슬라이더 투구수';
    tooltipObj['sliderAvgSpeed'] = '슬라이더 평균구속';
    tooltipObj['sliderMaxSpeed'] = '슬라이더 최고구속';

    tooltipObj['changeUpValue'] = '체인지업 구종가치';
    tooltipObj['changeUpValueDivOneHundred'] = '체인지업 구종가치/100';
    tooltipObj['changeUpPitchRate'] = '체인지업 투구%';
    tooltipObj['changeUpCnt'] = '체인지업 투구수';
    tooltipObj['changeUpAvgSpeed'] = '체인지업 평균구속';
    tooltipObj['changeUpMaxSpeed'] = '체인지업 최고구속';

    tooltipObj['folkValue'] = '포크 구종가치';
    tooltipObj['folkValueDivOneHundred'] = '포크 구종가치/100';
    tooltipObj['folkPitchRate'] = '포크 투구%';
    tooltipObj['folkCnt'] = '포크 투구수';
    tooltipObj['folkAvgSpeed'] = '포크 평균구속';
    tooltipObj['folkMaxSpeed'] = '포크 최고구속';

    tooltipObj['splitterValue'] = '스플리터 구종가치';
    tooltipObj['splitterValueDivOneHundred'] = '스플리터 구종가치/100';
    tooltipObj['splitterPitchRate'] = '스플리터 투구%';
    tooltipObj['splitterCnt'] = '스플리터 투구수';
    tooltipObj['splitterAvgSpeed'] = '스플리터 평균구속';
    tooltipObj['splitterMaxSpeed'] = '스플리터 최고구속';

    tooltipObj['nuckleValue'] = '너클볼 구종가치';
    tooltipObj['nuckleValueDivOneHundred'] = '너클볼 구종가치/100';
    tooltipObj['nucklePitchRate'] = '너클볼 투구%';
    tooltipObj['nuckleCnt'] = '너클볼 투구수';
    tooltipObj['nuckleAvgSpeed'] = '너클볼 평균구속';
    tooltipObj['nuckleMaxSpeed'] = '너클볼 최고구속';

    tooltipObj['sinkerValue'] = '싱커 구종가치';
    tooltipObj['sinkerValueDivOneHundred'] = '싱커 구종가치/100';
    tooltipObj['sinkerPitchRate'] = '싱커 투구%';
    tooltipObj['sinkerCnt'] = '싱커 투구수';
    tooltipObj['sinkerAvgSpeed'] = '싱커 평균구속';
    tooltipObj['sinkerMaxSpeed'] = '싱커 최고구속';

    tooltipObj['sluveValue'] = '슬러브 구종가치';
    tooltipObj['sluveValueDivOneHundred'] = '슬러브 구종가치/100';
    tooltipObj['sluvePitchRate'] = '슬러브 투구%';
    tooltipObj['sluveCnt'] = '슬러브 투구수';
    tooltipObj['sluveAvgSpeed'] = '슬러브 평균구속';
    tooltipObj['sluveMaxSpeed'] = '슬러브 최고구속';

    tooltipObj['palmballValue'] = '팜볼 구종가치';
    tooltipObj['palmballValueDivOneHundred'] = '팜볼 구종가치/100';
    tooltipObj['palmballPitchRate'] = '팜볼 투구%';
    tooltipObj['palmballCnt'] = '팜볼 투구수';
    tooltipObj['palmballAvgSpeed'] = '팜볼 평균구속';
    tooltipObj['palmballMaxSpeed'] = '팜볼 최고구속';

    tooltipObj.특수_tab1 = '특수 구종가치';
    tooltipObj.특수_tab2 = '특수 구종가치/100';
    tooltipObj.특수_tab3 = '특수 투구%';
    tooltipObj.특수_tab4 = '특수 투구수';
    tooltipObj.특수_tab5 = '특수 평균구속 [최고구속]';

    tooltipObj['etcValue'] = '기타 구종가치';
    tooltipObj['etcValueOneHundred'] = '기타 구종가치/100';
    tooltipObj['etcPitchRate'] = '기타 투구%';
    tooltipObj['etcCnt'] = '기타 투구수';
    tooltipObj['etcAvgSpeed'] = '기타 평균구속';
    tooltipObj['etcMaxSpeed'] = '기타 최고구속';

    /****** 투수 홈런 *******/
    tooltipObj.xh = "장타"
    tooltipObj.xhPerPa = "장타/타석 (%)"
    tooltipObj.xhPerAb = "장타/타수 (%)"
    tooltipObj.xhPerHit = "장타/안타 (%)"
    tooltipObj.paPerHr = "타석/홈런"
    tooltipObj.abPerHr = "타수/홈런"
    tooltipObj.hrPerFly = "홈런/뜬공"
    tooltipObj.hrPerFlyOut = "홈런/뜬공 아웃"
    tooltipObj.oneRbiHr = "1타점 홈런"
    tooltipObj.twoRbiHr = "2타점 홈런"
    tooltipObj.threeRbiHr = "3타점 홈런"
    tooltipObj.fourRbiHr = "4타점 홈런"
    tooltipObj.rightBatterHr = "우타자 상대 홈런"
    tooltipObj.leftBatterHr = "좌타자 상대 홈런"
    tooltipObj.fastBallHr = "직구 홈런"
    tooltipObj.curveBallHr = "변화구 홈런"
    tooltipObj.lHr = "좌익수뒤 홈런"
    tooltipObj.lcHr = "좌중간 홈런"
    tooltipObj.cHr = "중견수뒤 홈런"
    tooltipObj.rcHr = "우중간 홈런"
    tooltipObj.rHr = "우익수뒤 홈런"
    tooltipObj.underHr = "-4점차 이상 열세시 홈런"
    tooltipObj.threeUnderHr = "-3점차 열세시 홈런"
    tooltipObj.twoUnderHr = "-2점차 열세시 홈런"
    tooltipObj.oneUnderHr = "-1점차 열세시 홈런"
    tooltipObj.tieHr = "동점시 홈런"
    tooltipObj.oneUpHr = "+1점 우세시 홈런"
    tooltipObj.twoUpHr = "+2점 우세시 홈런"
    tooltipObj.threeUpHr = "+3점 우세시 홈런"
    tooltipObj.upperHr = "+4점차 이상 우세시 홈런"
    tooltipObj.firstPitchHr = "초구 홈런"
    tooltipObj.firstUpperPitchHr = "초구 이상 홈런"

    tooltipObj['inplay'] = '타구 중 인플레이된 타구 횟수';
    tooltipObj['xba'] = '트랙맨 의 발사각/속도 데이터를 기반으로 산출한 예상 피안타율';
    tooltipObj['xslg'] = '트랙맨 의 발사각/속도 데이터를 기반으로 산출한 예상 피장타율';
    tooltipObj['chaseRate'] = '스트라이크 존 밖 투구에 헛스윙을 유도한 비율';
    tooltipObj['inplay'] = '타구 중 인플레이된 타구 횟수';
    tooltipObj['firstStrikeRate'] = '전체 초구 중 스트라이크 비율';
    tooltipObj['twoStrikePitchStrikeRate'] = '2스트라이크 이후 스트라이크 비율';
    tooltipObj['twoStrikePitchChaseRate'] = '2스트라이크 이후 스트라이크 존 밖 투구에 헛스윙을 유도한 비율';
    tooltipObj['twoStrikePitchWhiffRate'] = '2스트라이크 이후 헛스윙을 유도한 비율';
    tooltipObj['zoneRate'] = '전체 투구 중 스트라이크 존 내 투구 비율';
    tooltipObj['hangRate'] = '당겨친 타구 비율';
    tooltipObj['centerRate'] = '가운데 방향 타구 비율';
    tooltipObj['pushRate'] = '밀어친 타구 비율';
    tooltipObj['solidContact'] = '안타로 이어질 확률이 높지만 장타가 되기는 어려운 타구';
    tooltipObj['poorlyUnder'] = '나쁜 땅볼';
    tooltipObj['poorlyTopped'] = '나쁜 뜬공';
    tooltipObj['poorlyWeak'] = '빗맞은 공';
    tooltipObj['barrelRate'] = '전체 타구에서 장타나 홈런이 되는 타구 비율';
    tooltipObj['poorlyUnderRate'] = '인플레이 타구 중 빗맞은 땅볼 타구 비율';
    tooltipObj['poorlyToppedRate'] = '인플레이 타구 중 빗맞은 뜬공 타구 비율';
    tooltipObj['poorlyWeakRate'] = '빗맞은 공 비율';
    tooltipObj['vaildHitRate'] = '전체 타구에서 배럴 타구 이외 가장 이상적인 타구 비율';

    return tooltipObj;
}

export default pitcherTooltip;