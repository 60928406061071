import React, { useEffect, useState } from 'react';
import bg_stadium from '../../assets/image/common/bg_stadium.png';
import styled from 'styled-components';
import moment from 'moment';
import { fetchRosterInfo } from '../../utils/api';
import _ from 'lodash';
import VsDepthChart from '../chart/VsDepthChart';

const DepthRow = styled.div`
display: flex;
position: absolute;
top: ${(props) => props.$top || '0px'}
`;

function DepthChartContainer(props) {
    const {gameInfoArr} = props;

    const [rosterObj, setRosterObj] = useState({
        roster: [],
        gameInfo: {},
        lineUpYn: 'N'
    });

    useEffect(() => {
        if(gameInfoArr.length !== 0) {
            const sortedData = _.sortBy(gameInfoArr, ['doubleHeaderYn']);

            const gameInfo = sortedData[0];

            let commonParam = {
                gameTypeCodes: 4201
            };

            const promiseArr = [];
            _.forEach(sortedData, (item, idx) => {
                if(gameInfo['lineUpYn'] === 'Y') {
                    commonParam['season'] = moment(item['gameDay']).format('YYYY');

                    const rosterInfoParam = {
                        ...commonParam, gameInfoIds: item['gameInfoId']
                    }
    
                    const rosterInfoPromise = fetchRosterInfo(rosterInfoParam)
                    promiseArr.push(rosterInfoPromise);
                } else { // 'N'
                    let awayTeamParam = {...commonParam,
                        season: moment(item['awayPreGameDay']).format('YYYY'),
                        gameInfoIds: item['awayPreGameInfoId']
                    }

                    let homeTeamParam = {...commonParam,
                        season: moment(item['homePreGameDay']).format('YYYY'),
                        gameInfoIds: item['homePreGameInfoId']
                    }

                    const awayRosterInfoPromise = fetchRosterInfo(awayTeamParam);
                    const homeRosterInfoPromise = fetchRosterInfo(homeTeamParam);

                    promiseArr.push(awayRosterInfoPromise);
                    promiseArr.push(homeRosterInfoPromise);
                }
            });

            Promise.all(promiseArr)
            .then((response) => {
                const tmpArr = [];
                _.forEach(response, (item ,idx) => {
                    tmpArr.push(item['data']['RECORD']);
                });

                const rosterPlayerArr = _.flatten(tmpArr);

                let setObj = new Set(rosterPlayerArr.map(JSON.stringify));
                let uniqPlayerArr = Array.from(setObj).map(JSON.parse);
                const rosterInfo = rosterCalc(uniqPlayerArr);

                const tmpObj = {
                    roster: rosterInfo,
                    gameInfo,
                    lineUpYn: gameInfo['lineUpYn']
                }
    
                setRosterObj(tmpObj)
            });
        }
    }, [gameInfoArr])

    function rosterCalc(info) {
        const rosterGroupedData = _.groupBy(info, (item, idx) => {
            return item['rosterTypeCode']+"_"+item['positionTypeCode'];
        });

        return rosterGroupedData;
    }

    if(rosterObj['gameInfo']['gameStatus']*1 === 4199) {
        return (
            <>
            <div className="trackmanArea">
                <div className="teamTitle">
                {
                `${rosterObj['gameInfo']['awayTeamName']} vs ${rosterObj['gameInfo']['homeTeamName']}`
                } ( 우천취소 )</div>
            </div>
            <div className="home_small_depth">
                <div className="depth_body">
                    <div className="stadium_bg">
                        <img src={bg_stadium} alt="bg_stadium"/>
                    </div>
                </div>
            </div>
            </>
        )
    }

    if(Object.keys(rosterObj['roster']).length === 0) {
        return <>라인업 정보가 없습니다.</>;
    }

    return (
    <>
    <div className="trackmanArea">
        <div className="teamTitle">
        {
        `${rosterObj['gameInfo']['awayTeamName']} vs ${rosterObj['gameInfo']['homeTeamName']}`
        }
        {
        rosterObj['gameInfo']['doubleHeaderYn'] !== 'N' && ' ( DH )'
        }
        {
        rosterObj['lineUpYn'] === 'N' && ' ( 직전 경기 라인업 )'
        }
        </div>
    </div>
    <div className="home_small_depth">
        <div className="depth_body">
            <div className="stadium_bg">
                <img src={bg_stadium} alt="bg_stadium"/>
            </div>
            <DepthRow $top={'20px'}>
                <div className="team_lf home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5207']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
                <div className="team_cf home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5208']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
                <div className="team_rf home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5209']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
            </DepthRow>
            <DepthRow $top={'85px'}>
                <div className="team_ss home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5206']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
                <div className="team_2b home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5204']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
            </DepthRow>
            <DepthRow $top={'140px'}>
                <div className="team_3b home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5205']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
                <div className="team_sp home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5102_5201']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
                <div className="team_1b home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5203']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
            </DepthRow>
            <DepthRow $top={'200px'}>
                <div className="team_c home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5202']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
                <div className="team_dh home_depth_list">
                    <div className="home_content">
                        <VsDepthChart
                        playerData={rosterObj['roster']['5101_5210']}
                        gameInfo={rosterObj['gameInfo']}
                        />
                    </div>
                </div>
            </DepthRow>
        </div>
    </div>
    </>
    )
}

export default DepthChartContainer;