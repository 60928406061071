import React from "react";

const ErrorPage = () => {
    return (
        <div className="container">
            <div id="errorWrap">
                <h1>Error</h1>
            </div>
        </div>
    );
};

export default ErrorPage;