import { FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: 'L', name: '좌'},
    {id: 'R', name: '우'}
];

function BattingHandSelectItem(props) {
    const {value, setValue} = props;
    const [selectId, setSelectId] = useState(value || []);

    const handleOnChange = (event) => {
        const {target:{value}} = event;
        setSelectId(value);
        setValue((prev) => {
            return {...prev, battingHands: value}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel
        id="batting-hand-select-label"
        sx={{fontSize: 12}}
        >타격좌우</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="batting-hand-select-label"
        id="batting-hand-select"
        value={selectId}
        onChange={handleOnChange}
        input={<OutlinedInput label="타격좌우" />}
        >
        <MenuItem value="">전체</MenuItem>
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"battingHands_"+item['id']} value={item['id']}>
                {item['name']}
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default BattingHandSelectItem;