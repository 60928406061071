const RANKING_CELL = {
    key: '', name: '랭킹',
    renderHeaderCell: function(props) {
        return props['column']['name']
    },
    renderCell: function(props) {
        return props['rowIdx']+1
    },
    frozen: true,
    sortable: false,
    width: 50
}
export default RANKING_CELL;