import React, { useState, useEffect } from "react";
import { privateApi } from "../../interceptor/axios-interceptor";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { decodeToken } from "../../utils/decodeToken";
import user_icon from '../../assets/image/common/user_icon_default.png';

const ProfilePage = () => {
    const [userInfo, setUserInfo] = useState('');
    const { onLogout } = useAuth();

    const teamCodeObj = {
        '1001': "롯데",
        '2001': "한화",
        '3001': "LG",
        '4001': "NC",
        '5001': "SSG",
        '6001': "기아",
        '7001': "키움",
        '8001': "두산",
        '9001': "삼성",
        '10001': "KT"
    };

    let decodedToken = decodeToken();

    const navigate = useNavigate();

    const modifyUserInfo = () => {
        navigate('/change/userinfo');
    };

    const modifyPassword = () => {
        navigate('/change/password');
    };

    const delUser = () => {
        // console.log(simpleReplyId);
        if(window.confirm("정말 탈퇴하시겠습니까?\n(6개월간 재가입 불가능)")){
            deleteUser().then((res) => {
                if(res.deleteUser){
                    onLogout();
                    alert("탈퇴 처리 되었습니다.");
                } else {
                    alert("탈퇴 권한이 없습니다.");
                }
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const userData = await selectUser();
            if(userData.selectUser){
                setUserInfo(userData.selectUser);
            } else {
                navigate('/error');
            }
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectUser = () =>
        new Promise((resolve) => {
            const params = {
                memberNo: decodedToken.memberNo
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/member/selectUser`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    const deleteUser = () =>
        new Promise((resolve) => {
            const params = {
                memberNo: decodedToken.memberNo
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/member/deleteUser`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    return (
        <div className="container">
            <div id="profileBox">
                {
                userInfo &&
                <>
                    <div id="topBtnWrap">
                        <button className="funcBtn" id="modifyUserInfoBtn" onClick={modifyUserInfo}>정보수정</button>
                        {
                        userInfo.memberRegTypeCode !== "google" && userInfo.memberRegTypeCode !== "kakao" && userInfo.memberRegTypeCode !== "naver" &&
                        <button className="funcBtn" id="modifyPasswordBtn" onClick={modifyPassword}>비밀번호 수정</button>
                        }
                    </div>
                    <form>
                        <div>
                            <div className="label">
                                아이디
                            </div>
                            <span>
                                {userInfo.memberLoginId}
                            </span>
                        </div>
                        <div>
                            <div className="label">
                                이름
                            </div>
                            <span>
                                {userInfo.memberName}
                            </span>
                        </div>
                        <div>
                            <div className="label">
                                닉네임
                            </div>
                            <span>
                                {userInfo.nickName}
                            </span>
                        </div>
                        <div>
                            <div className="label">
                                생년월일
                            </div>
                            <span>
                                {userInfo.birthYmd}
                            </span>
                        </div>
                        <div>
                            <div className="label">
                                성별
                            </div>
                            <span>
                                {userInfo.sex}
                            </span>
                        </div>
                        <div>
                            <div className="label">
                                이메일
                            </div>
                            <span>
                                {userInfo.emailAddress}
                            </span>
                        </div>
                        {/* <div>
                            <div className="label">
                                휴대폰
                            </div>
                            <span>
                                {userInfo.mobilePhone}
                            </span>
                        </div> */}
                        <div id="favTeamLabel">
                            <div className="label">
                                응원팀
                            </div>
                            <div id="favTeamWrap">
                            {
                                userInfo.favTeamCode === 0
                                ?
                                <div id="favTeamNone">
                                    <div>
                                        없음
                                    </div>
                                    <div>
                                        <img src={user_icon} alt="userIcon" id="uesrIcon" />
                                    </div>
                                </div>
                                :
                                <div id="favTeamItem">
                                    <div>
                                        {teamCodeObj[userInfo.favTeamCode]}
                                    </div>
                                    <div>
                                        {
                                        userInfo.favTeamCode &&
                                        <img src={require(`/src/assets/image/common/team_logo/logo_${userInfo.favTeamCode}.png`)} alt={teamCodeObj[userInfo.favTeamCode]}/>
                                        }
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </form>
                    <div id="bottomBtnWrap">
                        <button className="funcBtn" id="leaveMembershipBtn" onClick={delUser}>회원탈퇴</button>
                    </div>
                </>
                }
            </div>
        </div>
    );
};

export default ProfilePage;