import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './components/auth/ProtectedRoute';

import HomePage from './pages/home/home-page';
import ErrorPage from './pages/error/error-page';

import GameScheduleMain from './pages/gameschedule/game-schedule-main';
import GameScheduleResult from './pages/gameschedule/game-schedule-result';
import TodayGameDetail from './pages/todaygame/today-game-detail';
import RankingMain from './pages/ranking/ranking-main';
import RecordMain from './pages/record/record-main';
import PlayerInfoMain from './pages/player/player-info-main';
import TeamInfoMain from './pages/team/team-info-main';
import LogsMain from './pages/logs/logs-main';

import JoinLayout from './layout/join/join-layout';
import PolicyPage from './pages/join/policy-page';
import JoinPlatformPage from './pages/join/join-platform-page';
import JoinPage from './pages/join/join-page';

import LoginPage from './pages/login/login-page';
import ForgotIdPage from './pages/login/forgot-id-page';
import ForgotPwPage from './pages/login/forgot-pw-page';

import OAuthLayout from './layout/oauth/oauth-layout';
import OAuth2RedirectHandlerGoogle from './service/oauth/OAuth2RedirectHandlerGoogle';
import OAuth2RedirectHandlerKakao from './service/oauth/OAuth2RedirectHandlerKakao';
import OAuth2RedirectHandlerNaver from './service/oauth/OAuth2RedirectHandlerNaver';
import OAuthPolicyPage from './pages/oauth/oauth-policy-page';
import OAuthJoinPage from './pages/oauth/oauth-join-page';

import ProfilePage from './pages/profile/profile-page';
import ChangeUserInfoPage from './pages/profile/change-user-info-page';
import ChangePasswordPage from './pages/profile/change-password-page';

import BoardLayout from './layout/board/board-layout';
import BoardPostList from './pages/board/board-post-list';
import BoardPostWrite from './pages/board/board-post-write';
import BoardPostView from './pages/board/board-post-view';
import BoardPostModify from './pages/board/board-post-modify';
import TeamRecordMain from './pages/record/sub/team-record-main';
import PlayerRecordMain from './pages/record/sub/player-record-main';
import WeeklyMain from './pages/weekly/weekly-main';
import PlayerListMain from './pages/player/player-list-main';

function MainRouter() {
    return (
        <Routes>
            <Route index element={<HomePage/>}/>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/error" element={<ErrorPage/>}/>

            <Route path="/gameschedule/main" element={<GameScheduleMain/>} />
            <Route path="/gameschedule/result/:gameInfoId" element={<GameScheduleResult/>} />
            <Route path="/todaygame" element={<TodayGameDetail/>}/>
            <Route path="/todaygame/:gameDate/:gameInfoId" element={<TodayGameDetail/>}/>
            <Route path="/ranking/:mainId" element={<RankingMain/>}/>
            <Route path="/record/team/:subId/:tabId" element={<TeamRecordMain/>} />
            <Route path="/record/player/:subId/:tabId" element={<PlayerRecordMain/>} />
            <Route path="/player/batter/:playerId" element={<PlayerInfoMain/>} />
            <Route path="/player/pitcher/:playerId" element={<PlayerInfoMain/>} />
            <Route path="/team/:teamId" element={<TeamInfoMain />} />
            <Route path="/logs" element={<LogsMain/>}/>
            <Route path="/weekly" element={<WeeklyMain/>}/>
            <Route path="/player/:searchColumn/:searchStr" element={<PlayerListMain />}/>

            <Route element={<JoinLayout/>}>
                <Route path="/join" element={<JoinPage/>}/>
                <Route path="/join/policy" element={<PolicyPage/>}/>
                <Route path="/join/platform" element={<JoinPlatformPage/>}/>
            </Route>

            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/forgot/id" element={<ForgotIdPage/>}/>
            <Route path="/forgot/pw" element={<ForgotPwPage/>}/>

            <Route element={<OAuthLayout/>}>
                <Route path="/oauth/callback/google" element={<OAuth2RedirectHandlerGoogle/>} />
                <Route path="/oauth/callback/kakao" element={<OAuth2RedirectHandlerKakao/>} />
                <Route path="/oauth/callback/naver" element={<OAuth2RedirectHandlerNaver/>} />
                <Route path="/oauth/policy" element={<OAuthPolicyPage/>}/>
                <Route path="/oauth/join" element={<OAuthJoinPage/>}/>
            </Route>

            <Route path="/profile" element={
                <ProtectedRoute>
                    <ProfilePage/>
                </ProtectedRoute>
            }/>
            <Route path="/change/userinfo" element={
                <ProtectedRoute>
                    <ChangeUserInfoPage/>
                </ProtectedRoute>
            }/>
            <Route path="/change/password" element={
                <ProtectedRoute>
                    <ChangePasswordPage/>
                </ProtectedRoute>
            }/>

            <Route element={<BoardLayout/>}>
                <Route path="/board" element={<BoardPostList/>}/>
                <Route path="/board/:boardId" element={<BoardPostList/>}/>
                <Route path="/board/:boardId/write" element={
                    <ProtectedRoute>
                        <BoardPostWrite/>
                    </ProtectedRoute>
                }/>
                <Route path="/board/:boardId/:contentId" element={<BoardPostView/>}/>
                <Route path="/board/:boardId/:contentId/modify" element={
                    <ProtectedRoute>
                        <BoardPostModify/>
                    </ProtectedRoute>
                }/>
            </Route>
        </Routes>
    )
}

export default MainRouter;