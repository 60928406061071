import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const JoinLayout = () => {
    const [userPolicy, setUserPolicy] = useState();
    const [policyAgreeYn, setPolicyAgreeYn] = useState('N');

    return (
        <div className="container">
            <div id="JoinMainWrap">
                <Outlet context={{userPolicy, setUserPolicy, policyAgreeYn, setPolicyAgreeYn}} />
            </div>
        </div>
    );
};

export default JoinLayout;