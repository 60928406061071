import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchTeamWinResult } from '../../../utils/api';
import _ from 'lodash';

function WeeklyTeamRecord(props) {
    const {fromDate, toDate, teamId} = props;
    const [teamRecord, setTeamRecord] = useState([]);

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const teamParam = {
            seasons,
            gameTypeCodes: 4201,
            teamIds: teamId,
            fromDate, toDate
        }

        const teamRankingPromise = fetchTeamWinResult(teamParam);
        teamRankingPromise.then((response) => {
            const teamWinResult = teamRecordCalc(response['data']['RECORD']);
            setTeamRecord(teamWinResult);
        })
    }, [fromDate, teamId]);

    const teamRecordCalc = (data) => {
        let tmpObj = {win: 0, lose: 0, draw: 0};

        _.forEach(data, item => {
            tmpObj['win'] += item['win']*1;
            tmpObj['lose'] += item['lose']*1;
            tmpObj['draw'] += item['draw']*1;
        })

        return tmpObj;
    }

    return (
    <>
    <div className="title">주간 팀 기록 : {teamRecord['win']+"승"} {teamRecord['lose']+"패"} {teamRecord['draw']+"무"}</div>
    </>
    )
}

export default WeeklyTeamRecord;