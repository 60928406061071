import React, { useState } from 'react';
import TodaySeasonBox from './today-season-box';
import TodayRelativeBox from './today-relative.box';
import TodayScoreBox from './today-score-box';
import TodayDayBox from './today-day-box';

function TodayGameUpperBox() {
    const [mainId, setMainId] = useState('seasonRecord');

    const mainMenuEventHandler = (e) => {
        const id = e.currentTarget.id;
        setMainId(id);
    }

    const UpperTabContent = function() {
        switch(mainId) {
            case 'relativeRecord' :
                return <TodayRelativeBox />
            case 'scoreRecord' :
                return <TodayScoreBox />
            case 'dayRecord' :
                return <TodayDayBox />
            default :
                return <TodaySeasonBox />
        }
    }

    return (
        <>
        <div id="upper_tab_wrap">
            <div id="upper_tab_btn_area">
                <div id="seasonRecord"
                className={"btn "+((mainId === "seasonRecord") && "active")}
                onClick={mainMenuEventHandler}
                >시즌전적</div>

                <div id="relativeRecord"
                className={"btn "+((mainId === "relativeRecord") && "active")}
                onClick={mainMenuEventHandler}
                >상대전적</div>

                <div id="scoreRecord"
                className={"btn "+((mainId === "scoreRecord") && "active")}
                onClick={mainMenuEventHandler}
                >득점차</div>
                
                <div id="dayRecord"
                className={"btn "+((mainId === "dayRecord") && "active")}
                onClick={mainMenuEventHandler}
                >요일</div>
            </div>
            <div id="upper_tab_content">
                <UpperTabContent />
            </div>
        </div>
        </>
    )
}

export default TodayGameUpperBox;