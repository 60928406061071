import React, { useEffect, useState } from 'react';
import { fetchBatterRecordDetail, fetchBatterRecordTpa, fetchLastGameDay, fetchRunnerRecord } from '../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Oval } from 'react-loader-spinner';
import { loopFlatObject } from '../../../utils/flat';
import TeamBattingBasicRecord from './batting/team-batting-basic-record';
import TeamBattingDetailRecord from './batting/team-batting-detail-record';
import TeamBattingTpaRecord from './batting/team-batting-tpa-record';
import TeamBattingHitRecord from './batting/team-batting-hit-record';
import TeamBattingClutchRecord from './batting/team-batting-clutch-record';
import TeamBattingRunnerRecord from './batting/team-batting-runner-record';
import moment from 'moment';
import TeamBattingFilter from '../../../components/filter/team-batting-filter';
import { useAtom } from 'jotai';
import { team_batting_filter_atom } from '../../../assets/model/detail/store';
import _ from 'lodash';
import FilterToggleButton from '../../../components/button/FilterToggleButton';
import { Collapse } from '@mui/material';

function TeamBattingRecord() {
    const navigate = useNavigate();
    const [filterObj, setFilterObj] = useAtom(team_batting_filter_atom);

    const [record, setRecord] = useState([]);
    const [open, setOpen] = useState(false);
    const params = useParams();
    const tabId = params['tabId'];
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        if(!filterObj['seasons']) {
            const todaySeason = moment(new Date()).format('YYYY');
            fetchLastGameDay({
                seasons: [todaySeason, todaySeason-1].toString(),
                gameTypeCodes: 4201
            }).then((response) => {
                const {lastGameDay} = response['data']['RECORD'][0];
                const lastSeason = moment(lastGameDay).format('YYYY');

                setFilterObj((prev) => {
                    return {...prev, seasons: lastSeason}
                })
            })
        }
    }, [])

    useEffect(() => {
        if(filterObj['seasons']) {
            setOpen(true);
            const {seasons} = filterObj;
            const recordParam = {
                ...filterObj,
                gameTypeCodes: 4201,
                groupingColumn: 'seasonTeam'
            }

            _.map(recordParam, (item, idx) => {
                if(Array.isArray(item)) {
                    return recordParam[idx] = item.toString();
                }
            });

            if(!recordParam['toDate'] && !recordParam['fromDate']) {
                recordParam['fromDate'] = moment(new Date(seasons, 0, 1)).format('YYYY-MM-DD');
                recordParam['toDate'] = moment(new Date(seasons, 11, 31)).format('YYYY-MM-DD');
            }

            if(tabId == 'tpa') {
                fetchBatterRecordTpa(recordParam)
                .then((response) => {
                    const battingRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(battingRecord);
                    setOpen(false);
                })
            } else if(tabId === 'runner') {
                fetchRunnerRecord(recordParam)
                .then((response) => {
                    const battingRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(battingRecord);
                    setOpen(false);
                })
            } else {
                fetchBatterRecordDetail(recordParam)
                .then((response) => {
                    const battingRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(battingRecord);
                    setOpen(false);
                })
            }
        }
    }, [filterObj])

    const TeamBattingRecordContent = () => {
        switch(tabId) {
            case 'basic':
                return <TeamBattingBasicRecord data={record}/>
            case 'detail':
                return <TeamBattingDetailRecord data={record}/>
            case 'tpa':
                return <TeamBattingTpaRecord data={record}/>;
            case 'hit':
                return <TeamBattingHitRecord data={record}/>;
            case 'clutch':
                return <TeamBattingClutchRecord data={record}/>;
            case 'runner':
                return <TeamBattingRunnerRecord data={record}/>;
            default:
                return <></>
        }
    }

    const filterToggleEventHandler = () => {
        setFilterOpen(!filterOpen);
    }

    return (
    <>
    <div id="record_sub_tab_container">
        <div id="record_sub_tab_area">
            <div className={"btn "+((tabId === "basic") && "active")}
            onClick={() => navigate('/record/team/batting/basic')}
            >
            기본
            </div>
            <div className={"btn "+((tabId === "detail") && "active")}
            onClick={() => navigate('/record/team/batting/detail')}
            >
            상세
            </div>
            <div className={"btn "+((tabId === "tpa") && "active")}
            onClick={() => navigate('/record/team/batting/tpa')}
            >
            타석
            </div>
            <div className={"btn "+((tabId === "hit") && "active")}
            onClick={() => navigate('/record/team/batting/hit')}
            >
            안타
            </div>
            <div className={"btn "+((tabId === "clutch") && "active")}
            onClick={() => navigate('/record/team/batting/clutch')}
            >
            클러치
            </div>
            <div className={"btn "+((tabId === "runner") && "active")}
            onClick={() => navigate('/record/team/batting/runner')}
            >
            주루
            </div>
        </div>
        <div>
            <FilterToggleButton
            onClick={filterToggleEventHandler}
            >
                필터
            </FilterToggleButton>
        </div>
    </div>
    <div id="record_sub_container">
        <Collapse in={filterOpen} timeout={'auto'}>
            <TeamBattingFilter/>
        </Collapse>
        {
            record.length === 0
            ? 
            <></>
            :
            <TeamBattingRecordContent/>
        }
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
            <Oval
            height="80" width="80"
            radius="9" color="#00bfff"
            secondaryColor="#fff"
            ariaLabel="three-dots-loading"
            />
        </Backdrop>
    </div>
    </>
    )
}

export default TeamBattingRecord;