import React from "react";

function RadioFavTeam(props) {
    const {teamCode, teamName, action, favTeamCode} = props;

    return (
        <label id="favTeamItem">
            <div>
                <input type="radio" name="favTeamCode" value={teamCode} onChange={action} checked={favTeamCode !== undefined ? favTeamCode * 1 === teamCode * 1 : undefined} />
                {teamName}
            </div>
            <div>
                <img src={require(`/src/assets/image/common/team_logo/logo_${teamCode}.png`)} alt={teamName}/>
            </div>
        </label>
    )
};

export default RadioFavTeam;