import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchPitcherRecord, fetchPlayerInfo } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';

function PitcherBasicRecordBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [pitcherRecord, setPitcherRecord] = useState([]);
    const [totalRecord, setTotalRecord] = useState({});

    useEffect(() => {
        const playerId = params['playerId'];
        const recordParam = {
            pitcherIds: playerId,
            gameTypeCodes: 4201,
            groupingColumn: 'seasonPlayer'
        }

        const playerParam = {
            membersId: playerId,
            seasons: season
        }

        const totalParam = {
            pitcherIds: playerId,
            gameTypeCodes: 4201
        }

        const playerInfoPromise = fetchPlayerInfo(playerParam);
        const pitcherRecordPromise = fetchPitcherRecord(recordParam);
        const pitcherTotalPromise = fetchPitcherRecord(totalParam);

        Promise.all([
            pitcherRecordPromise,
            pitcherTotalPromise,
            playerInfoPromise
        ]).then((response) => {
            const pitcherData = loopFlatObject(response[0]['data']['RECORD']);

            _.map(pitcherData, (item) => item['birthDate'] = response[2]['data']['RECORD'][0]['birthDate']);
            setPitcherRecord(pitcherData);
            setTotalRecord(loopFlatObject(response[1]['data']['RECORD']));
        })
    }, [])

    const ageRenderer = (item) => {
        const {season, birthDate} = item;
        if(birthDate) {
            let birthdateYear = birthDate.split('/');
            let age = (season*1-birthdateYear[0]*1)-1;

            return age;
        } else {
            return 0
        }
    }

    return (
    <div id="player_basic_container">
        <div className="table_title">
            시즌 기록
        </div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>시즌</th>
                        <th>나이</th>
                        <th>팀</th>
                        <th>경기</th>
                        <th>승</th>
                        <th>패</th>
                        <th>세이브</th>
                        <th>홀드</th>
                        <th>이닝</th>
                        <th>투구수</th>
                        <th>피안타</th>
                        <th>피홈런</th>
                        <th>탈삼진</th>
                        <th>사사구</th>
                        <th>실점</th>
                        <th>자책</th>
                        <th>평균자책점</th>
                        <th>WHIP</th>
                        <th>QS</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(pitcherRecord, (item, idx) => (
                    <tr key={item['season']}>
                        <td>{item['season']}</td>
                        <td>{ageRenderer(item)}</td>
                        <td>{item['pitcherTeamName']}</td>
                        <td>{item['basicStat_gameCnt']}</td>
                        <td>{item['win']}</td>
                        <td>{item['lose']}</td>
                        <td>{item['save']}</td>
                        <td>{item['hold']}</td>
                        <td>{item['ip']}</td>
                        <td>{item['basicStat_pitchingCnt']}</td>
                        <td>{item['basicStat_hit']}</td>
                        <td>{item['basicStat_hr']}</td>
                        <td>{item['basicStat_so']}</td>
                        <td>{item['basicStat_bb']}</td>
                        <td>{item['basicStat_r']}</td>
                        <td>{item['basicStat_er']}</td>
                        <td>{item['era']}</td>
                        <td>{item['whip']}</td>
                        <td>{item['qs']}</td>
                    </tr>    
                ))
                }
                </tbody>
            </table>
        </div>
        <div className="table_title">
            통산 기록
        </div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>팀</th>
                        <th>경기</th>
                        <th>승</th>
                        <th>패</th>
                        <th>세이브</th>
                        <th>홀드</th>
                        <th>이닝</th>
                        <th>투구수</th>
                        <th>피안타</th>
                        <th>피홈런</th>
                        <th>탈삼진</th>
                        <th>사사구</th>
                        <th>실점</th>
                        <th>자책</th>
                        <th>평균자책점</th>
                        <th>WHIP</th>
                        <th>QS</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(totalRecord, (item, idx) => (
                    <tr key={item['season']}>
                        <td>{item['pitcherTeamName']}</td>
                        <td>{item['basicStat_gameCnt']}</td>
                        <td>{item['win']}</td>
                        <td>{item['lose']}</td>
                        <td>{item['save']}</td>
                        <td>{item['hold']}</td>
                        <td>{item['ip']}</td>
                        <td>{item['basicStat_pitchingCnt']}</td>
                        <td>{item['basicStat_hit']}</td>
                        <td>{item['basicStat_hr']}</td>
                        <td>{item['basicStat_so']}</td>
                        <td>{item['basicStat_bb']}</td>
                        <td>{item['basicStat_r']}</td>
                        <td>{item['basicStat_er']}</td>
                        <td>{item['era']}</td>
                        <td>{item['whip']}</td>
                        <td>{item['qs']}</td>
                    </tr>    
                ))
                }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default PitcherBasicRecordBox;