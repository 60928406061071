export default function pitcherTypeCode() {
    const pitcherTypeCodes = [
        {pitcherTypeCode: 'L/5102/801', pitcherTypeName: '좌완 선발 정통파'},
        {pitcherTypeCode: 'L/5104/801', pitcherTypeName: '좌완 불펜 정통파'},
        {pitcherTypeCode: 'L/5102/804', pitcherTypeName: '좌완 선발 언더'},
        {pitcherTypeCode: 'L/5104/804', pitcherTypeName: '좌완 불펜 언더'},
        {pitcherTypeCode: 'R/5102/801', pitcherTypeName: '우완 선발 정통파'},
        {pitcherTypeCode: 'R/5104/801', pitcherTypeName: '우완 불펜 정통파'},
        {pitcherTypeCode: 'R/5102/804', pitcherTypeName: '우완 선발 언더'},
        {pitcherTypeCode: 'R/5104/804', pitcherTypeName: '우완 불펜 언더'}
    ];

    return pitcherTypeCodes;
}