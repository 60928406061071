import _ from 'lodash';
import React from 'react';
import { teamColorCode } from '../../utils/color/commonColor';

function VsDepthChart(props) {
    const {playerData: item, gameInfo} = props;

    const teamIndexData = _.groupBy(item, 'teamId');

    const awayPlayer = teamIndexData[gameInfo['awayTeamId']];
    const homePlayer = teamIndexData[gameInfo['homeTeamId']];

    const playerArr = [];
    _.forEach(awayPlayer, (info, idx) => {
        const homePlayerInfo = homePlayer[idx];

        playerArr.push({
            key:info['gameInfoId']+'_'+info['playerId']+'_'+homePlayerInfo['playerId'],
            awayPlayerName: info['playerName'].slice(0,3),
            homePlayerName: homePlayerInfo['playerName'].slice(0,3)
        })
    })

    return (
    <>
    {
    _.map(playerArr, (info, idx) => (
        <div key={info['key']}>
            <span style={{color: teamColorCode(gameInfo['awayTeamId'])}}>{info['awayPlayerName']}</span>&nbsp;vs&nbsp;
            <span style={{color: teamColorCode(gameInfo['homeTeamId'])}}>{info['homePlayerName']}</span>
        </div>
    ))
    }
    </>
    )
}

export default VsDepthChart;