import axios from "axios";

//토큰을 함께 보내는 instance
export const privateApi = axios.create();

privateApi.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = 'application/json';
        config.headers['Authorization'] = `${localStorage.getItem('accessToken')}`;
        config.headers['X-Content-Token'] = `${localStorage.getItem('contentToken')}`;

        return config;
    }
)