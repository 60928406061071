import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import battingColumnCalc from '../../../../utils/table/battingColumnCalc';
import { useRunnerRender, useRunnerTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function PlayerBattingRunnerRecord(props) {
    const statType = "runner";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_stat_stealCnt",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL,
        { name: '시즌', key: 'season', frozen: true, sortable: false},
        { name: '팀', key: 'runnerTeamName', frozen: true, sortable: false, renderCell: useRunnerTeamRender},
        { name: '선수', width: 75, key: 'runnerName', frozen: true, sortable: false, renderCell: useRunnerRender},
        { name: '도루', width: 55, key: 'statCalced_stat_stealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['stealCnt']},
        { name: '도실', width: 55, key: 'statCalced_stat_failStealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['failStealCnt']},
        { name: '도루%', width: 59, key: 'statCalced_stealRate', renderHeaderCell: tooltipRender, tooltip: tooltip['stealRate']},
        { name: '좌도루', width: 63, key: 'statCalced_stat_leftHandStealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['leftHandStealCnt']},
        { name: '좌도실', width: 63, key: 'statCalced_stat_leftHandFailStealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['leftHandFailStealCnt']},
        { name: '좌도루%', width: 70, key: 'statCalced_leftHandStealRate', renderHeaderCell: tooltipRender, tooltip: tooltip['leftHandStealRate']},
        { name: '2B도루', width: 65, key: 'statCalced_stat_base1StealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['base1StealCnt']},
        { name: '2B도실', width: 65, key: 'statCalced_stat_base1FailStealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['base1FailStealCnt']},
        { name: '2B도루%', width: 73, key: 'statCalced_base1StealRate', renderHeaderCell: tooltipRender, tooltip: tooltip['base1StealRate']},
        { name: '3B도루', width: 65, key: 'statCalced_stat_base2StealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['base2StealCnt']},
        { name: '3B도실', width: 65, key: 'statCalced_stat_base2FailStealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['base2FailStealCnt']},
        { name: '3B도루%', width: 73, key: 'statCalced_base2StealRate', renderHeaderCell: tooltipRender, tooltip: tooltip['base2StealRate']},
        { name: '홈도루', width: 63, key: 'statCalced_stat_base3StealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['base3StealCnt']},
        { name: '홈도실', width: 63, key: 'statCalced_stat_base3FailStealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['base3FailStealCnt']},
        { name: '홈도루%', width: 70, key: 'statCalced_base3StealRate', renderHeaderCell: tooltipRender, tooltip: tooltip['base3StealRate']},
        { name: '견제사', width: 63, key: 'stat_checkOutCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['checkOutCnt']},
        { name: '주루사', width: 63, key: 'stat_runnerOutCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['runnerOutCnt']},
        { name: '폭투진루', width: 73, key: 'stat_wildPitchBaseMoveCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['wildPitchBaseMoveCnt']},
        { name: '포일진루', width: 73, key: 'stat_passedBallBaseMoveCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['passedBallBaseMoveCnt']},
        { name: '추가진루기회', width: 95, key: 'stat_addRunnerCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['addRunnerCnt']},
        { name: '추가진루성공', width: 95, key: 'stat_addRunner', renderHeaderCell: tooltipRender, tooltip: tooltip['addRunner']},
        { name: '추가진루율', width: 78, key: 'stat_addRunnerRate', renderHeaderCell: tooltipRender, tooltip: tooltip['addRunnerRate']}
    ];

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = battingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['runnerId'];
    }

    const gridElement = (
        <DataGrid
        defaultColumnOptions={{
            sortable: true
        }}
        className="record_table_height"
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 타격 주루기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerBattingRunnerRecord;