import React, { useState } from 'react';
import ResultRecord from './sub/result-record';
import WERecord from './sub/we-record';

function GameResultDetailBox() {
    const [mainId, setMainId] = useState('homeRecordTab');

    const mainMenuEventHandler = (e) => {
        const id = e.currentTarget.id;
        setMainId(id);
    }

    const ResultDetailTabContent = function() {
        switch(mainId) {
            case 'homeRecordTab':
                return <ResultRecord homeOrAway={'home'}/>;
            case 'awayRecordTab':
                return <ResultRecord homeOrAway={'away'}/>;
            default :
                return <WERecord />;
        }
    }

    return (
        <div id="result_detail_wrap">
            <div id="result_btn_area">
                <div id="homeRecordTab"
                className={"btn "+((mainId === "homeRecordTab") && "active")}
                onClick={mainMenuEventHandler}
                >홈</div>
                <div id="awayRecordTab"
                className={"btn "+((mainId === "awayRecordTab") && "active")}
                onClick={mainMenuEventHandler}
                >원정</div>
                <div id="weRecordTab"
                className={"btn "+((mainId === "weRecordTab") && "active")}
                onClick={mainMenuEventHandler}
                >WE</div>
            </div>
            <div id="result_tab_content">
                <ResultDetailTabContent />
            </div>
        </div>
    )
}

export default GameResultDetailBox;