import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGameList } from '../../utils/api';
import ScoreBoardBox from '../../layout/gameschedule/result/score-board-box';
import GameResultBox from '../../layout/gameschedule/result/game-result-box';
import GameResultDetailBox from '../../layout/gameschedule/result/game-result-detail-box';
import { useSetAtom } from 'jotai';
import { game_info_obj_atom } from '../../assets/model/detail/store';
import GameResultSprayBox from '../../layout/gameschedule/result/game-result-spray-box';
import GameResultStringBox from '../../layout/gameschedule/result/game-result-string-box';

function GameScheduleResult() {
    const params = useParams();
    const setGameInfoObj = useSetAtom(game_info_obj_atom);

    useEffect(() => {
        const {gameInfoId} = params;

        fetchGameList({
            gameInfoIds: gameInfoId,
            gameTypeCodes: 4201
        }).then((response) => {
            const gameInfoObj = response['data']['RECORD'][0];
            setGameInfoObj(gameInfoObj);
        })
    }, [])

    return (
    <>
    <div className="container">
        <div id="result_container">
            <div className="inner_container">
                <div id="result_wrapper">
                    <div id="score_board_container">
                        <ScoreBoardBox />
                    </div>
                    <div id="game_result_container">
                        <GameResultBox />
                    </div>
                </div>
                <div id="winrate_wrapper">
                    <div id="result_spray_container">
                        <GameResultSprayBox />
                    </div>
                </div>
            </div>
            <div>
                <GameResultStringBox/>
            </div>
        </div>
        <div id="result_detail_container">
            <GameResultDetailBox />
        </div>
    </div>
    </>
    )
}

export default GameScheduleResult;