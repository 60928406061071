import React, { useRef, useState } from 'react';
import { hitZoneCalc } from '../../utils/hitZoneCalc';
import _ from 'lodash';
import HitCourseInfo from './sub/HitCourseInfo';
import { chooseColorCode } from '../../utils/color/commonColor';
import { hitResultCodeFilter } from '../../utils/filter/dataFilter';

function HitCourseZoneChart({size, data, ballCodeArr, colorType}) {
    const [checkObj, setCheckObj] = useState(['6601']);
    let textBox = useRef();
    let infoBox = useRef();
    let rectBox = useRef();
    let calcData = hitZoneCalc(size, data);
    calcData = calcData.filter(item => checkObj.indexOf(hitResultCodeFilter(item['hitResultCode'])+"") === -1);

    const width = size*1.2;
    const height = size*1.1;

    let hitResultObj = [
        {hitResultCode : 6613, hitResultName: '홈런', hitResultEng: 'homerun'},
        {hitResultCode : 6610, hitResultName: '3루타', hitResultEng: 'triple'},
        {hitResultCode : 6607, hitResultName: '2루타', hitResultEng: 'double'},
        {hitResultCode : 6604, hitResultName: '1루타', hitResultEng: 'single'},
        {hitResultCode : 6602, hitResultName: '플라이아웃', hitResultEng: 'flyout'},
        {hitResultCode : 6605, hitResultName: '땅볼아웃', hitResultEng: 'grounder'},
        {hitResultCode : 6601, hitResultName: '파울', hitResultEng: 'foul'},
        {hitResultCode : 6699, hitResultName: '기타', hitResultEng: 'other'}
    ];

    const handleMouseOver = (event) => {
        const id = event.currentTarget.id;
        const findData = _.find(data, (item) => item['gameRecordId'] === id);

        const cx = event.currentTarget.cx['animVal'].value;
        const cy = event.currentTarget.cy['animVal'].value;
        textBoxRenderer(cx, cy, findData);
        infoBoxRenderer(findData);
    };

    const infoBoxRenderer = (data) => {
        let text = "";
        text+= "<tspan x='0' y='0'>"+data['gameDay']+"</tspan>";
        text+= "<tspan x='0' y='15'>타자 : "+data['batterName'].slice(0,3) + " | " + "투수 : "+data['pitcherName'].slice(0,3)+"</tspan>";
        text+= "<tspan x='0' y='30'>구종 : "+data['ballCodeName'] + " | " + "구속 : "+data['ballSpeed']+"</tspan>";

        if(data['hitResultName'].length !== 0) {
            text+= "<tspan x='0' y='45'>타격결과 : "+data['hitResultName']+"</tspan>";
        }

        infoBox.current.innerHTML = text;
    }

    const textBoxRenderer = (x, y, data) => {
        const {cx, cy, rectX, rectY} = calcSetting(x, y);
        textBox.current.setAttribute('transform', `translate(${cx},${cy})`);
        textBox.current.setAttribute('visibility', `visible`);

        rectBox.current.setAttribute('transform', `translate(${-rectX},${-rectY})`)
    }

    const calcSetting = (cx, cy) => {
        const half = size/2;

        const xPoint = 10;
        const yPoint = 35;

        const xPoint2 = xPoint+135;
        const yPoint2 = yPoint+10;

        if( (cx >= 0 && cx <= half) && (cy >= 0 && cy <= half)) {
            return {cx: cx+xPoint, cy: cy+yPoint, rectX: 3, rectY: 11}
        } else if( (cx >= 0 && cx <= half) && (cy > half && cy <= size)) {
            return {cx: cx+xPoint, cy: cy-yPoint2, rectX: 3, rectY: 11}
        } else if( (cx > half && cx <= size) && (cy >= 0 && cy <= half)) {
            return {cx: cx-xPoint2, cy: cy+yPoint, rectX: 3, rectY: 11}
        } else {
            return {cx: cx-xPoint2, cy: cy-yPoint2, rectX: 3, rectY: 11}
        }

    }
    
    const handleMouseOut = () => {
        textBox.current.setAttribute('visibility', 'hidden');
    };

    const hitResultClickHandler = (event) => {
        const hitCode = event.currentTarget.id;

        if(checkObj.indexOf(hitCode) > -1) {
            setCheckObj(list => list.filter(item => item !== hitCode));
        } else {
            setCheckObj([...checkObj, hitCode]); 
        }
    }

    const chooseHitCodeStyle = (code) => {
        const commonStyle = {
            cursor: 'pointer'
        };

        if(checkObj.indexOf(code+"") > -1) {
            return { ...commonStyle, opacity: 0.5}
        } else {
            return { ...commonStyle, opacity: 1}
        }
    }

    return (
        <div>
            <svg width={width} height={height}>
                <g transform={`translate(${size*0.01}, ${size*0.05})`}>
                    <image 
                    href={`${process.env.PUBLIC_URL}/image/ground_green.jpeg`} 
                    width={size} 
                    height={size} />
                    {calcData.map((item, idx) => (
                        item['x'] &&
                        <HitCourseInfo data={item} key={item.gameRecordId}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        />
                    ))}
                </g>
                <g transform={`translate(${size*0.97}, ${size*0.7})`}>
                    {hitResultObj.map((item, idx) => (
                        <g key={item['hitResultCode']} 
                        id={item['hitResultCode']}
                        style={chooseHitCodeStyle(item['hitResultCode'])}
                        onClick={hitResultClickHandler}>
                            <circle cx={5} cy={15*idx} r={5} 
                            fill={chooseColorCode(item['hitResultCode'])}
                            key={"circle_"+item['hitResultCode']}
                            ></circle>
                            <text dy={15*idx+4} dx={15} fontSize="11px"
                            key={"text_"+item['hitResultCode']}
                            >
                                {item['hitResultName']}
                            </text>
                        </g>
                    ))}
                </g>
                <g transform={`translate(0,0)`} ref={textBox} 
                visibility={'hidden'}
                style={{fontSize: '12px'}}>
                    <rect ref={rectBox}
                    width="145" height="65"
                    fill={'#fff'}
                    rx={10}
                    ry={10}
                    stroke={'#000'}
                    ></rect>
                    <text ref={infoBox}
                    dominantBaseline={'middle'} 
                    ></text>
                </g>
            </svg>
        </div>
    )
}

export default HitCourseZoneChart;