import _ from 'lodash';
import React from 'react'

function LineupTableComponent(props) {
    let {data} = props;

    return (
    <>
    {
    !_.isEmpty(data)
    ?
    <table className='record_table'>
        <thead>
            <tr>
                <th>이닝</th>
                <th>K%</th>
                <th>BB%</th>
                <th>ERA</th>
                <th>WHIP</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{data['statCalced_ip2']}</td>
                <td>{data['statCalced_kRate']}</td>
                <td>{data['statCalced_bbRate']}</td>
                <td>{data['statCalced_era']}</td>
                <td>{data['statCalced_whip']}</td>
            </tr>
        </tbody>
        <thead>
            <tr>
                <th>IP/G</th>
                <th>Pit/IP</th>
                <th>QS</th>
                <th>피안타율</th>
                <th>피OPS</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{data['statCalced_gDivIp']}</td>
                <td>{data['statCalced_pDivip']}</td>
                <td>{data['statCalced_stat_qs']}</td>
                <td>{data['statCalced_avg']}</td>
                <td>{data['statCalced_ops']}</td>
            </tr>
        </tbody>
    </table>
    :
    <table className='record_table'>
        <thead>
            <tr>
                <th>이닝</th>
                <th>K%</th>
                <th>BB%</th>
                <th>ERA</th>
                <th>WHIP</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
            </tr>
        </tbody>
        <thead>
            <tr>
                <th>IP/G</th>
                <th>Pit/IP</th>
                <th>QS</th>
                <th>피안타율</th>
                <th>피OPS</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
            </tr>
        </tbody>
    </table>
    }
    </>
    )
}

export default LineupTableComponent;