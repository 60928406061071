import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";

const LoginPage = () => {
    const { onLogin } = useAuth();

    const [userId, setUserId] = useState("");
    const [userPw, setUserPw] = useState("");

    // validation 관리용 state
    const [validUserId, setValidUserId] = useState(false);
    const [validUserPw, setValidUserPw] = useState(false);
    const [messageUserId, setMessageUserId] = useState("");
    const [messageUserPw, setMessageUserPw] = useState("");

    const navigate = useNavigate();

    const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&scope=email profile`;
    const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
    const naverUrl = `https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}&response_type=code`;

    const googleLoginBtnHandler = (e) => {
        window.location.href = googleUrl;
    }

    const kakaoLoginBtnHandler = (e) => {
        window.location.href = kakaoUrl;
    }

    const naverLoginBtnHandler = (e) => {
        window.location.href = naverUrl;
    }

    const saveUserId = event => {
        setUserId(event.target.value.trim());
    };

    const saveUserPw = event => {
        setUserPw(event.target.value.trim());
    };

    const handleOnKeyDown = event => {
        if(event.key === "Enter"){
            loginHandler();
        }
    }

    const forgotIdHandler = () => {
        navigate('/forgot/id');
    }

    const forgotPwHandler = () => {
        navigate('/forgot/pw');
    }

    const loginHandler = () => {
        let user = {
            id: userId,
            pw: userPw
        };

        if(validationCheck()){
            onLogin(user);
        }
    }

    const validationCheck = () => {
        let checkResult = true;

        if(userId === ""){
            setValidUserId(true);
            setMessageUserId("∙ 아이디를 입력해주세요.");
            checkResult = false;
        } else {
            setValidUserId(false);
            setMessageUserId("");
        }

        if(userPw === ""){
            setValidUserPw(true);
            setMessageUserPw("∙ 비밀번호를 입력해주세요.");
            checkResult = false;
        } else {
            setValidUserPw(false);
            setMessageUserPw("");
        }

        return checkResult;
    }

    return (
        <div className="container">
            <div id="loginBox">
                <div className="titleWrap">
                    <span>Membership</span><span>Login</span>
                </div>

                <form>
                    <div className="inputWrap">
                        <input type="text" className={validUserId ? "warning" : ""}
                            name="id" placeholder="ID" value={userId} onChange={saveUserId} onKeyDown={handleOnKeyDown} />
                    </div>
                    <div className="inputWrap">
                        <input type="password" className={validUserPw ? "warning" : ""}
                            name="pw" placeholder="PASSWORD" value={userPw} onChange={saveUserPw} onKeyDown={handleOnKeyDown} autoComplete="off" />
                    </div>
                    <div id="warningMessage">
                        <div>
                            {messageUserId}
                        </div>
                        <div>
                            {messageUserPw}
                        </div>
                    </div>
                </form>

                <div className="fncWrap">
                    <button id="forgotId" onClick={forgotIdHandler}>아이디 찾기</button>
                    <button id="forgotPw" onClick={forgotPwHandler}>비밀번호 찾기</button>
                </div>

                <div className="buttonWrap">
                    <button id="loginBtn" onClick={loginHandler}>
                        LOGIN
                    </button>

                    <button id="googleBtn" onClick={googleLoginBtnHandler}>
                        <div className="icon google"></div>
                        Google 로그인
                    </button>

                    <button id="kakaoBtn" onClick={kakaoLoginBtnHandler}>
                        <div className="icon kakao"></div>
                        카카오 로그인
                    </button>

                    <button id="naverBtn" onClick={naverLoginBtnHandler}>
                        <div className="icon naver"></div>
                        네이버 로그인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;