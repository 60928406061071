import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchPitcherSituationRecord } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';
import moment from 'moment';
import weekDaysCode from '../../../utils/code/weekDaysCode';
const weekDaysArr = weekDaysCode();

function PitcherSituation2RecordBox() {
    const statArr = [
        {key:'day', name: '요일'},
        {key:'homeOrAway', name: '홈/어웨이'},
        {key:'stadium', name: '구장'},
        {key:'outCount', name: '아웃카운트'},
        {key:'cycle', name: '사이클'}];
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get("season");
    const [recordObj, setRecordObj] = useState({
        day: [],
        homeOrAway: [],
        stadium: [],
        outCount: [],
        cycle: []
    });
    
    useEffect(() => {
        const playerId = params['playerId'];
        const commonParam = {
            seasons: season,
            gameTypeCodes: 4201,
            pitcherIds: playerId,
            fromDate: moment(new Date(season, 0, 1)).format('YYYY-MM-DD'),
            toDate: moment(new Date(season, 11, 31)).format('YYYY-MM-DD'),
        }

        const weekdayRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'weekDay'});
        const homeOrAwayRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'homeOrAway'});
        const stadiumRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'stadium'});
        const outCountRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'outCount'});
        const cycleRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'tpaByPitcher'})

        Promise.all([
            weekdayRecordPromise, homeOrAwayRecordPromise,
            stadiumRecordPromise, outCountRecordPromise,
            cycleRecordPromise
        ]).then((response) => {
            const weekdayRecord = loopFlatObject(response[0]['data']['RECORD']);
            const homeOrAwayRecord = loopFlatObject(response[1]['data']['RECORD']);
            const stadiumRecord = loopFlatObject(response[2]['data']['RECORD']);
            const outCountRecord = loopFlatObject(response[3]['data']['RECORD']);
            const cycleRecord = loopFlatObject(response[4]['data']['RECORD'])

            setRecordObj((prevObj) => {
                return {
                    ...prevObj,
                    day: weekdayRecord,
                    homeOrAway: homeOrAwayRecord,
                    stadium: stadiumRecord,
                    outCount: outCountRecord,
                    cycle: cycleRecord
                }
            });
        })
    }, [])

    const SituationColumn = (props) => {
        const {data} = props;
        switch(props['type']) {
            case 'day':
                const findData = _.find(weekDaysArr, item => item['weekDay'] === data['situation_weekDay']);
                return  <td>
                    {findData['weekDayName']}
                </td>
            case 'homeOrAway':
                return <td>
                    {data['situation_homeOrAway']}
                </td>
            case 'stadium':
                return <td>
                    {data['situation_stadium']}
                </td>
            case 'outCount':
                return <td>
                    {data['situation_beforeOutCount']}
                </td>
            case 'cycle':
                return <td>
                    {data['situation_tpaByPitcher']}
                </td>
            default:
                return <td></td>
        }
    }

    return (
    <div id="situation_basic_container">
    {
    _.map(statArr, (item) => (
    <Fragment key={item['name']}>
        <div className="table_title">{item['name']}</div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>타자수</th>
                        <th>이닝</th>
                        <th>투구수</th>
                        <th>피안타</th>
                        <th>피2루타</th>
                        <th>피3루타</th>
                        <th>피홈런</th>
                        <th>탈삼진</th>
                        <th>사사구</th>
                        <th>실점</th>
                        <th>자책</th>
                        <th>평균자책점</th>
                        <th>WHIP</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(recordObj[item['key']], (data, dataIdx) => (
                    <tr key={item['key']+dataIdx}>
                        <SituationColumn type={item['key']} data={data} />
                        <td>{data['statCacled_stat_tpa']}</td>
                        <td>{data['statCacled_ip']}</td>
                        <td>{data['statCacled_stat_pitchingCnt']}</td>
                        <td>{data['statCacled_stat_hit']}</td>
                        <td>{data['statCacled_stat_b2b']}</td>
                        <td>{data['statCacled_stat_b3b']}</td>
                        <td>{data['statCacled_stat_hr']}</td>
                        <td>{data['statCacled_stat_so']}</td>
                        <td>{data['statCacled_stat_totalBb']}</td>
                        <td>{data['statCacled_stat_r']}</td>
                        <td>{data['statCacled_stat_er']}</td>
                        <td>{data['statCacled_stat_era']}</td>
                        <td>{data['statCacled_whip']}</td>
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
    </Fragment>
    ))
    }
    </div>
    )
}

export default PitcherSituation2RecordBox;