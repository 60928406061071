import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import pitchingColumnCalc from '../../../../utils/table/pitchingColumnCalc';
import { usePitcherTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import pitcherTooltip from '../../../../utils/tooltip/pitcherTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = pitcherTooltip();

function TeamPitchingBasicRecord(props) {
    const statType = "basic";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_ip",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL,
        { name: '시즌', key: 'season', frozen: true, sortable: false},
        { name: '팀', key: 'pitcherTeamName', frozen: true, sortable: false, renderCell: usePitcherTeamRender},
        // { name: '선수', key: 'pitcherName', frozen: true, sortable: false},
        { name: '피안타율', width: 72, key: 'statCalced_avg', renderHeaderCell: tooltipRender, tooltip: tooltip['avg']},
        { name: '피출루율', width: 72, key: 'statCalced_obp', renderHeaderCell: tooltipRender, tooltip: tooltip['obp']},
        { name: '피장타율', width: 72, key: 'statCalced_slg', renderHeaderCell: tooltipRender, tooltip: tooltip['slg']},
        { name: '피OPS', width: 72, key: 'statCalced_ops', renderHeaderCell: tooltipRender, tooltip: tooltip['ops']},
        { name: '출장', width: 55, key: 'statCalced_stat_gameCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: '선발', width: 55, key: 'stat_starter', renderHeaderCell: tooltipRender, tooltip: tooltip['starter']},
        { name: '구원', width: 5, key: 'stat_substitute', renderHeaderCell: tooltipRender, tooltip: tooltip['substitute']},
        { name: '승', key: 'win', renderHeaderCell: tooltipRender, tooltip: tooltip['win']},
        { name: '패', key: 'lose', renderHeaderCell: tooltipRender, tooltip: tooltip['lose']},
        { name: '세', key: 'save', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['save']},
        { name: '홀', key: 'hold', renderHeaderCell: tooltipRender, tooltip: tooltip['hold']},
        { name: '블론', width: 55, key: 'stat_blown', renderHeaderCell: tooltipRender, tooltip: tooltip['blown']},
        { name: '이닝', width: 55, key: 'statCalced_ip', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: '투구', width: 55, key: 'stat_pitchingCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['pitchingCnt']},
        { name: '실점', width: 55, key: 'stat_r', renderHeaderCell: tooltipRender, tooltip: tooltip['r']},
        { name: '자책', width: 55, key: 'stat_er', renderHeaderCell: tooltipRender, tooltip: tooltip['er']},
        { name: '타석', width: 55, key: 'stat_tpa', renderHeaderCell: tooltipRender, tooltip: tooltip['tpa']},
        { name: '타수', width: 55, key: 'stat_ab', renderHeaderCell: tooltipRender, tooltip: tooltip['ab']},
        { name: '안타', width: 55, key: 'stat_hit', renderHeaderCell: tooltipRender, tooltip: tooltip['hit']},
        { name: '1루타', width: 63, key: 'stat_b1b', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['b1b']},
        { name: '2루타', width: 63, key: 'stat_b2b', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['b2b']},
        { name: '3루타', width: 63, key: 'stat_b3b', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['b3b']},
        { name: '홈런', width: 55, key: 'stat_hr', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['hr']},
        { name: '장타', width: 55, key: 'stat_xh', renderHeaderCell: tooltipRender, tooltip: tooltip['xh']},
        { name: '삼진', width: 55, key: 'stat_so', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['so']},
        { name: '볼넷', width: 55, key: 'stat_bbAddIbb', renderHeaderCell: tooltipRender, tooltip: tooltip['bbAddIbb']},
        { name: '고의4구', width: 70, key: 'stat_ibb', renderHeaderCell: tooltipRender, tooltip: tooltip['ibb']},
        { name: '사구', width: 55, key: 'stat_hbp', renderHeaderCell: tooltipRender, tooltip: tooltip['hbp']},
        { name: '희비', width: 55, key: 'stat_sf', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['sf']},
        { name: '희타', width: 55, key: 'stat_sb', renderHeaderCell: tooltipRender, tooltip: tooltip['sb']},
        { name: '승률', width: 55, key: 'statCalced_winRate', renderHeaderCell: tooltipRender, tooltip: tooltip['winRate']},
        { name: "ERA", width: 63, key: 'statCalced_era', renderHeaderCell: tooltipRender, tooltip: tooltip['era']},
        { name: "FIP", width: 63, key: 'statCalced_fip', renderHeaderCell: tooltipRender, tooltip: tooltip['fip']},
        { name: "WHIP", width: 63, key: 'statCalced_whip', renderHeaderCell: tooltipRender, tooltip: tooltip['whip']},
        { name: "WPA", width: 63, key: 'stat_wpa', renderHeaderCell: tooltipRender, tooltip: tooltip['wpa']},
        { name: "bWAR", width: 63, key: 'statCalced_bWar', renderHeaderCell: tooltipRender, tooltip: tooltip['bWar']},
        { name: "fWAR", width: 63, key: 'statCalced_pitcherWAR', renderHeaderCell: tooltipRender, tooltip: tooltip['pitcherWAR']}
    ];

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {

                const comparator = pitchingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['pitcherId'];
    }

    const gridElement = (
        <DataGrid
        className="record_team_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '팀 투구 기본기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default TeamPitchingBasicRecord;