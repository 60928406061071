import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Oval } from 'react-loader-spinner';
import { loopFlatObject } from '../../../utils/flat';
import { fetchLastGameDay, fetchPitcherBallType, fetchPitcherRecordDetail, fetchPitcherRecordTpa, fetchStraightPitch } from '../../../utils/api';
import TeamPitchingBasicRecord from './pitching/team-pitching-basic-record';
import TeamPitchingDetailRecord from './pitching/team-pitching-detail-record';
import TeamPitchingTpaRecord from './pitching/team-pitching-tpa-record';
import TeamPitchingHitRecord from './pitching/team-pitching-hit-record';
import TeamPitchingClutchRecord from './pitching/team-pitching-clutch-record';
import TeamPitchingStarterRecord from './pitching/team-pitching-starter-record';
import TeamPitchingReliefRecord from './pitching/team-pitching-relief-record';
import TeamPitchingBallSpeedRecord from './pitching/team-pitching-ballspeed-record';
import { useAtom } from 'jotai';
import { team_pitching_filter_atom } from '../../../assets/model/detail/store';
import moment from 'moment';
import TeamPitchingFilter from '../../../components/filter/team-pitching-filter';
import _ from 'lodash';
import FilterToggleButton from '../../../components/button/FilterToggleButton';
import { Collapse } from '@mui/material';

function TeamPitchingRecord() {
    const navigate = useNavigate();
    const [filterObj, setFilterObj] = useAtom(team_pitching_filter_atom);

    const [record, setRecord] = useState([]);
    const [open, setOpen] = useState(false);
    const params = useParams();
    const tabId = params['tabId'];
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        if(!filterObj['seasons']) {
            const todaySeason = moment(new Date()).format('YYYY');
            fetchLastGameDay({
                seasons: [todaySeason, todaySeason-1].toString(),
                gameTypeCodes: 4201
            }).then((response) => {
                const {lastGameDay} = response['data']['RECORD'][0];
                const lastSeason = moment(lastGameDay).format('YYYY');

                setFilterObj((prev) => {
                    return {...prev, seasons: lastSeason}
                })
            })
        }
    }, [])

    useEffect(() => {
        if(filterObj['seasons']) {
            setOpen(true);
            const {seasons} = filterObj;
            const recordParam = {
                ...filterObj,
                gameTypeCodes: 4201,
                groupingColumn: 'seasonTeam'
            }

            _.map(recordParam, (item, idx) => {
                if(Array.isArray(item)) {
                    return recordParam[idx] = item.toString();
                }
            });

            if(!recordParam['toDate'] && !recordParam['fromDate']) {
                recordParam['fromDate'] = moment(new Date(seasons, 0, 1)).format('YYYY-MM-DD');
                recordParam['toDate'] = moment(new Date(seasons, 11, 31)).format('YYYY-MM-DD');
            }

            if(tabId === 'starter') {
                recordParam['pitcherPositionTypeCodes'] = 5102;
            } else if(tabId === 'relief') {
                recordParam['pitcherPositionTypeCodes'] = '5101, 5103, 5104';
            }

            if(tabId === 'ballspeed') {
                fetchPitcherBallType(recordParam)
                .then((response) => {
                    const pitchingRecord = loopFlatObject(response['data']['RECORD']);
                    const pitcherGroupData = _.groupBy(pitchingRecord, (item, idx) => {
                        return item['pitcher_pitcherTeamName'];
                    });

                    const ballCodeGroup = _.groupBy(pitchingRecord, (item) => item['ballCode']);
                    const ballCodeArr = [];

                    _.forEach(ballCodeGroup, (item, idx) => {
                        const ballCode = item[0]['ballCode'];
                        const ballCodeName = item[0]['ballCodeName'];

                        ballCodeArr.push({ballCode, ballCodeName});
                    });

                    const pitcherBallRecordArr = [];
                    _.forEach(pitcherGroupData, (item, idx) => {
                        const playerObj = item[0];

                        let keyByData = _.keyBy(item, 'ballCode');
                        let pitcherObj = {
                            playerId: idx, season: playerObj['season'],
                            playerName: playerObj['pitcher_pitcherName'],
                            playerTeamName: playerObj['pitcher_pitcherTeamName'],
                            totalCnt: 0
                        };

                        _.forEach(ballCodeArr, (innerItem, innerIdx) => {
                            const innerBallCode = innerItem['ballCode'];
                            if(keyByData[innerBallCode]) { //exist ballcode
                                const ballObj = keyByData[innerBallCode];
                                pitcherObj[innerBallCode+"_cnt"] = ballObj['pitch'];
                                pitcherObj[innerBallCode+"_name"] = ballObj['ballCodeName'];
                                pitcherObj[innerBallCode+"_rate"] = (ballObj['pitchRate']*100).toFixed(1);
                                pitcherObj['totalCnt'] += ballObj['pitch']*1;
                            } else {
                                pitcherObj[innerBallCode+"_cnt"] = '-';
                                pitcherObj[innerBallCode+"_name"] = innerItem['ballCodeName'];
                                pitcherObj[innerBallCode+"_rate"] = '-';
                            }
                        });

                        pitcherBallRecordArr.push(pitcherObj);
                    });
                    setRecord(pitcherBallRecordArr);
                    setOpen(false);
                })
            } else if(tabId === 'tpa') {
                fetchPitcherRecordTpa(recordParam)
                .then((response) => {
                    const pitchingRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(pitchingRecord);
                    setOpen(false);
                })
            } else if(tabId === 'relief') {
                const recordDetailPromise = fetchPitcherRecordDetail(recordParam);
                const reliefPromise = fetchStraightPitch({
                    ...recordParam, groupingColumn: 'team'
                });

                Promise.all([
                    recordDetailPromise,
                    reliefPromise
                ]).then((response) => {
                    const pitchingRecord = loopFlatObject(response[0]['data']['RECORD']);
                    const straightData = response[1]['data']['RECORD'];

                    let pitchDay1Total = 0;
                    let pitchDay2Total = 0;
                    let pitchDay3Total = 0;
                    _.each(pitchingRecord, function(item, idx){
                        item["straightPitchDay1"] = 0;
                        item["straightPitchDay2"] = 0;
                        item["straightPitchDay3"] = 0;
                        _.each(straightData, function(InnerItem, InnerIdx){
                            if(item['pitcherTeamId'] == InnerItem['pitcherTeamId']){
                                if(InnerItem["straightPitchDay"]*1 === 1){
                                    pitchDay1Total = pitchDay1Total+InnerItem["straightPitchDayCount"]*1
                                    item["straightPitchDay1"] = InnerItem["straightPitchDayCount"]*1
                                }else if(InnerItem["straightPitchDay"]*1 === 2){
                                    pitchDay2Total = pitchDay2Total+InnerItem["straightPitchDayCount"]*1
                                    item["straightPitchDay2"] = InnerItem["straightPitchDayCount"]*1
                                }else{
                                    pitchDay3Total = pitchDay3Total+InnerItem["straightPitchDayCount"]*1
                                    var pitchDayTotal = 0;
                                    item["straightPitchDay3"] = pitchDayTotal+(InnerItem["straightPitchDayCount"]*1)
                                }
                            }
                        })
                    });

                    setRecord(pitchingRecord);
                    setOpen(false);
                })
            } else {
                fetchPitcherRecordDetail(recordParam)
                .then((response) => {
                    const pitchingRecord = loopFlatObject(response['data']['RECORD']);
                    console.log(pitchingRecord);
                    setRecord(pitchingRecord);
                    setOpen(false);
                })
            }
        }
    }, [filterObj]);

    const TeamPitchingRecordContent = () => {
        switch(tabId) {
            case 'basic':
                return <TeamPitchingBasicRecord data={record} />
            case 'detail':
                return <TeamPitchingDetailRecord data={record} />
            case 'tpa':
                return <TeamPitchingTpaRecord data={record} />
            case 'hit':
                return <TeamPitchingHitRecord data={record} />
            case 'ballspeed':
                return <TeamPitchingBallSpeedRecord data={record}/>
            case 'clutch':
                return <TeamPitchingClutchRecord data={record} />
            case 'starter':
                return <TeamPitchingStarterRecord data={record} />
            case 'relief':
                return <TeamPitchingReliefRecord data={record} />
            case 'ballspeed':
            default:
                return <></>;
        }
    }

    const filterToggleEventHandler = () => {
        setFilterOpen(!filterOpen);
    }

    return (
    <>
    <div id="record_sub_tab_container">
        <div id="record_sub_tab_area">
            <div className={"btn "+((tabId === "basic") && "active")}
            onClick={() => navigate('/record/team/pitching/basic')}
            >
            기본
            </div>
            <div className={"btn "+((tabId === "detail") && "active")}
            onClick={() => navigate('/record/team/pitching/detail')}
            >
            상세
            </div>
            <div className={"btn "+((tabId === "tpa") && "active")}
            onClick={() => navigate('/record/team/pitching/tpa')}
            >
            타석
            </div>
            <div className={"btn "+((tabId === "hit") && "active")}
            onClick={() => navigate('/record/team/pitching/hit')}
            >
            안타
            </div>
            <div className={"btn "+((tabId === "ballspeed") && "active")}
            onClick={() => navigate('/record/team/pitching/ballspeed')}
            >
            구질
            </div>
            <div className={"btn "+((tabId === "clutch") && "active")}
            onClick={() => navigate('/record/team/pitching/clutch')}
            >
            클러치
            </div>
            <div className={"btn "+((tabId === "starter") && "active")}
            onClick={() => navigate('/record/team/pitching/starter')}
            >
            선발
            </div>
            <div className={"btn "+((tabId === "relief") && "active")}
            onClick={() => navigate('/record/team/pitching/relief')}
            >
            구원
            </div>
        </div>
        <div>
            <FilterToggleButton
            onClick={filterToggleEventHandler}
            >
                필터
            </FilterToggleButton>
        </div>
    </div>
    <div id="record_sub_container">
        <Collapse in={filterOpen} timeout={'auto'}>
            <TeamPitchingFilter/>
        </Collapse>
        {
            record.length === 0
            ?
            <></>
            :
            <TeamPitchingRecordContent/>
        }    
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
            <Oval
            height="80" width="80"
            radius="9" color="#00bfff"
            secondaryColor="#fff"
            ariaLabel="three-dots-loading"
            />
        </Backdrop>
    </div>
    </>
    )
}

export default TeamPitchingRecord;