import GridOnIcon from '@mui/icons-material/GridOn';
import { Button } from '@mui/material';
import { createTheme, alpha, ThemeProvider } from '@mui/material/styles';

const hiballBase = '#20489d';
const hiballMain = alpha(hiballBase, 0.7);

const theme = createTheme({
    palette: {
        hiball: {
            main: hiballMain
        }
    },
});

const ExportCsvButton = (props) => {
    return (
    <ThemeProvider theme={theme}>
        <Button variant='outlined' 
        size="small"
        color="hiball"
        onClick={props.onClick}
        >
            <div style={{display: 'flex', alignItems: "flex-end"}}>
                <GridOnIcon/>
                {props.children}
            </div>
        </Button>
    </ThemeProvider>
    )
}

export default ExportCsvButton;