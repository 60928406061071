import React, { useState } from 'react';
import TeamBasicRecordBox from './sub/team-basic-record-box';
import TeamDepthBox from './sub/team-depth-box';
import TeamPlayerBox from './sub/team-player-box';
import TeamRecentLineupBox from './sub/team-recent-lineup-box';
import TeamWinloseBox from './sub/team-winlose-box';

function TeamRecordBox() {
    const [subId, setSubId] = useState('team_basic');

    const subMenuEventHandler = (e) => {
        const id = e.currentTarget.id;
        setSubId(id);
    }

    const SubTabContent = function() {
        switch(subId) {
            case 'team_basic':
                return <TeamBasicRecordBox />;
            case 'team_depth':
                return <TeamDepthBox />;
            case 'team_player':
                return <TeamPlayerBox />;
            case 'team_lineup':
                return <TeamRecentLineupBox />;
            case 'team_winlose':
                return <TeamWinloseBox />;
            default:
                return <></>;
        }
    }

    return (
    <>
    <div id="team_tab_btn_area">
        <div id="team_basic"
        className={"btn "+(subId === 'team_basic' && 'active')}
        onClick={subMenuEventHandler}
        >기본</div>
        <div id="team_player"
        className={"btn "+(subId === 'team_player' && 'active')}
        onClick={subMenuEventHandler}
        >선수</div>
        <div id="team_depth"
        className={"btn "+(subId === 'team_depth' && 'active')}
        onClick={subMenuEventHandler}
        >뎁스</div>
        <div id="team_lineup"
        className={"btn "+(subId === 'team_lineup' && 'active')}
        onClick={subMenuEventHandler}
        >시즌라인업</div>
        <div id="team_winlose"
        className={"btn "+(subId === 'team_winlose' && 'active')}
        onClick={subMenuEventHandler}
        >승무패</div>
    </div>
    <div id="team_tab_content">
        <SubTabContent/>
    </div>
    </>
    )
}

export default TeamRecordBox;