import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchPitcherRecord, fetchTeamRecentLineupInfo } from '../../../utils/api';
import _ from 'lodash';

function WeeklyRotationInfo(props) {
    const {fromDate, toDate, teamId} = props;
    const [rotation, setRotation] = useState({});
    const [dayArr, setDayArr] = useState([]);

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const dateArr = [];
        for(let i=0; i < 7; i++) {
            const tmpDate = moment(fromDate).add(i, 'd').format('YYYY-MM-DD');
            dateArr.push(tmpDate);
        }

        setDayArr(dateArr);

        const commonParam = {
            gameTypeCodes: 4201,
            teamIds: teamId,
            seasons, fromDate, toDate,
            rosterTypeCodes: 5102
        }

        const teamRecentLineupPromise = fetchTeamRecentLineupInfo(commonParam)

        teamRecentLineupPromise.then((response) => {
            const rosterPlayer = response['data']['RECORD'];
            const groupedData = _.groupBy(rosterPlayer, item => item['gameDay']);

            const promiseArr = [];
            _.forEach(rosterPlayer, (item, idx) => {
                const pitcherParam = {
                    gameTypeCodes: 4201,
                    gameInfoIds: item['gameInfoId'],
                    pitcherId: item['playerId']
                }

                promiseArr.push(
                    fetchPitcherRecord(pitcherParam)
                )
            });

            Promise.all(
                promiseArr
            ).then((response) => {
                const recordArr = [];
                _.forEach(response, (item, idx) => {
                    recordArr.push(item['data']['RECORD'][0]);
                });

                const pitcherRecord = _.groupBy(recordArr, item => item['gameDay']);
                setRotation(pitcherRecord);
            })
        })
    }, [fromDate, teamId])

    return (
    <>
    <div className="title">선발 로테이션</div>
    <div style={{display:'flex', justifyContent: 'space-between', textAlign:'center', flexDirection:'column'}}>
        <div className='record_div'>
            <div className='half_div'>날짜</div>
            <div className='half_div'>기록</div>
        </div>
    {
    rotation.length !== 0
    &&
    dayArr.map((item) => (
        <div key={item} className='record_div'>
            <div className='half_div'>{item}</div>
            <div className='half_div'>
            {
            rotation[item] ?
            rotation[item].map((innerItem) => (
                <div key={innerItem['gameInfoId']}>
                    {innerItem['pitcherName']+" "}
                    {innerItem['ip']}이닝 {innerItem['basicStat']['r']}실점 {innerItem['basicStat']['er']}자책
                </div>
            ))
            :
            <>{'경기 없음'}</>
            }
            </div>
        </div>
    ))
    }
    </div>
    </>
    )
}

export default WeeklyRotationInfo;