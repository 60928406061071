export function zoneCalc(width, height, data, playerType) {
    const originWidth = 1000;
    const originHeight = 1000;

    const originPerWidth = 200;
    const originPerHeight = 200;

    const calcWidth = width;
    const calcHeight = height;

    const perWidth = (originPerWidth/originWidth)*width;
    const perHeight = (originPerHeight/originHeight)*height;

    const x = (data['pitchZoneX']*calcWidth/originWidth);
    const y = calcHeight - (data['pitchZoneY']*calcHeight/originHeight);

    let zoneString = 0;

    if(playerType === 'batter') {
        if( (x >= perWidth*1 && x < perWidth*4 && y >= perWidth*1 && y < perWidth*4) ) {
            for( let i=1; i < 4; i++) {
                for( let j=1; j < 4; j++) {
                    if(x >= perWidth*i && x < perWidth*(i+1) && y >= perHeight*j && y < perHeight*(j+1) ) {
                        zoneString = (4-i)+"_"+j;
                    }
                }
            }
        } else {
            if ( 0 < x && (calcWidth/2) >= x && 0 < y && (calcHeight/2) >= y) {
                zoneString = 12;
            } else if ( (calcWidth/2) < x && calcWidth >= x && 0 < y && (calcHeight/2) >= y) {
                zoneString = 11;
            } else if ( 0 < x && (calcWidth/2) >= x && (calcHeight/2) < y && calcHeight >= y) {
                zoneString = 14;
            } else if ( (calcWidth/2) < x && calcWidth >= x && (calcHeight/2) < y && calcHeight >= y) {
                zoneString = 13;
            }
        }
    } else { //pitcher
        if( (x >= perWidth*1 && x < perWidth*4 && y >= perWidth*1 && y < perWidth*4) ) {
            for( let i=1; i < 4; i++) {
                for( let j=1; j < 4; j++) {
                    if(x >= perWidth*i && x < perWidth*(i+1) && y >= perHeight*j && y < perHeight*(j+1) ) {
                        zoneString = i+"_"+j;
                    }
                }
            }
        } else {
            if ( 0 < x && (calcWidth/2) >= x && 0 < y && (calcHeight/2) >= y) {
                zoneString = 11;
            } else if ( (calcWidth/2) < x && calcWidth >= x && 0 < y && (calcHeight/2) >= y) {
                zoneString = 12;
            } else if ( 0 < x && (calcWidth/2) >= x && (calcHeight/2) < y && calcHeight >= y) {
                zoneString = 13;
            } else if ( (calcWidth/2) < x && calcWidth >= x && (calcHeight/2) < y && calcHeight >= y) {
                zoneString = 14;
            }
        }
    }

    return zoneString;
}