import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '0-0', name: '0-0'},
    {id: '0-1', name: '0-1'},
    {id: '0-2', name: '0-2'},
    {id: '1-0', name: '1-0'},
    {id: '1-1', name: '1-1'},
    {id: '1-2', name: '1-2'},
    {id: '2-0', name: '2-0'},
    {id: '2-1', name: '2-1'},
    {id: '2-2', name: '2-2'},
    {id: '3-0', name: '3-0'},
    {id: '3-1', name: '3-1'},
    {id: '3-2', name: '3-2'}
];

function BsCountCheckboxItem(props) {
    const {value, setValue} = props;
    const [checkIdArr, setCheckIdArr] = useState(value || []);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setValue((prev) => {
            return {...prev, beforeBallStrikeCnts: checkIdArr}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="bs-count-checkbox-label"
        sx={{fontSize: 12}}
        >B-S</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="bs-count-checkbox-label"
        id="bs-count-checkbox"
        multiple
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        input={<OutlinedInput label="B-S" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(rows, (item, idx) => selected.indexOf(item['id']) > -1);
            const selectedName = _.map(selectedArr, 'name');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"beforeBallStrikeCnts_"+item['id']} value={item['id']}>
                <Checkbox checked={checkIdArr.indexOf(item['id']) > -1}/>
                <ListItemText primary={item['name']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default BsCountCheckboxItem;