import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import _ from 'lodash';
import { fetchPlayerInfo } from '../../utils/api';
import SearchList from './SearchList';

function PlayerSearchBox() {
    let [playerArr, setPlayerArr] = useState([]);
    let inputRef = useRef();
    let listRef = useRef();
    const teamIdArr = [1001, 2001, 3001, 4001, 5001, 6001, 7001, 8001, 9001, 10001];
    const todaySeason = new Date().getFullYear();
    const navigate = useNavigate();

    useEffect(() => {
        searchEvent();
    }, [])

    function searchEvent() {
        document.addEventListener('click', (event) => {
            if(event.target.id !== 'searchInput') {
                listRef.current.style.display = 'none';
            }
        });
    }

    function formKeyDownHandler(event) {
        if (event.key === 'Enter' && event.code == "Enter" && !event.nativeEvent.isComposing) {
            // 엔터 키가 눌렸을 때 실행할 동작
            let value = inputRef.current.value;

            try {
                validationFunc(value);
                searchFunc(value);
            } catch(err) {

            }

            event.preventDefault();
        }
    }

    function searchFunc(playerName) {
        const seasons = moment(new Date()).format('YYYY');

        const params = {seasons}

        if(isNaN(playerName)) { //string
            params['membersName'] = playerName;
        } else { //number
            params['backNumber'] = playerName;
        }

        fetchPlayerInfo(params)
        .then((response) => {
            let profiles = response['data']['RECORD'];
            
            let filteredProfiles = _.filter(profiles, function(i){
                if(teamIdArr.indexOf(i.team*1) > -1){
                    return true;
                } else {
                    return false;
                }
            })

            // console.log(filteredProfiles);

            searchProcess(filteredProfiles);
        })
    }

    function searchProcess(profiles) {
        console.log(profiles);
        let length = searchDataValidation(profiles);
        
        if(length == 1) {
            onePlayerProccess(profiles[0]);
        } else {
            oneMorePlayerProcess(profiles);
        }
    }

    function searchDataValidation(profiles) {
        if(profiles.length <= 0) {
            alert("존재하지 않는 선수명입니다.");
            throw new Error("존재하지 않는 선수명입니다.");
        }
        return profiles.length;
    }

    function onePlayerProccess(profile) {
        let playerId = profile.membersId;
        let {mainPosition} = profile;

        let playerType = (mainPosition === '투수') ? 'pitcher' : 'batter';
        navigate(`/player/${playerType}/${playerId}?season=${todaySeason}`);
    }

    function positionCheck(positionTypeCode) {
        if(positionTypeCode == 5201 || positionTypeCode == 5211) return 'pitcher';
        else return 'batter';
    }

    function oneMorePlayerProcess(profiles) {
        setPlayerArr(profiles);
        listRef.current.style.display = 'inline-block';
    }

    function validationFunc(playerName) {
        if(isNaN(playerName)) { // string
            let rc = '[a-z|A-Z|\*|\?|\!|\@|\#|\$|\%|\^|\&]+';
            let reg = new RegExp(rc);
            let t = reg.exec(playerName);
        
            if(t != null) {
                alert("한글만 입력가능합니다.");
                throw new Error("한글만 입력가능합니다.");
            }
        
            if(playerName.length < 2) {
                alert('검색 선수명이 최소 2글자 이상이어야 합니다.');
                throw new Error('검색 선수명이 최소 2글자 이상이어야 합니다.')
            }
        } else { //number
            
        }        
    }

    return (
        <>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        display="flex"
        justifyContent="flex-end"
        >
            <TextField
            onKeyDown={formKeyDownHandler}
            inputRef={inputRef}
            id="outlined-basic"
            label="선수 검색"
            variant="outlined" 
            size="small"
            />
        </Box>
        <div className="search_player_list_container" style={{display:'none'}} ref={listRef}>
            {/* <div className="search_player_list_conatiner_title">
                검색 선수
            </div> */}
            <div className="search_list_area" id="search_list_area">
                { 
                playerArr.length !== 0 && 
                <SearchList data={playerArr} />
                }
            </div>
        </div>
        </>
    )
}

export default PlayerSearchBox;