import React, { useState } from "react";
import { privateApi } from "../../interceptor/axios-interceptor";
import { useNavigate } from 'react-router-dom';

const ChangePasswordPage = () => {
    const [encContent, setEncContent] = useState('');
    const [encContentConfirm, setEncContentConfirm] = useState('');

    // validation 관리용 state
    const [validEncContent, setValidEncContent] = useState(false);
    const [validEncContentConfirm, setValidEncContentConfirm] = useState(false);

    const [messageEncContent, setMessageEncContent] = useState("");
    const [messageEncContentConfirm, setMessageEncContentConfirm] = useState("");

    const navigate = useNavigate();

    const moveToProfile = () => {
        navigate('/profile');
    }

    const saveEncContent = event => {
        setEncContent(event.target.value.trim());
    };

    const saveEncContentConfirm = event => {
        setEncContentConfirm(event.target.value.trim());
    };

    const checkSpacebar = event => {
        if(event.keyCode === 32){
            event.preventDefault();
        }
    }

    const validationReset = () => {
        setValidEncContent(false);
        setValidEncContentConfirm(false);
        setMessageEncContent("");
        setMessageEncContentConfirm("");
    }

    const validationCheck = () => {
        validationReset();
        let checkResult = true;

        if(encContent === ""){
            setValidEncContent(true);
            setMessageEncContent("∙ 비밀번호: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 비밀번호: 8~16자의 영문 소문자, 숫자, 특수문자
            const passwordRegex = /^(?=.*[a-z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

            if(!passwordRegex.test(encContent)){
                setValidEncContent(true);
                setMessageEncContent("∙ 비밀번호: 8~16자의 영문 소문자, 숫자, 특수문자 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(encContentConfirm === ""){
            setValidEncContentConfirm(true);
            setMessageEncContentConfirm("∙ 비밀번호 확인: 필수 정보입니다.");
            checkResult = false;
        } else {
            if(encContent !== "" && encContent !== encContentConfirm){
                setValidEncContentConfirm(true);
                setMessageEncContentConfirm("∙ 비밀번호가 일치하지 않습니다.");
                checkResult = false;
            }
        }

        return checkResult;
    }

    const onChangePassword = () => {
        if(validationCheck()){
            updateUserEnc()
            .then((res) => {
                let result = res.updateUserEnc;

                if(result > 0){
                    navigate('/profile');
                    alert("비밀번호를 변경했습니다.");
                } else {
                    navigate('/error');
                }
            });
        }
    };

    const updateUserEnc = () =>
        new Promise((resolve) => {
            const params = {
                encContent: encContent
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/member/updateUserEnc`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
        });

    return (
        <div className="container">
            <div id="joinBox">
                <div id="topBtnWrap">
                    <button className="funcBtn" id="cancleBtn" onClick={moveToProfile}>취소</button>
                </div>
                <form>
                    <div>
                        <div className="label">
                            비밀번호
                        </div>
                        <input type="password" className={validEncContent ? "warning" : ""}
                            name="encContent" value={encContent} maxLength="16" onChange={saveEncContent} onKeyDown={checkSpacebar} autoComplete="off" />
                    </div>
                    <div>
                        <div className="label">
                            비밀번호<br/>확인
                        </div>
                        <input type="password" className={validEncContentConfirm ? "warning" : ""}
                            name="encContent" value={encContentConfirm} maxLength="16" onChange={saveEncContentConfirm} onKeyDown={checkSpacebar} autoComplete="off" />
                    </div>
                    <div id="warningMessage">
                        <div>
                            {messageEncContent}
                        </div>
                        <div>
                            {messageEncContentConfirm}
                        </div>
                    </div>
                </form>

                <button id="joinBtn" onClick={onChangePassword}>
                    변경
                </button>
            </div>
        </div>
    );
};

export default ChangePasswordPage;