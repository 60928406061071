import _ from 'lodash';
import {chooseColorCode} from './color/commonColor';

export function hitZoneCalc(size, data) {
    let hitValue = configGroundPadding(size);
    let originSize = 500;

    let calcData = _.forEach(data, (item) => {
        if(item['hitCourseX'] && item['hitCourseY']) {
            item['x'] = item['hitCourseX']*size/originSize;
            item['y'] = item['hitCourseY']*size/originSize;
            item['color'] = chooseColorCode(item['hitResultCode']);
            item['key'] = (item['season']+"_"+item['gameRecordId']);
        }
    });

    return calcData;
}

function configGroundPadding(size) {
    let configObj = {};
    let originSize = 500;
    let hitConfig = {
        playgroundImgBorder: 9,
        playgroundImgBottom: 55,
        playgroundImgTop: 5,
        playgroundOuterBorder: 15
    }

    let canvasConfig = {
        size: 500,
        catcherPointY: 455,
        catcherPointX: 250,
        topPointY: 35,
        outerPointDis: 120,
        outerRadius: 250,
        pitcherPointY: 379.4,
        pitcherRadius: 9,
        inOutRadius: 110,
        topLength: 405,
        sideLength: 345,
        pointRadius: 8,
    }

    let hitOriSize = 350;

    configObj['size'] = size;
    configObj['catcherPointX'] = (canvasConfig.catcherPointX * size) / originSize;
    configObj['catcherPointY'] = (canvasConfig.catcherPointY * size) / originSize;
    configObj['outerPointDis'] = (canvasConfig.outerPointDis * size) / originSize;
    configObj['outerRadius'] = (canvasConfig.outerRadius * size) / originSize;
    configObj['topPointY'] = (canvasConfig.topPointY * size) / originSize;
    configObj['pitcherPointY'] = (canvasConfig.pitcherPointY * size) / originSize;
    configObj['pitcherRadius'] = (canvasConfig.pitcherRadius * size) / originSize;
    configObj['inOutRadius'] = (canvasConfig.inOutRadius * size) / originSize;
    configObj['topLength'] = (canvasConfig.topLength * size) / originSize;
    configObj['sideLength'] = (canvasConfig.sideLength * size) / originSize;
    configObj['rate'] = size/originSize;

    configObj['playgroundImgBorder'] = (hitConfig.playgroundImgBorder * size) / hitOriSize;
    configObj['playgroundImgBottom'] = (hitConfig.playgroundImgBottom * size) / hitOriSize;
    configObj['playgroundImgTop'] = (hitConfig.playgroundImgTop * size) / hitOriSize;
    configObj['playgroundOuterBorder'] = (hitConfig.playgroundOuterBorder * size) / hitOriSize;
    configObj['pointRadius'] = (canvasConfig.pointRadius * size) / hitOriSize;
}