import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_day_atom } from '../../../assets/model/detail/store';
import moment from 'moment';
import { fetchGameList } from '../../../utils/api';
import _ from 'lodash';
import LineupChartContainer from '../../../components/lineup/LineupChartContainer';
import { Grid } from '@mui/material';

function TeamLineupDepthBox() {
    const gameDay = useAtomValue(game_day_atom);
    const [gameList, setGameList] = useState(new Map());

    useEffect(() => {
        const gameDaySeason = moment(gameDay).format('YYYY');
        const gameDayString = moment(gameDay).format('YYYY-MM-DD');

        fetchGameList({
            gameDay: gameDayString,
            gameTypeCodes: 4201,
            seasons: gameDaySeason
        }).then((response) => {
            const sortedGameList = _.sortBy(response['data']['RECORD'], ['gameStatus']);
            const groupedDate = _.groupBy(sortedGameList, (item, idx) => item['awayTeamId']+"_"+item['homeTeamId']);

            const groupedMap = new Map();
            _.forEach(groupedDate, (item, idx) => {
                groupedMap.set(idx, item);
            });

            setGameList((prev) => {
                return groupedMap;
            });
        })
    }, [gameDay])

    const lineupRenderer = () => {
        const result = [];
        for (const [key, value] of gameList) {
            result.push(<LineupChartContainer gameInfoArr={value} key={key}/>);
        }

        return result;
    }

    return (
    <>
    <Grid className='container'>
        <div style={{
            display:'flex', justifyContent:'space-between',
            marginBottom: '20px', flexWrap: 'wrap'
        }}>
        {lineupRenderer()}
        </div>
    </Grid>
    </>
    )
}

export default TeamLineupDepthBox;