import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchTeamInfo, fetchTeamRanking } from '../../utils/api';
import _ from 'lodash';

function TeamInfoBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [teamInfo, setTeamInfo] = useState({});
    const [teamRecord, setTeamRecord] = useState({});

    useEffect(() => {
        const teamId = params['teamId'];
        const commonParam = {
            seasons: season,
            gameTypeCodes: 4201
        }

        const teamParam = {
            ...commonParam,
            teamIds: teamId,
        }

        const teamInfoPromise = fetchTeamInfo(teamParam);
        const teamRankingPromise = fetchTeamRanking(commonParam);

        Promise.all([
            teamInfoPromise, teamRankingPromise
        ]).then((response) => {
            setTeamInfo(response[0]['data']['RECORD'][0]);

            const rankings = _.values(response[1]['data']['RECORD'])[0];
            const rankingData = _.find(rankings, (item) => teamId === item['teamId']);
            setTeamRecord(rankingData);
        })
    }, [])

    return (
    <>
    <div className="trackmanArea">
        <div className="teamTitle">{'팀 정보'}</div>
    </div>
    <div className="body">
        <div className="top">
            <div className="backnum">
                <div className="backnum_wrap">
                {
                _.isEmpty(teamInfo) ?
                <div></div>
                :
                <img src={require(`/src/assets/image/common/team_logo/logo_${teamInfo['teamId']}_profile.png`)} />
                }
                </div>
            </div>
            <div className="profile">
                <table>
                    <tbody>
                        <tr>
                            <td>팀명 : </td><td>{teamInfo['name']}</td>
                        </tr>
                        <tr>
                            <td></td><td></td>
                        </tr>
                        <tr>
                            <td></td><td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div style={{padding: '0px 5px'}}>
        <table className="hiball_table">
            <thead>
                <tr>
                    <th>순위</th>
                    <th>팀</th>
                    <th>승</th>
                    <th>무</th>
                    <th>패</th>
                    <th>승률</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{teamRecord['ranking']}</td>
                    <td>{teamRecord['teamName']}</td>
                    <td>{teamRecord['win']}</td>
                    <td>{teamRecord['draw']}</td>
                    <td>{teamRecord['lose']}</td>
                    <td>{(teamRecord['winRate']*1).toFixed(3)}</td>
                </tr>
            </tbody>
        </table>
    </div>
    </>
    )
}

export default TeamInfoBox;