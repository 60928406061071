import React, { Fragment, useEffect, useState } from 'react';
import teamCode from '../../../utils/team/teamCode';
import _ from 'lodash';
import { fetchTeamRanking, fetchTeamWinResult } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VsRecordDialog from '../../../components/box/VsRecordDialog';
const teamCodeList = teamCode();


function TeamRelativeRanking(props) {
    const navigate = useNavigate();
    const {season} = props;
    const [recordObj, setRecordObj] = useState({});
    const [open, setOpen] = useState(false);
    const [teamId, setTeamId] = useState();
    
    useEffect(() => {
        if(season) {
            let teamRecordPromiseArr = [];

            _.forEach(teamCodeList, (team, idx) => {
                if(team['teamCode'] !== 'all') {
                    const teamParam = {
                        seasons: season,
                        gameTypeCodes: 4201,
                        teamIds: team['teamCode']
                    }
                    teamRecordPromiseArr.push(fetchTeamWinResult(teamParam));
                }
            });

            const rankingParam = {
                seasons: season,
                gameTypeCodes: 4201
            }

            const teamRankingPromise = fetchTeamRanking(rankingParam)
            teamRecordPromiseArr.push(teamRankingPromise);

            Promise.all(
                teamRecordPromiseArr
            ).then((response) => {
                const tmpObj = {};
                const teamRanking = _.values(response[10]['data']['RECORD'])[0];
                
                tmpObj['teamRanking'] = teamRanking;

                const versusRecordByKey = {};

                for(let i=0; i < 10; i++) {
                    let versusArr = response[i]['data']['RECORD'];
                    let versusByKey = _.keyBy(versusArr, (item) => {
                        return item['teamId']+"_"+item['oppTeamId']
                    });

                    Object.assign(versusRecordByKey, versusByKey);
                }

                tmpObj['versusRecordByKey'] = versusRecordByKey;
                setRecordObj(tmpObj);
            })
        }
    }, [season])

    const teamPlayerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const teamId = teamObj.split("/")[1];
        const season = moment(teamObj.split("/")[0]).format('YYYY');
        navigate(`/team/${teamId}?season=${season}`);
    }

    const vsRecordClickEventHandler = (e) => {
        const id = e.currentTarget.id;
        setTeamId({
            id: id,
            season
        });
        setOpen(true);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    

    if(_.isEmpty(recordObj)) {
        return <div>데이터를 불러오는 중입니다.</div>
    }

    return (
        <>
        <table className='hiball_table' style={{tableLayout: 'fixed', height: '400px'}}>
            <thead>
                <tr>
                    <th style={{width: '55px'}}>팀명</th>
                    {
                    recordObj['teamRanking'].map((team, idx) => (
                        <th key={'column_'+team['teamId']} style={{lineHeight: 1.1, padding: '2px'}}>
                            {team['teamName']}<br/>(승-무-패)
                        </th>
                    ))
                    }
                    <th>합계</th>
                </tr>
            </thead>
            <tbody>
                {
                recordObj['teamRanking'].map((team, idx) => (
                    <tr key={'row_'+team['teamId']}>
                        <td className='link'>
                            <div className="flex_div"
                            onClick={teamPlayerInfoHandler} id={team['gameDay']+"/"+team['teamId']}
                            >
                                <img
                                src={require(`/src/assets/image/common/team_logo/logo_${team['teamId']}.png`)}
                                alt={team['teamName']}
                                />
                                <span>{team['teamName']}</span>
                            </div>
                        </td>
                        {
                        recordObj['teamRanking'].map((oppTeam, idx) => (
                            recordObj['versusRecordByKey'][team['teamId']+"_"+oppTeam['teamId']] ?
                            <Fragment key={'td_'+team['teamId']+"_"+oppTeam['teamId']}>
                            <td key={'td_'+team['teamId']+"_"+oppTeam['teamId']}
                            >
                            <span 
                            className='link'
                            onClick={vsRecordClickEventHandler}
                            id={team['teamId']+"_"+oppTeam['teamId']}
                            >
                            {
                            recordObj['versusRecordByKey'][team['teamId']+"_"+oppTeam['teamId']]['win']
                            +"-"+
                            recordObj['versusRecordByKey'][team['teamId']+"_"+oppTeam['teamId']]['draw']
                            +"-"+
                            recordObj['versusRecordByKey'][team['teamId']+"_"+oppTeam['teamId']]['lose']
                            }
                            </span>
                            </td>
                            </Fragment>
                            :
                            (
                                team['teamId'] === oppTeam['teamId']
                                ? 
                                <td key={'td_'+team['teamId']+"_"+oppTeam['teamId']}>-</td>
                                :
                                <>
                                <td key={'td_'+team['teamId']+"_"+oppTeam['teamId']}>0-0-0</td>
                                </>
                            )
                        ))
                        }
                        <td>{`${team['win']}-${team['draw']}-${team['lose']}`}</td>
                    </tr>
                ))
                }
            </tbody>
        </table>
        <Dialog open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"상대전적"}
            </DialogTitle>
            <DialogContent>
                <VsRecordDialog teamObj={teamId} />
                {/* <DialogContentText id="alert-dialog-description">
                </DialogContentText> */}
            </DialogContent>
        </Dialog>
        </>
    )
}

export default TeamRelativeRanking;