import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '6604', name: '1루타'},
    {id: '6607', name: '2루타'},
    {id: '6610', name: '3루타'},
    {id: '6613,6629', name: '홈런'},
    {id: '6601,6623', name: '파울'},
    {id: '6608,6645', name: '병살타'},
    {id: '6614,6637,6642', name: '희비'},
    {id: '6616,6640,6641', name: '희타'},
    {id: '6602,6612', name: '플라이아웃'},
    {id: '6602,6605,6608,6611,6612,6614,6616,6618,6619,6620,6622,6627,6630,6631,6636,6637,6641,6638,6645', name: '범타'},
    {id: '6605,6638', name: '땅볼아웃'},
    {id: '6603,6624,6634,6649,6650,6635,6647,6659', name: '삼진'},
    {id: '6645', name: '삼중살'},
    {id: '6625', name: '보크'},
    {id: '6606,6628,6657,6648,6651,6657', name: '볼넷'},
    {id: '6609', name: '사구'},
    {id: '6628,6657', name: '고의4구'},
    {id: '6615,6640,6642', name: '에러'},
    {id: '6602,6604,6605,6607,6608,6610,6611,6612,6614,6615,6616,6617,6618,6619,6621,6622,6627,6629,6631,6636,6638,6639,6640,6641,6642,6645', name: '인플레이타구'},
];

function HitResultCheckboxItem(props) {
    const {value, setValue} = props;
    const [checkIdArr, setCheckIdArr] = useState(value || []);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setValue((prev) => {
            return {...prev, hitResultCodes: checkIdArr}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="hit-result-checkbox-label"
        sx={{fontSize: 12}}
        >타격결과</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="hit-result-checkbox-label"
        id="hit-result-checkbox"
        multiple
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        input={<OutlinedInput label="타격결과" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(rows, (item, idx) => selected.indexOf(item['id']) > -1);
            const selectedName = _.map(selectedArr, 'name');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"hitResultCodes_"+item['id']} value={item['id']}>
                <Checkbox checked={checkIdArr.indexOf(item['id']) > -1}/>
                <ListItemText primary={item['name']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default HitResultCheckboxItem;