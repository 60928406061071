import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_today_atom, game_info_obj_atom } from '../../../../../assets/model/detail/store';
import _ from 'lodash';
import moment from 'moment';
import { fetchPitcherRecord } from '../../../../../utils/api';
import CommonTableComponent from '../../../../../components/table/CommonTableComponent';
import { loopFlatObject } from '../../../../../utils/flat';
import { useNavigate } from 'react-router-dom';

function TodayRecentGameRecord(props) {
    const [recordType, setRecordType] = useState('all');
    const [pitcherRecord, setPitcherRecord] = useState([]);
    const [pitcherDayRecord, setPitcherDayRecord] = useState([]);

    const gameInfoObj = useAtomValue(game_info_obj_atom);
    const gameDay = useAtomValue(game_today_atom);
    const navigate = useNavigate();

    const recentRecordColumn = [
        {columnName: '경기수', property:'basicStat_gameCnt'},
        {columnName: '승', property:'win'},
        {columnName: '패', property:'lose'},
        {columnName: '이닝', property:'ip'},
        {columnName: '실점', property:'basicStat_r'},
        {columnName: '자책점', property:'basicStat_er'},
        {columnName: '피안타', property:'basicStat_hit'},
        {columnName: '피홈런', property:'basicStat_hr'},
        {columnName: '삼진', property:'basicStat_so'},
        {columnName: '볼넷', property:'basicStat_bb'},
        {columnName: 'ERA', property:'era'}
    ];

    useEffect(() => {
        if(!_.isEmpty(gameInfoObj)) {
            const season = moment(gameDay).format('YYYY');
            const commonObj = {
                gameTypeCodes: 4201, gameDay: gameInfoObj['gameDay'], season,
                limitEnd: 10, pitcherId: props['playerId']
            };
            if(recordType !== 'all') {
                commonObj['homeOrAway'] = recordType;
            }

            const gameObj = {...commonObj, groupingColumn: 'game'};

            const pitcherRecentPromise = fetchPitcherRecord(commonObj);
            const pitcherDayPromise = fetchPitcherRecord(gameObj);

            Promise.all([
                pitcherRecentPromise,
                pitcherDayPromise
            ]).then((response) => {
                const pitcherData = response[0]['data']['RECORD'];
                const pitcherDayData = response[1]['data']['RECORD'];

                setPitcherRecord(loopFlatObject(pitcherData));
                setPitcherDayRecord(loopFlatObject(pitcherDayData));
            })
        }
    }, [gameInfoObj, recordType]);

    const recordTypeHandler = (event) => {
        const id = event.currentTarget.id;
        if(id.indexOf('gameTotal') > -1 ) {
            setRecordType('all');
        } else if(id.indexOf('gameHome') > -1) {
            setRecordType('home');
        } else {
            setRecordType('away');
        }
    }

    function winLoseCalc(data) {

        if(data['win']*1 === 1) {
            return <div className="win_div">승</div>;
        } else if(data['lose']*1 === 1) {
            return <div className="lose_div">패</div>;
        } else if(data['hold']*1 === 1) {
            return <div className="hold_div">홀</div>;
        } else if(data['save']*1 === 1) {
            return <div className="save_div">세</div>;
        } else {
            return <div className="none_div">-</div>;
        }
    }

    function gameDayClickHandler(event) {
        const gameInfoId = event.currentTarget.id;
        navigate(`/gameschedule/result/${gameInfoId}`);
    }

    return (
    <>
    <div className="record_season_10games">
        <div className="title_wrap">
            <div className="title">
                최근 10경기 시즌성적
            </div>
            <div className="tab_wrap">
            <div className={recordType === 'all' ? 'active' : ''}
                onClick={recordTypeHandler} id={'gameTotal_'+props['homeOrAway']}>
                    전체
                </div>
                <div className={recordType === 'home' ? 'active' : ''}
                onClick={recordTypeHandler} id={'gameHome_'+props['homeOrAway']}>
                    홈
                </div>
                <div className={recordType === 'away' ? 'active': ''}
                onClick={recordTypeHandler} id={'gameAway_'+props['homeOrAway']}>
                    원정
                </div>
            </div>
        </div>
        <div className="body">
            <div className="top">
                <div className="table_all">
                    {
                    pitcherRecord ?
                    <CommonTableComponent
                    columnObj={recentRecordColumn}
                    data={pitcherRecord}
                    name={'record_season_10games_'+props['homeOrAway']}
                    />
                    :
                    <div>데이터가 없습니다.</div>
                    }
                </div>
            </div>
            <div className="bottom">
                <div className="table_all">
                    <table className="hiball_table">
                        <thead>
                        <tr>
                            <th>경기일</th>
                            <th>구분</th>
                            <th>상대팀</th>
                            <th>결과</th>
                            <th>이닝</th>
                            <th>실점</th>
                            <th>자책점</th>
                            <th>피안타</th>
                            <th>피홈런</th>
                            <th>삼진</th>
                            <th>볼넷</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                        _.map(pitcherDayRecord, (item, idx) => (
                            <tr key={'pitcher_'+item['pitcher_pitcherId']+'_'+idx}>
                                <td>
                                    <span
                                    id={item['gameInfoId']}
                                    onClick={gameDayClickHandler}
                                    className='link'>
                                    {item['gameDay']}
                                    </span>
                                </td>
                                <td>
                                {
                                    item['homeOrAway'] === 'AWAY' ?
                                    <div className="away_div">원정</div> :
                                    <div className="home_div">홈</div>
                                }
                                </td>
                                <td><div className="color_orange">{item['batterTeamName']}</div></td>
                                <td>
                                {   
                                    winLoseCalc(item)
                                }
                                </td>
                                <td>{item['ip']}</td>
                                <td>{item['basicStat_r']}</td>
                                <td>{item['basicStat_er']}</td>
                                <td>{item['basicStat_hit']}</td>
                                <td>{item['basicStat_hr']}</td>
                                <td>{item['basicStat_so']}</td>
                                <td>{item['basicStat_bb']}</td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default TodayRecentGameRecord;