import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchTeamDepth } from '../../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../../utils/flat';
import DepthChart from '../../../components/chart/DepthChart';
import bg_stadium from '../../../assets/image/common/bg_stadium.png';

function TeamDepthBox() {
    const params = useParams();
    const [teamDepth, setTeamDepth] = useState([]);
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const teamId = params['teamId'];

    useEffect(() => {
        const recordParam = {
            teamIds: teamId,
            gameTypeCodes: 4201,
            seasons: season
        };

        const teamDepthPromise = fetchTeamDepth(recordParam);

        teamDepthPromise.then((response) => {
            const depthData = _.groupBy(loopFlatObject(response['data']['RECORD']), (item, idx) => {
                return item['teamId']+"_"+item['positionCode'];
            });

            setTeamDepth(depthData);
        })
    }, []);

    return (
    <div id="team_depth_container">
        <div className="team_depth" style={{backgroundColor: '#fff', borderRadius: '10px'}}>
            <div className="head">
                <div className="team_logo_wrap">
                    <div className="team_logo">
                        <img src={require(`/src/assets/image/common/team_logo/logo_${teamId}_l.png`)} />
                    </div>
                </div>
            </div>
            <div className="body">
                <div className="stadium_bg">
                    <img src={bg_stadium} alt="bg_stadium"/>
                </div>
                <div className="depth_row1">
                    <div id="team_lf" className="depth_list">
                        <div className="position_name">
                            좌익수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_7']}
                            limit={3}/>
                        </div>
                    </div>
                    <div id="team_cf" className="depth_list">
                        <div className="position_name">
                            중견수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_8']}
                            limit={3}/>
                        </div>
                    </div>
                    <div id="team_rf" className="depth_list">
                        <div className="position_name">
                            우익수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_9']}
                            limit={3}/>
                        </div>
                    </div>
                </div>
                <div className="depth_row2">
                    <div id="team_ss" className="depth_list">
                        <div className="position_name">
                            유격수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_6']}
                            limit={3}/>
                        </div>
                    </div>
                    <div id="team_2b" className="depth_list">
                        <div className="position_name">
                            2루수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_4']}
                            limit={3}/>
                        </div>
                    </div>
                </div>
                <div className="depth_row3">
                    <div id="team_rp" className="depth_list">
                        <div className="position_name">
                            구원
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_11']}
                            limit={9}/>
                        </div>
                    </div>
                    <div id="team_3b" className="depth_list">
                        <div className="position_name">
                            3루수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_5']}
                            limit={3}/>
                        </div>
                    </div>
                    <div id="team_sp" className="depth_list">
                        <div className="position_name">
                            선발
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_1']}
                            limit={5}/>
                        </div>
                    </div>
                    <div id="team_1b" className="depth_list">
                        <div className="position_name">
                            1루수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_3']}
                            limit={3}/>
                        </div>
                    </div>
                    <div id="team_dh" className="depth_list">
                        <div className="position_name">
                            지명타자
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_10']}
                            limit={3}/>
                        </div>
                    </div>
                </div>
                <div className="depth_row4">
                    <div id="team_c" className="depth_list">
                        <div className="position_name">
                            포수
                        </div>
                        <div className="depth_content">
                            <DepthChart
                            season={season}
                            playerData={teamDepth[teamId+'_2']}
                            limit={3}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default TeamDepthBox;