import { useAtomValue } from 'jotai';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { game_today_atom, game_info_obj_atom } from '../../../../../assets/model/detail/store';
import moment from 'moment';
import { fetchPitcherRecord } from '../../../../../utils/api';
import CommonTableComponent from '../../../../../components/table/CommonTableComponent';
import { loopFlatObject } from '../../../../../utils/flat';

function TodayRecentYearRecord(props) {
    const [recordType, setRecordType] = useState('all');
    const [pitcherRecord, setPitcherRecord] = useState([]);
    const gameInfoObj = useAtomValue(game_info_obj_atom);
    const gameDay = useAtomValue(game_today_atom);

    const pitcherRecordColumn = [
        {columnName: '시즌', property:'season'},
        {columnName: '경기', property:'basicStat_gameCnt'},
        {columnName: '승', property:'win'},
        {columnName: '패', property:'lose'},
        {columnName: '이닝', property:'ip'},
        {columnName: '실점', property:'basicStat_r'},
        {columnName: '자책점', property:'basicStat_er'},
        {columnName: '피안타', property:'basicStat_hit'},
        {columnName: '피홈런', property:'basicStat_hr'},
        {columnName: '삼진', property:'basicStat_so'},
        {columnName: '볼넷', property:'basicStat_bb'},
        {columnName: 'ERA', property:'era'}
    ];

    useEffect(() => {
        if(!_.isEmpty(gameInfoObj)) {
            const season = moment(gameDay).format('YYYY');
            const commonParam = {gameTypeCodes: 4201, gameDay: gameDay};
            const seasonPlayerParam = {...commonParam, groupingColumn: 'seasonPlayer', pitcherId: props['playerId']};
            if(recordType !== 'all') {
                seasonPlayerParam['homeOrAway'] = recordType
            }

            const pitcherRecordPromise = fetchPitcherRecord(seasonPlayerParam);

            pitcherRecordPromise.then((response) => {
                const pitcherData = response['data']['RECORD'].slice(0, 3);
                if(pitcherData.length < 3) {
                    pitcherData.push([]);
                    pitcherData.push([]);
                    pitcherData.push([]);
                }

                setPitcherRecord(loopFlatObject(pitcherData.slice(0, 3)));
            })
        }
    }, [gameInfoObj, recordType])

    const recordTypeHandler = (event) => {
        const id = event.currentTarget.id;
        if(id.indexOf('recentTotal') > -1 ) {
            setRecordType('all');
        } else if(id.indexOf('recentHome') > -1) {
            setRecordType('home');
        } else {
            setRecordType('away');
        }
    }

    return (
    <div className="record_season_3years">
        <div className="title_wrap">
            <div className="title">
                {
                    props['homeOrAway'] ==='away'
                    ?
                    gameInfoObj['awayStartPitcherName']+" 최근3년 성적"
                    :
                    gameInfoObj['homeStartPitcherName']+" 최근3년 성적"
                }
            </div>
            <div className="tab_wrap">
                <div className={recordType === 'all' ? 'active' : ''}
                onClick={recordTypeHandler} id={'recentTotal_'+props['homeOrAway']}>
                    전체
                </div>
                <div className={recordType === 'home' ? 'active' : ''}
                onClick={recordTypeHandler} id={'recentHome_'+props['homeOrAway']}>
                    홈
                </div>
                <div className={recordType === 'away' ? 'active': ''}
                onClick={recordTypeHandler} id={'recentAway_'+props['homeOrAway']}>
                    원정
                </div>
            </div>
        </div>
        <div className="body">
            <div className={"table_"+recordType}>
                <CommonTableComponent
                columnObj={pitcherRecordColumn}
                data={pitcherRecord}
                name={'record_season_3year_'+recordType}
                />
            </div>
        </div>
    </div>
    )
}

export default TodayRecentYearRecord;