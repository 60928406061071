import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchTeamDepth } from '../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../utils/flat';
import DepthChart from '../../components/chart/DepthChart';
import bg_stadium from '../../assets/image/common/bg_stadium.png';

function TeamBestDepthBox() {
    const params = useParams();
    const [teamDepth, setTeamDepth] = useState([]);
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const teamId = params['teamId'];

    useEffect(() => {
        const teamParam = {
            teamIds: teamId,
            seasons: season,
            gameTypeCodes: 4201
        };

        const teamDepthPromise = fetchTeamDepth(teamParam);

        teamDepthPromise.then((response) => {
            const depthData = _.groupBy(loopFlatObject(response['data']['RECORD']), (item, idx) => {
                return item['teamId']+"_"+item['positionCode'];
            });

            setTeamDepth(depthData);
        })
    }, [])

    return (
    <>
    <div className="trackmanArea">
        <div className="teamTitle">{'팀 주요 포지션'}</div>
    </div>
    <div className="team_small_depth">
        <div className="depth_body">
            <div className="stadium_bg">
                <img src={bg_stadium} alt="bg_stadium"/>
            </div>
            <div className="depth_row1">
                <div className="team_lf depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_7']}
                        limit={1}/>
                    </div>
                </div>
                <div className="team_cf depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_8']}
                        limit={1}/>
                    </div>
                </div>
                <div className="team_rf depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_9']}
                        limit={1}/>
                    </div>
                </div>
            </div>
            <div className="depth_row2">
                <div className="team_ss depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_6']}
                        limit={1}/>
                    </div>
                </div>
                <div className="team_2b depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_4']}
                        limit={1}/>
                    </div>
                </div>
            </div>
            <div className="depth_row3">
                <div className="team_3b depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_5']}
                        limit={1}/>
                    </div>
                </div>
                <div className="team_sp depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_1']}
                        limit={1}/>
                    </div>
                </div>
                <div className="team_1b depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_3']}
                        limit={1}/>
                    </div>
                </div>
            </div>
            <div className="depth_row4">
                <div className="team_rp depth_list">
                    <div className="position_name">
                        구원
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_11']}
                        limit={2}/>
                    </div>
                </div>
                <div className="team_c depth_list">
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_2']}
                        limit={1}/>
                    </div>
                </div>
                <div className="team_dh depth_list">
                    <div className="position_name">
                        지명타자
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={season}
                        playerData={teamDepth[teamId+'_10']}
                        limit={1}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default TeamBestDepthBox;