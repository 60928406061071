import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchTeamDepth } from '../../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../../utils/flat';
import bg_stadium from '../../../assets/image/common/bg_stadium.png';
import DepthChart from '../../../components/chart/DepthChart';

function WeeklyTeamDepth(props) {
    const {fromDate, toDate, teamId} = props;
    const [teamDepth, setTeamDepth] = useState({});
    const seasons = moment(fromDate).format('YYYY');

    useEffect(() => {
        const teamParam = {
            seasons,
            gameTypeCodes: 4201,
            fromDate, toDate,
            teamIds: teamId
        }

        const teamDepthPromise = fetchTeamDepth(teamParam);
        teamDepthPromise.then((response) => {
            const teamDepthData = _.groupBy(loopFlatObject(response['data']['RECORD']), (item, idx) => {
                return item['positionCode'];
            });

            setTeamDepth(teamDepthData);
        })
    }, [fromDate, teamId])

    return (
    <>
    {
    !_.isEmpty(teamDepth)
    ?
    <div className='team_depth_container'>
        <div className="body">
            <div className="stadium_bg">
                <img src={bg_stadium} alt="bg_stadium"/>
            </div>
            <div className="depth_row1">
                <div className="depth_list team_lf">
                    <div className="position_name">
                        좌익수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['7']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_cf">
                    <div className="position_name">
                        중견수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['8']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_rf">
                    <div className="position_name">
                        우익수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['9']}
                        limit={3}/>
                    </div>
                </div>
            </div>
            <div className="depth_row2">
                <div className="depth_list team_ss">
                    <div className="position_name">
                        유격수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['6']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_2b">
                    <div className="position_name">
                        2루수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['4']}
                        limit={3}/>
                    </div>
                </div>
            </div>
            <div className="depth_row3">
                <div className="depth_list team_rp">
                    <div className="position_name">
                        구원
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['11']}
                        limit={9}/>
                    </div>
                </div>
                <div className="depth_list team_3b">
                    <div className="position_name">
                        3루수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['5']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_sp">
                    <div className="position_name">
                        선발
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['1']}
                        limit={5}/>
                    </div>
                </div>
                <div className="depth_list team_1b">
                    <div className="position_name">
                        1루수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['3']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_dh">
                    <div className="position_name">
                        지명타자
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['10']}
                        limit={3}/>
                    </div>
                </div>
            </div>
            <div className="depth_row4">
                <div className="depth_list team_c">
                    <div className="position_name">
                        포수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={seasons}
                        playerData={teamDepth['2']}
                        limit={3}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    :
    <></>
    }
    </>
    )
}

export default WeeklyTeamDepth;