import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import pitchingColumnCalc from '../../../../utils/table/pitchingColumnCalc';
import { usePitcherRender, usePitcherTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import pitcherTooltip from '../../../../utils/tooltip/pitcherTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = pitcherTooltip();

function PlayerPitchingDetailRecord(props) {
    const statType = "detail";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_ip",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL,
        { name: '시즌', key: 'season', frozen: true, sortable: false},
        { name: '팀', key: 'pitcherTeamName', frozen: true, sortable: false, renderCell: usePitcherTeamRender},
        { name: '선수', width: 75, key: 'pitcherName', frozen: true, sortable: false, renderCell: usePitcherRender},
        { name: '출장', width: 55, key: 'statCalced_stat_gameCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: '이닝', width: 55, key: 'statCalced_ip', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: '피안타율', width: 72, key: 'statCalced_avg', renderHeaderCell: tooltipRender, tooltip: tooltip['avg']},
        { name: '피출루율', width: 72, key: 'statCalced_obp', renderHeaderCell: tooltipRender, tooltip: tooltip['obp']},
        { name: '피장타율', width: 72, key: 'statCalced_slg', renderHeaderCell: tooltipRender, tooltip: tooltip['slg']},
        { name: '피OPS', width: 72, key: 'statCalced_ops', renderHeaderCell: tooltipRender, tooltip: tooltip['ops']},
        { name: "bWAR", width: 63, key: 'statCalced_bWar', renderHeaderCell: tooltipRender, tooltip: tooltip['bWar']},
        { name: "fWAR", width: 63, key: 'statCalced_pitcherWAR', renderHeaderCell: tooltipRender, tooltip: tooltip['pitcherWAR']},
        { name: "ERA", width: 63, key: 'statCalced_era', renderHeaderCell: tooltipRender, tooltip: tooltip['era']},
        { name: "FIP", width: 63, key: 'statCalced_fip', renderHeaderCell: tooltipRender, tooltip: tooltip['fip']},
        { name: "ERA-FIP", width: 78, key: 'statCalced_eraSubFip', renderHeaderCell: tooltipRender, tooltip: tooltip['eraSubFip']},
        { name: "K%", key: 'statCalced_kRate', renderHeaderCell: tooltipRender, tooltip: tooltip['kRate']},
        { name: "BB%", width: 55, key: 'statCalced_bbRate', renderHeaderCell: tooltipRender, tooltip: tooltip['bbRate']},
        { name: "K/BB%", width: 65, key: 'statCalced_kBbRate', renderHeaderCell: tooltipRender, tooltip: tooltip['kBbRate']},
        { name: "H/9", width: 55, key: 'statCalced_hit9', renderHeaderCell: tooltipRender, tooltip: tooltip['hit9']},
        { name: "HR/9", width: 60, key: 'statCalced_hr9', renderHeaderCell: tooltipRender, tooltip: tooltip['hr9']},
        { name: "K/9", width: 55, key: 'statCalced_k9', renderHeaderCell: tooltipRender, tooltip: tooltip['k9']},
        { name: "BB/9", width: 60, key: 'statCalced_bb9', renderHeaderCell: tooltipRender, tooltip: tooltip['bb9']},
        { name: "K.Lk", width: 60, key: 'statCalced_stat_lookingSo', renderHeaderCell: tooltipRender, tooltip: tooltip['lookingSo']},
        { name: "K.Lk%", width: 65, key: 'statCalced_lookingSoRate', renderHeaderCell: tooltipRender, tooltip: tooltip['lookingSoRate']},
        { name: "K.Sw", width: 60, key: 'statCalced_stat_swingSo', renderHeaderCell: tooltipRender, tooltip: tooltip['swingSo']},
        { name: "K.Sw%", width: 65, key: 'statCalced_swingSoRate', renderHeaderCell: tooltipRender, tooltip: tooltip['swingSoRate']},
        { name: "BABIP", width: 68, key: 'statCalced_babip', renderHeaderCell: tooltipRender, tooltip: tooltip['babip']},
        { name: "LOB%", width: 63, key: 'statCalced_lobRate', renderHeaderCell: tooltipRender, tooltip: tooltip['lobRate']},
        { name: "Pit/IP", width: 68, key: 'statCalced_pDivip', renderHeaderCell: tooltipRender, tooltip: tooltip['pDivip']},
        { name: "IP/G", width: 60, key: 'statCalced_gDivIp', renderHeaderCell: tooltipRender, tooltip: tooltip['gDivIp']}
    ];
    
    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {

                const comparator = pitchingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['pitcherId'];
    }

    const gridElement = (
        <DataGrid
        className="record_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 투수 상세기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerPitchingDetailRecord;