import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import fieldingColumnCalc from '../../../../utils/table/fieldingColumnCalc';
import { useFielderRender, useFielderTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function PlayerFieldingOutFielderRecord(props) {
    const statType = "outfielder";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCacled_defenseInning",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL, 
        { name: '시즌', key: 'season', sortable: false},
        { name: '팀', key: 'fielderTeamName', sortable: false, renderCell: useFielderTeamRender},
        { name: '선수', width: 75, key: 'fielderName', sortable: false, renderCell: useFielderRender},
        { name: '출전', width: 55, key: 'stat_gameCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: "선발", width: 55, key: 'stat_starter', renderHeaderCell: tooltipRender, tooltip: tooltip['starter']},
        { name: '수비이닝', width: 72, key: 'statCacled_defenseInning', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: '자살', width: 55, key: 'stat_poCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['poCnt']},
        { name: '보살', width: 55, key: 'stat_aoCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['aoCnt']},
        { name: '실책', width: 55, key: 'stat_error', renderHeaderCell: tooltipRender, tooltip: tooltip['error']},

        { name: "수비기회", width: 72, key: 'stat_fieldingCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['fieldingCnt']},
        { name: "수비율", width: 63, key: 'statCacled_defenseRate', renderHeaderCell: tooltipRender, tooltip: tooltip['defenseRate']},
        { name: "처리기회", width: 72, key: 'stat_outfielderCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['outfielderCnt']},
        { name: "처리율", width: 63, key: 'statCacled_outFieldingRate', renderHeaderCell: tooltipRender, tooltip: tooltip['outFieldingRate']},

        // { name: "1H12%", key: 'b1bBase12Rate'},
        { name: "1H12", width: 60, key: 'stat_b1bBase12', renderHeaderCell: tooltipRender, tooltip: tooltip['b1bBase12']},
        // { name: "1H13%", key: 'b1bBase13Rate'},
        { name: "1H13", width: 60, key: 'stat_b1bBase13', renderHeaderCell: tooltipRender, tooltip: tooltip['b1bBase13']},
        // { name: "1H14%", key: 'b1bBase1hRate'},
        { name: "1H14", width: 60, key: 'stat_b1bBase1h', renderHeaderCell: tooltipRender, tooltip: tooltip['b1bBase1h']},
        // { name: "1H23%", key: 'b1bBase23Rate'},
        { name: "1H23", width: 60, key: 'stat_b1bBase23', renderHeaderCell: tooltipRender, tooltip: tooltip['b1bBase23']},
        // { name: "1H24%", key: 'b1bBase2hRate'},
        { name: "1H24", width: 60, key: 'stat_b1bBase2h', renderHeaderCell: tooltipRender, tooltip: tooltip['b1bBase2h']},
        // { name: "2H13%", key: 'b2bBase13Rate'},

        { name: "2H13", width: 60, key: 'stat_b2bBase13', renderHeaderCell: tooltipRender, tooltip: tooltip['b2bBase13']},
        // { name: "2H14%", key: 'b2bBase1hRate'},
        { name: "2H14", width: 60, key: 'stat_b2bBase1h', renderHeaderCell: tooltipRender, tooltip: tooltip['b2bBase1h']},
        // { name: "2H24%", key: 'b2bBase2hRate'},
        { name: "2H24", width: 60, key: 'stat_b2bBase2h', renderHeaderCell: tooltipRender, tooltip: tooltip['b2bBase2h']},

        // { name: "GO12%", key: 'goBase12Rate'},
        { name: "GO12", width: 60, key: 'stat_goBase12', renderHeaderCell: tooltipRender, tooltip: tooltip['goBase12']},
        // { name: "GO13%", key: 'goBase13Rate'},
        { name: "GO13", width: 60, key: 'stat_goBase13', renderHeaderCell: tooltipRender, tooltip: tooltip['goBase13']},
        // { name: "GO23%", key: 'goBase23Rate'},
        { name: "GO23", width: 60, key: 'stat_goBase23', renderHeaderCell: tooltipRender, tooltip: tooltip['goBase23']},
        // { name: "GO24%", key: 'goBase2hRate'},
        { name: "GO24", width: 60, key: 'stat_goBase2h', renderHeaderCell: tooltipRender, tooltip: tooltip['goBase2h']},
        // { name: "GO34%", key: 'goBase3hRate'},
        { name: "GO34", width: 60, key: 'stat_goBase3h', renderHeaderCell: tooltipRender, tooltip: tooltip['goBase3h']},

        // { name: "FO12%", key: 'foBase12Rate'},
        { name: "FO12", width: 60, key: 'stat_foBase12', renderHeaderCell: tooltipRender, tooltip: tooltip['foBase12']},
        // { name: "FO13%", key: 'foBase13Rate'},
        { name: "FO13", width: 60, key: 'stat_foBase13', renderHeaderCell: tooltipRender, tooltip: tooltip['foBase13']},
        // { name: "FO23%", key: 'foBase23Rate'},
        { name: "FO23", width: 60, key: 'stat_foBase23', renderHeaderCell: tooltipRender, tooltip: tooltip['foBase23']},
        // { name: "FO24%", key: 'foBase2hRate'},
        { name: "FO24", width: 60, key: 'stat_foBase2h', renderHeaderCell: tooltipRender, tooltip: tooltip['foBase2h']},
        // { name: "FO34%", key: 'foBase3hRate'},
        { name: "FO34", width: 60, key: 'stat_foBase3h', renderHeaderCell: tooltipRender, tooltip: tooltip['foBase3h']},
    ];

    const rows = props['data'];

    function rowKeyGetter(row) {
        return row['fielderId'];
    }

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {

                const comparator = fieldingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    const gridElement = (
        <DataGrid
        className="record_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 수비 외야기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerFieldingOutFielderRecord;