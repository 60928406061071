import React, { useEffect, useState } from 'react';
import HomeTeamRanking from './home-team-ranking';
import HomeHitterRanking from './home-hitter-ranking';
import HomePitcherRanking from './home-pitcher-ranking';
import moment from 'moment';
import { fetchLastGameDay } from '../../../utils/api';
import { Grid } from '@mui/material';

function HomeRankingBox() {
    const [lastGameDay, setLastGameDay] = useState('');

    useEffect(() => {
        const todaySeason = moment(new Date()).format('YYYY');
        fetchLastGameDay({
            seasons: [todaySeason, todaySeason-1].toString(),
            gameTypeCodes: 4201
        }).then((response) => {
            const lastDay = response['data']['RECORD'][0]['lastGameDay'];
            setLastGameDay(lastDay);
        })
    }, [])

    return (
        <Grid container columns={3}>
            <Grid id="ranking_team" className="ranking_box" item md={1} xs={3}>
                <HomeTeamRanking lastGameDay={lastGameDay}/>
            </Grid>
            <Grid id="ranking_hitter" className="ranking_box" item md={1} xs={3}>
                <HomeHitterRanking lastGameDay={lastGameDay}/>
            </Grid>
            <Grid id="ranking_pitcher" className="ranking_box" item md={1} xs={3}>
                <HomePitcherRanking lastGameDay={lastGameDay}/>
            </Grid>
        </Grid>
    )   
}

export default HomeRankingBox;