import React, { Fragment, useEffect } from 'react';

function VerticalChart(props) {
    const teamRecord = props['teamRecord'];

    const teamArr = ['away', 'home'];
    const statArr = ['winRate', 'hitPerGame', 'bbPerGame', 'rPerGame', 'soPerGame'];

    const winRateCalc = function(data, property, teamType) {
        const awayData = data['awayRecord'][property];
        const homeData = data['homeRecord'][property];

        if(teamType === 'away') {
            if(awayData*1 > homeData*1) {
                return true;
            } else {
                return false;
            }
        } else {
            if(homeData*1 > awayData*1) {
                return true;
            } else {
                return false;
            }
        }
    }

    const graphWidthCalc = function(data) {
        const basicWidth = 40;
        const maxWidth = 190;
        let width = maxWidth*(data ? data/10 : 0);

        if(width > maxWidth) {
            return (basicWidth+maxWidth)+'px';
        } else {
            return (basicWidth+width)+'px';
        }
    }

    const graphWidthCalcPer = function(data) {
        const basicWidth = 40
        const maxWidth = 190;
        let width = maxWidth*(data || 0);

        if(width > maxWidth) {
            return (basicWidth+maxWidth)+'px';
        } else {
            return (basicWidth+width)+'px';
        }
    }

    return (
    teamArr.map((team, teamIdx) => (
        <Fragment key={"team_"+team}>
        <div className={"team_"+team}>
        {
        statArr.map((item, idx) => (
            <div id={"record_"+item+"_team_"+team+"_bar"+(idx+1)} key={"record_"+item+"_team_"+team+"_bar"+(idx+1)}>
                <div className="bar_border">
                    <div className={'bar_graph '+(winRateCalc(teamRecord, item, team) ? 'active' : '')}
                    style={{width: (idx === 0 ? graphWidthCalcPer(teamRecord[team+'Record'][item]) : graphWidthCalc(teamRecord[team+'Record'][item]))}}
                    >
                        <div className="bar_value">
                            {
                            idx === 0
                            ?
                            (teamRecord[team+'Record'][item] ? (teamRecord[team+'Record'][item]*100).toFixed(1)+"%" : '0.0%')
                            :
                            (teamRecord[team+'Record'][item] ? teamRecord[team+'Record'][item] : '0.0')
                            }
                        </div>
                    </div>
                </div>
            </div>
        ))
        }
        </div>
        {
        teamIdx === 0
        &&
        <div className="bar_title">
            <div>승률</div>
            <div>경기당 안타</div>
            <div>경기당 볼넷</div>
            <div>경기당 득점</div>
            <div>경기당 삼진</div>
        </div>
        }
        </Fragment>
    ))
    )
}

export default VerticalChart;