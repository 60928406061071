import { useAtomValue } from 'jotai';
import React from 'react';
import { game_info_obj_atom } from '../../../assets/model/detail/store';
import { Grid, Skeleton, Stack } from '@mui/material';
import _ from 'lodash';
import TeamVersusRecordBox from './team-versus-record-box';

function HomeScheduleVersusBox() {
    const gameInfoObj = useAtomValue(game_info_obj_atom);
    const statTypeArr = ['season', 'relative', 'day', 'stadium', 'recent'];

    if(_.isEmpty(gameInfoObj)) {
        return (
        <Stack spacing={1}>
            <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
            <Skeleton variant="rounded" width={597} height={110} animation="wave"/>
            <Skeleton variant="rounded" width={597} height={110} animation="wave"/>
        </Stack>
        )
    }

    return (
    <>
    <Grid id="record_match" item md={1} xs={2}>
        <div className="body">
            <div className='top'>
                <div className="team_title">전적</div>
                <div className="team_away">
                    <div className="team_name">
                        <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfoObj['awayTeamId']}.png`)}
                        alt={gameInfoObj['awayTeamName']}/>
                        {gameInfoObj['awayTeamName']}
                    </div>
                </div>
                <div className="team_home">
                    <div className='team_name'>
                        <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfoObj['homeTeamId']}.png`)}
                        alt={gameInfoObj['homeTeamName']}/>
                        {gameInfoObj['homeTeamName']}
                    </div>
                </div>
            </div>
            {
                statTypeArr.map((item, idx) => (
                    <div className='top' key={item}>
                    <TeamVersusRecordBox
                    statType={item} key={idx}
                    gameInfoObj={gameInfoObj}
                    />
                    </div>
                ))
            }
        </div>
    </Grid>
    </>
    )
}

export default HomeScheduleVersusBox;