import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '0', name: '0Out'},
    {id: '1', name: '1Out'},
    {id: '2', name: '2Out'}
];

function OutCountCheckboxItem(props) {
    const {value, setValue} = props;
    const [checkIdArr, setCheckIdArr] = useState(value || []);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setValue((prev) => {
            return {...prev, beforeOutCounts: checkIdArr}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="out-count-checkbox-label"
        sx={{fontSize: 12}}
        >아웃카운트</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="out-count-checkbox-label"
        id="out-count-checkbox"
        multiple
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        input={<OutlinedInput label="아웃카운트" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(rows, (item, idx) => selected.indexOf(item['id']) > -1);
            const selectedName = _.map(selectedArr, 'name');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"beforeOutCounts_"+item['id']} value={item['id']}>
                <Checkbox checked={checkIdArr.indexOf(item['id']) > -1}/>
                <ListItemText primary={item['name']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default OutCountCheckboxItem;