export default function positionTypeCode() {
    const positionTypeCodes = [
        {positionTypeCode: 5201, positionTypeName: '투수', shortName: 'SP'},
        {positionTypeCode: 5202, positionTypeName: '포수', shortName: 'C'},
        {positionTypeCode: 5203, positionTypeName: '1루수', shortName: '1B'},
        {positionTypeCode: 5204, positionTypeName: '2루수', shortName: '2B'},
        {positionTypeCode: 5205, positionTypeName: '3루수', shortName: '3B'},
        {positionTypeCode: 5206, positionTypeName: '유격수', shortName: 'SS'},
        {positionTypeCode: 5207, positionTypeName: '좌익수', shortName: 'LF'},
        {positionTypeCode: 5208, positionTypeName: '중견수', shortName: 'CF'},
        {positionTypeCode: 5209, positionTypeName: '우익수', shortName: 'RF'},
        {positionTypeCode: 5210, positionTypeName: '지명타자', shortName: 'DH'},
    ];

    return positionTypeCodes;
}