import React, { useEffect } from "react";
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';

const PolicyPage = () => {
    const {userPolicy, setUserPolicy, setPolicyAgreeYn}  = useOutletContext();

    const navigate = useNavigate();

    const moveToMain = () => {
        navigate('/');
    };

    const moveToJoinPage = () => {
        setPolicyAgreeYn("Y");
        sessionStorage.setItem("policyId", userPolicy.policyId);
        sessionStorage.setItem("policyAgreeYn", "Y");
        navigate('/join/platform');
    };

    useEffect(() => {
        const fetchData = async () => {
            const policyData = await getUserPolicy();
            setUserPolicy(policyData.data.selectUserPolicy);
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserPolicy = () =>
    new Promise((resolve) => {
        const params = {};

        axios.post(`${process.env.REACT_APP_API_URL}/member/selectUserPolicy`, params).then((Response)=>{
            // console.log(Response);

            resolve(Response);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    return (
        userPolicy &&
        <div className="container">
            <div id="policyWrap">
                <div id="policyName">
                    {userPolicy.policyName}
                </div>
                <div id="policyContent">
                    {userPolicy.policyContent}
                </div>
                <div id="policyAgreeBtn">
                    <button className="funcBtn" id="refuseBtn" onClick={moveToMain}>
                        거부
                    </button>
                    <button className="funcBtn" id="agreeBtn" onClick={moveToJoinPage}>
                        동의
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PolicyPage;