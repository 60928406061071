import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import pitchingColumnCalc from '../../../../utils/table/pitchingColumnCalc';
import { usePitcherRender, usePitcherTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import pitcherTooltip from '../../../../utils/tooltip/pitcherTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = pitcherTooltip();

function PlayerPitchingStarterRecord(props) {
    const statType = "starter";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_ip",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL,
        { name: '시즌', key: "season", frozen: true, sortable: false},
        { name: '팀', key: 'pitcherTeamName', frozen: true, sortable: false, renderCell: usePitcherTeamRender},
        { name: '선수', width: 75, key: 'pitcherName', frozen: true, sortable: false, renderCell: usePitcherRender},
        { name: '출장', width: 55, key: 'stat_gameCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: '이닝', width: 55, key: 'statCalced_ip', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: "bWAR", width: 63, key: 'statCalced_bWar', renderHeaderCell: tooltipRender, tooltip: tooltip['bWar']},
        { name: "fWAR", width: 63, key: 'statCalced_pitcherWAR', renderHeaderCell: tooltipRender, tooltip: tooltip['pitcherWAR']},
        { name: "WPA", width: 63, key: 'stat_wpa', renderHeaderCell: tooltipRender, tooltip: tooltip['wpa']},
        { name: "ERA", width: 63, key: 'statCalced_era', renderHeaderCell: tooltipRender, tooltip: tooltip['era']},
        { name: '승', key: 'stat_win', renderHeaderCell: tooltipRender, tooltip: tooltip['win']},
        { name: '패', key: 'stat_lose', renderHeaderCell: tooltipRender, tooltip: tooltip['lose']},
        { name: "CG", width: 55, key: 'stat_cg', renderHeaderCell: tooltipRender, tooltip: tooltip['cg']},
        { name: "SH", width: 55, key: 'stat_shutOut', renderHeaderCell: tooltipRender, tooltip: tooltip['shutOut']},
        { name: "5Ip+", width: 63, key: 'stat_over5Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['over5Inn']},
        { name: "6Ip+", width: 63, key: 'stat_over6Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['over6Inn']},
        { name: "7Ip+", width: 63, key: 'stat_over7Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['over7Inn']},
        { name: "8Ip+", width: 63, key: 'stat_over8Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['over8Inn']},
        { name: "QS", width: 63, key: 'stat_qs', renderHeaderCell: tooltipRender, tooltip: tooltip['qs']},
        { name: "QS%", width: 63, key: 'statCalced_qsRate', renderHeaderCell: tooltipRender, tooltip: tooltip['qsRate']},
        { name: "QS+", width: 63, key: 'stat_qsPlus', renderHeaderCell: tooltipRender, tooltip: tooltip['qs_p']},
        { name: "QS+%", width: 63, key: 'statCalced_qsPlusRate', renderHeaderCell: tooltipRender, tooltip: tooltip['qsPlusRate']},
        { name: "QK", width: 55, key: 'stat_qk', renderHeaderCell: tooltipRender, tooltip: tooltip['qk']},
        { name: "TL", width: 55, key: 'stat_tl', renderHeaderCell: tooltipRender, tooltip: tooltip['tl']},
        { name: "CW", width: 55, key: 'stat_cw', renderHeaderCell: tooltipRender, tooltip: tooltip['cw']},
        { name: "PR", width: 55, key: 'stat_pr', renderHeaderCell: tooltipRender, tooltip: tooltip['pr']},
        { name: "PRS", width: 55, key: 'stat_prs', renderHeaderCell: tooltipRender, tooltip: tooltip['prs']},
        { name: "BW", width: 55, key: 'stat_bw', renderHeaderCell: tooltipRender, tooltip: tooltip['bw']},
        { name: "BL", width: 55, key: 'stat_bl', renderHeaderCell: tooltipRender, tooltip: tooltip['bl']},
        { name: "Pit/G", width: 68, key: 'statCalced_gDivPitch', renderHeaderCell: tooltipRender, tooltip: tooltip['gDivPitch']},
        { name: "IP/G", width: 60, key: 'statCalced_gDivIp', renderHeaderCell: tooltipRender, tooltip: tooltip['gDivIp']},
        { name: "팀승", width: 55, key: 'stat_teamWin', renderHeaderCell: tooltipRender, tooltip: tooltip['teamWin']},
        { name: "팀패", width: 55, key: 'stat_teamLose', renderHeaderCell: tooltipRender, tooltip: tooltip['teamLose']},
    ];

    const rows = props['data'];

    function rowKeyGetter(row) {
        return row['pitcherId'];
    }

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = pitchingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    const gridElement = (
        <DataGrid
        defaultColumnOptions={{
            sortable: true
        }}
        className="record_table_height"
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 투구 선발기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerPitchingStarterRecord;