import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import SeasonSelectItem from './item/select/season-select-item';
import { fetchLastGameDay } from '../../utils/api';
import moment from 'moment';
import BatterTeamSelectItem from './item/select/batter-team-select-item';
import BatterPlayerSelectItem from './item/select/batter-player-select-item';
import { useSetAtom } from 'jotai';
import { filter_obj_atom } from '../../assets/model/detail/store';
import PitcherTeamSelectItem from './item/select/pitcher-team-select-item';
import PitcherPlayerSelectItem from './item/select/pitcher-player-select-item';
import MonthCheckboxItem from './item/checkbox/month-checkbox-item';
import StadiumCheckboxItem from './item/checkbox/stadium-checkbox-item';
import DayCheckboxItem from './item/checkbox/day-checkbox-item';
import HitTypeCheckboxItem from './item/checkbox/hit-type-checkbox-item';
import BattedTypeCheckboxItem from './item/checkbox/batted-type-checkbox-item';
import BattingHandSelectItem from './item/select/batting-hand-select-item';
import PitchingFormSelectItem from './item/select/pitching-form-select-item';
import PitchingHandSelectItem from './item/select/pitching-hand-select-item';
import PitchingZoneCheckboxItem from './item/checkbox/pitching-zone-checkbox-item';
import OutCountCheckboxItem from './item/checkbox/out-count-checkbox-item';
import BsCountCheckboxItem from './item/checkbox/bs-count-checkbox-item';
import BattingOrderCheckboxItem from './item/checkbox/batting-order-checkbox-item';
import RunnerStateCheckboxItem from './item/checkbox/runner-state-checkbox-item';
import InningCheckboxItem from './item/checkbox/inning-checkbox-item';
import HitResultCheckboxItem from './item/checkbox/hit-result-checkbox-item';
import BallCodeCheckboxItem from './item/checkbox/ball-code-checkbox-item';
import ScoreGapCheckboxItem from './item/checkbox/score-gap-checkbox-item';
import BatterPositionCheckboxItem from './item/checkbox/batter-position-checkbox-item';
import PitcherPositionSelectItem from './item/select/pitcher-position-select-item';
import BatterHomeSelectItem from './item/select/batter-home-select-item';
import BatterForignerSelectItem from './item/select/batter-foreigner-select-item';
import PitcherForignerSelectItem from './item/select/pitcher-foreigner-select-item';
import BoxBasic from './box/box-basic';
import { Button } from '@mui/material';
import FilterBoxDiv from './box/filter-box-div';
import _ from 'lodash';

const FilterBox = FilterBoxDiv();

function PitchLogsFilter() {
    const setFilterObj = useSetAtom(filter_obj_atom);
    const [season, setSeason] = useState();
    const [defaultSeason, setDefaultSeason] = useState();

    const [innerFilterObj, setInnerFilterObj] = useState({
        batterTeamId: 'all',
        batterIds: [],
        pitcherTeamId: 'all',
        pitcherIds: [],
        months: [],
        stadiums: [],
        weekDays: [],
        hitTypeCodes: [],
        battedBallTypeCode: [],
        battingHands: [],
        pitchingForms: [],
        pitchingHands: [],
        zoneStrikeYns: [],
        beforeOutCounts: [],
        beforeBallStrikeCnts: [],
        battingOrders: [],
        beforeRunnerStates: [],
        innings: [],
        hitResultCodes: [],
        ballCodes: [],
        scoreGap: [],
        positionTypeCodes: [],
        pitcherPositionTypeCodes: [],
        battingForeignerYn: [],
        pitchingForeignerYn: [],
        homeOrAway: []
    });

    useEffect(() => {
        const todaySeason = moment(new Date()).format('YYYY');
        fetchLastGameDay({
            seasons: [todaySeason, todaySeason-1].toString(),
            gameTypeCodes: 4201
        }).then((response) => {
            const {lastGameDay} = response['data']['RECORD'][0];
            const lastSeason = moment(lastGameDay).format('YYYY')*1;
            setDefaultSeason(lastSeason);
            setSeason(lastSeason);
        });
    }, []);

    if(!defaultSeason) {
        return <></>;
    }

    const buttonClickHandler = (event) => {
        const dataParam = {
            seasons: season,
            fromDate: moment(new Date(season, 0, 1)).format('YYYY-MM-DD'),
            toDate: moment(new Date(season, 11, 31)).format('YYYY-MM-DD')
        }

        let selectNum = 0;
        _.forEach(innerFilterObj, (item, idx) => {
            if(idx === 'batterIds' || idx === 'pitcherIds') {
                if(item.length !== 0) {
                    selectNum++;
                }
            } else if(idx === 'batterTeamId' || idx === 'pitcherTeamId') {
                if(item !== 'all') {
                    selectNum++;
                }
            } else {
                if(item.length !== 0) {
                    selectNum++;
                }
            }
        });
        console.log(selectNum);
        console.log(innerFilterObj);

        if(selectNum < 2) {
            alert("항목을 2개이상 선택해주세요.");
        } else {
            if(innerFilterObj['batterTeamId'] !== 'all') {
                dataParam['batterTeamId'] = innerFilterObj['batterTeamId'];
            }
    
            if(innerFilterObj['batterIds'] !== 'all') {
                dataParam['batterIds'] = innerFilterObj['batterIds'].toString();
            }
    
            if(innerFilterObj['pitcherTeamId'] !== 'all') {
                dataParam['pitcherTeamId'] = innerFilterObj['pitcherTeamId'];
            }
    
            if(innerFilterObj['pitcherIds'] !== 'all') {
                dataParam['pitcherIds'] = innerFilterObj['pitcherIds'].toString();
            }
    
            if(innerFilterObj['months'].length !== 0) {
                dataParam['months'] = innerFilterObj['months'].toString();
            }
    
            if(innerFilterObj['stadiums'].length !== 0) {
                dataParam['stadiums'] = innerFilterObj['stadiums'].toString();
            }
    
            if(innerFilterObj['weekDays'].length !== 0) {
                dataParam['weekDays'] = innerFilterObj['weekDays'].toString();
            }
    
            if(innerFilterObj['hitTypeCodes'].length !== 0) {
                dataParam['hitTypeCodes'] = innerFilterObj['hitTypeCodes'].toString();
            }
    
            if(innerFilterObj['battedBallTypeCode'].length !== 0) {
                dataParam['battedBallTypeCode'] = innerFilterObj['battedBallTypeCode'].toString();
            }
    
            if(innerFilterObj['battingHands'].length !== 0) {
                dataParam['battingHands'] = innerFilterObj['battingHands'].toString();
            }
    
            if(innerFilterObj['pitchingForms'].length !== 0) {
                dataParam['pitchingForms'] = innerFilterObj['pitchingForms'].toString();
            }
    
            if(innerFilterObj['pitchingHands'].length !== 0) {
                dataParam['pitchingHands'] = innerFilterObj['pitchingHands'].toString();
            }
    
            if(innerFilterObj['zoneStrikeYns'].length !== 0) {
                dataParam['zoneStrikeYns'] = innerFilterObj['zoneStrikeYns'].toString();
            }
    
            if(innerFilterObj['beforeOutCounts'].length !== 0) {
                dataParam['beforeOutCounts'] = innerFilterObj['beforeOutCounts'].toString();
            }
    
            if(innerFilterObj['beforeBallStrikeCnts'].length !== 0) {
                dataParam['beforeBallStrikeCnts'] = innerFilterObj['beforeBallStrikeCnts'].toString();
            }
    
            if(innerFilterObj['battingOrders'].length !== 0) {
                dataParam['battingOrders'] = innerFilterObj['battingOrders'].toString();
            }
    
            if(innerFilterObj['beforeRunnerStates'].length !== 0) {
                dataParam['beforeRunnerStates'] = innerFilterObj['beforeRunnerStates'].toString();
            }
    
            if(innerFilterObj['innings'].length !== 0) {
                dataParam['innings'] = innerFilterObj['innings'].toString();
            }
    
            if(innerFilterObj['hitResultCodes'].length !== 0) {
                dataParam['hitResultCodes'] = innerFilterObj['hitResultCodes'].toString();
            }
    
            if(innerFilterObj['ballCodes'].length !== 0) {
                dataParam['ballCodes'] = innerFilterObj['ballCodes'].toString();
            }
    
            if(innerFilterObj['scoreGap'].length !== 0) {
                dataParam['scoreGap'] = innerFilterObj['scoreGap'].toString();
            }
    
            if(innerFilterObj['positionTypeCodes'].length !== 0) {
                dataParam['positionTypeCodes'] = innerFilterObj['positionTypeCodes'].toString();
            }
    
            if(innerFilterObj['pitcherPositionTypeCodes'].length !== 0) {
                dataParam['pitcherPositionTypeCodes'] = innerFilterObj['pitcherPositionTypeCodes'].toString();
            }
    
            if(innerFilterObj['battingForeignerYn'].length !== 0) {
                dataParam['battingForeignerYn'] = innerFilterObj['battingForeignerYn'].toString();
            }
    
            if(innerFilterObj['pitchingForeignerYn'].length !== 0) {
                dataParam['pitchingForeignerYn'] = innerFilterObj['pitchingForeignerYn'].toString();
            }
    
            if(innerFilterObj['homeOrAway'].length !== 0) {
                dataParam['homeOrAway'] = innerFilterObj['homeOrAway'].toString();
            }
    
            setFilterObj(dataParam);
        }
    }

    const initClickHandler = (event) => {
        window.location.reload();
    }

    return (
    <FilterBox>
    <Grid container spacing={0.5}>
        <Grid item xs={1}>
            <BoxBasic>시즌</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <SeasonSelectItem defaultSeason={defaultSeason}
            season={season} setSeason={setSeason} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>월</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <MonthCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>요일</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <DayCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>구장</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <StadiumCheckboxItem setValue={setInnerFilterObj} />
        </Grid>

        <Grid item xs={1}>
            <BoxBasic>타자팀</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BatterTeamSelectItem setValue={setInnerFilterObj}/>
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타자</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BatterPlayerSelectItem season={season}
            value={innerFilterObj} setValue={setInnerFilterObj}
            />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투수팀</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitcherTeamSelectItem setValue={setInnerFilterObj}/>
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투수</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitcherPlayerSelectItem season={season} 
            value={innerFilterObj} setValue={setInnerFilterObj}
            />
        </Grid>

        <Grid item xs={1}>
            <BoxBasic>타격유형</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <HitTypeCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타구유형</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BattedTypeCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타격좌우</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BattingHandSelectItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투구폼</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitchingFormSelectItem setValue={setInnerFilterObj} />
        </Grid>

        <Grid item xs={1}>
            <BoxBasic>투구좌우</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitchingHandSelectItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투구존</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitchingZoneCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>아웃카운트</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <OutCountCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>B-S</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BsCountCheckboxItem setValue={setInnerFilterObj} />
        </Grid>

        <Grid item xs={1}>
            <BoxBasic>타순</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BattingOrderCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>주자상황</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <RunnerStateCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>이닝</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <InningCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타격결과</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <HitResultCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        
        <Grid item xs={1}>
            <BoxBasic>구종</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BallCodeCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>득점차</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <ScoreGapCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타자포지션</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BatterPositionCheckboxItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투수포지션</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitcherPositionSelectItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타자 홈/원정</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BatterHomeSelectItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타자구분</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BatterForignerSelectItem setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투수구분</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitcherForignerSelectItem setValue={setInnerFilterObj} />
        </Grid>
    </Grid>
    <div style={{gap: 10, display: 'flex', justifyContent: 'center'}}>
        <Button variant='outlined' onClick={buttonClickHandler}>조회</Button>
        <Button variant='outlined' onClick={initClickHandler}>초기화</Button>
    </div>
    </FilterBox>
    )
}

export default PitchLogsFilter;