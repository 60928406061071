import React, { useState } from 'react';
import BatterBasicRecordBox from './batter-basic-record-box';
import BatterSituation1RecordBox from './batter-situation1-record-box';
import BatterSituation2RecordBox from './batter-situation2-record-box';
import BatterDateRecordBox from './batter-date-record-box';

function BatterRecordBox() {
    const [subId, setSubId] = useState('batter_basic');

    const subMenuEventHandler = (e) => {
        const id = e.currentTarget.id;
        setSubId(id);
    }

    const SubTabContent = function() {
        switch(subId) {
            case 'batter_basic':
                return <BatterBasicRecordBox />
            case 'batter_date':
                return <BatterDateRecordBox />
            case 'batter_situation1':
                return <BatterSituation1RecordBox />
            case 'batter_situation2':
                return <BatterSituation2RecordBox />
            default:
                return <></>
        }
    }

    return (
    <>
    <div id="player_tab_btn_area">
        <div id="batter_basic"
        className={"btn "+(subId === 'batter_basic' && 'active')}
        onClick={subMenuEventHandler}
        >기본</div>
        <div id="batter_date"
        className={"btn "+(subId === 'batter_date' && 'active')}
        onClick={subMenuEventHandler}
        >날짜별</div>
        <div id="batter_situation1"
        className={"btn "+(subId === 'batter_situation1' && 'active')}
        onClick={subMenuEventHandler}
        >상황1</div>
        <div id="batter_situation2"
        className={"btn "+(subId === 'batter_situation2' && 'active')}
        onClick={subMenuEventHandler}
        >상황2</div>
        <div id="batter_chart"
        className={"btn "+(subId === 'batter_chart' && 'active')}
        onClick={subMenuEventHandler}
        >차트</div>
    </div>
    <div id="player_tab_content">
        <SubTabContent />
    </div>
    </>
    )
}

export default BatterRecordBox;