import React from 'react';
import HomeScheduleBox from '../../layout/home/game/home-schedule-box';
import { Provider } from 'jotai';
import HomeRankingBox from '../../layout/home/ranking/home-ranking-box';
import HomeScheduleRecordBox from '../../layout/home/game/home-schedule-record-box';
import TeamRosterDepthBox from '../../layout/home/game/team-roster-depth-box';
import HomeScheduleVersusBox from '../../layout/home/game/home-schedule-versus-box';
import HomeScheduleStarterBox from '../../layout/home/game/home-schedule-starter-box';
import TeamLineupDepthBox from '../../layout/home/game/team-lineup-depth-box';
import { Grid } from '@mui/material';

function HomePage() {
    return (
        <Provider>
            <div className="container">
                <HomeScheduleBox/>
                <Grid id="record_container" container columns={2}>
                    <HomeScheduleStarterBox/>
                    <HomeScheduleVersusBox/>
                    {/* <HomeScheduleRecordBox/> */}
                </Grid>
                <div className="section_div">
                    <span className="section_title">로스터</span>
                    <div className="section_line"></div>
                </div>
                <div id="team_roster_container">
                    <TeamLineupDepthBox/>
                    <TeamRosterDepthBox/>
                </div>
                <div className="section_div">
                    <span className="section_title">랭킹</span>
                    <div className="section_line"></div>
                </div>
                <div id="ranking_container">
                    <HomeRankingBox/>
                </div>
            </div>
        </Provider>
    )
}

export default HomePage;