export default function fieldingColumnCalc(statType, sortColumn, direction) {
    if(statType === "basic") {
        switch (sortColumn) {
            case 'stat_gameCnt':
            case 'statCacled_defenseInning':
            case 'stat_poCnt':
            case 'stat_aoCnt':
            case 'stat_error':
            case 'stat_fieldingCnt':
            case 'statCacled_defenseRate':
            case 'statCacled_rf9':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                }
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "infielder") {
        switch (sortColumn) {
        case 'stat_gameCnt':
        case 'stat_starter':
        case 'statCacled_defenseInning':
        case 'stat_aoCnt':
        case 'stat_poCnt':
        case 'stat_error':
        case 'stat_fielderChoice':
        case 'stat_infielderHit':
        case 'stat_fieldingCnt':
        case 'statCacled_defenseRate':
        case 'stat_infielderCnt':
        case 'statCacled_fieldingRate':
        case 'stat_buntCnt':
        case 'stat_buntOut':
        case 'statCacled_stat_buntCnt':
        case 'statCacled_stat_buntOut':
        case 'statCacled_buntRate':
        case 'stat_gdpCnt':
        case 'stat_gdpOut':
        case 'statCacled_gdpRate':
            return (a, b) => {
                return a[sortColumn]*1 - b[sortColumn]*1;
            }
        default:
            throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "outfielder") {
        switch(sortColumn) {
            case 'stat_gameCnt':
            case 'stat_starter':
            case 'statCacled_defenseInning':
            case 'stat_aoCnt':
            case 'stat_poCnt':
            case 'stat_error':
            case 'stat_fieldingCnt':
            case 'statCacled_defenseRate':
            case 'stat_outfielderCnt':
            case 'statCacled_outFieldingRate':
            case 'b1bBase12Rate':
            case 'stat_b1bBase12':
            case 'b1bBase13Rate':
            case 'stat_b1bBase13':
            case 'b1bBase1hRate':
            case 'stat_b1bBase1h':
            case 'b1bBase23Rate':
            case 'stat_b1bBase23':
            case 'b1bBase2hRate':
            case 'stat_b1bBase2h':
            case 'b2bBase13Rate':
            case 'stat_b2bBase13':
            case 'b2bBase1hRate':
            case 'stat_b2bBase1h':
            case 'b2bBase2hRate':
            case 'stat_b2bBase2h':
            case 'goBase12Rate':
            case 'stat_goBase12':
            case 'goBase13Rate':
            case 'stat_goBase13':
            case 'goBase23Rate':
            case 'stat_goBase23':
            case 'goBase2hRate':
            case 'stat_goBase2h':
            case 'goBase3hRate':
            case 'stat_goBase3h':
            case 'foBase12Rate':
            case 'stat_foBase12':
            case'foBase13Rate':
            case 'stat_foBase13':
            case 'foBase23Rate':
            case 'stat_foBase23':
            case 'foBase2hRate':
            case 'stat_foBase2h':
            case 'foBase3hRate':
            case 'stat_foBase3h':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    } else if(statType === 'catcher') {
        switch (sortColumn) {
            case 'stat_starter':
            case 'statCacled_ip':
            case 'stat_er':
            case 'statCacled_cera':
            case 'stat_wildPitch':
            case 'stat_passedBall':
            case 'statCacled_wildPitch9':
            case 'statCacled_passedBall9':
            case 'statCacled_pass9':
            case 'stat_stealCnt':
            case 'stat_failStealCnt':
            case 'statCacled_csRate':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }
};