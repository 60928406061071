import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { fetchPitcherRecord, fetchTeamRecentLineupInfo } from '../../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../../utils/flat';

function WeeklyReliefPitcherRecord(props) {
    const {fromDate, toDate, teamId} = props;
    const [reliefRecord, setReliefRecord] = useState({});
    const [totalRecord, setTotalRecord] = useState({});
    const [gameObj, setGameObj] = useState([]);
    const [dayArr, setDayArr] = useState([]);
    const [playerArr, setPlayerArr] = useState([]);

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const dateArr = [];
        for(let i=0; i < 7; i++) {
            const tmpDate = moment(fromDate).add(i, 'd').format('YYYY-MM-DD');
            dateArr.push(tmpDate);
        }

        setDayArr(dateArr);

        const commonParam = {
            gameTypeCodes: 4201,
            pitcherTeamIds: teamId,
            seasons, fromDate, toDate,
            groupingColumn: 'player'
        }

        const totalDataPromise = fetchPitcherRecord(commonParam);
        totalDataPromise.then((response) => {
            const totalPlayer = _.filter(loopFlatObject(response['data']['RECORD']), item => item['substitute'] > 0);
            const playerIds = _.map(totalPlayer, 'pitcherId');

            setTotalRecord(totalPlayer);

            const playerParam = {
                gameTypeCodes: 4201,
                seasons, fromDate, toDate,
                pitcherIds: playerIds.toString(),
                groupingColumn: 'gamePlayer'
            }

            fetchPitcherRecord(playerParam)
            .then((response) => {
                const gameData = _.keyBy(loopFlatObject(response['data']['RECORD']), (item) => {
                    return item['gameDay']+"_"+item['pitcherId'];
                });

                const totalData = _.keyBy(totalPlayer, 'pitcherId');
                setReliefRecord(gameData);
                setTotalRecord(totalData);
            });
        });
    }, [fromDate, teamId])

    return (
    <>
    <div className="title">주간 불펜 기록</div>
    <div id="relief_container">
        <div className="name_div">
            <div>불펜투수</div>
            {
            _.map(totalRecord, (player) => (
                <div key={player['pitcherId']+"Name"}>
                {player['pitcherName']}
                </div>
            ))
            }
        </div>
        {
        dayArr.map((item) => (
            <div key={item} className="relief_div">
                <div>{item}</div>
                {
                _.map(totalRecord, (player) => (
                    reliefRecord[item+"_"+player['pitcherId']]
                    ?
                    <div key={item+"_"+player['pitcherId']} style={{backgroundColor: '#FAED7D'}}>
                        <div style={{textAlign: 'center'}}>
                        {reliefRecord[item+"_"+player['pitcherId']]['ip']+"이닝"}<br/>
                        {reliefRecord[item+"_"+player['pitcherId']]['basicStat_pitchingCnt']+"구"}
                        </div>
                    </div>
                    :
                    <div key={item+"_"+player['pitcherId']}></div>
                ))
                }
            </div>
        ))
        }
        <div className="total_div">
            <div>합계</div>
            {
            _.map(totalRecord, (player) => (
                <div key={player['pitcherId']+"Stat"}>
                    <div style={{textAlign: 'center'}}>
                    {player['ip']+"이닝"}<br/>
                    {player['basicStat_pitchingCnt']+"구"}
                    </div>
                </div>
            ))
            }
        </div>
    </div>
    </>
    );
}

export default WeeklyReliefPitcherRecord;