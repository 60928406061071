import utf8 from 'utf8'
import base64 from 'base-64';
import _ from 'lodash';

function decodeToken() {
    let token = JSON.parse(localStorage.getItem("accessToken"));
    let decodedToken = {};

    if(token){
        let payload = token.substring(token.indexOf('.')+1,token.lastIndexOf('.'));
        let decoded = base64.decode(payload);
        let jsonObj = JSON.parse(decoded);

        _.forEach(jsonObj, (val, key) => {
            let resultVal = val;

            if(typeof val == "string") {
                resultVal = utf8.decode(val);
            }

            decodedToken[key] = resultVal;
        });

        // console.log(jsonObj);
        // console.log(decodedToken);
    }

    return decodedToken;
}

export {
    decodeToken
}