import React from 'react';
import { useNavigate } from 'react-router-dom';

function SearchList({data}) {
    const todaySeason = new Date().getFullYear();
    const navigate = useNavigate();

    function positionCheckForKr(positionTypeCode) {
        if(positionTypeCode*1 === 5201 || positionTypeCode*1 === 5211) return '투수';
        else return '타자';
    }   

    function handCheck(hand) {
        if(hand ==='R') return '우';
        else return '좌';
    }

    const playerProfileHandler = (event) => {
        let id = event.currentTarget.id;

        let idSplit = id.split('_');
        let mainPosition = idSplit[0];
        let playerId = idSplit[1];

        let playerType = (mainPosition === '투수') ? 'pitcher' : 'batter';
        navigate(`/player/${playerType}/${playerId}?season=${todaySeason}`);
    }

    return (
        <>
        {
        data.map((profile) => (
            <div className="search_player_item" id={profile['mainPosition']+"_"+profile['membersId']} key={profile['membersId']} onClick={playerProfileHandler}>
                <div className="title">
                    <img src={require(`/src/assets/image/common/team_logo/logo_${profile['team']}.png`)}
                    width={45}
                    height={32}
                    alt={profile['teamName']}/>&nbsp;{profile['name']} No.{profile['lastBackNumber']}
                </div>
                <div className="content">
                    {profile['teamName']} {profile['mainPosition']} {handCheck(profile['pitchingHand'])}투{handCheck(profile['battingHand'])}타
                </div>
            </div>   
        ))
        }
        </>
    )
}

export default SearchList;