import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '6701', name: '땅볼'},
    {id: '6702', name: '라이너'},
    {id: '6703', name: '플라이'}
];

function BattedTypeCheckboxItem(props) {
    const {value, setValue} = props;
    const [checkIdArr, setCheckIdArr] = useState(value || []);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setValue((prev) => {
            return {...prev, battedBallTypeCode: checkIdArr}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        sx={{fontSize: 12}}
        id="batted-type-checkbox-label"
        >타구유형</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="batted-type-checkbox-label"
        id="batted-type-checkbox"
        multiple
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        input={<OutlinedInput label="타구유형" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(rows, (item, idx) => selected.indexOf(item['id']) > -1);
            const selectedName = _.map(selectedArr, 'name');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"battedBallTypeCode_"+item['id']} value={item['id']}>
                <Checkbox checked={checkIdArr.indexOf(item['id']) > -1}/>
                <ListItemText primary={item['name']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default BattedTypeCheckboxItem;