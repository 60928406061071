import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_today_atom, game_info_obj_atom } from '../../../../assets/model/detail/store';
import moment from 'moment';
import { fetchTeamWinRate } from '../../../../utils/api';
import _ from 'lodash';
import ScoreVerticalChart from '../../../../components/chart/ScoreVerticalChart';

function TodayScoreBox() {
    const gameDay = useAtomValue(game_today_atom);
    const gameInfo = useAtomValue(game_info_obj_atom);
    const [teamRecord, setTeamRecord] = useState({awayRecord: {},homeRecord: {}});

    useEffect(() => {
        if(!_.isEmpty(gameInfo)) {
            const season = moment(gameDay).format('YYYY');
            const {awayTeamId, homeTeamId} = gameInfo;
            const awayWinRatePromise = fetchTeamWinRate({
                teamId: awayTeamId,
                seasons: season,
                groupingColumn: 'scoreGap',
                gameTypeCodes: 4201
            })

            const homeWinRatePromise = fetchTeamWinRate({
                teamId: homeTeamId,
                seasons: season,
                groupingColumn: 'scoreGap',
                gameTypeCodes: 4201
            })

            Promise.all([
                awayWinRatePromise,
                homeWinRatePromise
            ]).then((response) => {
                setTeamRecord((prev) => {
                    return {
                        ...prev,
                        awayRecord: _.keyBy(response[0]['data']['RECORD'], (item) => 'score_'+item['scoreGap']),
                        homeRecord: _.keyBy(response[1]['data']['RECORD'], (item) => 'score_'+item['scoreGap'])
                    }
                });
            })
        }
    }, [gameInfo])

    return (
        <div id="record_season_wrap">
            <ScoreVerticalChart
            teamRecord={teamRecord}
            name="scoreGap" />
        </div>
    )
}

export default TodayScoreBox;