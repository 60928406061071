function hitResultCodeFilter(code) {
    let hitResultCode = 6699;
    if (code === 6601) {//파울
        hitResultCode = 6601;
    } else if (code===6602 || code ===6612 || code === 6614 || code === 6630) {//플라이아웃, 직선타, 희플, 파플아
        hitResultCode = 6602;
    } else if (code===6604) {//1루타
        hitResultCode = 6604;
    } else if (code===6605 || code === 6608 || code === 6638) {//땅볼아웃, 병살
        hitResultCode = 6605;
    } else if (code===6607) {//2루타
        hitResultCode = 6607;
    } else if (code===6610) {//3루타
        hitResultCode = 6610;
    } else if (code===6613) {//홈런
        hitResultCode = 6613;
    } else {
        hitResultCode = 6699;
    }

    return hitResultCode;
}

export {
    hitResultCodeFilter
}