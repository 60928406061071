import React, { useRef, useMemo } from "react";
import axios from 'axios';
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import ImageResize from 'quill-image-resize';

Quill.register('modules/ImageResize', ImageResize);

function EditorBox(props) {
    const quillRef = useRef(null);

    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            const file  = input.files ? input.files[0] : null;
            if (!file) return;
            const formData = new FormData();
            formData.append("uploadFile", file);
            let quillObj = quillRef.current.getEditor();
            const range = quillObj.getSelection();
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/board/uploadFile`,
                    formData
                );
                const imgUrl = res.data.filePath;
                quillObj.insertEmbed(range.index, "image", `${imgUrl}`);
            } catch (error) {
                console.log(error);
            }
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    // [{ font: [] }],
                    [{ align: [] }],
                    ['bold', 'italic', 'underline','strike', 'blockquote'],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        'link',
                        { indent: '-1' },
                        { indent: '+1' }
                    ],
                    [
                        { color: [] },
                        { background: [] },
                    ],
                    ['image'],
                    ['clean']
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            ImageResize: {
                parchment: Quill.import('parchment')
            }
        };
    }, []);

    return (
        <ReactQuill
            ref={quillRef}
            modules={modules}
            defaultValue={props.value || ''}
            onChange={props.onChange}
        />
    );
}
export default EditorBox;