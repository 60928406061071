import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_today_atom, game_info_obj_atom } from '../../../assets/model/detail/store';
import { fetchPitcherBallType, fetchPitcherRecord, fetchPlayerInfo } from '../../../utils/api';
import moment from 'moment';
import _ from 'lodash';
import { flatObject, loopFlatObject } from '../../../utils/flat';
import { useNavigate } from 'react-router-dom';

function TodayGameSummaryBox({children}) {
    const gameInfoObj = useAtomValue(game_info_obj_atom);
    const gameDay = useAtomValue(game_today_atom);
    const [awayInfo, setAwayInfo] = useState({});
    const [homeInfo, setHomeInfo] = useState({});
    const [awayRecord, setAwayRecord] = useState({});
    const [homeRecord, setHomeRecord] = useState({});
    const [awayPitcherAwayRecord, setAwayPitcherAwayRecord] = useState({});
    const [awayPitcherHomeRecord, setAwayPitcherHomeRecord] = useState({});
    const [homePitcherAwayRecord, setHomePitcherAwayRecord] = useState({});
    const [homePitcherHomeRecord, setHomePitcherHomeRecord] = useState({});
    const [awayPitcherBall, setAwayPitcherBall] = useState({});
    const [homePitcherBall, setHomePitcherBall] = useState({});
    const [isLoader, setIsLoader] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if(!_.isEmpty(gameInfoObj)) {
            setIsLoader(true);

            const seasons = moment(gameDay).format('YYYY');
            const {awayStartPitcherId, homeStartPitcherId} = gameInfoObj;

            const awayPitcherInfo = awayStartPitcherId ? fetchPlayerInfo({membersId: awayStartPitcherId}) : null;
            const homePitcherInfo = homeStartPitcherId ? fetchPlayerInfo({membersId: homeStartPitcherId}) : null;

            const awayPitcherParam = {seasons, gameTypeCodes: 4201, pitcherId: awayStartPitcherId, gameDay};
            const homePitcherParam = {seasons, gameTypeCodes: 4201, pitcherId: homeStartPitcherId, gameDay};

            const awayPitcherRecord = awayStartPitcherId ? fetchPitcherRecord(awayPitcherParam) : null;
            const homePitcherRecord = homeStartPitcherId ? fetchPitcherRecord(homePitcherParam) : null;

            const awayPitcherAwayRecordPromise = awayStartPitcherId ? fetchPitcherRecord({homeOrAway:'away', ...awayPitcherParam}) : null;
            const awayPitcherHomeRecordPromise = awayStartPitcherId ? fetchPitcherRecord({homeOrAway:'home', ...awayPitcherParam}) : null;

            const homePitcherAwayRecordPromise = homeStartPitcherId ? fetchPitcherRecord({homeOrAway:'away', ...homePitcherParam}) : null;
            const homePitcherHomeRecordPromise = homeStartPitcherId ? fetchPitcherRecord({homeOrAway:'home', ...homePitcherParam}) : null;

            const awayPitcherBallType = awayStartPitcherId ? fetchPitcherBallType({limitEnd:4, ...awayPitcherParam, groupingColumn: 'seasonPlayer'}) : null;
            const homePitcherBallType = homeStartPitcherId ? fetchPitcherBallType({limitEnd:4, ...homePitcherParam, groupingColumn: 'seasonPlayer'}) : null;

            Promise.all([
                awayPitcherInfo, homePitcherInfo,
                awayPitcherRecord, homePitcherRecord,
                awayPitcherBallType, homePitcherBallType,
                awayPitcherAwayRecordPromise, homePitcherAwayRecordPromise,
                awayPitcherHomeRecordPromise, homePitcherHomeRecordPromise
            ]).then((response) => {
                setAwayInfo(response[0] ? response[0]['data']['RECORD'][0] : {});
                setHomeInfo(response[1] ? response[1]['data']['RECORD'][0] : {});

                setAwayRecord(response[2] ? flatObject(response[2]['data']['RECORD'][0]) : []);
                setHomeRecord(response[3] ? flatObject(response[3]['data']['RECORD'][0]) : []);

                setAwayPitcherBall(response[4] ? loopFlatObject(response[4]['data']['RECORD']) : []);
                setHomePitcherBall(response[5] ? loopFlatObject(response[5]['data']['RECORD']) : []);

                setAwayPitcherAwayRecord(response[6] ? flatObject(response[6]['data']['RECORD'][0]) : []);
                setHomePitcherAwayRecord(response[7] ? flatObject(response[7]['data']['RECORD'][0]) : []);
                
                setAwayPitcherHomeRecord(response[8] ? flatObject(response[8]['data']['RECORD'][0]) : []);
                setHomePitcherHomeRecord(response[9] ? flatObject(response[9]['data']['RECORD'][0]) : []);
                setIsLoader(false);
            })
        } else {
            setIsLoader(true);
        }
    }, [gameInfoObj]);

    const playerClickHandler = (event) => {
        const id = event.currentTarget.id;
        const {season} = gameInfoObj;
        navigate(`/player/pitcher/${id}?season=${season}`);
    }

    return (
        <>
        {
        isLoader
        ?
        <div id="sp_away">
            <div className='title'>데이터를 불러오는 중입니다.</div>
        </div>
        :
        <div id="sp_away">
            <div className='title'>
                <span className='color_blue'>[원정팀]</span>선발투수
            </div>
            <div className='body'>
                <div className='top'>
                    <div className="backnum">
                        <div className="backnum_wrap">
                            {gameInfoObj['awayTeamId'] ? 
                            <img 
                            src={require(`/src/assets/image/common/team_logo/logo_${gameInfoObj['awayTeamId']}_profile.png`)}
                            alt={awayInfo['name']}
                            />
                            :
                            <div style={{height: '118px', width: '94px'}}></div>
                            }
                            <div className="backnum_text">
                            {awayInfo['lastBackNumber']}
                            </div>
                        </div>
                    </div>
                    <div className="profile">
                        {
                        gameInfoObj['awayStartPitcherId'] ?
                        <>
                        <div className="profile_title">
                            <span
                            className='link'
                            id={awayInfo['membersId']}
                            onClick={playerClickHandler}
                            >
                            {awayInfo['lastBackNumber']}. {awayInfo['name']}
                            </span>
                        </div>
                        <div className="profile_content">
                            <table>
                                <tbody>
                                <tr>
                                    <td>소속: </td>
                                    <td>{gameInfoObj['awayTeamName']}</td>
                                </tr>
                                <tr>
                                    <td>투타: </td><td>{awayInfo['pitchingHand']}/{awayInfo['battingHand']}</td>
                                </tr>
                                <tr>
                                    <td>출생: </td><td>{awayInfo['birthDate']}</td>
                                </tr>
                                <tr>
                                    <td>신체: </td><td>{awayInfo['height']}cm / {awayInfo['weight']}kg</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        </> :
                        <>
                        <div className="profile_title"></div>
                        <div className="profile_content">선발투수 발표 전 입니다</div>
                        </>
                        }
                    </div>
                </div>
                <div className="bottom">
                    <div className="record">
                    <table className="starter_record_table">
                        <tbody>
                        <tr>
                        <td>시즌성적<br/>(홈/원정)</td>
                        <td>
                            {
                            gameInfoObj['awayStartPitcherId'] ?
                            <>
                            {awayRecord['basicStat_gameCnt'] > 0
                            ?
                            <>
                            <span className="color_blue">
                            {awayRecord['basicStat_gameCnt']}경기 {awayRecord['win']}승{awayRecord['lose']}패
                            </span><br/>
                            ({awayPitcherHomeRecord['win']}승{awayPitcherHomeRecord['lose']}패/
                            {awayPitcherAwayRecord['win']}승{awayPitcherAwayRecord['lose']}패)
                            </>
                            :
                            <>기록 없음</>
                            }
                            </>
                            :
                            <>
                            <span className="color_blue">-</span><br/>
                            (-/-)
                            </>
                            }
                        </td>
                        </tr>
                        <tr>
                        <td>시즌자책점<br/>(홈/원정)</td>
                        <td>
                            {
                            awayRecord['basicStat_gameCnt'] > 0
                            ?
                            <>
                            <span>{awayRecord['era']}</span>
                            <br/>({awayPitcherHomeRecord['era']}/{awayPitcherAwayRecord['era']})
                            </>
                            :
                            <>기록 없음</>
                            }
                        </td>
                        </tr>
                        <tr>
                        <td>구종<br/>구사율<br/>구속</td>
                        <td>
                            <table className="ball_table">
                                <tbody>
                                <tr>
                                {
                                    awayPitcherBall.map((item) => (
                                        <th key={'ballCode_'+item['ballCode']+"_"+item['pitcher_pitcherId']}>
                                        {item['ballCodeName'].slice(0, 2)}
                                        </th>
                                    ))
                                }   
                                </tr>
                                <tr>
                                {
                                    awayPitcherBall.map((item) => (
                                        <td key={item['ballCode']+"_"+item['pitcher_pitcherId']}>
                                            <span>{(item['pitchRate']*100).toFixed(1)}</span>
                                        </td>
                                    ))
                                }
                                </tr>
                                <tr>
                                {
                                    awayPitcherBall.map((item) => (
                                        <td key={'ballSpeed'+item['ballCode']+"_"+item['pitcher_pitcherId']}>
                                            <span>{(item['ballSpeed']*1).toFixed()}</span>
                                        </td>
                                    ))
                                }
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        }
        {/* {children} */}
        {
        isLoader
        ?
        <div id="sp_home">
            <div className="title right">데이터를 불러오는 중입니다.</div>
        </div>
        :
        <div id="sp_home">
            <div className="title right">
                <span className="color_blue">[홈팀]</span>선발투수
            </div>
            <div className="body">
                <div className="top">
                    <div className="profile">
                        {
                        gameInfoObj['homeStartPitcherId'] ?
                        <>
                        <div className="profile_title">
                            <span
                            className='link'
                            id={homeInfo['membersId']}
                            onClick={playerClickHandler}
                            >
                            {homeInfo['lastBackNumber']}. {homeInfo['name']}
                            </span>
                        </div>
                        <div className="profile_content">
                            <table>
                                <tbody>
                                <tr>
                                    <td>소속: </td>
                                    <td>{gameInfoObj['homeTeamName']}</td>
                                </tr>
                                <tr>
                                    <td>투타: </td><td>{homeInfo['pitchingHand']}/{homeInfo['battingHand']}</td>
                                </tr>
                                <tr>
                                    <td>출생: </td><td>{homeInfo['birthDate']}</td>
                                </tr>
                                <tr>
                                    <td>신체: </td><td>{homeInfo['height']}cm / {homeInfo['weight']}kg</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        </> :
                        <>
                        <div className="profile_title"></div>
                        <div className="profile_content">선발투수 발표 전 입니다</div>
                        </>
                        }
                    </div>
                    <div className="backnum">
                        <div className="backnum_wrap">
                            {gameInfoObj['homeTeamId'] ? 
                            <img
                            src={require(`/src/assets/image/common/team_logo/logo_${gameInfoObj['homeTeamId']}_profile.png`)}
                            alt={homeInfo['name']}
                            />
                            :
                            <div style={{height: '118px', width: '94px'}}></div>
                            }
                            <div className="backnum_text">
                            {homeInfo['lastBackNumber']}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="record">
                    <table className="starter_record_table">
                        <tbody>
                        <tr>
                        <td>시즌성적<br/>(홈/원정)</td>
                        <td>
                            {
                            gameInfoObj['homeStartPitcherId'] ?
                            <>
                            {homeRecord['basicStat_gameCnt'] > 0
                            ?
                            <>
                            <span className="color_blue">
                            {homeRecord['basicStat_gameCnt']}경기 {homeRecord['win']}승{homeRecord['lose']}패
                            </span><br/>
                            ({homePitcherHomeRecord['win']}승{homePitcherHomeRecord['lose']}패/
                            {homePitcherAwayRecord['win']}승{homePitcherAwayRecord['lose']}패)
                            </>
                            :
                            <>기록 없음</>
                            }
                            </>
                            :
                            <>
                            <span className="color_blue">-</span><br/>
                            (-/-)
                            </>
                            }
                        </td>
                        </tr>
                        <tr>
                        <td>시즌자책점<br/>(홈/원정)</td>
                        <td>
                            {
                            homeRecord['basicStat_gameCnt'] > 0
                            ?
                            <>
                            <span>{homeRecord['era']}</span>
                            <br/>({homePitcherHomeRecord['era']}/{homePitcherAwayRecord['era']})
                            </>
                            :
                            <>기록 없음</>
                            }
                        </td>
                        </tr>
                        <tr>
                        <td>구종<br/>구사율<br/>구속</td>
                        <td>
                            <table className="ball_table">
                                <tbody>
                                <tr>
                                {
                                    homePitcherBall.map((item) => (
                                        <th key={'ballCode_'+item['ballCode']+"_"+item['pitcher_pitcherId']}>
                                        {item['ballCodeName'].slice(0, 2)}
                                        </th>
                                    ))
                                } 
                                </tr>
                                <tr>
                                {
                                    homePitcherBall.map((item) => (
                                        <td key={item['ballCode']+"_"+item['pitcher_pitcherId']}>
                                            <span>{(item['pitchRate']*100).toFixed(1)}</span>
                                        </td>
                                    ))
                                }
                                </tr>
                                <tr>
                                {
                                    homePitcherBall.map((item) => (
                                        <td key={'ballSpeed'+item['ballCode']+"_"+item['pitcher_pitcherId']}>
                                            <span>{(item['ballSpeed']*1).toFixed()}</span>
                                        </td>
                                    ))
                                }   
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default TodayGameSummaryBox;