function chooseColorCode(code) {
    let colorCode = 'RGB(192,192,192)';
    if (code === 6601) {//파울
        colorCode = 'RGB(6,186,65)';
    } else if (code===6602 || code ===6612 || code === 6614 || code === 6630) {//플라이아웃, 직선타, 희플, 파플아
        colorCode = 'RGB(255,132,0)';
    } else if (code===6603) {//삼진아웃
        colorCode = 'RGB(188,58,58)';
    } else if (code===6604) {//1루타
        colorCode = 'RGB(46,218,254)';
    } else if (code===6605 || code === 6608 || code === 6638) {//땅볼아웃, 병살
        colorCode = 'RGB(188,58,58)';
    } else if (code===6607) {//2루타
        colorCode = 'RGB(0,166,255)';
    } else if (code===6610) {//3루타
        colorCode = 'RGB(0,99,245)';
    } else if (code===6613) {//홈런
        colorCode = '#122c91';
        // colorCode = 'RGB(255,24,0)';
    } else {
        //실책
    }

    return colorCode;
}

function teamColorCode(code) {
    if(code == 1001) {
        return '#002855';
    } else if(code == 2001) {
        return '#ff6600';
    } else if(code == 3001) {
        return '#a50034';
    } else if(code == 4001) {
        return '#326295';
    } else if(code == 5001) {
        return '#c8102e';
    } else if(code == 6001) {
        return '#ea0029';   
    } else if(code == 7001) {
        return '#820024';
    } else if(code == 8001) {
        return '#131230';
    } else if(code == 9001) {
        return '#074ca1';
    } else {
        return '#000000';
    }
}

export {
    chooseColorCode,
    teamColorCode
}