import React from 'react';
import { useParams } from 'react-router-dom';
import PlayerRecordMain from './sub/player-record-main';
import TeamRecordMain from './sub/team-record-main';

function RecordMain() {
    const params =  useParams();
    const mainId = params['mainId'];

    const RecordTabContent = function() {
        switch(mainId) {
            case 'team':
                return <TeamRecordMain/>;
            case 'player':
                return <PlayerRecordMain/>;
            default:
                return <></>;
        }
    };

    return (
        <RecordTabContent/>
    )
}

export default RecordMain;