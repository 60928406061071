import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchBatterRecord, fetchLastGameDay, fetchPitcherRecord, fetchTeamRanking } from '../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../utils/flat';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TeamRelativeRanking from './team/team-relative-ranking';
import INIT_SEASON from '../../utils/initSeason';

function TeamRanking() {
    const [season, setSeason] = useState('');
    const [teamRanking, setTeamRanking] = useState([]);
    const [teamBatting, setTeamBatting] = useState([]);
    const [teamPitching, setTeamPitching] = useState([]);

    const seasonArr = _.range(moment(new Date()).format('YYYY')*1, INIT_SEASON-1);
    const navigate = useNavigate();

    useEffect(() => {
        const todaySeason = moment(new Date()).format('YYYY');
        fetchLastGameDay({
            seasons: [todaySeason, todaySeason-1].toString(),
            gameTypeCodes: 4201
        }).then((response) => {
            const {lastGameDay} = response['data']['RECORD'][0];
            const lastSeason = moment(lastGameDay).format('YYYY');
            setSeason(lastSeason);
        })
    }, [])

    useEffect(() => {
        if(season) {
            const rankingParam = {
                seasons: season,
                gameTypeCodes: 4201
            }
    
            const teamRankingPromise = fetchTeamRanking(rankingParam);
            const teamBattingPromise = fetchBatterRecord({...rankingParam, groupingColumn: 'team'});
            const teamPitchingPromise = fetchPitcherRecord({...rankingParam, groupingColumn: 'team'});
    
            Promise.all([
                teamRankingPromise,
                teamBattingPromise, 
                teamPitchingPromise
            ]).then((response) => {
                setTeamRanking(_.values(response[0]['data']['RECORD'])[0]);
                setTeamBatting(_.sortBy(loopFlatObject(response[1]['data']['RECORD']), 'avg').reverse());
                setTeamPitching(_.sortBy(loopFlatObject(response[2]['data']['RECORD']), 'era'))
            });
        }
    }, [season]);

    const handleChange = (e) => setSeason(e.target.value);
    const teamPlayerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const teamId = teamObj.split("/")[1];
        const season = moment(teamObj.split("/")[0]).format('YYYY');
        navigate(`/team/${teamId}?season=${season}`);
    }

    return (
    <div>
    <FormControl size="small">
        <Select
        style={{backgroundColor: '#fff'}}
        value={season}
        onChange={handleChange}
        >
        {
            _.map(seasonArr, (item) => (
                <MenuItem value={item} key={item}>{item}</MenuItem>
            ))    
        }
        </Select>
    </FormControl>
    <div style={{display:'flex'}}>
        <div className="ranking_item">
            <h3>종합순위</h3>
            <table className="hiball_table" style={{height: '400px'}}>
                <thead>
                    <tr>
                        <th>순위</th>
                        <th>팀</th>
                        <th>경기</th>
                        <th>승</th>
                        <th>무</th>
                        <th>패</th>
                        <th>승률</th>
                        <th>게임차</th>
                    </tr>
                </thead>
                <tbody>
                {
                    _.map(teamRanking, (item, idx) => (
                    <tr key={item['teamId']}>
                        <td className='first'>{item['ranking']}</td>
                        <td className='link'>
                            <div className="flex_div"
                            onClick={teamPlayerInfoHandler} 
                            id={item['gameDay']+"/"+item['teamId']} 
                            >
                                <img
                                src={require(`/src/assets/image/common/team_logo/logo_${item['teamId']}.png`)}
                                alt={item['teamName']}
                                />
                                <span>{item['teamName']}</span>
                            </div>
                        </td>
                        <td>{item['gameCnt']}</td>
                        <td>{item['win']}</td>
                        <td>{item['draw']}</td>
                        <td>{item['lose']}</td>
                        <td>{(item['winRate']*1).toFixed(3)}</td>
                        <td>{item['winGap'] || '0.0'}</td>
                    </tr>       
                    ))
                }
                </tbody>
            </table>
        </div>
        <div className="ranking_item">
            <h3>팀 상대전적</h3>
            <TeamRelativeRanking season={season}/>
        </div>
    </div>
    <h3>타격순위</h3>
    <table className="hiball_table">
        <colgroup>
            <col></col>
            <col width="5%"></col>
        </colgroup>
        <thead>
            <tr>
                <th>순위</th>
                <th>팀</th>
                <th>타수</th>
                <th>안타</th>
                <th>2타</th>
                <th>3타</th>
                <th>홈런</th>
                <th>타점</th>
                <th>득점</th>
                <th>도루</th>
                <th>사사구</th>
                <th>삼진</th>
                <th>병살</th>
                <th>타율</th>
                <th>출루율</th>
                <th>장타율</th>
                <th>OPS</th>
            </tr>
        </thead>
        <tbody>
        {
        _.map(teamBatting, (item, idx) => (
            <tr key={"batter_"+item['batterTeamId']}>
                <td className='first'>{idx+1}</td>
                <td className='link'>
                    <div className="flex_div"
                    onClick={teamPlayerInfoHandler} 
                    id={item['gameDay']+"/"+item['batterTeamId']} 
                    >
                        <img
                        src={require(`/src/assets/image/common/team_logo/logo_${item['batterTeamId']}.png`)}
                        alt={item['teamName']}
                        />
                        <span>{item['batterTeamName']}</span>
                    </div>
                </td>
                <td>{item['basicStat_ab']}</td>
                <td>{item['basicStat_hit']}</td>
                <td>{item['basicStat_b2b']}</td>
                <td>{item['basicStat_b3b']}</td>
                <td>{item['basicStat_hr']}</td>
                <td>{item['basicStat_rbi']}</td>
                <td>{item['basicStat_r']}</td>
                <td>{item['basicStat_sbSucc']}</td>
                <td>{item['basicStat_bb']}</td>
                <td>{item['basicStat_so']}</td>
                <td>{item['basicStat_gdp']}</td>
                <td>{item['avg']}</td>
                <td>{item['obp']}</td>
                <td>{item['slg']}</td>
                <td>{item['ops']}</td>
            </tr>
        ))}
        </tbody>
    </table>
    <h3>투수순위</h3>
    <table className="hiball_table">
        <colgroup>
            <col></col>
            <col width="5%"></col>
        </colgroup>
        <thead>
            <tr>
                <th>순위</th>
                <th>팀</th>
                <th>이닝</th>
                <th>피안타</th>
                <th>피홈런</th>
                <th>실점</th>
                <th>자책</th>
                <th>사사구</th>
                <th>탈삼진</th>
                <th>평균자책</th>
                {/* <th>실책</th> */}
                <th>WHIP</th>
                <th>QS</th>
                <th>QS+</th>
                <th>홀드</th>
                <th>세이브</th>
            </tr>
        </thead>
        <tbody>
        {
        _.map(teamPitching, (item, idx) => (
            <tr key={'pitcher_'+item['pitcherTeamId']}>
                <td className='first'>{idx+1}</td>
                <td className='link'>
                    <div className="flex_div"
                    onClick={teamPlayerInfoHandler} 
                    id={item['gameDay']+"/"+item['pitcherTeamId']} 
                    >
                        <img
                        src={require(`/src/assets/image/common/team_logo/logo_${item['pitcherTeamId']}.png`)}
                        alt={item['teamName']}
                        />
                        <span>{item['pitcherTeamName']}</span>
                    </div>
                </td>
                <td>{item['ip']}</td>
                <td>{item['basicStat_hit']}</td>
                <td>{item['basicStat_hr']}</td>
                <td>{item['basicStat_r']}</td>
                <td>{item['basicStat_er']}</td>
                <td>{item['basicStat_bb']}</td>
                <td>{item['basicStat_so']}</td>
                <td>{item['era']}</td>
                <td>{item['whip']}</td>
                <td>{item['qs']}</td>
                <td>{item['qsPlus']}</td>
                <td>{item['hold']}</td>
                <td>{item['save']}</td>
            </tr>
        ))}
        </tbody>
    </table>
    </div>
    )
}

export default TeamRanking;