import React, { useEffect, useState } from 'react';
import { fetchBatterRecordDetail, fetchBatterRecordTpa, fetchLastGameDay, fetchRunnerRecord, fetchTeamRanking } from '../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Oval } from 'react-loader-spinner';
import { loopFlatObject } from '../../../utils/flat';
import PlayerBattingBasicRecord from './batting/player-batting-basic-record';
import PlayerBattingDetailRecord from './batting/player-batting-detail-record';
import PlayerBattingTpaRecord from './batting/player-batting-tpa-record';
import PlayerBattingHitRecord from './batting/player-batting-hit-record';
import PlayerBattingClutchRecord from './batting/player-batting-clutch-record';
import PlayerBattingRunnerRecord from './batting/player-batting-runner-record';
import moment from 'moment';
import { useAtom } from 'jotai';
import { player_batting_filter_atom } from '../../../assets/model/detail/store';
import PlayerBattingFilter from '../../../components/filter/player-batting-filter';
import _ from 'lodash';
import FilterToggleButton from '../../../components/button/FilterToggleButton';
import { Collapse } from '@mui/material';


function PlayerBattingRecord() {
    const navigate = useNavigate();
    const [filterObj, setFilterObj] = useAtom(player_batting_filter_atom);
    const [record, setRecord] = useState([]);

    const [open, setOpen] = useState(false);
    const params = useParams();
    const tabId = params['tabId'];
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        if(!filterObj['seasons']) {
            const todaySeason = moment(new Date()).format('YYYY');
            fetchLastGameDay({
                seasons: [todaySeason, todaySeason-1].toString(),
                gameTypeCodes: 4201
            }).then((response) => {
                const {lastGameDay} = response['data']['RECORD'][0];
                const lastSeason = moment(lastGameDay).format('YYYY')*1;

                setFilterObj((prev) => {
                    return {...prev, seasons: lastSeason, regularTpa: '1'}
                })
            })
        } 
    }, [])

    useEffect(() => {
        if(filterObj['seasons']) {
            setOpen(true);
            const {seasons} = filterObj;
            const recordParam = {
                ...filterObj,
                gameTypeCodes: 4201,
                groupingColumn: 'seasonPlayer'
            }

            _.map(recordParam, (item, idx) => {
                if(Array.isArray(item)) {
                    return recordParam[idx] = item.toString();
                }
            });

            if(!recordParam['toDate'] && !recordParam['fromDate']) {
                recordParam['fromDate'] = moment(new Date(seasons, 0, 1)).format('YYYY-MM-DD');
                recordParam['toDate'] = moment(new Date(seasons, 11, 31)).format('YYYY-MM-DD');
            }

            if(tabId === 'runner') {
                fetchRunnerRecord(recordParam)
                .then((response) => {
                    const battingRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(battingRecord);
                    setOpen(false);
                })
            } else {
                let promiseArr = [];

                if(!filterObj['regularTpa'] || filterObj['regularTpa'] === 'all') {
                    promiseArr.push(fetchBatterRecordDetail(recordParam));
                } else {
                    if(tabId === 'tpa') {
                        promiseArr.push(fetchBatterRecordTpa(recordParam));
                    } else {
                        promiseArr.push(fetchBatterRecordDetail(recordParam));
                    }

                    const teamRankingPromise = fetchTeamRanking({ seasons, gameTypeCodes: 4201 });
                    promiseArr.push(teamRankingPromise);
                }

                Promise.all(
                    promiseArr
                ).then((response) => {
                    if(response.length > 1) {
                        const battingRecord = loopFlatObject(response[0]['data']['RECORD']);
                        const teamRanking = _.values(response[1]['data']['RECORD'])[0];

                        const teamIdx = _.keyBy(teamRanking, 'teamId');

                        const filterRecord = _.filter(battingRecord, (item, idx) => {
                            const teamId = item['batterTeamId'];
                            const teamInfo = teamIdx[teamId];
                            const regularTpaNum = Math.floor(teamInfo['gameCnt']*3.1*filterObj['regularTpa']);

                            return regularTpaNum <= item['statCalced_stat_tpa']*1;
                        });

                        setRecord(filterRecord);
                        setOpen(false);
                    } else {
                        const battingRecord = loopFlatObject(response[0]['data']['RECORD']);
                        setRecord(battingRecord);
                        setOpen(false);
                    }
                });
            }
        }        
    }, [filterObj])

    const BattingRecordContent = () => {
        switch(tabId) {
            case 'basic':
                return <PlayerBattingBasicRecord data={record}/>
            case 'detail':
                return <PlayerBattingDetailRecord data={record}/>
            case 'tpa':
                return <PlayerBattingTpaRecord data={record}/>
            case 'hit':
                return <PlayerBattingHitRecord data={record}/>
            case 'clutch':
                return <PlayerBattingClutchRecord data={record}/>
            case 'runner':
                return <PlayerBattingRunnerRecord data={record}/>
            default:
                return <></>
        }
    }

    const filterToggleEventHandler = () => {
        setFilterOpen(!filterOpen);
    }

    return (
    <>
    <div id="record_sub_tab_container">
        <div id="record_sub_tab_area">
            <div className={"btn "+((tabId === "basic") && "active")}
            onClick={() => navigate('/record/player/batting/basic')}
            >
            기본
            </div>
            <div className={"btn "+((tabId === "detail") && "active")}
            onClick={() => navigate('/record/player/batting/detail')}
            >
            상세
            </div>
            <div className={"btn "+((tabId === "tpa") && "active")}
            onClick={() => navigate('/record/player/batting/tpa')}
            >
            타석
            </div>
            <div className={"btn "+((tabId === "hit") && "active")}
            onClick={() => navigate('/record/player/batting/hit')}
            >
            안타
            </div>
            <div className={"btn "+((tabId === "clutch") && "active")}
            onClick={() => navigate('/record/player/batting/clutch')}
            >
            클러치
            </div>
            <div className={"btn "+((tabId === "runner") && "active")}
            onClick={() => navigate('/record/player/batting/runner')}
            >
            주루
            </div>
        </div>
        <div>
            <FilterToggleButton
            onClick={filterToggleEventHandler}
            >
                필터
            </FilterToggleButton>
        </div>
    </div>
    <div id="record_sub_container">
        <Collapse in={filterOpen} timeout={'auto'}>
            <PlayerBattingFilter/>
        </Collapse>
        {
            record.length === 0
            ?
            <></>
            :
            <BattingRecordContent/>
        }
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
            <Oval
            height="80" width="80"
            radius="9" color="#00bfff"
            secondaryColor="#fff"
            ariaLabel="three-dots-loading"
            />
        </Backdrop>
    </div>
    </>
    )
}

export default PlayerBattingRecord;