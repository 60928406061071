import { Provider } from 'jotai';
import React, { useEffect, useState } from 'react';
import arrow_big_prev from '../../assets/image/main/arrow_big_prev.png';
import arrow_big_next from '../../assets/image/main/arrow_big_next.png';
import moment from 'moment';
import GamePlanList from '../../layout/gameschedule/main/game-plan-list';
import _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import teamCode from '../../utils/team/teamCode';
import ActiveTeamRecord from '../../layout/gameschedule/main/active-team-record';
import { Button } from '@mui/material';
import INIT_SEASON from '../../utils/initSeason';
const teams = teamCode();

function GameScheduleMain() {
    const [activeTeam, setActiveTeam] = useState('all');
    const [defaultDate, setDefaultDate] = useState(new Date());
    const [season, setSeason] = useState('');
    const [month, setMonth] = useState('');
    const [dateType, setDateType] = useState('sort_asc');

    const seasonArr = _.range(moment(new Date()).format('YYYY')*1, INIT_SEASON-1);
    const monthArr = _.range(1, 13);

    useEffect(() => {
        const todaySeason = moment(defaultDate).format('YYYY');
        const todayMonth = moment(defaultDate).format('MM')*1;

        setSeason(todaySeason);
        setMonth(todayMonth);
    }, [defaultDate]);

    const dateClickHandler = (event) => {
        let dateId = event.currentTarget.id;
        let innerSeason = moment(defaultDate).format("YYYY");
        let innerMonth = moment(defaultDate).format("MM");

        if(dateId === 'prevDate') {
            if(innerMonth === '01') {
                innerSeason--;
                innerMonth = 12;
            } else {
                innerMonth--;
            }
        } else if (dateId === 'nextDate') {
            if(innerMonth === '12') {
                innerSeason++;
                innerMonth = '01';
            } else {
                innerMonth++;
            }
        }

        setDefaultDate(new Date(innerSeason,innerMonth, 0));
    }

    const dateTypeChange = (e) => {
        const dateTypeId = e.currentTarget.id;
        setDateType(dateTypeId);
    } 

    const seasonChange = (e) => {
        setDefaultDate(new Date(e.target.value, month, 0));
    }

    const monthChange = (e) => {
        setDefaultDate(new Date(season, e.target.value, 0));
    }

    const teamChangeEventHandler = (event) => {
        setActiveTeam(event.target.value);
    }

    const teamClickEventHandler = (e) => {
        const teamId = e.currentTarget.id;
        setActiveTeam(teamId)
    }

    const todayGameClickHandler = (e) => {
        const today = moment(new Date()).format("YYYY-MM-DD");

        setDefaultDate(today);
        setTimeout(() => {
            document.getElementById(today).scrollIntoView({ behavior: 'smooth' });
        }, 500)
    }

    return (
        <Provider>
            <div className="container">
                <div id="date_navigator_container">
                    <div className="arrow_prev" id="prevDate" onClick={dateClickHandler}>
                        <img src={arrow_big_prev} alt="arrow_big_prev"/>
                    </div>
                    <div className="text">
                        <div className="month">
                            <FormControl size={'small'}>
                                <Select
                                value={season}
                                onChange={seasonChange}
                                >
                                {
                                _.map(seasonArr, (item) => (
                                    <MenuItem value={item} key={item}>{item}</MenuItem>
                                ))    
                                }
                                </Select>
                            </FormControl>
                        </div>년
                        <div className="day">
                            <FormControl size={'small'}>
                                <Select
                                value={month}
                                onChange={monthChange}
                                >
                                {
                                _.map(monthArr, (item) => (
                                    item < 10
                                    ?
                                    <MenuItem value={item} key={'0'+item}>{'0'+item}</MenuItem>
                                    :
                                    <MenuItem value={item} key={item}>{item}</MenuItem>
                                ))    
                                }
                                </Select>
                            </FormControl>
                        </div>월
                    </div>
                    <div className="arrow_next" id="nextDate" onClick={dateClickHandler}>
                        <img src={arrow_big_next} alt="arrow_big_next"/>
                    </div>
                    <div style={{marginLeft: '10px'}}>
                        <Button
                        variant="outlined"
                        onClick={todayGameClickHandler}
                        >
                        오늘경기
                        </Button>
                    </div>
                    <div style={{marginLeft: '10px'}}>
                        <FormControl size={'small'}>
                            <Select
                            value={activeTeam}
                            onChange={teamChangeEventHandler}
                            >
                            {
                            teams.map((team, idx) => (
                                <MenuItem value={team['teamCode']} key={team['teamCode']}>
                                    {team['teamName']}
                                </MenuItem>
                            ))
                            }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {/* <div id="tab_container">
                    <div id="tab_btn_area">
                        {
                        teams.map((team, idx) => (
                            <div 
                            key={"team_"+team['teamCode']} 
                            id={team['teamCode']} 
                            className={"btn"+(activeTeam === team['teamCode'] ? ' active' : '')}
                            onClick={teamClickEventHandler}
                            >
                                {team['teamName']}
                            </div>
                        ))
                        }
                    </div>
                </div> */}
                <div id="game_calendar_container">
                    <div style={{display:'flex'}}>
                        <div id="sort_btn_wrap" className="half_div">
                            <div id="sort_asc"
                            className={"sort_btn" + (dateType === 'sort_asc' ? ' active' : '')}
                            onClick={dateTypeChange}
                            >
                            날짜순
                            </div>
                            <div id="sort_desc"
                            className={"sort_btn" + (dateType === 'sort_desc' ? ' active' : '')}
                            onClick={dateTypeChange}
                            >
                            날짜역순
                            </div>
                        </div>
                        <div id="active_team_record_wrap" className="half_div">
                            <ActiveTeamRecord gameDay={defaultDate} teamCode={activeTeam} />
                        </div>
                    </div>
                    <div id="game_calendar">
                        <GamePlanList gameDay={defaultDate} teamCode={activeTeam} dateType={dateType}/>
                    </div>
                </div>
            </div>
        </Provider>
    )
}

export default GameScheduleMain;