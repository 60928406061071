import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_info_obj_atom } from '../../../../assets/model/detail/store';
import _ from 'lodash';
import { fetchGameWpaResult, fetchGameWpaTpaResult } from '../../../../utils/api';
import { ResponsiveLine } from '@nivo/line';
import { teamColorCode } from '../../../../utils/color/commonColor';

function WERecord() {
    const pitcherRosterType = [5102, 5104];
    const batterRosterType = [5101, 5103];
    const gameInfoObj = useAtomValue(game_info_obj_atom);
    const [awayRecord, setAwayRecord] = useState({best: [], worst: []});
    const [homeRecord, setHomeRecord] = useState({best: [], worst: []});
    const [wpaRecord, setWpaRecord] = useState([]);
    const [colorArr, setColorArr] = useState([]);

    useEffect(() => {
        if(!_.isEmpty(gameInfoObj)) {
            const {awayTeamId, homeTeamId} = gameInfoObj;

            const wpaResultParam = {
                gameInfoIds: gameInfoObj['gameInfoId'],
                gameTypeCodes: 4201,
                groupingColumn: 'player'
            }

            const gameParam = {
                gameInfoIds: gameInfoObj['gameInfoId'],
                gameTypeCodes: 4201
            }

            const wpaResultPromise = fetchGameWpaResult(wpaResultParam);
            const wpaResultTpaPromise = fetchGameWpaTpaResult(gameParam);

            Promise.all([
                wpaResultPromise,
                wpaResultTpaPromise
            ]).then((response) => {
                const wpaTpaResult = _.sortBy(response[1]['data']['RECORD'], ['inning', 'inningTb', 'bat_inning_turn', 'outCount']);

                let lineChartData = [];
                let awayTeamArr = [];
                let homeTeamArr = [];

                _.forEach(wpaTpaResult, (item, idx) => {
                    // if(idx === 0) {
                    //     awayTeamArr.push({we: 0.48, inning: 1, inningTb: 0, y: 0.48, x: "시작"})
                    //     homeTeamArr.push({we: 0.52, inning: 1, inningTb: 0, y: 0.52, x: "시작"})
                    // }

                    const homeTeamWE = (1-item['beforeWe']).toFixed(3);
                    const afterHomeTeamWE = (1-item['afterWe']).toFixed(3);

                    if(item['inningTb'] == 0) {
                        awayTeamArr.push({
                            ...item,
                            we : item['beforeWe'], 
                            y : item['beforeWe'],
                            x : axixTitleRender(item),
                            teamName: gameInfoObj['awayTeamName']
                        })
    
                        homeTeamArr.push({
                            ...item,
                            afterWe: afterHomeTeamWE,
                            wpa: -item['wpa'],
                            we : homeTeamWE,
                            y : homeTeamWE,
                            x : axixTitleRender(item),
                            teamName: gameInfoObj['homeTeamName']
                        })
                    } else {
                        awayTeamArr.push({
                            ...item,
                            afterWe: afterHomeTeamWE,
                            wpa: -item['wpa'],
                            we : homeTeamWE,
                            y : homeTeamWE,
                            x : axixTitleRender(item),
                            teamName: gameInfoObj['awayTeamName']
                        })
    
                        homeTeamArr.push({
                            ...item,
                            we : item['beforeWe'], 
                            y : item['beforeWe'],
                            x : axixTitleRender(item),
                            teamName: gameInfoObj['homeTeamName']
                        })
                    }
                });

                lineChartData.push({
                    id: gameInfoObj['awayTeamName'],
                    color: teamColorCode(gameInfoObj['awayTeamId']),
                    data: awayTeamArr
                });

                lineChartData.push({
                    id: gameInfoObj['homeTeamName'],
                    color: teamColorCode(gameInfoObj['homeTeamId']),
                    data: homeTeamArr
                });

                setColorArr([teamColorCode(gameInfoObj['awayTeamId']), teamColorCode(gameInfoObj['homeTeamId'])])
                setWpaRecord(lineChartData);

                const wpaResult = response[0]['data']['RECORD'];
                const groupResult = _.groupBy(wpaResult, (item, idx) => {
                    if(item['inningTb'] == 1 && pitcherRosterType.indexOf(item['playerRosterType']) > -1) {
                        return awayTeamId;
                    } else if(item['inningTb'] == 1 && batterRosterType.indexOf(item['playerRosterType']) > -1) {
                        return homeTeamId;
                    } else if(item['inningTb'] == 0 && pitcherRosterType.indexOf(item['playerRosterType']) > -1) {
                        return homeTeamId;
                    } else {
                        return awayTeamId;
                    }
                });

                const awayCalcRecord = _.sortBy(groupResult[awayTeamId], 'wpa');
                const homeCalcRecord = _.sortBy(groupResult[homeTeamId], 'wpa');

                setAwayRecord(
                    {
                        worst: awayCalcRecord.slice(0, 3),
                        best: awayCalcRecord.reverse().slice(0, 3)
                    }
                );

                setHomeRecord(
                    {
                        worst: homeCalcRecord.slice(0, 3),
                        best: homeCalcRecord.reverse().slice(0, 3)
                    }
                )
            })
        }
    }, [gameInfoObj])

    const axixTitleRender = (item) => {
        return `${item['inning']}/${item['inningTb']}/${item['bat_inning_turn']}/${item['batter_name']}`;
    }

    const exportClickHandler = (event) => {
        const $svg = document.querySelector("#we_chart_line svg");

        const data = new XMLSerializer().serializeToString($svg);
        const blob = new Blob([data], {type: "image/svg+xml;charset=utf-8"});

        const $canvas = document.createElement("canvas");

        const {width, height} = $svg.getBoundingClientRect();

        $canvas.width = width; 
        $canvas.height = height;

        const ctx = $canvas.getContext('2d');

        const img = new Image();

        img.onload = (e) => {
            ctx.drawImage(e.target, 0, 0);

            const $link = document.createElement("a");

            $link.download = "image.png";
            $link.href = $canvas.toDataURL("image/png");

            $link.click();
        };

        img.src = URL.createObjectURL(blob);
    }

    const Title = ({ width, height }) => {
        // You can console.log(data) to see what other properties are available

        const style = {fontWeight: 'bold'}

        return (
            <text 
                x={(width / 2)-70}
                y={-15}
                style={style}
                textAnchor='middle'
            >{gameInfoObj['gameDay']+" "+gameInfoObj['awayTeamName']+" vs "+gameInfoObj['homeTeamName']}
            &nbsp;
            {gameInfoObj['doubleHeaderYn'] !== "N" && "DH"+gameInfoObj['doubleHeaderYn']}
            </text>
        )
    } 

    return (
    <>
    <div id="we_chart_container">
        <div className="we_chart_title">
            <span>WE Chart</span>
            <span style={{float:'right'}}>
            <button
                onClick={exportClickHandler}
                >Export To PNG</button>
            </span>
        </div>
        {
        wpaRecord.length == 0
        ?
        <div className="we_chart_content">
            No Data
        </div>
        :
        <div className="we_chart_content" style={{height: '450px'}} id="we_chart_line">
            <ResponsiveLine 
            colors={colorArr}
            theme={{
                background:"#ffffff"
            }}
            layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends', Title]}
            data={wpaRecord}
            curve={'cardinal'}
            margin={{ top: 50, right: 110, bottom: 60, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                reverse: false
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: '타석',
                legendOffset: 40,
                legendPosition: 'middle',
                format: function(value) {
                    if(value !== '경기시작') {
                        const splitValue = value.split('/')
                        const inning = splitValue[0];
                        const inningTb = splitValue[1];
                        const batInningTurn = splitValue[2];
                        const playerName = splitValue[3];

                        if(batInningTurn == 1) {
                            if(inningTb == 0) {
                                return `${inning}회초`
                            } else {
                                return `${inning}회말`
                            }
                        } else {
                            return '';
                        }
                    } else {
                        return value;
                    }
                }
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'WE',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            tooltip={({point}) => {
                const item = point;
                const data = item['data'];
                const inningTb = (data['inningTb'] === 0) ? '회초' : '회말';
                const {batter_name, inning} = data;
                return (
                    <div style={{
                        backgroundColor:'#fff', color: 'inherit', fontSize: 'inherit', borderRadius: '2px',
                        boxShadow: 'rgba(0,0,0,0.25) 0px 1px 2px', padding: '5px 9px'
                    }}>
                        <div><strong>{`${inning}${inningTb} ${batter_name}`}</strong></div>
                        <div>
                            <div style={{display: 'inline-block', width: "12px",height: '12px',backgroundColor: item['serieColor'],marginRight:'7px'}}></div>
                            <strong>{data['teamName']} WE : {data['we']}</strong>
                            <div><strong>타석 후 WE : {data['afterWe']}</strong></div>
                            <div><strong>wpa : {data['wpa']}</strong></div>
                        </div>
                    </div>
                )
            }}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            />
        </div>
        }
    </div>
    <div className="wpa_stat_container">
        <div className={"wpa_title font_color_"+gameInfoObj['awayTeamId']}>
            {gameInfoObj['awayTeamName']} WPA
        </div>
        <div className="wpa_table_wrap">
            <div className="wpa_stat_table">
                <table className="hiball_table">
                    <thead>
                        <tr><th colSpan={2}>BEST</th></tr>
                    </thead>
                    <tbody>
                    {
                    _.map(awayRecord['best'], (item, idx) => (
                        <tr key={item['playerName']}>
                            <td>{item['playerName']}</td>
                            <td>{item['wpa']}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
            <div className="wpa_stat_table">
                <table className="hiball_table">
                    <thead>
                        <tr><th colSpan={2}>WORST</th></tr>
                    </thead>
                    <tbody>
                    {
                    _.map(awayRecord['worst'], (item, idx) => (
                        <tr key={item['playerName']}>
                            <td>{item['playerName']}</td>
                            <td>{item['wpa']}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div className="wpa_stat_container">
        <div className={"wpa_title font_color_"+gameInfoObj['homeTeamId']}>
            {gameInfoObj['homeTeamName']} WPA
        </div>
        <div className="wpa_table_wrap">
            <div className="wpa_stat_table">
                <table className="hiball_table">
                    <thead>
                        <tr><th colSpan={2}>BEST</th></tr>
                    </thead>
                    <tbody>
                    {
                    _.map(homeRecord['best'], (item, idx) => (
                        <tr key={item['playerName']}>
                            <td>{item['playerName']}</td>
                            <td>{item['wpa']}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
            <div className="wpa_stat_table">
                <table className="hiball_table">
                    <thead>
                        <tr><th colSpan={2}>WORST</th></tr>
                    </thead>
                    <tbody>
                    {
                    _.map(homeRecord['worst'], (item, idx) => (
                        <tr key={item['playerName']}>
                            <td>{item['playerName']}</td>
                            <td>{item['wpa']}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </>
    )
}

export default WERecord;