import React, { Fragment, useState } from 'react';

function HitCourseInfo(props) {
    const item = props['data'];

    return (
        <Fragment key={item.gameRecordId}>
            <circle 
                key={item.gameRecordId}
                id={item.gameRecordId}
                cx={item.x}
                cy={item.y}
                r="6" 
                fill={item.color}
                opacity="1" 
                stroke="black"
                onMouseOver={props.handleMouseOver}
                onMouseOut={props.handleMouseOut}
            />
        </Fragment>
    )
}

export default HitCourseInfo;