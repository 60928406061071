import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchTeamRecord } from '../../../utils/api';
import _ from 'lodash';
import { ResponsiveCalendar } from '@nivo/calendar';
import moment from 'moment';

function TeamWinloseBox() {
    const [schedule, setSchedule] = useState([]);

    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const teamId = params['teamId'];

    const fromDate = moment(new Date(season-2, 0, 1)).format('YYYY-MM-DD');
    const toDate = moment(new Date(season, 11, 31)).format('YYYY-MM-DD');

    useEffect(() => {
        moment.locale("ko");
        const scheduleParam = {
            gameTypeCodes: 4201,
            seasons: season
        }

        const teamScheduleParam = {
            ...scheduleParam, teamId: teamId, groupingColumn: 'game'
        }

        const teamSchedulePromise = fetchTeamRecord(teamScheduleParam);
        const exTeamSchedulePromise = fetchTeamRecord({...teamScheduleParam, seasons: season-1});
        const exExTeamSchedulePromise = fetchTeamRecord({...teamScheduleParam, seasons: season-2});

        Promise.all([
            teamSchedulePromise,
            exTeamSchedulePromise,
            exExTeamSchedulePromise
        ]).then((response) => {

            const teamSchedule = response[0]['data']['RECORD'];
            const exTeamSchedule = response[1]['data']['RECORD'];
            const exExTeamSchedule = response[2]['data']['RECORD'];

            const totalGameArr = _.sortBy(_.flattenDeep([teamSchedule, exTeamSchedule, exExTeamSchedule]), ['gameDay']);
            
            const calcGameArr = _.map(totalGameArr, (item, idx) => {
                item['day'] = item['gameDay'];
                if(item['win']*1 === 1) {
                    item['value'] = 1.5;
                } else {
                    item['value'] = 0;
                }
                return item;
            });

            calcGameArr.push({
                gameDay: moment(new Date()).format('YYYY-MM-DD'),
                day: moment(new Date()).format('YYYY-MM-DD'),
                value: 2.5
            })

            setSchedule(calcGameArr);
        })
    }, [])

    return (
    <div id="team_basic_container">
        <div className="table_title">승무패</div>
        <div className="calendar_chart" style={{height: '550px'}} id="calendar_chart">
        <ResponsiveCalendar
            data={schedule}
            from={fromDate}
            to={toDate}
            emptyColor="#eeeeee"
            colors={[ '#eeeeee', '#f47560', '#FFE400']}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            yearSpacing={40}
            monthSpacing={20}
            monthBorderColor="#ffffff"
            monthLegend={(year, month, date) => {
                return month+1+"월";
            }}
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            minValue={0}
            maxValue={3}
            tooltip={(item) => {
                const {win, lose, draw, gameDay} = item['data'];
                
                let result = "";

                if(gameDay === moment(new Date()).format('YYYY-MM-DD')) {
                    result = "오늘";
                } else {
                    if(win*1+lose*1+draw*1 ===  0 ) {
                        result = "결과 없음"
                    } else if(win*1 === 1) {
                        result = "승";
                    } else if(lose*1 === 1) {
                        result = "패";
                    } else if(draw*1 === 1) {
                        result = "무";
                    }
                }

                return (
                    <div style={{
                        backgroundColor:'#fff', color: 'inherit', fontSize: 'inherit', borderRadius: '2px',
                        boxShadow: 'rgba(0,0,0,0.25) 0px 1px 2px', padding: '5px 9px'
                    }}>
                        <div>
                            <div style={{display: 'inline-block', width: "12px",height: '12px',backgroundColor: item['color'],marginRight:'7px'}}></div>
                            <strong>{gameDay+" : "+ result}</strong>
                        </div>
                    </div>
                )
            }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left'
                }
            ]}
        />
        </div>
    </div>
    )
}

export default TeamWinloseBox;