export function getComparatorBatting(statType, sortColumn, direction) {
    if(statType === "basic") {
        switch (sortColumn) {
            case 'statCalced_stat_gameCnt':
            case 'stat_starter':
            case 'stat_substitute':
            case 'statCalced_stat_tpa':
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'stat_leftPitcherAvg':
            case 'stat_leftPitcherXh':
            case 'stat_rightPitcherAvg':
            case 'stat_rightPitcherXh':
            case 'statCalced_stat_ab':
            case 'statCalced_stat_hit':
            case 'statCalced_stat_b1b':
            case 'statCalced_stat_b2b':
            case 'statCalced_stat_b3b':
            case 'statCalced_stat_hr':
            case 'statCalced_stat_bbAddIbb':
            case 'statCalced_stat_ibb':
            case 'statCalced_stat_hbp':
            case 'statCalced_stat_r':
            case 'statCalced_stat_rbi':
            case 'statCalced_stat_so':
            case 'statCalced_stat_pitchingCnt':
            case 'statCalced_stat_lookingSo':
            case 'statCalced_stat_swingSo':
            case 'statCalced_lookingSoRate':
            case 'statCalced_swingSoRate':
            case 'statCalced_stat_sf':
            case 'statCalced_stat_sb':
            case 'statCalced_stat_gdp':
            case 'statCalced_stat_stealCnt':
            case 'statCalced_stat_failStealCnt':
            case 'statCalced_stealRate':
            case 'statCalced_scoringAvg':
            case 'statCalced_woba':
            case 'statCalced_wrcPlus':
            case 'statCalced_wraa':
            case 'statCalced_oWAR':
            case 'statCalced_stat_wpa':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }
}

export function getComparatorPitching(statType, sortColumn, direction) {
    if(statType === "basic") {
        switch (sortColumn) {
            case 'statCalced_stat_gameCnt':
            case 'statCalced_stat_tpa':
            case 'statCalced_ip':
            case 'stat_blown':
            case 'stat_pitchingCnt':
            case 'stat_starter':
            case 'stat_substitute':
            case 'win':
            case 'lose':
            case 'save':
            case 'hold':
            case 'stat_r':
            case 'stat_er':
            case 'stat_tpa':
            case 'stat_ab':
            case 'stat_hit':
            case 'stat_b1b':
            case 'stat_b2b':
            case 'stat_b3b':
            case 'stat_hr':
            case 'stat_xh':
            case 'stat_so':
            case 'stat_bbAddIbb':
            case 'stat_ibb':
            case 'stat_hbp':
            case 'stat_sf':
            case 'stat_sb':
            case 'statCalced_winRate':
            case 'statCalced_era':
            case 'statCalced_fip':
            case 'statCalced_whip':
            case 'stat_wpa':
            case 'statCalced_totalWar':
            case 'statCalced_bWar':
            case 'statCalced_pitcherWAR':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                }
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "detail") {
        switch (sortColumn) {
            case 'statCalced_stat_tpa':
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'statCalced_totalWar':
            case 'statCalced_bWar':
            case 'statCalced_pitcherWAR':
            case 'statCalced_era':
            case 'statCalced_fip':
            case 'statCalced_eraSubFip':
            case 'statCalced_kRate':
            case 'statCalced_bbRate':
            case 'statCalced_kBbRate':
            case 'statCalced_hit9':
            case 'statCalced_hr9':
            case 'statCalced_k9':
            case 'statCalced_bb9':
            case 'statCalced_stat_lookingSo':
            case 'statCalced_lookingSoRate':
            case 'statCalced_stat_swingSo':
            case 'statCalced_swingSoRate':
            case 'statCalced_babip':
            case 'statCalced_lobRate':
            case 'statCalced_pDivip':
            case 'statCalced_gDivIp':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "tpa") {
        switch (sortColumn) {
            case 'statCalced_ip':
            case 'statCalced_stat_pitchingCnt':
            case 'statCalced_pDivPa':
            case 'statCalced_strikeRate':
            case 'statCalced_ballRate':
            case 'statCalced_zoneStrikeRate':
            case 'statCalced_zoneBallRate':
            case 'statCalced_strikeStandRate':
            case 'statCalced_strikeSwingRate':
            case 'statCalced_strikeFoulRate':
            case 'statCalced_strikeInplayRate':
            case 'statCalced_firstStrikeRate':
            case 'statCalced_swStr':
            case 'statCalced_oStandRate':
            case 'statCalced_zStandRate':
            case 'statCalced_standRate':
            case 'statCalced_oSwingRate':
            case 'statCalced_zSwingRate':
            case 'statCalced_swingRate':
            case 'statCalced_oContactRate':
            case 'statCalced_zContactRate':
            case 'statCalced_contactRate':
            case 'statCalced_whiff':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "ballspeed") {
        switch (sortColumn) {
            case 'totalCnt':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            case '6116_cnt':
            case '6101_cnt':
            case '6117_cnt':
            case '6108_cnt':
            case '6111_cnt':
            case '6103_cnt':
            case '6107_cnt':
            case '6119_cnt':
            case '6114_cnt':            
            case '6106_cnt':
            case '6112_cnt':
            case '6116_rate':
            case '6101_rate':
            case '6117_rate':
            case '6108_rate':
            case '6111_rate':
            case '6103_rate':
            case '6107_rate':
            case '6119_rate':
            case '6114_rate':
            case '6106_rate':
            case '6112_rate':
                return (a, b) => {
                    if(direction === 'ASC') {
                        if(a[sortColumn] === "-") return 1;
                        if(b[sortColumn] === "-") return -1;
                        if(a[sortColumn] === b[sortColumn]) return 0;    
                    } else {
                        if(a[sortColumn] === "-") return -1;
                        if(b[sortColumn] === "-") return 1;
                        if(a[sortColumn] === b[sortColumn]) return 0;
                    }
                    return a[sortColumn] - b[sortColumn];
                }
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }
}