import React from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';

const JoinPlatformPage = () => {
    const { policyAgreeYn }  = useOutletContext();

    const navigate = useNavigate();

    const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&scope=email profile`;
    const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
    const naverUrl = `https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}&response_type=code`;

    const joinHandler = () => {
        navigate('/join');
    }
    const googleJoinBtnHandler = (e) => {
        window.location.href = googleUrl;
    }

    const kakaoJoinBtnHandler = (e) => {
        window.location.href = kakaoUrl;
    }

    const naverJoinBtnHandler = (e) => {
        window.location.href = naverUrl;
    }

    return (
        policyAgreeYn === "Y"
        ?
        <div className="container">
            <div id="loginBox">
                <div className="titleWrap">
                    <span>Membership</span><span>Join</span>
                </div>

                <div className="buttonWrap">
                    <button id="loginBtn" onClick={joinHandler}>
                        Hiball 회원가입
                    </button>

                    <button id="googleBtn" onClick={googleJoinBtnHandler}>
                        <div className="icon google"></div>
                        Google 계정으로 가입
                    </button>

                    <button id="kakaoBtn" onClick={kakaoJoinBtnHandler}>
                        <div className="icon kakao"></div>
                        카카오 계정으로 가입
                    </button>

                    <button id="naverBtn" onClick={naverJoinBtnHandler}>
                        <div className="icon naver"></div>
                        네이버 계정으로 가입
                    </button>
                </div>
            </div>
        </div>
        :
        <div className="container">
            <div id="errorWrap">
                <h1>올바르지 않은 접근입니다.</h1>
            </div>
        </div>
    );
};

export default JoinPlatformPage;