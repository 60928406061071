import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import battingColumnCalc from '../../../../utils/table/battingColumnCalc';
import { useBatterTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
const tooltip = batterTooltip();

function TeamBattingBasicRecord(props) {
    const statType = 'basic';
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_stat_tpa",
        direction: "DESC"
    }]);

    const columns = [
        {
            key: '', name: '랭킹',
            renderHeaderCell: function(props) {
                return props['column']['name']
            },
            renderCell: function(props) {
                return props['rowIdx']+1
            },
            frozen: true,
            sortable: false
        },
        { name: '시즌', key: 'season', frozen: true, sortable: false},
        { name: '팀', key: 'batterTeamName', frozen: true, sortable: false, renderCell: useBatterTeamRender},
        // { name: '선수', key: 'batterName', sortable: false, frozen: true},
        { name: '출장', width: 55, key: 'statCalced_stat_gameCnt', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: '선발', width: 55, key: 'stat_starter', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['starter']},
        { name: '교체', width: 55, key: 'stat_substitute', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['substitute']},
        { name: '타석', width: 55, key: 'statCalced_stat_tpa', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['tpa']},
        { name: '타율', width: 55, key: 'statCalced_avg', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['avg']},
        { name: '출루율', width: 63, key: 'statCalced_obp', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['obp']},
        { name: '장타율', width: 63, key: 'statCalced_slg', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['slg']},
        { name: "OPS", width: 63, key: 'statCalced_ops', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['ops']},
        { name: '좌장타', width: 63, key: 'stat_leftPitcherXh', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['leftPitcherXh']},
        { name: '우장타', width: 63, key: 'stat_rightPitcherXh', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['rightPitcherXh']},
        { name: '타수', width: 55, key: 'statCalced_stat_ab', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['ab']},
        { name: '안타', width: 55, key: 'statCalced_stat_hit', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['hit']},
        { name: '1루타', width: 63, key: 'statCalced_stat_b1b', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['b1b']},
        { name: '2루타', width: 63, key: 'statCalced_stat_b2b', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['b2b']},
        { name: '3루타', width: 63, key: 'statCalced_stat_b3b', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['b3b']},
        { name: '홈런', width: 55, key: 'statCalced_stat_hr', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['hr']},
        { name: '볼넷', width: 55, key: 'statCalced_stat_bbAddIbb', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['bbAddIbb']},
        { name: '고의4구', width: 70, key: 'statCalced_stat_ibb', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['ibb']},
        { name: '사구', width: 55, key: 'statCalced_stat_hbp', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['hbp']},
        { name: '득점', width: 55, key: 'statCalced_stat_r', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['r']},
        { name: '타점', width: 55, key: 'statCalced_stat_rbi', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['rbi']},
        { name: '삼진', width: 55, key: 'statCalced_stat_so', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['so']},
        { name: '투구수', width: 63, key: 'statCalced_stat_pitchingCnt', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['pitchingCnt']},
        { name: "Lk.K", width: 63, key: 'statCalced_stat_lookingSo', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['lookingSo']},
        { name: "Sw.K", width: 63, key: 'statCalced_stat_swingSo', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['swingSo']},
        { name: "Lk.K%", width: 63, key: 'statCalced_lookingSoRate', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['lookingSoRate']},
        { name: "Sw.K%", width: 63, key: 'statCalced_swingSoRate', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['swingSoRate']},
        { name: '희비', width: 55, key: 'statCalced_stat_sf', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['sf']},
        { name: '희타', width: 55, key: 'statCalced_stat_sb', type: 'number' , renderHeaderCell: tooltipRender, tooltip: tooltip['sb']},
        { name: '병살타', width: 63, key: 'statCalced_stat_gdp', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['gdp']},
        { name: '도루', width: 55, key: 'statCalced_stat_stealCnt', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['stealCnt']},
        { name: '도실', width: 55, key: 'statCalced_stat_failStealCnt', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['failStealCnt']},
        { name: '도루%', width: 63, key: 'statCalced_stealRate', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['stealRate']},
        { name: '득점권타율', width: 85, key: 'statCalced_scoringAvg', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringAvg']},
        { name: "wOBA", width: 63, key: 'statCalced_woba', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['woba']},
        { name: "wRC+", width: 63, key: 'statCalced_wrcPlus', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['wrcPlus']},
        { name: "wRAA", width: 63, key: 'statCalced_wraa', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['wraa']},
        { name: "oWAR", width: 63, key: 'statCalced_oWAR', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['oWAR']},
        { name: "WPA", width: 63, key: 'statCalced_stat_wpa', type: 'number', renderHeaderCell: tooltipRender, tooltip: tooltip['wpa']}
    ];

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {

                const comparator = battingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['batterId'];
    }

    const gridElement = (
        <DataGrid
        className="record_team_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '팀 타격 기본기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default TeamBattingBasicRecord;