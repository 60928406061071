import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCatcherRecord, fetchFielderRecord, fetchInfielderRecord, fetchLastGameDay, fetchOutfielderRecord } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import Backdrop from '@mui/material/Backdrop';
import { Oval } from 'react-loader-spinner';
import PlayerFieldingBasicRecord from './fielding/player-fielding-basic-record';
import PlayerFieldingCatcherRecord from './fielding/player-fielding-catcher-record';
import moment from 'moment';
import PlayerFieldingInFielderRecord from './fielding/player-fielding-infielder-record';
import PlayerFieldingOutFielderRecord from './fielding/player-fielding-outfielder-record';
import { player_fielding_filter_atom } from '../../../assets/model/detail/store';
import PlayerFieldingFilter from '../../../components/filter/player-fielding-filter';
import _ from 'lodash';
import { useAtom } from 'jotai';
import FilterToggleButton from '../../../components/button/FilterToggleButton';
import { Collapse } from '@mui/material';

function PlayerFieldingRecord() {
    const navigate = useNavigate();
    const [filterObj, setFilterObj] = useAtom(player_fielding_filter_atom);
    const [record, setRecord] = useState([]);

    const [open, setOpen] = useState(false);
    const params = useParams();
    const tabId = params['tabId'];
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        if(!filterObj['seasons']) {
            const todaySeason = moment(new Date()).format('YYYY');
            fetchLastGameDay({
                seasons: [todaySeason, todaySeason-1].toString(),
                gameTypeCodes: 4201
            }).then((response) => {
                const {lastGameDay} = response['data']['RECORD'][0];
                const lastSeason = moment(lastGameDay).format('YYYY')*1;

                setFilterObj((prev) => {
                    return {...prev, seasons: lastSeason}
                })
            })
        } 
    }, [])

    useEffect(() => {
        if(filterObj['seasons']) {
            setOpen(true);
            const {seasons} = filterObj;
            const recordParam = {
                ...filterObj,
                gameTypeCodes: 4201,
                groupingColumn: 'seasonPlayer'
            }

            _.map(recordParam, (item, idx) => {
                if(Array.isArray(item)) {
                    return recordParam[idx] = item.toString();
                }
            });

            if(!recordParam['toDate'] && !recordParam['fromDate']) {
                recordParam['fromDate'] = moment(new Date(seasons, 0, 1)).format('YYYY-MM-DD');
                recordParam['toDate'] = moment(new Date(seasons, 11, 31)).format('YYYY-MM-DD');
            }

            if(tabId === 'catcher') {
                fetchCatcherRecord(recordParam)
                .then((response) => {
                    const fielderRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(fielderRecord);
                    setOpen(false);
                })  
            } else if(tabId === 'infielder'){
                recordParam['foreignerYn'] = "";
                recordParam['battingHands'] = "";
                recordParam['positionTypeCodes'] = '5203,5204,5205,5206';
                fetchInfielderRecord(recordParam)
                .then((response) => {
                    const fielderRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(fielderRecord);
                    setOpen(false);
                })
            } else if(tabId === 'outfielder') {
                recordParam['foreignerYn'] = "";
                recordParam['battingHands'] = "";
                recordParam['positionTypeCodes'] = '5207,5208,5209';
                fetchOutfielderRecord(recordParam)
                .then((response) => {
                    const fielderRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(fielderRecord);
                    setOpen(false);
                })
            } else {
                fetchFielderRecord(recordParam)
                .then((response) => {
                    const fielderRecord = loopFlatObject(response['data']['RECORD']);
                    setRecord(fielderRecord);
                    setOpen(false);
                })
            }
        }
    }, [filterObj]);

    const FieldingRecordContent = () => {
        switch(tabId) {
            case 'basic':
                return <PlayerFieldingBasicRecord data={record}/>
            case 'infielder':
                return <PlayerFieldingInFielderRecord data={record}/>
            case 'outfielder':
                return <PlayerFieldingOutFielderRecord data={record}/>
            case 'catcher':
                return <PlayerFieldingCatcherRecord data={record}/>
            default:
                return <></>
        }
    }

    const filterToggleEventHandler = () => {
        setFilterOpen(!filterOpen);
    }

    return (
    <>
    <div id="record_sub_tab_container">
        <div id="record_sub_tab_area">
            <div className={"btn "+((tabId === "basic") && "active")}
            onClick={() => navigate('/record/player/fielding/basic')}
            >
            기본
            </div>
            <div className={"btn "+((tabId === "infielder") && "active")}
            onClick={() => navigate('/record/player/fielding/infielder')}
            >
            내야
            </div>
            <div className={"btn "+((tabId === "outfielder") && "active")}
            onClick={() => navigate('/record/player/fielding/outfielder')}
            >
            외야
            </div>
            <div className={"btn "+((tabId === "catcher") && "active")}
            onClick={() => navigate('/record/player/fielding/catcher')}
            >
            포수
            </div>
        </div>
        <div>
            <FilterToggleButton
            onClick={filterToggleEventHandler}
            >
                필터
            </FilterToggleButton>
        </div>
    </div>
    <div id="record_sub_container">
        <Collapse in={filterOpen} timeout={'auto'}>
            <PlayerFieldingFilter/>
        </Collapse>
        {
            record.length === 0
            ?
            <></>
            :
            <FieldingRecordContent/>
        }
        
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
            <Oval
            height="80" width="80"
            radius="9" color="#00bfff"
            secondaryColor="#fff"
            ariaLabel="three-dots-loading"
            />
        </Backdrop>
    </div>
    </>
    )
}

export default PlayerFieldingRecord;