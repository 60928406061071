import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_info_obj_atom } from '../../../assets/model/detail/store';
import _ from 'lodash';
import moment from 'moment';
import { fetchPitcherRecord, fetchPitcherRecordDetail, fetchPitcherBallType } from '../../../utils/api';
import { flatObject } from '../../../utils/flat';
import LineupTableComponent from '../../../components/table/LineupTableComponent';
import BallTypeTableComponent from '../../../components/table/BallTypeTableComponent';
import { Grid } from '@mui/material';

function HomeScheduleStarterBox() {
    const gameInfoObj = useAtomValue(game_info_obj_atom);
    const [awayPitcherRecord, setAwayPitcherRecord] = useState({});
    const [homePitcherRecord, setHomePitcherRecord] = useState({});
    const [awayPitcherBall, setAwayPitcherBall] = useState([]);
    const [homePitcherBall, setHomePitcherBall] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        if(!_.isEmpty(gameInfoObj)) {
            setIsLoader(true);
            const {awayStartPitcherId, homeStartPitcherId, season, gameDay} = gameInfoObj;

            const awayPitcherParam = {seasons: season, gameTypeCodes: 4201, pitcherId: awayStartPitcherId, gameDay};
            const homePitcherParam = {seasons: season, gameTypeCodes: 4201, pitcherId: homeStartPitcherId, gameDay};
            
            const fromDate = moment(new Date(season, 0, 1)).format('YYYY-MM-DD');
            const toDate = gameDay;

            const detailCommonParam = {fromDate, toDate, groupingColumn: "seasonPlayer", seasons: season, gameTypeCodes: 4201};
            const awayPitcherDetailParam = {...detailCommonParam, pitcherIds: awayStartPitcherId};
            const homePitcherDetailParam = {...detailCommonParam, pitcherIds: homeStartPitcherId};

            const awayPitcherRecordPromise = awayStartPitcherId ? fetchPitcherRecord(awayPitcherParam) : null;
            const homePitcherRecordPromise = homeStartPitcherId ? fetchPitcherRecord(homePitcherParam) : null;

            const awayPitcherDetailPromise = awayStartPitcherId ? fetchPitcherRecordDetail(awayPitcherDetailParam) : null;
            const homePitcherDetailPromise = homeStartPitcherId ? fetchPitcherRecordDetail(homePitcherDetailParam) : null;

            const awayPitcherBallType = awayStartPitcherId ? fetchPitcherBallType({limitEnd:4, ...awayPitcherParam, groupingColumn: 'seasonPlayer'}) : null;
            const homePitcherBallType = homeStartPitcherId ? fetchPitcherBallType({limitEnd:4, ...homePitcherParam, groupingColumn: 'seasonPlayer'}) : null;

            Promise.all([
                awayPitcherRecordPromise,
                homePitcherRecordPromise,
                awayPitcherDetailPromise,
                homePitcherDetailPromise,
                awayPitcherBallType,
                homePitcherBallType
            ]).then((response) => {
                const awayRecord = response[2] ? (response[2]['data']['RECORD'][0]) : {};
                const homeRecord = response[3] ? (response[3]['data']['RECORD'][0]) : {};

                setAwayPitcherRecord(awayRecord ? flatObject(awayRecord) : {});
                setHomePitcherRecord(homeRecord ? flatObject(homeRecord) : {});
                setAwayPitcherBall(response[4] ? response[4]['data']['RECORD'] : []);
                setHomePitcherBall(response[5] ? response[5]['data']['RECORD'] : []);
                setIsLoader(false);
            })
        } else {
            setIsLoader(true);
        }
    }, [gameInfoObj])

    return (
    <>
    {
    isLoader
    ?
    <Grid id="record_season" item md={1} xs={2}>
        데이터를 불러오는 중입니다.
    </Grid>
    :
    <Grid id="record_season" item md={1} xs={2}>
        <div className='body'>
            <div className='top'>
                <div className='team_pitcher away'>
                    <div className="pitcher_name">
                        <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfoObj['awayTeamId']}.png`)}
                        alt={gameInfoObj['awayTeamName']}/>
                        {gameInfoObj['awayStartPitcherName']}
                    </div>
                    <div className='pitcher_stat'>
                    {
                    awayPitcherRecord['statCalced_stat_gameCnt']
                    ?
                    <>
                    {awayPitcherRecord['statCalced_stat_gameCnt']}경기&nbsp;
                    {awayPitcherRecord['win']}승{awayPitcherRecord['lose']}패 {awayPitcherRecord['statCalced_era']}
                    </>
                    :
                    <>
                    기록없음
                    </>
                    }
                    </div>
                </div>
                <div className="title">선발투수</div>
                <div className='team_pitcher home'>
                    <div className="pitcher_name">
                        <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfoObj['homeTeamId']}.png`)}
                        alt={gameInfoObj['homeTeamName']}/>
                        {gameInfoObj['homeStartPitcherName']}
                    </div>
                    <div className='pitcher_stat'>
                    {
                    homePitcherRecord['statCalced_stat_gameCnt']
                    ?
                    <>
                    {homePitcherRecord['statCalced_stat_gameCnt']}경기&nbsp;
                    {homePitcherRecord['win']}승{homePitcherRecord['lose']}패 {homePitcherRecord['statCalced_era']}
                    </>
                    :
                    <>
                    기록없음
                    </>
                    }
                    </div>
                </div>
            </div>
            <div className='bottom'>
                <div className='team_pitcher away'>
                    <div style={{marginBottom: '10px'}}>
                        <LineupTableComponent data={awayPitcherRecord}/>
                    </div>
                    <div>
                        <BallTypeTableComponent data={awayPitcherBall}/>
                    </div>
                </div>
                <div className='team_pitcher home'>
                    <div style={{marginBottom: '10px'}}>
                        <LineupTableComponent data={homePitcherRecord}/>
                    </div>
                    <div>
                        <BallTypeTableComponent data={homePitcherBall}/>
                    </div>
                </div>
            </div>
        </div>
    </Grid>
    }
    </>
    )
}

export default HomeScheduleStarterBox;