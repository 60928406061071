import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fetchPitchByPitch } from '../../utils/api';
import HitCourseZoneChart from '../../components/chart/HitCourseZoneChart';
import { toPng } from 'html-to-image';
import moment from 'moment';
import ExportPngButton from '../../components/button/ExportPngButton';

function PlayerSprayBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [colorType, setColorType] = useState('hitResult');
    const [pbpData, setPbpData] = useState([]);
    const pathname = useLocation()['pathname'];
    const playerType = (pathname.indexOf('batter') > -1) ? 'batter' : 'pitcher';

    const ballCodeArr = [
        {name: 'fastball', code: 6116, color: '#ff0000'}, {name: 'sinker', code: 6101, color: '#ff7800'}, 
        {name: 'cutter', code: 6117, color: '#00ff00'}, {name: 'slider', code:6111, color: '#00aa00'},
        {name: 'curve', code: 6108, color: '#000000'}, {name: 'changeup', code: 6103, color: '#0000ff'}, 
        {name: 'splitter', code: 6107, color: '#a01ff0'}, {name: 'other', code:6199, color: '#ececec'}
    ]

    useEffect(() => {
        const playerId = params['playerId'];
        const pbpParam = {
            seasons: season,
            gameTypeCodes: 4201
        }

        if(pathname.indexOf('batter') > -1) {
            pbpParam['batterIds'] = playerId;
        } else { //pitcher
            pbpParam['pitcherIds'] = playerId;
        }

        fetchPitchByPitch(pbpParam)
        .then((response) => {
            const pitchData = response['data']['RECORD'];
            setPbpData(pitchData);
        })
    }, []);

    const exportClickHandler = (event) => {
        const firstData = pbpData[0];
        
        let title = moment(firstData['gameDay']).format('YYYY')+" ";
        if(playerType === 'batter') {
            title += firstData['batterTeamName']+ " "+firstData['batterName'] + " 타구분포"
        } else {
            title += firstData['pitcherTeamName']+ " "+firstData['pitcherName'] + " 타구분포";
        }

        let node = document.getElementById('player_info_spray_svg');

        toPng(node)
        .then(function (dataUrl) {
            const link = document.createElement('a')
            link.download = title+'.png';
            link.href = dataUrl
            link.click()
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    }

    return (
    <>
    <div style={{textAlign: 'right', padding:'2px 2px 0px 0px'}}>
        <span>
        <ExportPngButton
        onClick={exportClickHandler}
        >
        PNG   
        </ExportPngButton>
        </span>
    </div>
    <div style={{textAlign:'center', backgroundColor: '#fff'}} id="player_info_spray_svg">
        <HitCourseZoneChart
        data={pbpData} 
        ballCodeArr={ballCodeArr} 
        colorType={colorType}
        size={300}/>
    </div>
    </>
    )
}

export default PlayerSprayBox;