import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import { getComparatorPitching } from '../../../../utils/table/columnCalc';
import { usePitcherTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import pitcherTooltip from '../../../../utils/tooltip/pitcherTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = pitcherTooltip();

function TeamPitchingBallSpeedRecord(props) {
    const statType = "ballspeed";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "totalCnt",
        direction: "DESC"
    }]);

    const width = 60;
    const columns = [
        RANKING_CELL, 
        { name: '시즌', key: 'season', frozen: true, sortable: false},
        { name: '팀', key: 'playerTeamName', frozen: true, sortable: false, renderCell: usePitcherTeamRender},
        // { name: '선수', key: 'playerName', frozen: true, sortable: false, width: 85, renderCell: usePitcherRender},
        { name: '투구수', width: 70, key: 'totalCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['totalPitch']},
        { name: '직구', key: '6116_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['fastBallCnt']},
        { name: '직구%', key: '6116_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['fastBallPitchRate']},
        { name: '투심', key: '6101_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['twoSeamCnt']},
        { name: '투심%', key: '6101_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['twoSeamPitchRate']},
        { name: '커터', key: '6117_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['cutterCnt']},
        { name: '커터%', key: '6117_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['cutterPitchRate']},
        { name: '커브', key: '6108_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['curveCnt']},
        { name: '커브%', key: '6108_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['curvePitchRate']},
        { name: '슬라', key: '6111_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['sliderCnt']},
        { name: '슬라%', key: '6111_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['sliderPitchRate']},
        { name: '체인', key: '6103_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['changeUpCnt']},
        { name: '체인%', key: '6103_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['changeUpPitchRate']},
        { name: '포크', key: '6107_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['folkCnt']},
        { name: '포크%', key: '6107_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['folkPitchRate']},
        { name: '스플', key: '6119_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['splitterCnt']},
        { name: '스플%', key: '6119_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['splitterPitchRate']},
        { name: '너클', key: '6114_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['nuckleCnt']},
        { name: '너클%', key: '6114_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['nucklePitchRate']},
        { name: '싱커', key: '6106_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['sinkerCnt']},
        { name: '싱커%', key: '6106_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['sinkerPitchRate']},
        { name: '슬러', key: '6112_cnt',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['sluveCnt']},
        { name: '슬러%', key: '6112_rate',width: width, renderHeaderCell: tooltipRender, tooltip: tooltip['sluvePitchRate']}
    ];

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparatorPitching(statType, sort.columnKey, sort.direction);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['playerId'];
    }

    const gridElement = (
        <DataGrid
        className="record_team_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '팀 투구 구질기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default TeamPitchingBallSpeedRecord;