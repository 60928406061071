import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import fieldingColumnCalc from '../../../../utils/table/fieldingColumnCalc';
import { useFielderTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function TeamFieldingBasicRecord(props) {
    const statType = "basic";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCacled_defenseInning",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL, 
        { name: '시즌', key: 'season', sortable: false},
        { name: '팀', key: 'fielderTeamName', sortable: false, renderCell: useFielderTeamRender},
        { name: '출전', key: 'stat_gameCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: '수비이닝', key: 'statCacled_defenseInning', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: '자살', key: 'stat_poCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['poCnt']},
        { name: '보살', key: 'stat_aoCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['aoCnt']},
        { name: '실책', key: 'stat_error', renderHeaderCell: tooltipRender, tooltip: tooltip['error']},
        { name: '수비기회', key: 'stat_fieldingCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['fieldingCnt']},
        { name: '수비율', key: 'statCacled_defenseRate', renderHeaderCell: tooltipRender, tooltip: tooltip['defenseRate']},
        { name: "RF9", key: 'statCacled_rf9', renderHeaderCell: tooltipRender, tooltip: tooltip['rf9']}
    ];

    const rows = props['data'];

    function rowKeyGetter(row) {
        return row['fielderId'];
    }

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {

                const comparator = fieldingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    const gridElement = (
        <DataGrid
        className="record_team_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '팀 수비 기본기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default TeamFieldingBasicRecord;