import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotIdPage = () => {
    const [emailAddress, setEmailAddress] = useState('');

    // validation 관리용 state
    const [validEmailAddress, setValidEmailAddress] = useState(false);
    const [messageEmailAddress, setMessageEmailAddress] = useState("");

    const navigate = useNavigate();

    const moveToLogin = () => {
        navigate('/login');
    }

    const saveEmailAddress = event => {
        setEmailAddress(event.target.value.trim());
    };

    const checkSpacebar = event => {
        if(event.keyCode === 32){
            event.preventDefault();
        }
    }

    const validationReset = () => {
        setValidEmailAddress(false);
        setMessageEmailAddress("");
    }

    const validationCheck = () => {
        validationReset();
        let checkResult = true;

        if(emailAddress === ""){
            setValidEmailAddress(true);
            setMessageEmailAddress("∙ 이메일: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 이메일: 이메일 형식 체크
            const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

            if(!emailRegex.test(emailAddress)){
                setValidEmailAddress(true);
                setMessageEmailAddress("∙ 이메일: 올바른 이메일인지 확인해주세요.");
                checkResult = false;
            }
        }

        return checkResult;
    }

    const onSendEmail = () => {
        if(validationCheck()){
            checkEmailAddress()
            .then((res) => {
                let result = res.checkEmailAddress;

                if(result === "SUCCESS"){
                    navigate('/login');
                    alert("정상 처리되었습니다.");
                } else {
                    navigate('/error');
                }

            });
        }
    };

    const checkEmailAddress = () =>
        new Promise((resolve) => {
            const params = {
                emailAddress: emailAddress
            };

            axios.post(`${process.env.REACT_APP_API_URL}/member/checkEmailAddress`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
        });

    return (
        <div className="container">
            <div id="joinBox">
                <div id="topBtnWrap">
                    <button className="funcBtn" id="cancleBtn" onClick={moveToLogin}>취소</button>
                </div>
                <form>
                    <div>
                        <div className="label">
                            이메일
                        </div>
                        <input type="text" className={validEmailAddress ? "warning" : ""}
                            name="emailAddress" value={emailAddress} onChange={saveEmailAddress} onKeyDown={checkSpacebar} />
                    </div>
                    <div id="warningMessage">
                        <div>
                            {messageEmailAddress}
                        </div>
                    </div>
                </form>

                <button id="joinBtn" onClick={onSendEmail}>
                    메일 전송
                </button>
            </div>
        </div>
    );
};

export default ForgotIdPage;