import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchGameList, fetchGameWpaResult, fetchGameWpaTpaResult } from '../../../utils/api';
import _ from 'lodash';
import runnerStateCode from '../../../utils/code/runnerStateCode';

const runnerStateArr = runnerStateCode();

function WeeklyBestMoment(props) {
    const {fromDate, toDate, teamId} = props;
    const [playerTpa, setPlayerTpa] = useState([]);
    const [pitcherTpa, setPitcherTpa] = useState([]);
    const [worstWpa, setWorstWpa] = useState([]);
    const [bestWpa, setBestWpa] = useState([]);

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const dateArr = [];
        for(let i=0; i < 7; i++) {
            const tmpDate = moment(fromDate).add(i, 'd').format('YYYY-MM-DD');
            dateArr.push(tmpDate);
        }

        const promiseArr = [];
        _.forEach(dateArr, (item) => {
            const commonParam = {
                gameTypeCodes: 4201,
                seasons,
                gameDay: item
            }

            const gamePromise = fetchGameList({...commonParam});

            promiseArr.push(gamePromise);
        });

        Promise.all(promiseArr)
        .then((response) => {
            const gameListArr = [];
            _.forEach(response, (item, idx) => {
                const gameData = item['data']['RECORD'];
                const teamGameList = _.filter(gameData, (item) => (item['awayTeamId'] === teamId || item['homeTeamId'] === teamId));

                _.forEach(teamGameList, (team) => {
                    gameListArr.push(team['gameInfoId']);
                })
            });

            const tpaParam = {
                gameTypeCodes: 4201,
                gameInfoIds: gameListArr.toString()
            }

            const gameParam = {
                gameTypeCodes: 4201,
                fromDate, toDate,
                pitcherTeamIds: teamId,
                batterTeamIds: teamId,
                groupingColumn: 'player'
            }

            const wpaResultTpaPromise = fetchGameWpaTpaResult(tpaParam);
            const wpaResultPromise = fetchGameWpaResult(gameParam);
            
            wpaResultTpaPromise.then((response) => {
                const playerTpaWpa = _.filter(response['data']['RECORD'], item => item['batterTeamId'] === teamId*1);
                const pitcherTpaWpa = _.filter(response['data']['RECORD'], item => item['pitcherTeamId'] === teamId*1);

                const sortData = (_.sortBy(playerTpaWpa, ['wpa']).reverse()).slice(0, 3);
                const pitcherSortData = (_.sortBy(pitcherTpaWpa, ['wpa'])).slice(0, 3);

                setPitcherTpa(pitcherSortData);
                setPlayerTpa(sortData);
            });

            wpaResultPromise.then((response) => {
                const playerWpa = _.filter(response['data']['RECORD'], item => item['teamId'] === teamId*1);
                const sortData = _.sortBy(playerWpa, ['wpa']).reverse();

                setBestWpa(sortData.slice(0, 3));
                setWorstWpa(sortData.slice(-3).reverse());
            })
        })
    }, [fromDate, teamId])

    const tpaResultCalc = (item) => {
        let tpaResult = "";

        const runnerState = runnerStateArr.find(data => data['runnerState'] === item['runnerState']*1)['runnerStateName'];

        tpaResult += item['pitcher_Team_name']+"전 "
        tpaResult += item['inning']+(item['inningTb'] === 0 ? '회초' : '회말')+" ";
        tpaResult += (item['outCount'] === 0 ? "무" : item['outCount'])+"사 ";
        tpaResult += runnerState+" ";
        tpaResult += (item['score_gap'] === 0 ? "동점" : item['score_gap']+"점차")+"상황 ";

        tpaResult += "vs"+item['pitcher_name']+" ";
        tpaResult += item['batter_name']+"의 ";

        return <>{tpaResult}<span className="color_red">{item['hitResultName']}</span> {"WPA : "+item['wpa']}</>
    }

    const pitcherTpaResultCalc = (item) => {
        let tpaResult = "";

        const runnerState = runnerStateArr.find(data => data['runnerState'] === item['runnerState']*1)['runnerStateName'];

        tpaResult += item['batter_team_name']+"전 "
        tpaResult += item['inning']+(item['inningTb'] === 0 ? '회초' : '회말')+" ";
        tpaResult += (item['outCount'] === 0 ? "무" : item['outCount'])+"사 ";
        tpaResult += runnerState+" ";
        tpaResult += (item['score_gap'] === 0 ? "동점" : item['score_gap']+"점차")+"상황 ";

        tpaResult += "vs"+item['pitcher_name']+" ";
        tpaResult += item['batter_name']+"의 ";

        return <>{tpaResult}<span className="color_blue">{item['hitResultName']}</span> {"WPA : "+item['wpa']*-1}</>
    }

    return (
    <>
    <div className="title">팀 최고의 순간 TOP3</div>
    <div style={{display: 'flex'}}>
        <div className="half_div">
            <div className='title'>타자</div>
            {
                playerTpa.map((item, idx) => (
                    <div className="best_moment" key={item['batter_id']+item['bat_inning_turn']+item['inning']+item['wpa']}>
                    {idx+1}. {tpaResultCalc(item)}
                    </div>
                ))
            }
        </div>
        <div className="half_div">
            <div className='title'>투수</div>
            {
                pitcherTpa.map((item, idx) => (
                    <div className="best_moment" key={item['pitcher_id']+item['bat_inning_turn']+item['inning']+item['wpa']}>
                    {idx+1}. {pitcherTpaResultCalc(item)}
                    </div>
                ))
            }
        </div>
    </div>
    <div style={{display: 'flex'}}>
        <div className="half_div">
            <div className="title">WPA BEST</div>
            <div className="content_table">
                <table className="hiball_table">
                    <thead>
                        <tr><th colSpan={2}>BEST</th></tr>
                    </thead>
                    <tbody>
                    {
                    _.map(bestWpa, (item, idx) => (
                        <tr key={item['playerName']+item['wpa']}>
                            <td>{item['playerName']}</td>
                            <td>{item['wpa']}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
        <div className="half_div">
            <div className="title">WPA WORST</div>
            <div className="content_table">
                <table className="hiball_table">
                    <thead>
                        <tr><th colSpan={2}>WORST</th></tr>
                    </thead>
                    <tbody>
                    {
                    _.map(worstWpa, (item, idx) => (
                        <tr key={item['playerName']+item['wpa']}>
                            <td>{item['playerName']}</td>
                            <td>{item['wpa']}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
    </>
    )
}

export default WeeklyBestMoment;