import _ from 'lodash';
import React, { useEffect, useState } from 'react';

function CommonTableComponent(props) {
    const columnObj = props['columnObj'];
    const name = props['name'];
    const data = props['data'];

    useEffect(() => {
        
    }, [data]);

    return (
        <>
        <table className="hiball_table">
            <thead>
            <tr>
            {
            columnObj.map((item) => (
                <th key={name+'_'+item['columnName']}>
                    {item['columnName']}
                </th>
            ))
            } 
            </tr>
            </thead>
            <tbody>
            {
            (data.length !== 0) ?

            _.map(data, (item, idx) => {
                return (
                    <tr key={name+'_'+idx}>
                    {
                    columnObj.map((innerItem, innerIdx) => (
                        <td key={name+'_'+idx+'_'+innerIdx}>
                        {item[innerItem['property']]}
                        </td>
                    ))
                    }
                    </tr>
                )
            })
            :
            <tr>
                <td colSpan="columnObj['length']"></td>
            </tr>
            }
            </tbody>
        </table>
        </>
    )
}

export default CommonTableComponent;