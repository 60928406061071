import { FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '801', name: '정통파'},
    {id: '804', name: '언더/사이드'}
];

function PitchingFormSelectItem(props) {
    const {value, setValue} = props;
    const [selectId, setSelectId] = useState(value || []);

    const handleOnChange = (event) => {
        const {target:{value}} = event
        setSelectId(value);
        setValue((prev) => {
            return {...prev, pitchingForms: value}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="pitching-form-select-label"
        sx={{fontSize: 12}}
        >투구폼</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="pitching-form-select-label"
        id="pitching-form-select"
        value={selectId}
        onChange={handleOnChange}
        input={<OutlinedInput label="투구폼" />}
        >
        <MenuItem value="">전체</MenuItem>
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"pitchingForm_"+item['id']} value={item['id']}>
                {item['name']}
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default PitchingFormSelectItem;