import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import battingColumnCalc from '../../../../utils/table/battingColumnCalc';
import { useBatterTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function TeamBattingClutchRecord(props) {
    const statType = "clutch";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_stat_tpa",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL, 
        { name: '시즌', key: "season", frozen: true, sortable: false},
        { name: '팀', key: 'batterTeamName', frozen: true, sortable: false, renderCell: useBatterTeamRender},
        // { name: '선수', key: 'batterName', frozen: true},
        { name: '타석', width: 55, key: 'statCalced_stat_tpa', renderHeaderCell: tooltipRender, tooltip: tooltip['tpa']},
        { name: `득점권타석`, width: 83, key: 'statCalced_stat_scoringPosTpa', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringPosTpa']},
        { name: `득점권타석%`, width: 92, key: 'statCalced_scoringPaRate', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringPaRate']},
        { name: `득점권타율`, width: 83, key: 'statCalced_scoringAvg', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringAvg']},
        { name: '타점', width: 55, key: 'statCalced_stat_rbi', renderHeaderCell: tooltipRender, tooltip: tooltip['rbi']},
        { name: '순타점', width: 63, key: 'statCalced_pureRbi', renderHeaderCell: tooltipRender, tooltip: tooltip['pureRbi']},
        { name: `득점권안타%`, width: 92, key: 'statCalced_scoringHitRate', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringHitRate']},
        { name: "클러치안타%", width: 92, key: 'statCalced_clutchHit', renderHeaderCell: tooltipRender, tooltip: tooltip['clutchHit']},
        { name: `득점권홈런%`, width: 92, key: 'statCalced_scoringHrRate', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringHrRate']},
        { name: "클러치홈런%", width: 92, key: 'statCalced_clutchHr', renderHeaderCell: tooltipRender, tooltip: tooltip['clutchHr']},
        { name: `득점권삼진%`, width: 92, key: 'statCalced_scoringSoRate', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringSoRate']},
        { name: "클러치삼진%", width: 92, key: 'statCalced_clutchK', renderHeaderCell: tooltipRender, tooltip: tooltip['clutchK']},
        { name: `득점권볼넷%`, width: 92, key: 'statCalced_scoringBbRate', renderHeaderCell: tooltipRender, tooltip: tooltip['scoringBbRate']},
        { name: "클러치볼넷%", width: 92, key: 'statCalced_clutchBb', renderHeaderCell: tooltipRender, tooltip: tooltip['clutchBb']}
    ];

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = battingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['batterId'];
    }

    const gridElement = (
        <DataGrid
        defaultColumnOptions={{
            sortable: true
        }}
        className="record_team_table_height"
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '팀 타격 클러치기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default TeamBattingClutchRecord;