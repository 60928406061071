import { interpolate, color } from "d3";

function indicatorColorCalc(data) {
    let bgColor = bgColorCalc(data*1);
    let textColor = getContrastYIQ(bgColor);

    return {
        bgColor, textColor
    }
}

function bgColorCalc(data) {
    let red = '#D82129';
    let white = '#ffffff';
    let blue = '#3661AD';

    if(data === 0) {
        return red;
    } else if( data > 0 && 50 > data) {
        let perData = data/50;
        return interpolate(red, white)(perData);
    } else if(data === 50) {
        return white;
    } else if(data > 50 && 100 > data) {
        let perData = (data-50)/50;
        return interpolate(white, blue)(perData);
    } else { // 1
        return blue;
    }
}

function getContrastYIQ(value){
    let hexcolor = color(value).formatHex();
    var r = parseInt(hexcolor.substr(1, 2), 16);
    var g = parseInt(hexcolor.substr(3, 2), 16);
    var b = parseInt(hexcolor.substr(5, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
    return (yiq >= 128) ? 'black' : 'white';
}

export default indicatorColorCalc;