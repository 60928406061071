import React, { useState, useEffect } from "react";
import { privateApi } from "../../interceptor/axios-interceptor";
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import user_icon from '../../assets/image/common/user_icon_default.png';
import Pagination from "../../components/board/Pagination";
import _ from "lodash";

const BoardPostList = () => {
    const {boardData, firstBoardId, currentPage, setCurrentPage, inputKeyword, setInputKeyword, searchKeyword, setSearchKeyword, decodedToken}  = useOutletContext();
    const [postData, setPostData] = useState();
    const [postTotalCnt, setPostTotalCnt] = useState();
    const [boardPostWriteGrade, setBoardPostWriteGrade] = useState();
    const [tableSetting, setTableSetting] = useState([]);

    const navigate = useNavigate();
    const params = useParams();
    const boardId = params['boardId'] ? params['boardId'] : firstBoardId;

    const tableConfig = [
        {colItem: "contentKeyWord", colName: "", colWdith: "100px"},
        {colItem: "contentTitle", colName: "제목", colWdith: "*"},
        {colItem: "currentWriteNickName", colName: "글쓴이", colWdith: "120px"},
        {colItem: "writeDateTime", colName: "작성일", colWdith: "120px"},
        {colItem: "viewCnt", colName: "조회", colWdith: "50px"}
    ];

    const moveToWrite = () => {
        navigate('/board/' + boardId + '/write');
    }

    const searchAction = () => {
        setSearchKeyword(inputKeyword);
    }

    const saveInputKeyword = event => {
        setInputKeyword(event.target.value);
    };

    const handleOnKeyDown = event => {
        if(event.key === "Enter"){
            searchAction();
        }
    }

    const getBoardWriteGradeAndYn = () => {
        const filteredBoardData = _.filter(boardData, ['boardId', boardId]);
        const writeGrade = filteredBoardData[0].writeGrade;
        const recommendYN = filteredBoardData[0].recommendYN;

        setBoardPostWriteGrade(writeGrade);

        if(recommendYN === "Y"){
            setTableSetting(prevList => [...prevList, {colItem: "recommendCnt", colName: "추천", colWdith: "50px"}]);
        }
    }

    useEffect(() => {
        setTableSetting(tableConfig);

        const fetchData = async () => {
            const postData = await getPost();
            setPostData(postData.RECORD);
            setPostTotalCnt(postData.totalCnt);

            getBoardWriteGradeAndYn();
        };
        if(boardData && boardId){
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardData, boardId, currentPage, searchKeyword]);

    const getPost = () =>
        new Promise((resolve) => {
            const params = {
                boardId: boardId,
                pageNo: currentPage
            };

            if(searchKeyword !== ""){
                params['search'] = searchKeyword;
            }

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/selectPost`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    return (
        <>
            <div id="boardBodyWrap">
                <div id="funcBtnWrap">
                    <input type="text" name="searchPost" id="inputSearchPost" value={inputKeyword} onChange={saveInputKeyword}
                        onKeyDown={handleOnKeyDown} placeholder="검색어를 입력해주세요" />
                    <button className="funcBtn" id="searchBtn" onClick={searchAction}>
                        검색
                    </button>
                    {
                    decodedToken &&
                    decodedToken.memberGrade * 1 <= boardPostWriteGrade * 1 &&
                    <button className="funcBtn" id="writeBtn" onClick={moveToWrite}>
                        글쓰기
                    </button>
                    }
                </div>

                <div id="postListWrap">
                    <table>
                        <colgroup>
                            {tableSetting.map((config, index) => (
                                <col key={index} width={config.colWdith} />
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                {tableSetting.map((config, index) => (
                                    <th key={index}>{config.colName}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {postData &&
                            postData.map((row, index) => (
                                <tr key={index}>
                                    {tableSetting.map((config, index) => (
                                        config.colItem === "contentTitle" ? (
                                            <td key={index} className="tdTitle">
                                                <span className="postTitle" onClick={() => navigate('/board/' + boardId + '/' + row.contentId)}>
                                                    {row[config.colItem]}
                                                </span>
                                                {
                                                row["simpleReplyCnt"] && (
                                                    row["simpleReplyCnt"] !== "0" &&
                                                    <span className="simpleReplyCnt">
                                                        [{row["simpleReplyCnt"]}]
                                                    </span>
                                                )
                                                }
                                            </td>
                                        ) : (
                                            config.colItem === "contentKeyWord" ? (
                                                <td key={index}>
                                                    <span className="postKeyword">
                                                        {row[config.colItem]}
                                                    </span>
                                                </td>
                                            ) : (
                                                config.colItem === "currentWriteNickName" ? (
                                                    <td key={index}>
                                                        <span className="postNickName">
                                                            {
                                                            row["currentWriteFavTeamCode"] !== "0"
                                                            ?
                                                            <img className="teamIcon" src={require(`/src/assets/image/common/team_logo/logo_${row["currentWriteFavTeamCode"]}.png`)} alt="teamIcon" id="teamIcon" />
                                                            :
                                                            <img src={user_icon} alt="userIcon" id="userIcon" />
                                                            }
                                                            {row[config.colItem]}
                                                        </span>
                                                    </td>
                                                ) : (
                                                    config.colItem === "recommendCnt" ? (
                                                        <td key={index}>
                                                            <span className="postRecommendCnt">
                                                                {row[config.colItem]}
                                                            </span>
                                                        </td>
                                                    ) : (
                                                        <td key={index}>{row[config.colItem]}</td>
                                                    )
                                                )
                                            )
                                        )
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination
                        postsNum={postTotalCnt}
                        postsPerPage={10}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                </div>
            </div>
        </>
    );
};

export default BoardPostList;