import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchBatterRecord } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';

function BatterDateRecordBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [batterRecord, setBatterRecord] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const playerId = params['playerId'];
        const recordParam = {
            batterIds: playerId,
            gameTypeCodes: 4201,
            groupingColumn: 'game',
            seasons: season
        };

        const batterRecordPromise = fetchBatterRecord(recordParam)

        batterRecordPromise.then((response) => {
            const batterData = loopFlatObject(response['data']['RECORD']);
            setBatterRecord(batterData);
        })
    }, []);

    const avgRenderer = (avg) => {
        if(avg >= 0.3) {
            return <span className='color_red'>{avg}</span>
        } else if(avg < 0.25) {
            return <span className='color_blue'>{avg}</span>
        } else {
            return <span>{avg}</span>
        }
    }

    function gameInfoIdClickHandler(event) {
        const gameInfoId = event.currentTarget.id;
        navigate(`/gameschedule/result/${gameInfoId}`);
    }

    return (
    <div id="player_basic_container">
        <div className="table_title">
            날짜별 기록
        </div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>날짜</th>
                        <th>상대팀</th>
                        <th>타석</th>
                        <th>타수</th>
                        <th>안타</th>
                        <th>2루타</th>
                        <th>3루타</th>
                        <th>홈런</th>
                        <th>득점</th>
                        <th>타점</th>
                        <th>사사구</th>
                        <th>삼진</th>
                        <th>도루</th>
                        <th>타율</th>
                        <th>출루율</th>
                        <th>장타율</th>
                        <th>OPS</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(batterRecord, (item, idx) => (
                    <tr key={item['gameInfoId']}>
                        <td>
                            <span
                            id={item['gameInfoId']}
                            onClick={gameInfoIdClickHandler}
                            className="link"
                            >
                            {item['gameDay']}
                            </span>
                        </td>
                        <td>{item['pitcherTeamName']}</td>
                        <td>{item['basicStat_tpa']}</td>
                        <td>{item['basicStat_ab']}</td>
                        <td>{item['basicStat_hit']}</td>
                        <td>{item['basicStat_b2b']}</td>
                        <td>{item['basicStat_b3b']}</td>
                        <td>{item['basicStat_hr']}</td>
                        <td>{item['basicStat_r']}</td>
                        <td>{item['basicStat_rbi']}</td>
                        <td>{item['basicStat_bb']}</td>
                        <td>{item['basicStat_so']}</td>
                        <td>{item['basicStat_sbSucc']}</td>
                        <td>{avgRenderer(item['avg'])}</td>
                        <td>{item['obp']}</td>
                        <td>{item['slg']}</td>
                        <td>{item['ops']}</td>
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default BatterDateRecordBox;