import { FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: 'L', name: '좌'},
    {id: 'R', name: '우'}
];

function PitchingHandSelectItem(props) {
    const {value, setValue} = props;
    const [selectId, setSelectId] = useState(value || []);

    const handleOnChange = (event) => {
        const {target:{value}} = event
        setSelectId(value);
        setValue((prev) => {
            return {...prev, pitchingHands: value}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="pitching-hand-select-label"
        sx={{fontSize: 12}}
        >투구좌우</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="pitching-hand-select-label"
        id="pitching-hand-select"
        value={selectId}
        onChange={handleOnChange}
        input={<OutlinedInput label="투구좌우" />}
        >
        <MenuItem value="">전체</MenuItem>
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"pitchingHands_"+item['id']} value={item['id']}>
                {item['name']}
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default PitchingHandSelectItem;