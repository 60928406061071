import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchPlayerInfo } from '../../../utils/api';
import _ from 'lodash';
import styled from 'styled-components';
const ContainerDiv = styled.div`
display: flex;

`

function TeamPlayerBox() {
    const params = useParams();
    const [batterPlayer, setBatterPlayer] = useState([]);
    const [pitcherPlayer, setPitcherPlayer] = useState([]);
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const teamId = params['teamId'];
    const navigate = useNavigate();

    useEffect(() => {
        const playerParam = {
            teamId: teamId,
            gameTypeCodes: 4201,
            seasons: season
        };

        const teamPlayerInfo = fetchPlayerInfo(playerParam);

        teamPlayerInfo.then((response) => {

            const groupedPlayer = _.groupBy(response['data']['RECORD'], (item, idx) => {
                if(item['mainPosition'] === '투수') {
                    return 'pitcher';
                } else {
                    return 'batter';
                }
            });

            setBatterPlayer(_.sortBy(groupedPlayer['batter'], ['name']));
            setPitcherPlayer(_.sortBy(groupedPlayer['pitcher'], ['name']));
        })
    }, [])

    const divStyle = {
        width: '50%',
        padding: '0px 5px'
    }

    function batterClickHandler(event) {
        const playerId = event.currentTarget.id;
        navigate(`/player/batter/${playerId}?season=${season}`);
    }

    function pitcherClickHandler(event) {
        const playerId = event.currentTarget.id;
        navigate(`/player/pitcher/${playerId}?season=${season}`);
    }

    return (
    <div id="team_basic_container">
        <ContainerDiv>
        <div style={divStyle}>
                <div className="table_title">타자</div>
                <div className="table_body">
                    <table className="hiball_table">
                        <thead>
                            <tr>
                                <th>이름</th>
                                <th>생일</th>
                                <th>키</th>
                                <th>몸무게</th>
                                <th>등번호</th>
                                <th>지명</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        _.map(batterPlayer, (item, idx) => (
                            <tr key={item['membersId']}>
                                <td id={item['membersId']}
                                className='link'
                                onClick={batterClickHandler}
                                >{item['name']}</td>
                                <td>{item['birthDate']}</td>
                                <td>{item['height']}</td>
                                <td>{item['weight']}</td>
                                <td>{item['lastBackNumber']}</td>
                                <td>{item['firstJoinOrder']}</td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={divStyle}>
                <div className="table_title">투수</div>
                <div className="table_body">
                    <table className="hiball_table">
                        <thead>
                            <tr>
                                <th>이름</th>
                                <th>생일</th>
                                <th>키</th>
                                <th>몸무게</th>
                                <th>등번호</th>
                                <th>지명</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        _.map(pitcherPlayer, (item, idx) => (
                            <tr key={item['membersId']}>
                                <td id={item['membersId']}
                                className='link'
                                onClick={pitcherClickHandler}
                                >{item['name']}</td>
                                <td>{item['birthDate']}</td>
                                <td>{item['height']}</td>
                                <td>{item['weight']}</td>
                                <td>{item['lastBackNumber']}</td>
                                <td>{item['firstJoinOrder']}</td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </ContainerDiv>
    </div>
    )
}

export default TeamPlayerBox;