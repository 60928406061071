import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import battingColumnCalc from '../../../../utils/table/battingColumnCalc';
import { useBatterRender, useBatterTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function PlayerBattingDetailRecord(props) {
    const statType = "detail";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_stat_tpa",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL,
        {key: 'season', name: '시즌', sortable: false},
        {key: 'batterTeamName', name: '팀', sortable: false, renderCell: useBatterTeamRender},
        {key: 'batterName', name: '선수', sortable: false, renderCell: useBatterRender},
        {key: 'statCalced_stat_gameCnt', name: '출장', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        {key: 'statCalced_stat_tpa', name: '타석', renderHeaderCell: tooltipRender, tooltip: tooltip['tpa']},
        {key: 'statCalced_obp', name: '출루율', renderHeaderCell: tooltipRender, tooltip: tooltip['obp']},
        {key: 'statCalced_slg', name: '장타율', renderHeaderCell: tooltipRender, tooltip: tooltip['slg']},
        {key: 'statCalced_ops', name: 'OPS', renderHeaderCell: tooltipRender, tooltip: tooltip['ops']},
        {key: 'statCalced_isoP', name: 'IsoP', renderHeaderCell: tooltipRender, tooltip: tooltip['isoP']},
        {key: 'statCalced_bbRate', name: 'BB%', renderHeaderCell: tooltipRender, tooltip: tooltip['bbRate']},
        {key: 'statCalced_kRate', name: 'K%', renderHeaderCell: tooltipRender, tooltip: tooltip['kRate']},
        {key: 'statCalced_bbKRate', name: 'BB/K', renderHeaderCell: tooltipRender, tooltip: tooltip['bbKRate']},
        {key: 'statCalced_hrRate', name: 'HR%', renderHeaderCell: tooltipRender, tooltip: tooltip['hrRate']},
        {key: 'statCalced_babip', name: 'BABIP', renderHeaderCell: tooltipRender, tooltip: tooltip['babip']},
        {key: 'statCalced_spd', name: 'spd', renderHeaderCell: tooltipRender, tooltip: tooltip['spd']},
        {key: 'statCalced_stat_stealCnt', name: '도루', renderHeaderCell: tooltipRender, tooltip: tooltip['stealCnt']},
        {key: 'statCalced_stat_failStealCnt', name: '도실', renderHeaderCell: tooltipRender, tooltip: tooltip['failStealCnt']}
    ];

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = battingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['batterId'];
    }

    const gridElement = (
        <DataGrid
        defaultColumnOptions={{
            sortable: true
        }}
        className="record_table_height"
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 타격 상세기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerBattingDetailRecord;