import React, { useState, useEffect } from "react";
import axios from 'axios';
import { privateApi } from "../../interceptor/axios-interceptor";
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import tumbs_up from '../../assets/image/sub/board/thumbs_up.png';
import user_icon from '../../assets/image/common/user_icon_default.png';
import _ from "lodash";

const BoardPostView = () => {
    const {boardData, decodedToken}  = useOutletContext();
    const [rerender, setRerender] = useState(1);
    const [postData, setPostData] = useState();
    const [replyData, setReplyData] = useState();
    const [boardReplyWriteGrade, setBoardReplyWriteGrade] = useState();
    const [boardReplyYn, setBoardReplyYn] = useState();
    const [boardRecommendYn, setBoardRecommendYn] = useState();

    //writeReply Params
    const [simpleReplyContent, setSimpleReplyContent] = useState('');
    const [ipAddress, setIpAddress] = useState('');

    const navigate = useNavigate();
    const params = useParams();
    const boardId = params['boardId'];
    const contentId = params['contentId'];

    const moveToList = () => {
        navigate('/board/' + boardId);
    };

    const initContentToken = () => {
        let prevToken = JSON.parse(localStorage.getItem("contentToken"));
        if(!prevToken){
            setContentToken();
        }
    }

    const setContentToken = () => {
        let currentTimestamp = Date.now();
        localStorage.setItem("contentToken", JSON.stringify(currentTimestamp + "," + contentId));
    }

    const getBoardWriteGradeAndYn = () => {
        const filteredBoardData = _.filter(boardData, ['boardId', boardId]);
        const simpleReplyWriteGrade = filteredBoardData[0].simpleReplyWriteGrade;
        const simpleReplyYN = filteredBoardData[0].simpleReplyYN;
        const recommendYN = filteredBoardData[0].recommendYN;

        setBoardReplyWriteGrade(simpleReplyWriteGrade);
        setBoardReplyYn(simpleReplyYN);
        setBoardRecommendYn(recommendYN);
    }

    useEffect(() => {
        initContentToken();

        const fetchData = async () => {
            const post = await getPost();
            if(!post.errCode){
                setPostData(post.RECORD[0]);

                // 조회수 증가시 토큰 재생성
                if(post.postCount === "Success"){
                    setContentToken();
                }
            } else {
                setPostData("error");
            }

            const reply = await getReply();
            if(!reply.errCode){
                setReplyData(reply.RECORD);
            } else {
                setReplyData("error");
            }

            getBoardWriteGradeAndYn();
        };
        if(boardData){
            fetchData();
        }

        axios.get('https://geolocation-db.com/json/')
        .then((res) => {
            setIpAddress(res.data.IPv4);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardData, rerender]);

    const getPost = () =>
        new Promise((resolve) => {
            const params = {
                contentId: contentId
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/selectPost`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    const getReply = () =>
        new Promise((resolve) => {
            const params = {
                contentId: contentId
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/selectReply`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    const recommendPost = () => {
        updateRecommendPostCount().then((res) => {
            if(!res.errCode){
                if(res.RECORD === 1){
                    setRerender(rerender + 1);
                } else {
                    alert("게시물 당 1번만 가능합니다.");
                }
            } else {
                alert("로그인이 필요합니다.");
            }
        });
    }

    const modifyModeOn = () => {
        navigate('/board/' + boardId + '/' + contentId + '/modify');
    };

    const delPost = () => {
        if(window.confirm("정말 삭제하시겠습니까?")){
            deletePost().then((res) => {
                if(!res.errCode){
                    navigate('/board/' + boardId);
                } else {
                    alert("삭제 권한이 없습니다.");
                }
            });
        }
    };

    const delReply = (event) => {
        let id = event.target.id;
        let simpleReplyId = id.split("_")[1];
        // console.log(simpleReplyId);
        if(window.confirm("정말 삭제하시겠습니까?")){
            deleteReply(simpleReplyId).then((res) => {
                if(!res.errCode){
                    setRerender(rerender + 1);
                } else {
                    alert("삭제 권한이 없습니다.");
                }
            });
        }
    };

    const deletePost = () =>
        new Promise((resolve) => {
            const params = {
                contentId: contentId
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/deletePost`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    const updateRecommendPostCount = () =>
        new Promise((resolve) => {
            const params = {
                contentId: contentId
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/updateRecommendPostCount`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    const deleteReply = (simpleReplyId) =>
        new Promise((resolve) => {
            const params = {
                contentId: contentId,
                simpleReplyId: simpleReplyId
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/deleteReply`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    const writeReply = () => {
        insertReply().then((res) => {
            if(!res.errCode){
                setRerender(rerender + 1);
                setSimpleReplyContent('');
            } else {
                alert("작성 권한이 없습니다.");
            }
        });
    };

    const saveSimpleReplyContent = event => {
        setSimpleReplyContent(event.target.value);
    };

    const insertReply = () =>
    new Promise((resolve) => {
        const params = {
            pSimplyReplyId: "0",
            contentId: contentId,
            simpleReplyContent: simpleReplyContent,
            writeMemberNo: decodedToken.memberNo,
            ipAddress: ipAddress
        };

        privateApi.post(`${process.env.REACT_APP_API_URL}/board/insertReply`, params).then((Response)=>{
            // console.log(Response.data);

            resolve(Response.data);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    return (
        <>
            <div id="postViewWrap">
                <div id="postWrap">
                    <div id="postFuncWrap">
                        <button className="funcBtn" id="listBtn" onClick={moveToList}>
                        목록
                        </button>
                        {
                        postData &&
                        decodedToken &&
                        decodedToken.memberNo === postData.writeMemberNo &&
                        <>
                            <button className="funcBtn" id="modifyOnBtn" onClick={modifyModeOn}>
                            수정
                            </button>
                            <button className="funcBtn" id="delPostBtn" onClick={delPost}>
                            삭제
                            </button>
                        </>
                        }
                    </div>
                    {
                        postData &&
                        (
                            postData !== "error"
                            ?
                            <>
                                <div id="titleWrap">
                                    <div id="divTitle">
                                        <span id="spanKeyword">[{postData.contentKeyWord}]</span>
                                        <span id="spanTitle">{postData.contentTitle}</span>

                                    </div>
                                    <div id="divPostInfo">
                                        <div>
                                            {postData.currentWriteNickName}
                                        </div>
                                        <div>
                                            {postData.writeDateTime}
                                        </div>
                                        <div>
                                            조회 {postData.viewCnt ? postData.viewCnt : 0}
                                            {
                                            boardRecommendYn === "Y" &&
                                            <>
                                                &nbsp;|
                                                추천 {postData.recommendCnt ? postData.recommendCnt : 0}
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id="contentWrap">
                                    <div dangerouslySetInnerHTML={{__html: postData.content}}></div>
                                </div>
                                {
                                boardRecommendYn === "Y" &&
                                <>
                                    <div id="recommendCntWrap">
                                        추천 {postData.recommendCnt ? postData.recommendCnt : 0}
                                    </div>
                                    <div id="recommendImgWrap">
                                        <img src={tumbs_up} alt="tumbs_up" id="recommendBtn" onClick={recommendPost}/>
                                    </div>
                                </>
                                }
                            </>
                            :
                            <>
                                <div id="contentWrap">
                                    <div>
                                        조회 권한이 없습니다.
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>

                {
                boardReplyYn === "Y" &&
                <div id="replyWrap">
                    {
                        replyData &&
                        (
                            replyData !== "error"
                            ?
                            (
                                replyData.length > 0 &&
                                <div id="replyContentWrap">
                                {
                                    replyData.map((reply, index) => (
                                        <div className="divReplyRow" key={index}>
                                            <div className="left">
                                                {
                                                reply.replyWriteFavTeamCode !== null && reply.replyWriteFavTeamCode !== "0"
                                                ?
                                                <img className="teamIcon" src={require(`/src/assets/image/common/team_logo/logo_${reply.replyWriteFavTeamCode}.png`)} alt="teamIcon" id="teamIcon" />
                                                :
                                                <img src={user_icon} alt="userIcon" id="userIcon" />
                                                }
                                            </div>
                                            <div className="right">
                                                <div className="replyWriter">
                                                    {reply.replyWriteNickName}
                                                </div>
                                                <div className="replyContent">
                                                    {reply.simpleReplyContent}
                                                </div>
                                                <div className="replyInfo">
                                                    {reply.replyWriteDateTime}
                                                </div>
                                                {
                                                decodedToken &&
                                                decodedToken.memberNo === reply.replyWriteMemberNo &&
                                                <button className="funcBtn" id={"delReplyBtn_"+reply.simpleReplyId} onClick={delReply}>
                                                    삭제
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                                </div>
                            )
                            :
                            <>
                                <div id="replyContentWrap">
                                    조회 권한이 없습니다.
                                </div>
                            </>
                        )
                    }
                    {
                        decodedToken &&
                        decodedToken.memberGrade * 1 <= boardReplyWriteGrade * 1 &&
                        <div id="replyWriteWrap">
                            <textarea name="simpleReplyContent" placeholder="내용" value={simpleReplyContent} onChange={saveSimpleReplyContent}></textarea>

                            <button className="funcBtn" id="writeReplyBtn" onClick={writeReply}>
                            등록
                            </button>
                        </div>
                    }
                </div>
                }

                <div id="funcBtnWrap">
                    <button className="funcBtn" id="listBtn" onClick={moveToList}>
                    목록
                    </button>
                </div>
            </div>
        </>
    );
};

export default BoardPostView;