import React from 'react';
import { Provider } from 'jotai';
import PitchLogsContainer from '../../layout/logs/pitch-logs-container';

function LogsMain() {
    const LogsTabContent = function() {
        return <PitchLogsContainer />
    }

    return (
    <Provider>
        <div className="container" style={{marginTop: '10px'}}>
            <LogsTabContent/>
        </div>
    </Provider>
    )
}

export default LogsMain;