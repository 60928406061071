import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGameBatterInningResultInfo, fetchGamePitcherResultInfo, fetchGameRunnerInfo } from '../../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../../utils/flat';

function GameResultStringBox() {
    const params = useParams();
    const [recordObj, setRecordObj] = useState({});
    const resultCodeArr = [
        {name: '2루타', code: '6607'},
        {name: '3루타', code: '6610'},
        {name: '홈런', code: '6613'},
        {name: '사구', code: '6609'}
    ]

    useEffect(() => {
        const tmpObj = {};
        const gameInfoId = params['gameInfoId'];

        const gameParam = {
            gameInfoIds: gameInfoId,
            gameTypeCodes: 4201
        };

        const pitcherResultInfoPromise = fetchGamePitcherResultInfo(gameParam);
        const runnerResultInfoPromise = fetchGameRunnerInfo(gameParam);
        const batterResultByInningPromise = fetchGameBatterInningResultInfo(gameParam);

        Promise.all([
            batterResultByInningPromise,
            pitcherResultInfoPromise,
            runnerResultInfoPromise
        ]).then((response) => {
            
            const batterInfo = _.groupBy(loopFlatObject(response[0]['data']['RECORD']), 'hitResultCode')
            const homrunInfo = batterInfo['6613'];
            const pitcherInfo = loopFlatObject(response[1]['data']['RECORD']);
            const runnerInfo = response[2]['data']['RECORD'];

            let pitcherArr = [];

            _.each(pitcherInfo, (item, idx) => {
                if(item['pitcherResult'].length !== 0) {
                    pitcherArr.push(item);
                }
            });

            let batterArr = []
            _.each(resultCodeArr, (item, idx) => {
                if(batterInfo[item['code']]) {
                    batterArr.push(batterInfo[item['code']]);
                }
            })

            let batterObj = _.groupBy(_.flatten(batterArr), 'hitResultCode');
            let pitcherObj = _.groupBy(pitcherArr, 'pitcherResult');
            tmpObj['batterObj'] = batterObj;
            tmpObj['pitcherObj'] = pitcherObj;

            setRecordObj(tmpObj);
        })
    }, [])

    if(_.isEmpty(recordObj)) {
        return <></>;
    }

    return (
        <>
        <div id="player_result_container">
            <table id="player_result_table">
                <tbody>
                {
                resultCodeArr.map((item, idx) => (
                    recordObj['batterObj'][item['code']] ?
                    <React.Fragment key={item['code']}>
                    <tr>
                    <th>{item['name']}</th>
                    <td>
                    {
                    recordObj['batterObj'][item['code']].map((innerItem) => (
                        <span className="hr_batter" key={innerItem['inning']+"_"+innerItem['inningTb']+"_"+innerItem['batInningTurn']}>
                        [{innerItem['inning']}회{innerItem['inningTb']*1 === 0 ? '초' : '말'}]&nbsp;
                        {innerItem['batter_batterName']+" "}
                        </span>
                    ))
                    }
                    </td>
                    </tr>
                    </React.Fragment> :
                    <React.Fragment key={item['code']}></React.Fragment>
                ))
                }
                <tr>
                    <th>투수</th>
                    <td>
                        {
                        recordObj['pitcherObj'] ?
                        _.map(recordObj['pitcherObj']['WIN'], (item, idx) => (
                            <span key={item['pitcherId']}>승: {item['pitcherName']}</span>
                        ))
                        :
                        ""
                        }
                        &nbsp;&nbsp;
                        {
                        recordObj['pitcherObj'] ?
                        _.map(recordObj['pitcherObj']['LOSE'], (item, idx) => (
                            <span key={item['pitcherId']}>패: {item['pitcherName']}</span>
                        ))
                        :
                        ""
                        }
                        &nbsp;&nbsp;
                        {
                        recordObj['pitcherObj'] ?
                        _.map(recordObj['pitcherObj']['SAVE'], (item, idx) => (
                            <span key={item['pitcherId']}>세이브: {item['pitcherName']}</span>
                        ))
                        :
                        ""
                        }
                        <br/>
                        {
                        (recordObj['pitcherObj'] && recordObj['pitcherObj']['HOLD']) ?
                        <>
                        <span>홀드 : </span>
                        {
                        _.map(recordObj['pitcherObj']['HOLD'], (item, idx) => (
                            <span key={item['pitcherId']}>{item['pitcherName']}&nbsp;</span>
                        ))
                        }
                        </>
                        :
                        ""
                        }
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}

export default GameResultStringBox;