import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import _ from 'lodash';

function SeasonSelectItem(props) {
    const {defaultSeason, season, setSeason} = props;

    const tmpSeasonArr = _.range((defaultSeason*1), 2016-1);

    const handleChange = (event) => {
        setSeason(event.target.value);
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <Select
        sx={{height: 30}}
        value={season}
        onChange={handleChange}
        >
        {
        _.map(tmpSeasonArr, (item, idx) => (
            <MenuItem value={item} key={item}>
                {item}
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default SeasonSelectItem;