import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchBatterRecord, fetchPitcherRecord, fetchTeamRanking } from '../../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../../utils/flat';

function TeamBasicRecordBox() {
    const params = useParams();
    const [teamBattingRecord, setTeamBattingRecord] = useState({});
    const [teamPitchingRecord, setTeamPitchingRecord] = useState({});
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');

    useEffect(() => {
        const teamId = params['teamId'];
        const recordParam = {
            gameTypeCodes: 4201
        }

        const seasonParam = {
            ...recordParam, seasons: season, teamId: teamId
        }

        const teamRankingPromise = fetchTeamRanking(seasonParam);
        const teamBattingPromise = fetchBatterRecord({...recordParam, batterTeamIds: teamId, groupingColumn: 'seasonTeam'});
        const teamPitchingPromise = fetchPitcherRecord({...recordParam, pitcherTeamIds: teamId, groupingColumn: 'seasonTeam'});

        Promise.all([
            teamBattingPromise,
            teamPitchingPromise
        ]).then((response) => {
            const teamBattingSet = loopFlatObject(response[0]['data']['RECORD']);
            const teamPitchingSet = loopFlatObject(response[1]['data']['RECORD']);
            
            setTeamBattingRecord(teamBattingSet);
            setTeamPitchingRecord(teamPitchingSet);
        })
    }, [])

    return (
    <div id="team_basic_container">
        <div className="table_title">
            타격지표
        </div>
        <div className="table_body">
            <table className="hiball_table">
            <thead>
                <tr>
                    <th>팀</th>
                    <th>타수</th>
                    <th>안타</th>
                    <th>2타</th>
                    <th>3타</th>
                    <th>홈런</th>
                    <th>타점</th>
                    <th>득점</th>
                    <th>도루</th>
                    <th>사사구</th>
                    <th>삼진</th>
                    <th>병살</th>
                    <th>타율</th>
                    <th>출루율</th>
                    <th>장타율</th>
                    <th>OPS</th>
                </tr>
            </thead>
            <tbody>
            {
                _.map(teamBattingRecord, (item, idx) => (
                <tr key={item['season']}>
                    <td>{item['season']}</td>
                    <td>{item['basicStat_ab']}</td>
                    <td>{item['basicStat_hit']}</td>
                    <td>{item['basicStat_b2b']}</td>
                    <td>{item['basicStat_b3b']}</td>
                    <td>{item['basicStat_hr']}</td>
                    <td>{item['basicStat_rbi']}</td>
                    <td>{item['basicStat_r']}</td>
                    <td>{item['basicStat_sbSucc']}</td>
                    <td>{item['basicStat_bb']}</td>
                    <td>{item['basicStat_so']}</td>
                    <td>{item['basicStat_gdp']}</td>
                    <td>{item['avg']}</td>
                    <td>{item['obp']}</td>
                    <td>{item['slg']}</td>
                    <td>{item['ops']}</td>
                </tr>
                ))
            }   
            </tbody>
        </table>
        </div>
        <div className="table_title">
            투구지표
        </div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>팀</th>
                        <th>이닝</th>
                        <th>피안타</th>
                        <th>피홈런</th>
                        <th>실점</th>
                        <th>자책</th>
                        <th>사사구</th>
                        <th>탈삼진</th>
                        <th>평균자책</th>
                        <th>WHIP</th>
                        <th>QS</th>
                        <th>홀드</th>
                        <th>세이브</th>
                    </tr>
                </thead>
                <tbody>
                {
                    _.map(teamPitchingRecord, (item, idx) => (
                    <tr key={item['season']}>
                        <td>{item['season']}</td>
                        <td>{item['ip']}</td>
                        <td>{item['basicStat_hit']}</td>
                        <td>{item['basicStat_hr']}</td>
                        <td>{item['basicStat_r']}</td>
                        <td>{item['basicStat_er']}</td>
                        <td>{item['basicStat_bb']}</td>
                        <td>{item['basicStat_so']}</td>
                        <td>{item['era']}</td>
                        <td>{item['whip']}</td>
                        <td>{item['qs']}</td>
                        <td>{item['hold']}</td>
                        <td>{item['save']}</td>
                    </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default TeamBasicRecordBox;