import _ from 'lodash';
import React from 'react';
import { zoneCalc } from '../../utils/zoneCalc';
import colorCalcObj from '../../utils/color/colorCalc';
import statCalc from '../../utils/stat/statCalc';

function LocationChart({data, playerType}) {
    const index = ['1_1', '2_1', '3_1', '1_2', '2_2', '3_2', '1_3', '2_3', '3_3', 11, 12, 13, 14];

    if(data.length === 0) {
        return (
            <div style={{textAlign:'center', width:'260px', height:'170px', 
            display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                No Data
            </div>
        )
    }

    const width = 300;
    const perWidth = width/5;
    const halfWidth = width/2;

    const calcData = _.countBy(data, (item) => {
        const calcString = zoneCalc(width, width, item, playerType);
        return calcString
    });

    const groupData = _.groupBy(data, (item) => {
        let calcString = zoneCalc(width, width, item, playerType);
        return calcString;
    });

    let zoneData = {};
    const maxValue = _.max(Object.values(calcData));
    const dataLength = data ? data.length : 0;
    const dataType = 'pitchCnt';

    _.forEach(index, (item) => {
        const pitchCnt = calcData[item] ? calcData[item] : 0;
        const relativeZoneRate = maxValue ? (pitchCnt/maxValue) : 0;
        const zoneRate = dataLength ? parseInt((pitchCnt/dataLength)*100) : 0;
        const stat = statCalc(groupData[item]);

        const zoneText = (dataType === 'pitchCnt' ? pitchCnt : zoneRate+"%" );
        const zoneColor = colorCalcObj(relativeZoneRate, dataType);

        zoneData[item] = {
            zoneText, ...zoneColor
        }
    });

    const fontSize = '14px';
    const zoneFontSize = '12px';

    const outerZonePoint1 = perWidth*0.5;
    const outerZonePoint2 = perWidth*4+(perWidth*0.5);
    const outerZonePoint3 = perWidth*4+(perWidth*0.5);

    const addPoint = 3;

    const innerZonePoint1 = (perWidth+(perWidth*0.5));
    const innerZonePoint2 = innerZonePoint1+addPoint;
    const innerZonePoint3 = (perWidth*2+(perWidth*0.5));
    const innerZonePoint4 = innerZonePoint3+addPoint;
    const innerZonePoint5 = (perWidth*3+(perWidth*0.5));
    const innerZonePoint6 = innerZonePoint5+addPoint;

    const batterPointY = 0;
    const leftBatterPointX = 10;
    const rightBatterPointX = 190;
    const batterWidth = 60;
    const batterHeight = 150;

    // const statDataType = statYn ? dataType : 'pitchCnt';
    const statDataType = 'zoneText';
    const statColorType = 'bgColor';
    const statTextType = 'textColor';

    return (
    <>
    <div style={{textAlign:'center'}}>
        <svg width="350" height="330">
            <g transform='translate(25,15)'>
                <path id="z_11" d={`M0,0L${halfWidth},0L${halfWidth},${perWidth}L${perWidth},${perWidth}L${perWidth},${halfWidth}L0,${halfWidth}L0,0`}
                fill={zoneData[11][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={outerZonePoint1} y={outerZonePoint1}
                style={{fontSize: fontSize, fill:zoneData[11][statTextType]}}>{zoneData[11][statDataType]}</text>

                <path id="z_12" d={`M${halfWidth},0L${width},0L${width},${halfWidth}L${perWidth*4},${halfWidth}L${perWidth*4},${perWidth}L${halfWidth},${perWidth}L${halfWidth},0`} 
                fill={zoneData[12][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={outerZonePoint2} y={outerZonePoint1} 
                style={{fontSize: fontSize, fill: zoneData[12][statTextType]}}>{zoneData[12][statDataType]}</text>

                <path id="z_13" d={`M0,${halfWidth}L0,${width}L${halfWidth},${width}L${halfWidth},${perWidth*4}L${perWidth},${perWidth*4}L${perWidth},${halfWidth}L0,${halfWidth}`} 
                fill={zoneData[13][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={outerZonePoint1} y={outerZonePoint3} 
                style={{fontSize: fontSize, fill: zoneData[13][statTextType]}}>{zoneData[13][statDataType]}</text>

                <path id="z_14" d={`M${halfWidth},${perWidth*4}L${perWidth*4},${perWidth*4}L${perWidth*4},${halfWidth}L${width},${halfWidth}L${width},${width}L${halfWidth},${width}L${halfWidth},${perWidth*4}`} 
                fill={zoneData[14][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={outerZonePoint2} y={outerZonePoint3} 
                style={{fontSize: fontSize, fill: zoneData[14][statTextType]}}>{zoneData[14][statDataType]}</text>


                <path id="z_1" d={`M${perWidth},${perWidth}L${perWidth*2},${perWidth}L${perWidth*2},${perWidth*2}L${perWidth},${perWidth*2}`} 
                fill={zoneData['1_1'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint1} y={innerZonePoint2} 
                style={{fontSize: zoneFontSize, fill: zoneData['1_1'][statTextType]}}>{zoneData['1_1'][statDataType]}</text>

                <path id="z_2" d={`M${perWidth*2},${perWidth}L${perWidth*3},${perWidth}L${perWidth*3},${perWidth*2}L${perWidth*2},${perWidth*2}`} 
                fill={zoneData['2_1'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint3} y={innerZonePoint2} 
                style={{fontSize: zoneFontSize, fill: zoneData['2_1'][statTextType]}}>{zoneData['2_1'][statDataType]}</text>

                <path id="z_3" d={`M${perWidth*3},${perWidth}L${perWidth*4},${perWidth}L${perWidth*4},${perWidth*2}L${perWidth*3},${perWidth*2}`} 
                fill={zoneData['3_1'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint5} y={innerZonePoint2} 
                style={{fontSize: zoneFontSize, fill: zoneData['3_1'][statTextType]}}>{zoneData['3_1'][statDataType]}</text>

                <path id="z_4" d={`M${perWidth},${perWidth*2}L${perWidth*2},${perWidth*2}L${perWidth*2},${perWidth*3}L${perWidth},${perWidth*3}`} 
                fill={zoneData['1_2'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint1} y={innerZonePoint4} 
                style={{fontSize: zoneFontSize, fill: zoneData['1_2'][statTextType]}}>{zoneData['1_2'][statDataType]}</text>

                <path id="z_5" d={`M${perWidth*2},${perWidth*2}L${perWidth*3},${perWidth*2}L${perWidth*3},${perWidth*3}L${perWidth*2},${perWidth*3}`} 
                fill={zoneData['2_2'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint3} y={innerZonePoint4} 
                style={{fontSize: zoneFontSize, fill: zoneData['2_2'][statTextType]}}>{zoneData['2_2'][statDataType]}</text>

                <path id="z_6" d={`M${perWidth*3},${perWidth*2}L${perWidth*4},${perWidth*2}L${perWidth*4},${perWidth*3}L${perWidth*3},${perWidth*3}`} 
                fill={zoneData['3_2'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint5} y={innerZonePoint4} 
                style={{fontSize: zoneFontSize, fill: zoneData['3_2'][statTextType]}}>{zoneData['3_2'][statDataType]}</text>

                <path id="z_7" d={`M${perWidth},${perWidth*3}L${perWidth*2},${perWidth*3}L${perWidth*2},${perWidth*4}L${perWidth},${perWidth*4}`} 
                fill={zoneData['1_3'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint1} y={innerZonePoint6} 
                style={{fontSize: zoneFontSize, fill: zoneData['1_3'][statTextType]}}>{zoneData['1_3'][statDataType]}</text>

                <path id="z_8" d={`M${perWidth*2},${perWidth*3}L${perWidth*3},${perWidth*3}L${perWidth*3},${perWidth*4}L${perWidth*2},${perWidth*4}`} 
                fill={zoneData['2_3'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint3} y={innerZonePoint6} 
                style={{fontSize: zoneFontSize, fill: zoneData['2_3'][statTextType]}}>{zoneData['2_3'][statDataType]}</text>

                <path id="z_9" d={`M${perWidth*3},${perWidth*3}L${perWidth*4},${perWidth*3}L${perWidth*4},${perWidth*4}L${perWidth*3},${perWidth*4}`} 
                fill={zoneData['3_3'][statColorType]} stroke="#fff" strokeWidth={1}></path>
                <text className="numbers" textAnchor="middle" x={innerZonePoint5} y={innerZonePoint6} 
                style={{fontSize: zoneFontSize, fill: zoneData['3_3'][statTextType]}}>{zoneData['3_3'][statDataType]}</text>
            </g>
        </svg>
    </div>
    </>
    )
}

export default LocationChart;