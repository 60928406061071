import { interpolate, scaleLinear, color } from "d3";

function colorCalcObj(data, dataType) {
    let bgColor, textColor;
    if(dataType === 'ops' || dataType === 'avg' || dataType === 'slg') { //stat
        bgColor = statColorCalc(data*1, dataType);
        textColor = getContrastYIQ(bgColor);
    } else { //
        bgColor = bgColorCalc(data*1);
        textColor = getContrastYIQ(bgColor);
    }


    // let bgColor = bgColorCalc(pitchCnt*1);
    // let statColor = statColorCalc(data*1, dataType);
    // let textColor = getContrastYIQ(bgColor);
    // let statTextColor = getContrastYIQ(statColor);

    return {
        bgColor, textColor
    }
}

function statColorCalc(data, dataType) {
    let red = '#D82129';
    let white = '#ffffff';
    let blue = '#3661AD';

    let statScale = scaleLinear()
    .domain([0, 1])
    .range([0, 1])
    .clamp(true);

    let statArr = [0.25, 0.27, 0.3];

    if(dataType === 'ops') {
        statArr = [0.4, 0.7, 1];
    } else if(dataType === 'avg') {
        statArr = [0.25, 0.27, 0.3];
    } else if(dataType === 'slg') {
        statArr = [0.3, 0.4, 0.55];
    }

    let colorScale = scaleLinear()
    .domain(statArr)
    .range([blue, white, red])
    .clamp(true);

    let modifyData = statScale(data);
    return colorScale(modifyData);
    
    // return interpolate(blue, red)(modifyData)
}

function bgColorCalc(data) {
    let red = '#D82129';
    let white = '#ffffff';
    let blue = '#3661AD';

    // if(data === 0) {
    //     return blue;
    // } else if(data > 0 && 1 > data) {
    //     return interpolate(blue, red)(data);
    // } else { // 1
    //     return red;
    // }

    let colorScale = scaleLinear()
    .domain([0, 0.5, 1])
    .range([blue, white, red])
    .clamp(true);

    return colorScale(data)
}

function textColorCalc(data) {
    let white = 'rgb(255, 255, 255)';
    let black = 'rgb(0,0,0)';

    // if(data >= 0 && 0.25 >= data) {
    //     return white;
    // } else if(data > 0.25 && 0.75 >= data) {
    //     return black;
    // } else if(data > 0.75 && 1 > data) {
    //     return white;
    // } else { // 1
    //     return white;
    // }

    return white;
}

function getContrastYIQ(value){
    let hexcolor = color(value).formatHex();
    var r = parseInt(hexcolor.substr(1, 2), 16);
    var g = parseInt(hexcolor.substr(3, 2), 16);
    var b = parseInt(hexcolor.substr(5, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
    return (yiq >= 128) ? 'black' : 'white';
}

export default colorCalcObj;