import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import pitchingColumnCalc from '../../../../utils/table/pitchingColumnCalc';
import { usePitcherTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import pitcherTooltip from '../../../../utils/tooltip/pitcherTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = pitcherTooltip();

function TeamPitchingReliefRecord(props) {
    const statType = "relief";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_era",
        direction: "ASC"
    }]);

    const columns = [
        RANKING_CELL, 
        { name: "시즌", key: "season", frozen: true, sortable: false},
        { name: "팀", key: 'pitcherTeamName', frozen: true, sortable: false, renderCell: usePitcherTeamRender},
        // { name: "선수", key: 'pitcherName', frozen: true},
        { name: "출장", width: 55, key: 'stat_gameCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: '이닝', width: 55, key: 'statCalced_ip', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: "bWAR", width: 60, key: 'statCalced_bWar', renderHeaderCell: tooltipRender, tooltip: tooltip['bWar']},
        { name: "fWAR", width: 60, key: 'statCalced_pitcherWAR', renderHeaderCell: tooltipRender, tooltip: tooltip['pitcherWAR']},
        { name: "WPA", width: 60, key: 'stat_wpa', renderHeaderCell: tooltipRender, tooltip: tooltip['wpa']},
        { name: "ERA", width: 60, key: 'statCalced_era', renderHeaderCell: tooltipRender, tooltip: tooltip['era']},
        { name: "ifFIP", width: 63, key: 'statCalced_ifFip', renderHeaderCell: tooltipRender, tooltip: tooltip['ifFip']},
        { name: "FIP", width: 60, key: 'statCalced_fip', renderHeaderCell: tooltipRender, tooltip: tooltip['fip']},
        { name: '승', key: 'stat_win', renderHeaderCell: tooltipRender, tooltip: tooltip['win']},
        { name: '패', key: 'stat_lose', renderHeaderCell: tooltipRender, tooltip: tooltip['lose']},
        { name: "홀", key: 'stat_hold', renderHeaderCell: tooltipRender, tooltip: tooltip['hold']},
        { name: '세', key: 'stat_save', renderHeaderCell: tooltipRender, tooltip: tooltip['save']},
        { name: "블론", width: 55, key: 'stat_blown', renderHeaderCell: tooltipRender, tooltip: tooltip['blown']},
        { name: "세%+", width: 55, key: 'statCalced_savePlusRate', renderHeaderCell: tooltipRender, tooltip: tooltip['savePlusRate']},
        { name: "세%", width: 55, key: 'statCalced_saveRate', renderHeaderCell: tooltipRender, tooltip: tooltip['saveRate']},
        { name: "2연투", width: 63, key: 'straightPitchDay2', renderHeaderCell: tooltipRender, tooltip: tooltip['straightPitchDay2']},
        { name: "3연투+", width: 63, key: 'straightPitchDay3', renderHeaderCell: tooltipRender, tooltip: tooltip['straightPitchDay3']},
        { name: "1Up", width: 55, key: 'stat_under1Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['under1Inn']},
        { name: "2Up", width: 55, key: 'stat_under2Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['under2Inn']},
        { name: "3Up", width: 55, key: 'stat_under3Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['under3Inn']},
        { name: "3Up+", width: 58, key: 'stat_over3Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['over3Inn']},
        { name: "1Ip-", width: 55, key: 'stat_ip1Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['ip1Inn']},
        { name: "1Ip", width: 55, key: 'stat_ip2Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['ip2Inn']},
        { name: "2Ip", width: 55, key: 'stat_ip3Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['ip3Inn']},
        { name: "3Ip", width: 55, key: 'stat_ip4Inn', renderHeaderCell: tooltipRender, tooltip: tooltip['ip4Inn']},
        { name: "T홀", width: 55, key: 'stat_toughHold', renderHeaderCell: tooltipRender, tooltip: tooltip['toughHold']},
        { name: "T세", width: 55, key: 'stat_toughSave', renderHeaderCell: tooltipRender, tooltip: tooltip['toughSave']},
        { name: "T블론", width: 63, key: 'stat_toughBlown', renderHeaderCell: tooltipRender, tooltip: tooltip['toughBlown']},
        { name: "T세%", width: 63, key: 'statCalced_toughSaveRate', renderHeaderCell: tooltipRender, tooltip: tooltip['toughSaveRate']},
        { name: "IR", width: 55, key: 'stat_ir', renderHeaderCell: tooltipRender, tooltip: tooltip['ir']},
        { name: "IRA", width: 63, key: 'stat_ira', renderHeaderCell: tooltipRender, tooltip: tooltip['ira']},
        { name: "IRS%", width: 55, key: 'statCalced_irsPer', renderHeaderCell: tooltipRender, tooltip: tooltip['irsPer']},
        { name: "PR", width: 55, key: 'stat_pr', renderHeaderCell: tooltipRender, tooltip: tooltip['pr']},
        { name: "PRS", width: 63, key: 'stat_prs', renderHeaderCell: tooltipRender, tooltip: tooltip['prs']},
        { name: "gmLi", width: 63, key: 'statCalced_inLi', renderHeaderCell: tooltipRender, tooltip: tooltip['inLi']},
        { name: "pLi", width: 63, key: 'statCalced_pLi', renderHeaderCell: tooltipRender, tooltip: tooltip['pLi']}
    ];

    const rows = props['data'];

    function rowKeyGetter(row) {
        return row['pitcherId'];
    }

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = pitchingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    const gridElement = (
        <DataGrid
        className="record_team_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '팀 투구 불펜기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default TeamPitchingReliefRecord;