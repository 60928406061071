import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import fieldingColumnCalc from '../../../../utils/table/fieldingColumnCalc';
import { useFielderRender, useFielderTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function PlayerFieldingCatcherRecord(props) {
    const statType = "catcher";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCacled_cera",
        direction: "ASC"
    }]);

    const columns = [
        RANKING_CELL, 
        { name: '시즌', key: 'season', sortable: false},
        { name: '팀', key: 'fielderTeamName', sortable: false, renderCell: useFielderTeamRender},
        { name: '선수', key: 'fielderName', sortable: false, renderCell: useFielderRender},
        { name: '선발', key: 'stat_starter', renderHeaderCell: tooltipRender, tooltip: tooltip['starter']},
        { name: '이닝', key: 'statCacled_ip', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: '자책', key: 'stat_er', renderHeaderCell: tooltipRender, tooltip: tooltip['er']},
        { name: "CERA", key: 'statCacled_cera', renderHeaderCell: tooltipRender, tooltip: tooltip['cera']},
        { name: '폭투', key: 'stat_wildPitch', renderHeaderCell: tooltipRender, tooltip: tooltip['wildPitch']},
        { name: '포일', key: 'stat_passedBall', renderHeaderCell: tooltipRender, tooltip: tooltip['passedBall']},
        { name: "폭투/9", key: 'statCacled_wildPitch9', renderHeaderCell: tooltipRender, tooltip: tooltip['wildPitch9']},
        { name: "포일/9", key: 'statCacled_passedBall9', renderHeaderCell: tooltipRender, tooltip: tooltip['passBall9']},
        { name: "PASS/9", key: 'statCacled_pass9', renderHeaderCell: tooltipRender, tooltip: tooltip['pass9']},
        { name: '도루', key: 'stat_stealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['stealCnt']},
        { name: '도루자', key: 'stat_failStealCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['failStealCnt']},
        { name: '도루저지%', key: 'statCacled_csRate', renderHeaderCell: tooltipRender, tooltip: tooltip['csRate']},
    ];

    const rows = props['data'];

    function rowKeyGetter(row) {
        return row['fielderId'];
    }

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {

                const comparator = fieldingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    const gridElement = (
        <DataGrid
        className="record_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 수비 포수기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerFieldingCatcherRecord;