import React, { useEffect, useState } from 'react';
import { fetchGameList } from '../../utils/api';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

function VsRecordDialog(props) {
    const {teamObj} = props;
    const [gameList, setGameList] = useState([]); 
    const navigate = useNavigate();

    useEffect(() => {
        const teamId = teamObj['id'].split('_')[0];
        const oppTeamId = teamObj['id'].split('_')[1];
        const seasons = teamObj['season']

        const scheduleParam = {
            seasons,
            gameTypeCodes: 4201
        };

        const awayTeamSchedulePromise = fetchGameList({
            ...scheduleParam,
            awayTeamId: teamId,
            homeTeamId: oppTeamId
        });

        const homeTeamSchedulePromise = fetchGameList({
            ...scheduleParam,
            awayTeamId: oppTeamId,
            homeTeamId: teamId
        });

        Promise.all([
            awayTeamSchedulePromise,
            homeTeamSchedulePromise
        ]).then((response) => {
            const awayTeamGame = _.filter(response[0]['data']['RECORD'], (item, idx) => item['ingStatus'] === 'DONE');
            const homeTeamGame = _.filter(response[1]['data']['RECORD'], (item, idx) => item['ingStatus'] === 'DONE');

            const totalGameArr = _.sortBy(_.flattenDeep([awayTeamGame, homeTeamGame]), ['gameDay']);
            setGameList(totalGameArr);
        })
    }, [])

    const gameInfoIdClickHandler = (e) => {
        const id = e.currentTarget.id;
        navigate(`/gameschedule/result/${id.split('_')[1]}`);
    }

    return (
    <>
    {teamObj['teamId']}
    <table className='hiball_table'>
        <thead>
            <tr>
                <th>날짜</th>
                <th>구장</th>
                <th>전적</th>
                <th>결과</th>
            </tr>
        </thead>
        <tbody>
        {
        gameList.map((item) => (
            <tr key={item['gameInfoId']}>
                <td>{item['gameDay']}</td>
                <td>{item['stadiumsName']}</td>
                <td>
                {
                item['awayTeamScore'] !== item['homeTeamScore']
                ?
                (
                item['awayTeamScore'] > item['homeTeamScore']
                ?
                <>
                {item['awayTeamName']+" "}<span className='color_red'>{item['awayTeamScore']}</span>{' : '}<span className='color_blue'>{item['homeTeamScore']}</span>{" "+item['homeTeamName']}
                </>
                :
                <>
                {item['awayTeamName']+" "}<span className='color_blue'>{item['awayTeamScore']}</span>{' : '}<span className='color_red'>{item['homeTeamScore']}</span>{" "+item['homeTeamName']}
                </>
                )
                :
                (
                item['awayTeamName']+" "+item['awayTeamScore']+' : '+item['homeTeamScore']+" "+item['homeTeamName']
                )
                
                }    
                </td>
                <td>
                    <div id={"result_"+item['gameInfoId']}
                    className="result_btn"
                    onClick={gameInfoIdClickHandler}>
                        경기결과
                    </div>
                </td>
            </tr>
        ))
        }
        </tbody>
    </table>
    </>
    )
}

export default VsRecordDialog;