import React from 'react';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import MainRouter from './MainRouter';
import { AuthProvider } from './components/auth/AuthContext';

function App() {
    return (
        <AuthProvider>
            <div className='wrapper'>
                <Header/>
                <div id="main_content">
                    <MainRouter/>
                </div>
                <Footer/>
            </div>
        </AuthProvider>
    );
}

export default App;