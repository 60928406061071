import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import teamCode from '../../../../utils/team/teamCode';
import _ from 'lodash';

function PitcherTeamSelectItem(props) {
    const {value, setValue} = props;
    const [selectId, setSelectId] = useState(value || 'all');
    const teams = teamCode();

    const handleChange = (event) => {
        const {target:{value}} = event;
        setSelectId(value);
        setValue((prev) => {
            return {...prev, pitcherTeamId: value};
        });
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <Select
        sx={{height: 30}}
        value={selectId}
        onChange={handleChange}
        >
        {
        _.map(teams, (item, idx) => (
            <MenuItem key={item['teamCode']}
            value={item['teamCode']}
            >
            {item['teamName']}
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default PitcherTeamSelectItem;