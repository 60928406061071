import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchPitcherSituationRecord } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';
import moment from 'moment';
import batterTypeCode from '../../../utils/code/batterTypeCode';
import distBallSpeedCode from '../../../utils/code/distBallSpeedCode';
import runnerStateCode from '../../../utils/code/runnerStateCode';

const batterTypeArr = batterTypeCode();
const distBallSpeedArr = distBallSpeedCode();
const runnerStateArr = runnerStateCode();

function PitcherSituation1RecordBox() {
    const statArr = [
        {key:'batterType', name: '타자 유형'},
        {key:'ballCode', name: '구종'},
        {key:'ballSpeed', name: '구속'},
        {key:'ballCount', name: '볼카운트'},
        {key:'runnerState', name: '주자상황'}
    ];

    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get("season");
    const [recordObj, setRecordObj] = useState({
        batterType: [],
        ballCode: [],
        ballSpeed: [],
        ballCount: [],
        runnerState: []
    });

    useEffect(() => {
        const playerId = params['playerId'];
        const commonParam = {
            seasons: season,
            gameTypeCodes: 4201,
            pitcherIds: playerId,
            fromDate: moment(new Date(season, 0, 1)).format('YYYY-MM-DD'),
            toDate: moment(new Date(season, 11, 31)).format('YYYY-MM-DD'),
        }
        
        const batterTypeRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'batterType'});
        const ballCodeRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'ballType'});
        const ballSpeedRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'ballSpeed'});
        const ballCountRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'ballCount'});
        const runnerStateRecordPromise = fetchPitcherSituationRecord({...commonParam, groupingColumn: 'runnerState'})

        Promise.all([
            batterTypeRecordPromise, ballCodeRecordPromise,
            ballSpeedRecordPromise, ballCountRecordPromise,
            runnerStateRecordPromise
        ]).then((response) => {
            const batterTypeRecord = loopFlatObject(response[0]['data']['RECORD']);
            const ballCodeRecord = _.sortBy(loopFlatObject(response[1]['data']['RECORD']), ['statCacled_stat_tpa']).reverse();
            const ballSpeedRecord = _.sortBy(loopFlatObject(response[2]['data']['RECORD']), ['situation_distBallSpeedOrd']);
            const ballCountRecord = _.sortBy(loopFlatObject(response[3]['data']['RECORD']), ['situation_beforeDecidedBallCount']);
            const runnerStateRecord = _.sortBy(loopFlatObject(response[4]['data']['RECORD']), ['situation_beforeRunnerState']);

            setRecordObj((prevObj) => {
                return {
                    ...prevObj,
                    batterType: batterTypeRecord,
                    ballCode: ballCodeRecord,
                    ballSpeed: ballSpeedRecord,
                    ballCount: ballCountRecord,
                    runnerState: runnerStateRecord
                }
            });
        })
    }, []);

    const SituationColumn = (props) => {
        const {data} = props;

        switch(props['type']) {
            case 'batterType':
                const findData = batterTypeArr.find((item) => item['batterTypeCode'] === data['situation_battingHand']+"/"+data['situation_batterRosterType'])
                if(findData) {
                    return <td>{findData['batterTypeName']}</td>
                } else {
                    return <td>{data['situation_battingHand']+"/"+data['situation_batterRosterType']}</td>
                }
            case 'ballCode':
                return <td>
                    {data['situation_ballCodeName']}
                </td>
            case 'ballSpeed':
                return <td>
                    {distBallSpeedArr.find((item) => item['distBallSpeedCode'] === data['situation_distBallSpeedOrd'])['distBallSpeed']}
                </td>
            case 'ballCount':
                return <td>
                    {data['situation_beforeDecidedBallCount']}
                </td>
            case 'runnerState':
                return <td>
                    {runnerStateArr.find(item => item['runnerState'] === data['situation_beforeRunnerState'])['runnerStateName']}
                </td>
            default:
                return <td></td>;
        }
    }

    return (
    <div id="situation_basic_container">
    {
    _.map(statArr, (item) => (
    <Fragment key={item['name']}>
        <div className="table_title">{item['name']}</div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>타자수</th>
                        <th>이닝</th>
                        <th>투구수</th>
                        <th>피안타</th>
                        <th>피2루타</th>
                        <th>피3루타</th>
                        <th>피홈런</th>
                        <th>탈삼진</th>
                        <th>사사구</th>
                        <th>실점</th>
                        <th>자책</th>
                        <th>평균자책점</th>
                        <th>WHIP</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(recordObj[item['key']], (data, dataIdx) => (
                    <tr key={item['key']+dataIdx}>
                        <SituationColumn type={item['key']} data={data} />
                        <td>{data['statCacled_stat_tpa']}</td>
                        <td>{data['statCacled_ip']}</td>
                        <td>{data['statCacled_stat_pitchingCnt']}</td>
                        <td>{data['statCacled_stat_hit']}</td>
                        <td>{data['statCacled_stat_b2b']}</td>
                        <td>{data['statCacled_stat_b3b']}</td>
                        <td>{data['statCacled_stat_hr']}</td>
                        <td>{data['statCacled_stat_so']}</td>
                        <td>{data['statCacled_stat_totalBb']}</td>
                        <td>{data['statCacled_stat_r']}</td>
                        <td>{data['statCacled_stat_er']}</td>
                        <td>{data['statCacled_stat_era']}</td>
                        <td>{data['statCacled_whip']}</td>
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
    </Fragment>
    ))
    }
    </div>
    )
}

export default PitcherSituation1RecordBox;