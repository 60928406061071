import React from 'react';
import TodayScheduleBox from './today-schedule-box';
import TodayGameSummaryBox from './today-game-summary-box';
import TodayGameLowerBox from './lower/today-game-lower-box';
import TodayGameUpperBox from './upper/today-game-upper-box';

function TodayGameDetailBox() {


    return (
        <>
        <div className="container">
            <TodayScheduleBox/>
            <div id="upper_tab_container">
                <TodayGameSummaryBox>
                    <TodayGameUpperBox/>
                </TodayGameSummaryBox>
            </div>
            <div id="lower_tab_container">
                <TodayGameLowerBox/>
            </div>
        </div>
        </>
    )
}

export default TodayGameDetailBox;