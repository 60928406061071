export default function battingColumnCalc(statType, sortColumn, direction) {
    if(statType === "basic") {
        switch (sortColumn) {
            case 'statCalced_stat_gameCnt':
            case 'stat_starter':
            case 'stat_substitute':
            case 'statCalced_stat_tpa':
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'stat_leftPitcherAvg':
            case 'stat_leftPitcherXh':
            case 'stat_rightPitcherAvg':
            case 'stat_rightPitcherXh':
            case 'statCalced_stat_ab':
            case 'statCalced_stat_hit':
            case 'statCalced_stat_b1b':
            case 'statCalced_stat_b2b':
            case 'statCalced_stat_b3b':
            case 'statCalced_stat_hr':
            case 'statCalced_stat_bbAddIbb':
            case 'statCalced_stat_ibb':
            case 'statCalced_stat_hbp':
            case 'statCalced_stat_r':
            case 'statCalced_stat_rbi':
            case 'statCalced_stat_so':
            case 'statCalced_stat_pitchingCnt':
            case 'statCalced_stat_lookingSo':
            case 'statCalced_stat_swingSo':
            case 'statCalced_lookingSoRate':
            case 'statCalced_swingSoRate':
            case 'statCalced_stat_sf':
            case 'statCalced_stat_sb':
            case 'statCalced_stat_gdp':
            case 'statCalced_stat_stealCnt':
            case 'statCalced_stat_failStealCnt':
            case 'statCalced_stealRate':
            case 'statCalced_scoringAvg':
            case 'statCalced_woba':
            case 'statCalced_wrcPlus':
            case 'statCalced_wraa':
            case 'statCalced_oWAR':
            case 'statCalced_stat_wpa':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    } else if(statType === "detail") {
        switch (sortColumn) {
            case 'statCalced_stat_tpa':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'statCalced_isoP':
            case 'statCalced_bbRate':
            case 'statCalced_kRate':
            case 'statCalced_bbKRate':
            case 'statCalced_hrRate':
            case 'statCalced_babip':
            case 'statCalced_spd':
            case 'statCalced_stat_stealCnt':
            case 'statCalced_stat_failStealCnt':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    } else if(statType === "tpa") {
        switch(sortColumn) {
            case 'statCalced_stat_tpa':
            case 'statCalced_stat_pitchingCnt':
            case 'statCalced_pDivPa':
            case 'statCalced_strikeRate':
            case 'statCalced_ballRate':
            case 'statCalced_zoneStrikeRate':
            case 'statCalced_zoneBallRate':
            case 'statCalced_strikeStandRate':
            case 'statCalced_strikeSwingRate':
            case 'statCalced_strikeFoulRate':
            case 'statCalced_strikeInplayRate':
            case 'statCalced_firstStrikeRate':
            case 'statCalced_swStr':
            case 'statCalced_oStandRate':
            case 'statCalced_zStandRate':
            case 'statCalced_standRate':
            case 'statCalced_oSwingRate':
            case 'statCalced_zSwingRate':
            case 'statCalced_swingRate':
            case 'statCalced_oContactRate':
            case 'statCalced_zContactRate':
            case 'statCalced_contactRate':
            case 'statCalced_whiff':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    } else if(statType === 'hit') {
        switch(sortColumn) {
            case 'statCalced_stat_tpa':
            case 'statCalced_stat_hit':
            case 'statCalced_avg':
            case 'statCalced_obp':
            case 'statCalced_slg':
            case 'statCalced_ops':
            case 'statCalced_babip':
            case 'statCalced_stat_groundBall':
            case 'statCalced_stat_flyBall':
            case 'statCalced_stat_lineDriveBall':
            case 'statCalced_gdDivFly':
            case 'statCalced_gdRate':
            case 'statCalced_flyRate':
            case 'statCalced_lineRate':
            case 'statCalced_stat_hr':
            case 'statCalced_stat_xh':
            case 'statCalced_xhPerPa':
            case 'statCalced_xhPerAb':
            case 'statCalced_xhPerHit':
            case 'statCalced_paPerHr':
            case 'statCalced_abPerHr':
            case 'statCalced_hrPerFly':
            case 'statCalced_hrPerFlyOut':
            case 'statCalced_stat_oneRbiHr':
            case 'statCalced_stat_twoRbiHr':
            case 'statCalced_stat_threeRbiHr':
            case 'statCalced_stat_fourRbiHr':
            case 'statCalced_stat_rightPitcherHr':
            case 'statCalced_stat_leftPitcherHr':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    } else if(statType === "clutch") {
        switch(sortColumn) {
            case 'statCalced_stat_tpa':
            case 'statCalced_stat_scoringPosTpa':
            case 'statCalced_scoringPaRate':
            case 'statCalced_scoringAvg':
            case 'statCalced_stat_rbi':
            case 'statCalced_pureRbi':
            case 'statCalced_scoringHitRate':
            case 'statCalced_clutchHit':
            case 'statCalced_scoringHrRate':
            case 'statCalced_clutchHr':
            case 'statCalced_scoringSoRate':
            case 'statCalced_clutchK':
            case 'statCalced_scoringBbRate':
            case 'statCalced_clutchBb':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    } else if(statType === "runner") {
        switch(sortColumn) {
            case 'statCalced_stat_stealCnt':
            case 'statCalced_stat_failStealCnt':
            case 'statCalced_stealRate':
            case 'statCalced_stat_leftHandStealCnt':
            case 'statCalced_stat_leftHandFailStealCnt':
            case 'statCalced_leftHandStealRate':
            case 'statCalced_stat_base1StealCnt':
            case 'statCalced_stat_base1FailStealCnt':
            case 'statCalced_base1StealRate':
            case 'statCalced_stat_base2StealCnt':
            case 'statCalced_stat_base2FailStealCnt':
            case 'statCalced_base2StealRate':
            case 'statCalced_stat_base3StealCnt':
            case 'statCalced_stat_base3FailStealCnt':
            case 'statCalced_base3StealRate':
            case 'stat_checkOutCnt':
            case 'stat_runnerOutCnt':
            case 'stat_wildPitchBaseMoveCnt':
            case 'stat_passedBallBaseMoveCnt':
            case 'stat_addRunnerCnt':
            case 'stat_addRunner':
            case 'addRunnerRate':
                return (a, b) => {
                    return a[sortColumn]*1 - b[sortColumn]*1;
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`); 
        }
    }
}