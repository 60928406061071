import React, { useEffect, useState } from 'react';
import { fetchLastGameDay, fetchPitcherRecord, fetchTeamRanking } from '../../utils/api';
import moment from 'moment';
import { loopFlatObject } from '../../utils/flat';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import INIT_SEASON from '../../utils/initSeason';

function PitcherRanking() {
    const [season, setSeason] = useState('');
    const [pitcherRanking, setPitcherRanking] = useState([]);
    const navigate = useNavigate();
    const seasonArr = _.range(moment(new Date()).format('YYYY')*1, INIT_SEASON-1);

    useEffect(() => {
        const todaySeason = moment(new Date()).format('YYYY');
        fetchLastGameDay({
            seasons: [todaySeason, todaySeason-1].toString(),
            gameTypeCodes: 4201
        }).then((response) => {
            const {lastGameDay} = response['data']['RECORD'][0];
            const lastSeason = moment(lastGameDay).format('YYYY');
            setSeason(lastSeason);
        })
    }, [])

    useEffect(() => {
        if(season) {
            const commonParam = {
                seasons: season,
                gameTypeCodes: 4201
            }

            const pitcherParam = { ...commonParam, groupingColumn: 'seasonPlayer' };
            const teamParam = { ...commonParam };

            const pitcherRecordPromise = fetchPitcherRecord(pitcherParam);
            const teamRecordPromise = fetchTeamRanking(teamParam);

            Promise.all([
                pitcherRecordPromise,
                teamRecordPromise
            ]).then((response) => {
                const rankingData = Object.values(response[0]['data']['RECORD']);
                const teamRankingData = Object.values(response[1]['data']['RECORD'])[0];
                const indexTeamData = _.keyBy(teamRankingData, 'teamId');

                let filterData = _.filter(rankingData, (item) => {
                    let teamGameCnt = indexTeamData[item['pitcherTeamId']]['gameCnt'];
                    return item['ip'] > teamGameCnt;
                });

                let innerData = loopFlatObject(filterData);
                innerData.sort(function (a, b) {
                    if (a['era']*1 > b['era']*1) {
                        return 1;
                    }
                    if (a['era']*1 < b['era']*1) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                
                setPitcherRanking(innerData);
            });
        }
    }, [season]);

    const handleChange = (e) => setSeason(e.target.value);
    const pitcherPlayerInfoHandler = (e) => {
        const playerObj = e.currentTarget.id;
        const playerId = playerObj.split("/")[1];
        const season = playerObj.split("/")[0];
        navigate(`/player/pitcher/${playerId}?season=${season}`);
    }

    return (
    <>
    <div className="select_title">
        <div>
            <FormControl size={'small'}>
                <Select
                style={{backgroundColor: '#fff'}}
                value={season}
                onChange={handleChange}
                >
                {
                    _.map(seasonArr, (item) => (
                        <MenuItem value={item} key={item}>{item}</MenuItem>
                    ))
                }
                </Select>
            </FormControl>
        </div>
        <div className="title">
            투수순위
        </div>
    </div>
    <table className="hiball_table record_table">
        <thead>
            <tr>
                <th>순위</th>
                <th>선수</th>
                <th>팀</th>
                <th>경기</th>
                <th>승</th>
                <th>패</th>
                <th>세이브</th>
                <th>홀드</th>
                <th>이닝</th>
                <th>투구수</th>
                <th>피안타</th>
                <th>피홈런</th>
                <th>탈삼진</th>
                <th>사사구</th>
                <th>실점</th>
                <th>자책</th>
                <th>평균자책점</th>
                <th>WHIP</th>
                <th>QS</th>
            </tr>
        </thead>
        <tbody>
        {
        _.map(pitcherRanking, (item, idx) => (
            <tr key={item['pitcherId']}>
                <td className="first">{idx+1}</td>
                <td className="link" >
                    <div className="flex_div"
                    onClick={pitcherPlayerInfoHandler}
                    id={item['season']+"/"+item['pitcherId']}
                    >
                        <img
                        src={require(`/src/assets/image/common/team_logo/logo_${item['pitcherTeamId']}.png`)}
                        alt={item['pitcherName']}
                        />
                        <span>{item['pitcherName']}</span>
                    </div>
                </td>
                <td className="text">{item['pitcherTeamName']}</td>
                <td className="text">{item['basicStat_gameCnt']}</td>
                <td>{item['win']}</td>
                <td>{item['lose']}</td>
                <td>{item['save']}</td>
                <td>{item['hold']}</td>
                <td>{item['ip']}</td>
                <td>{item['basicStat_pitchingCnt']}</td>
                <td>{item['basicStat_hit']}</td>
                <td>{item['basicStat_hr']}</td>
                <td>{item['basicStat_so']}</td>
                <td>{item['basicStat_bb']}</td>
                <td>{item['basicStat_r']}</td>
                <td>{item['basicStat_er']}</td>
                <td>{item['era']}</td>
                <td>{item['whip']}</td>
                <td>{item['qs']}</td>
            </tr>    
        ))
        }
        </tbody>
    </table>
    </>
    )
}

export default PitcherRanking;