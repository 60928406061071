import React from 'react';
import indicatorColorCalc from '../../utils/color/indicatorColorCalc';

function IndicatorChart(props) {
    const {data} = props;
    const {name, value, stat, rank} = data || {name: null, value: null, stat: null, rank: null};
    let numberCalc = rank*10;

    const startPoint = 0;
    const endPoint = 73;

    let pointLength = endPoint-startPoint;    
    let dataPoint = rank ? (numberCalc/100)*pointLength : null;
    let colorObj = rank ? indicatorColorCalc(numberCalc) : null;

    return (
        <>
        { data ?
        <svg width="110" height="45">
            <text x="5" y="13" fontSize={11}>{name}</text>
            <g transform="translate(15,0)">
                <line x1="0" x2="73" y1="25" y2="25" stroke="#9b9b9b" strokeWidth="3px"></line>
                <circle cx="0.365" cy="25" r="2" fill="#9b9b9b" stroke="#9b9b9b"></circle>
                <circle cx="73.365" cy="25" r="2" fill="#9b9b9b" stroke="#9b9b9b"></circle>
                <circle cx="36.5" cy="25" r="2" fill="#9b9b9b" stroke="#9b9b9b"></circle>
                <g id="slider_percent_rank_exit_velocity_avg" transform={`translate(${dataPoint},25)`}>
                    <circle r="10" fill={colorObj['bgColor']} stroke="#000"></circle>
                    <text dy="4" textAnchor="middle" 
                    fill={colorObj['textColor']} fontSize=".7rem">{rank}</text>
                    <text dy="20" textAnchor="middle" 
                    fill={'black'} fontSize=".7rem">{value}</text>
                </g>
            </g>
        </svg> :
        <svg width="110" height="40"></svg>
        }
        
        </>
    )
}

export default IndicatorChart;