import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_info_obj_atom } from '../../../../assets/model/detail/store';
import moment from 'moment';
import { fetchBatterRecord, fetchTeamRecord } from '../../../../utils/api';
import _ from 'lodash';
import { flatObject } from '../../../../utils/flat';
import VerticalChart from '../../../../components/chart/VerticalChart';

function TodaySeasonBox() {
    const gameInfo = useAtomValue(game_info_obj_atom);
    const [teamRecord, setTeamRecord] = useState({awayRecord: {},homeRecord: {}});

    useEffect(() => {
        if(!_.isEmpty(gameInfo)) {
            const season = moment(gameInfo['gameDay']).format('YYYY');
            let {awayTeamId, homeTeamId} = gameInfo;

            const commonParam = {
                gameTypeCodes: 4201,
                seasons: season,
            }

            const awayRecordPromise = fetchBatterRecord({
                ...commonParam,
                batterTeamIds: awayTeamId,
                groupingColumn: 'team'
            });

            const awayWinRatePromise = fetchTeamRecord({
                ...commonParam,
                teamId: awayTeamId
            })

            const homeRecordPromise = fetchBatterRecord({
                ...commonParam,
                batterTeamIds: homeTeamId,
                groupingColumn: 'team'
            });

            const homeWinRatePromise = fetchTeamRecord({
                ...commonParam,
                teamId: homeTeamId
            })

            Promise.all([
                awayRecordPromise, awayWinRatePromise,
                homeRecordPromise, homeWinRatePromise
            ]).then((response) => {
                let awayRecord = flatObject(response[0]['data']['RECORD'][0]);
                let homeRecord = flatObject(response[2]['data']['RECORD'][0]);

                let awayWinRate = response[1]['data']['RECORD'][0];
                let homeWinRate = response[3]['data']['RECORD'][0];

                setTeamRecord((prev) => {
                    return {
                        ...prev,
                        awayRecord: {
                            ...awayRecord,
                            winRate: awayWinRate ? awayWinRate['winRate'] : 0,
                        },
                        homeRecord: {
                            ...homeRecord,
                            winRate: homeWinRate ? homeWinRate['winRate'] : 0
                        }
                    }
                });
            })
        }
    }, [gameInfo])

    return (
        <div id="record_season_wrap">
            <VerticalChart
            teamRecord={teamRecord}
            name="season"/>
        </div>
    )
}

export default TodaySeasonBox;