import React from 'react';
import moment from 'moment';

function Footer() {
    const todaySeason = moment(new Date()).format('YYYY');

    return (
        <div id="footer">
            <div className="container">
            <div id="footer_menu">
                <span>이용약관</span>
                <span>묻고답하기(FAQ)</span>
                <span>1:1문의하기</span>
                <span>사업제휴</span>
                <span>이메일무단수집거부</span>
            </div>
            <div id="footer_comment" className="color_darkgray">
                <span>하이볼(주)</span>&nbsp;<span>biz@hiball.net</span>
                <br />
                <div id="footer_copyright">
                <span>COPYRIGHT {todaySeason}. HIBALL INC. ALL RIGHTS RESERVED</span>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Footer;