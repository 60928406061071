import React from 'react';

function Pagination({
    postsNum,
    postsPerPage,
    setCurrentPage,
    currentPage
}) {
  const pageList = [];
  const totalPages = Math.ceil(postsNum / postsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageList.push(i);
  }

  const goToNextPage = () => {
    setCurrentPage(currentPage * 1 + 1);
  };

  const goToPrevPage = () => {
    setCurrentPage(currentPage * 1 - 1);
  };

  if (totalPages < 2) {
    return null;
  }

  return (
    <div id="paginationWrap">
      <button className="pageFuncBtn prevBtn" onClick={goToPrevPage} disabled={currentPage * 1 === 1}>
        &lt; 이전
      </button>

      {pageList.map((page) => (
        <button
          key={page}
          onClick={() => setCurrentPage(page)}
          className={currentPage * 1 === page * 1 ? "pageNum active" : "pageNum"}
        >
          {page}
        </button >
      ))}

      <button className="pageFuncBtn nextBtn" onClick={goToNextPage} disabled={currentPage * 1 === pageList.length}>
        다음 &gt;
      </button>
    </div>
  );
}

export default Pagination;