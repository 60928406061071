import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '4401', name: '사직'},
    {id: '4402', name: '대전'},
    {id: '4403', name: '잠실'},
    {id: '4404', name: '마산'},
    {id: '4405', name: '문학'},
    {id: '4406', name: '광주'},
    {id: '4415', name: '고척'},
    {id: '4409', name: '대구'},
    {id: '4411', name: '수원'},
    {id: '4410', name: '포항'},
    {id: '4413', name: '청주'},
    {id: '4414', name: '울산'}
];

function StadiumCheckboxItem(props) {
    const {value, setValue} = props;
    const [checkIdArr, setCheckIdArr] = useState(value || []);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setValue((prev) => {
            return {...prev, stadiums: checkIdArr}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="stadium-checkbox-label"
        sx={{fontSize: 12}}
        >구장</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="stadium-checkbox-label"
        id="stadium-checkbox"
        multiple
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        input={<OutlinedInput label="구장" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(rows, (item, idx) => selected.indexOf(item['id']) > -1);
            const selectedName = _.map(selectedArr, 'name');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"stadium_"+item['id']} value={item['id']}>
                <Checkbox checked={checkIdArr.indexOf(item['id']) > -1}/>
                <ListItemText primary={item['name']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default StadiumCheckboxItem;