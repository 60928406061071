import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '6116', name: '직구'},
    {id: '6101', name: '투심'},
    {id: '6117', name: '커터'},
    {id: '6108', name: '커브'},
    {id: '6111', name: '슬라이더'},
    {id: '6103', name: '체인지업'},
    {id: '6107', name: '포크'},
    {id: '6112', name: '슬러브'},
    {id: '6106', name: '싱커'},
    {id: '6115', name: '스크루볼'},
    {id: '6119', name: '스플리터'},
    {id: '6105', name: '슈트'},
    {id: '6114', name: '너클볼'},
];

function BallCodeCheckboxItem(props) {
    const {value, setValue} = props;
    const [checkIdArr, setCheckIdArr] = useState(value || []);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setValue((prev) => {
            return {...prev, ballCodes: checkIdArr}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="ball-code-checkbox-label"
        sx={{fontSize: 12}}
        >구종</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="ball-code-checkbox-label"
        id="ball-code-checkbox"
        multiple
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        input={<OutlinedInput label="구종" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(rows, (item, idx) => selected.indexOf(item['id']) > -1);
            const selectedName = _.map(selectedArr, 'name');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"ballCodes_"+item['id']} value={item['id']}>
                <Checkbox checked={checkIdArr.indexOf(item['id']) > -1}/>
                <ListItemText primary={item['name']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default BallCodeCheckboxItem;