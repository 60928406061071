import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PitcherRanking from '../../layout/ranking/pitcher-ranking';
import TeamRanking from '../../layout/ranking/team-ranking';
import BatterRanking from '../../layout/ranking/batter-ranking';

function RankingMain() {
    const navigate = useNavigate();
    const params = useParams();
    const mainId = params['mainId'];

    const RankingTabContent = function() {
        switch(mainId) {
            case 'batter':
                return <BatterRanking/>;
            case 'pitcher':
                return <PitcherRanking/>;
            default:
                return <TeamRanking/>;
        }
    }

    return (
    <>
    <div className="container">
        <div id="ranking_tab_container">
            <div id="ranking_tab_area">
                <div 
                className={"btn "+((mainId === "team") && "active")}
                onClick={() => navigate('/ranking/team')}
                >팀</div>
                <div
                className={"btn "+((mainId === "batter") && "active")}
                onClick={() => navigate('/ranking/batter')}
                >타자</div>
                <div
                className={"btn "+((mainId === "pitcher") && "active")}
                onClick={() => navigate('/ranking/pitcher')}
                >투수</div>
            </div>
        </div>
        <div id="ranking_detail_container">
            <RankingTabContent />
        </div>
    </div>
    </>
    )
}

export default RankingMain;