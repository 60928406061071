import { useAtomValue } from 'jotai';
import React from 'react';
import { game_info_obj_atom } from '../../../../assets/model/detail/store';
import _ from 'lodash';
import TodayRecentYearRecord from './total/today-recent-year-record';
import TodayRecentGameRecord from './total/today-recent-game-record';

function TodayTotalBox() {
    const teamArr = ['away','home'];
    const gameInfoObj = useAtomValue(game_info_obj_atom);

    return (
        <div id="lower_tab1" className="tab">
        {
        teamArr.map((team) => (
        <div className={"team_"+team} key={"team_"+team}>
            {
            gameInfoObj[team+"StartPitcherId"] ?
            <>
            <TodayRecentYearRecord
            playerId={team === 'away' ? gameInfoObj['awayStartPitcherId'] : gameInfoObj['homeStartPitcherId']}
            homeOrAway={team}
            />
            <TodayRecentGameRecord
            playerId={team === 'away' ? gameInfoObj['awayStartPitcherId'] : gameInfoObj['homeStartPitcherId']}
            homeOrAway={team}
            />
            </> :
            <div style={{margin: '20px', backgroundColor: '#fff', height:'400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                선발투수 발표 전 입니다
            </div>
            }
        </div>
        ))
        }
        </div>
    )
}

export default TodayTotalBox;