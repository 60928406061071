export default function runnerStateCode() {
    const runnerStateCodes = [
        {runnerState: 0, runnerStateName: '주자없음'},
        {runnerState: 1, runnerStateName: '1루'},
        {runnerState: 2, runnerStateName: '2루'},
        {runnerState: 3, runnerStateName: '1,2루'},
        {runnerState: 4, runnerStateName: '3루'},
        {runnerState: 5, runnerStateName: '1,3루'},
        {runnerState: 6, runnerStateName: '2,3루'},
        {runnerState: 7, runnerStateName: '주자만루'},
        {runnerState: 11, runnerStateName: '주자없음'},
        {runnerState: 10, runnerStateName: '주자있음'},
        {runnerState: 12, runnerStateName: '득점권'},
        {runnerState: 13, runnerStateName: '득점권X'}
    ];

    return runnerStateCodes;
}