import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import battingColumnCalc from '../../../../utils/table/battingColumnCalc';
import { useBatterTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function TeamBattingTpaRecord(props) {
    const statType = "tpa";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_stat_tpa",
        direction: "DESC"
    }]);
    
    const columns = [
        RANKING_CELL,
        { name: '시즌', key: 'season', frozen: true, sortable: false},
        { name: '팀', key: 'batterTeamName', frozen: true, sortable: false, renderCell: useBatterTeamRender},
        // { name: '선수',key: 'batterName',  frozen: true},
        { name: '타석', width: 55, key: 'statCalced_stat_tpa', renderHeaderCell: tooltipRender, tooltip: tooltip['tpa']},
        // {key: 'statCalced_stat_pitchingCnt', width: 63, name: '투구수', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        {key: 'statCalced_pDivPa', width: 63, name: 'Pit/PA', renderHeaderCell: tooltipRender, tooltip: tooltip['tpaDivPitch']},
        {key: 'statCalced_strikeRate', width: 77, name: 'STRIKE%', renderHeaderCell: tooltipRender, tooltip: tooltip['strikeRate']},
        {key: 'statCalced_ballRate', width: 68, name: 'BALL%', renderHeaderCell: tooltipRender, tooltip: tooltip['ballRate']},
        {key: 'statCalced_zoneStrikeRate', width: 94, name: 'STRIKE%(Z)', renderHeaderCell: tooltipRender, tooltip: tooltip['zoneStrikeRate']},
        {key: 'statCalced_zoneBallRate', width: 82, name: 'BALL%(Z)', renderHeaderCell: tooltipRender, tooltip: tooltip['zoneBallRate']},
        {key: 'statCalced_strikeStandRate', width: 70, name: 'Str.Lk%', renderHeaderCell: tooltipRender, tooltip: tooltip['strikeStandRate']},
        {key: 'statCalced_strikeSwingRate', width: 72, name: 'Str.Sw%', renderHeaderCell: tooltipRender, tooltip: tooltip['strikeSwingRate']},
        {key: 'statCalced_strikeFoulRate', width: 79, name: 'Str.Foul%', renderHeaderCell: tooltipRender, tooltip: tooltip['strikeFoulRate']},
        {key: 'statCalced_strikeInplayRate', width: 75, name: 'Str.InP%', renderHeaderCell: tooltipRender, tooltip: tooltip['strikeInplayRate']},

        {key: 'statCalced_firstStrikeRate', width: 79, name: 'F-Strike%', renderHeaderCell: tooltipRender, tooltip: tooltip['firstStrikeRate']},
        {key: 'statCalced_swStr', width: 68, name: 'SwStr%', renderHeaderCell: tooltipRender, tooltip: tooltip['swStr']},

        {key: 'statCalced_oStandRate', width: 80, name: 'O-Stand%', renderHeaderCell: tooltipRender, tooltip: tooltip['oStandRate']},
        {key: 'statCalced_zStandRate', width: 80, name: 'Z-Stand%', renderHeaderCell: tooltipRender, tooltip: tooltip['zStandRate']},
        {key: 'statCalced_standRate', width: 70, name: 'Stand%', renderHeaderCell: tooltipRender, tooltip: tooltip['standRate']},

        {key: 'statCalced_oSwingRate', width: 82, name: 'O-Swing%', renderHeaderCell: tooltipRender, tooltip: tooltip['oSwingRate']},
        {key: 'statCalced_zSwingRate', width: 82, name: 'Z-Swing%', renderHeaderCell: tooltipRender, tooltip: tooltip['zSwingRate']},
        {key: 'statCalced_swingRate', width: 70, name: 'Swing%', renderHeaderCell: tooltipRender, tooltip: tooltip['swingRate']},

        {key: 'statCalced_oContactRate', width: 92, name: 'O-Contact%', renderHeaderCell: tooltipRender, tooltip: tooltip['oContactRate']},
        {key: 'statCalced_zContactRate', width: 92, name: 'Z-Contact%', renderHeaderCell: tooltipRender, tooltip: tooltip['zContactRate']},
        {key: 'statCalced_contactRate', width: 80, name: 'Contact%', renderHeaderCell: tooltipRender, tooltip: tooltip['contactRate']},
        {key: 'statCalced_whiff', width: 70, name: 'Whiff%', renderHeaderCell: tooltipRender, tooltip: tooltip['whiffRate']}
    ]

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = battingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['batterId'];
    }

    const gridElement = (
        <DataGrid
        className="record_team_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '팀 타격 타석기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default TeamBattingTpaRecord;