import { FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: 'HOME', name: '홈'},
    {id: 'AWAY', name: '어웨이'}
];

function BatterHomeSelectItem(props) {
    const {setValue} = props;
    const [selectId, setSelectId] = useState([]);

    const handleOnChange = (event) => {
        const {target:{value}} = event
        setSelectId(value);
        setValue((prev) => {
            return {...prev, homeOrAway: value}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="batter-home-select-label"
        sx={{fontSize: 12}}
        >홈/원정</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="batter-home-select-label"
        id="batter-home-select"
        value={selectId}
        onChange={handleOnChange}
        input={<OutlinedInput label="홈/원정" />}
        >
        <MenuItem value="">전체</MenuItem>
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"homeOrAway_"+item['id']} value={item['id']}>
                {item['name']}
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default BatterHomeSelectItem;