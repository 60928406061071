import React from "react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (user) => {
    const auth = await getAuth(user);

    let jwtToken = auth.jwtToken;

    if(jwtToken != null) {
      localStorage.setItem("accessToken", JSON.stringify(jwtToken));

      const origin = location.state?.from?.pathname || '/';
      navigate(origin);
    } else {
      navigate('/error');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");

    const origin = location.state?.from?.pathname || '/';
    navigate(origin);
  };

  const value = {
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  const getAuth = (user) =>
    new Promise((resolve) => {
        const params = {
          memberLoginId: user.id,
          encContent: user.pw
        };

        axios.get(`${process.env.REACT_APP_API_URL}/api/actionLogin.do`, {params}).then((Response)=>{
            // console.log(Response.data);

            resolve(Response.data);
        }).catch((Error)=>{
            console.log(Error);
            navigate('/error');
        })
    });

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};