import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const OAuthLayout = () => {
    const [userInfo, setUserInfo] = useState();
    const [platform, setPlatform] = useState();
    const [userPolicy, setUserPolicy] = useState();
    const [policyId, setPolicyId] = useState();
    const [policyAgreeYn, setPolicyAgreeYn] = useState('N');

    return (
        <div className="container">
            <div id="OAuthMainWrap">
                <Outlet context={{userInfo, setUserInfo, platform, setPlatform, userPolicy, setUserPolicy, policyId, setPolicyId, policyAgreeYn, setPolicyAgreeYn}} />
            </div>
        </div>
    );
};

export default OAuthLayout;