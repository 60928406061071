import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPitchByPitch } from '../../../utils/api';
import HitCourseZoneChart from '../../../components/chart/HitCourseZoneChart';
import { toPng } from 'html-to-image';

function GameResultSprayBox() {
    const ballCodeArr = [
        {name: 'fastball', code: 6116, color: '#ff0000'}, {name: 'sinker', code: 6101, color: '#ff7800'}, 
        {name: 'cutter', code: 6117, color: '#00ff00'}, {name: 'slider', code:6111, color: '#00aa00'},
        {name: 'curve', code: 6108, color: '#000000'}, {name: 'changeup', code: 6103, color: '#0000ff'}, 
        {name: 'splitter', code: 6107, color: '#a01ff0'}, {name: 'other', code:6199, color: '#ececec'}
    ];

    const params = useParams();
    const [colorType, setColorType] = useState('hitResult');
    const [pbpData, setPbpData] = useState([]);

    useEffect(() => {
        const gameInfoId = params['gameInfoId'];
        const gameParam = {
            gameInfoIds: gameInfoId,
            gameTypeCodes: 4201
        }

        fetchPitchByPitch(gameParam)
        .then((response) => {
            setPbpData(response['data']['RECORD']);
        })
    }, [])

    const exportClickHandler = (event) => {
        const firstData = pbpData[0];
        const title = firstData['gameDay']+" "+firstData['batterTeamName']+" vs "+firstData['pitcherTeamName']+" 타구분포";

        let node = document.getElementById('game_result_spray_svg');

        toPng(node)
        .then(function (dataUrl) {
            const link = document.createElement('a')
            link.download = title+'.png';
            link.href = dataUrl
            link.click()
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    }

    return (
    <>
    <div style={{textAlign: 'right', padding:'4px'}}>
        <span>
        <button
        onClick={exportClickHandler}
        >Export To PNG</button>
        </span>
    </div>
    <div style={{textAlign:'center', backgroundColor: '#fff'}} id="game_result_spray_svg">
        <HitCourseZoneChart 
        data={pbpData}
        ballCodeArr={ballCodeArr}
        colorType={colorType}
        size={300}
        />
    </div>
    </>
    )
}

export default GameResultSprayBox;