import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchBatterSituationRecord } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';
import moment from 'moment';
import pitcherTypeCode from '../../../utils/code/pitcherTypeCode';
import distBallSpeedCode from '../../../utils/code/distBallSpeedCode';
import runnerStateCode from '../../../utils/code/runnerStateCode';

const pitcherTypeArr = pitcherTypeCode();
const distBallSpeedArr = distBallSpeedCode();
const runnerStateArr = runnerStateCode();

function BatterSituation1RecordBox() {
    const statArr = [
        {key:'pitcherType', name: '투수 유형'},
        {key:'ballCode', name: '구종'},
        {key:'ballSpeed', name: '구속'},
        {key:'ballCount', name: '볼카운트(S-B)'},
        {key:'runnerState', name: '주자상황'}
    ];

    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get("season");
    const [recordObj, setRecordObj] = useState({
        pitcherType: [],
        ballCode: [],
        ballSpeed: [],
        ballCount: [],
        runnerState: []
    });
    
    useEffect(() => {
        const playerId = params['playerId'];
        const commonParam = {
            seasons: season,
            fromDate: moment(new Date(season, 0, 1)).format('YYYY-MM-DD'),
            toDate: moment(new Date(season, 11, 31)).format('YYYY-MM-DD'),
            gameTypeCodes: 4201,
            batterIds: playerId
        }

        const pitcherTypeRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'pitcherType'});
        const ballCodeRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'ballType'});
        const ballSpeedRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'ballSpeed'});
        const ballCountRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'ballCount'});
        const runnerStateRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'runnerState'})

        Promise.all([
            pitcherTypeRecordPromise, ballCodeRecordPromise,
            ballSpeedRecordPromise, ballCountRecordPromise,
            runnerStateRecordPromise
        ]).then((response) => {
            const pitcherTypeRecord = loopFlatObject(response[0]['data']['RECORD']);
            const ballCodeRecord = _.sortBy(loopFlatObject(response[1]['data']['RECORD']), ['statCacled_stat_tpa']).reverse();
            const ballSpeedRecord = _.sortBy(loopFlatObject(response[2]['data']['RECORD']), ['situation_distBallSpeedOrd']);
            const ballCountRecord = _.sortBy(loopFlatObject(response[3]['data']['RECORD']), ['situation_beforeDecidedBallCount']);
            const runnerStateRecord = _.sortBy(loopFlatObject(response[4]['data']['RECORD']), ['situation_beforeRunnerState']);

            setRecordObj((prevObj) => {
                return {
                    ...prevObj,
                    pitcherType: pitcherTypeRecord,
                    ballCode: ballCodeRecord,
                    ballSpeed: ballSpeedRecord,
                    ballCount: ballCountRecord,
                    runnerState: runnerStateRecord
                }
            });
        })
    }, [])

    const SituationColumn = (props) => {
        const {data} = props;
        switch(props['type']) {
            case 'pitcherType':
                const findData = pitcherTypeArr.find((item) => item['pitcherTypeCode'] === data['situation_pitchingHand']+"/"+data['situation_pitcherRosterType']+"/"+data['situation_pitchingForm'])
                if(findData) {
                    return <td>{findData['pitcherTypeName']}</td>
                } else {
                    return <td>{data['situation_pitchingHand']+"/"+data['situation_pitcherRosterType']+"/"+data['situation_pitchingForm']}</td>
                }
            case 'ballCode':
                return <td>
                    {data['situation_ballCodeName']}
                </td>
            case 'ballSpeed':
                return <td>
                    {distBallSpeedArr.find((item) => item['distBallSpeedCode'] === data['situation_distBallSpeedOrd'])['distBallSpeed']}
                </td>
            case 'ballCount':
                return <td>
                    {data['situation_beforeDecidedBallCount']}
                </td>
            case 'runnerState':
                return <td>
                    {runnerStateArr.find(item => item['runnerState'] === data['situation_beforeRunnerState'])['runnerStateName']}
                </td>
            default:
                return <td></td>;
        }
    }

    return (
    <div id="situation_basic_container">
    {
    _.map(statArr, (item, idx) => (
        <Fragment key={item['name']}>
        <div className="table_title">{item['name']}</div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>타석</th>
                        <th>타수</th>
                        <th>안타</th>
                        <th>1루타</th>
                        <th>2루타</th>
                        <th>3루타</th>
                        <th>홈런</th>
                        <th>삼진</th>
                        <th>볼넷</th>
                        <th>고의4구</th>
                        <th>사구</th>
                        <th>병살타</th>
                        <th>희비</th>
                        <th>타점</th>
                        <th>득점</th>
                        <th>타율</th>
                        <th>출루율</th>
                        <th>장타율</th>
                        <th>OPS</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(recordObj[item['key']], (data, dataIdx) => (
                    <tr key={item['key']+dataIdx}>
                        <SituationColumn type={item['key']} data={data} />
                        <td>{data['statCacled_stat_tpa']}</td>
                        <td>{data['statCacled_stat_ab']}</td>
                        <td>{data['statCacled_stat_hit']}</td>
                        <td>{data['statCacled_stat_b1b']}</td>
                        <td>{data['statCacled_stat_b2b']}</td>
                        <td>{data['statCacled_stat_b3b']}</td>
                        <td>{data['statCacled_stat_hr']}</td>
                        <td>{data['statCacled_stat_so']}</td>
                        <td>{data['statCacled_stat_bb']}</td>
                        <td>{data['statCacled_stat_ibb']}</td>
                        <td>{data['statCacled_stat_hbp']}</td>
                        <td>{data['statCacled_stat_gdp']}</td>
                        <td>{data['statCacled_stat_sf']}</td>
                        <td>{data['statCacled_stat_rbi']}</td>
                        <td>{data['statCacled_stat_r']}</td>
                        <td>{data['statCacled_avg']}</td>
                        <td>{data['statCacled_obp']}</td>
                        <td>{data['statCacled_slg']}</td>
                        <td>{data['statCacled_ops']}</td>
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
        </Fragment>
    ))
    }
    </div>
    )
}

export default BatterSituation1RecordBox;