import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_today_atom, game_info_obj_atom } from '../../../../assets/model/detail/store';
import _ from 'lodash';
import { fetchBatterRecord, fetchPitcherRecord, fetchRosterInfo } from '../../../../utils/api';
import { flatObject } from '../../../../utils/flat';
import moment from 'moment';

function TodayLineUpBox() {
    const gameDay = useAtomValue(game_today_atom);
    const teamArr = ['away','home'];
    const [homePitcherRosterRecord, setHomePitcherRosterRecord] = useState([]);
    const [awayPitcherRosterRecord, setAwayPitcherRosterRecord] = useState([]);

    const [homeBatterRosterRecord, setHomeBatterRosterRecord] = useState([]);
    const [awayBatterRosterRecord, setAwayBatterRosterRecord] = useState([]);
    const gameInfo = useAtomValue(game_info_obj_atom);

    useEffect(() => {
        if(!_.isEmpty(gameInfo)) {
            const season = moment(gameDay).format('YYYY');
            const rosterParam = {
                gameInfoIds: gameInfo['gameInfoId'],
                gameTypeCodes: 4201
            }

            if(gameInfo['lineUpYn'] === 'Y') {
                const rosterInfoPromise = fetchRosterInfo({
                    ...rosterParam, groupingColumn: 'lineUp'
                });
                
                rosterInfoPromise.then((value) => {
                    const lineupInfo = value['data']['RECORD'];
                    const {awayTeamId, homeTeamId} = gameInfo;
                    const rosterInfoObj = _.keyBy(lineupInfo, (info) => info['playerId']);

                    const batterRoster = rosterDivideFunc(lineupInfo, awayTeamId, homeTeamId, 'batter');
                    const pitcherRoster = rosterDivideFunc(lineupInfo, awayTeamId, homeTeamId, 'pitcher');

                    const awayBatterRosterPromise = fetchBatterRecord(paramSetting('batterIds', awayTeamId, batterRoster, gameInfo, 'awayBatter', season));
                    const homeBatterRosterPromise = fetchBatterRecord(paramSetting('batterIds', homeTeamId, batterRoster, gameInfo, 'homeBatter', season));
                    const awayPitcherRosterPromise = fetchPitcherRecord(paramSetting('pitcherIds', awayTeamId, pitcherRoster, gameInfo, 'awayPitcher', season));
                    const homePitcherRosterPromise = fetchPitcherRecord(paramSetting('pitcherIds', homeTeamId, pitcherRoster, gameInfo, 'homePitcher', season));

                    Promise.all([
                        awayBatterRosterPromise, homeBatterRosterPromise,
                        awayPitcherRosterPromise, homePitcherRosterPromise
                    ]).then((response) => {
                        let tmpObj = {};
                        response.forEach((item, idx) => {
                            for(let key in item['data']) {
                                tmpObj[key] = item['data'][key];
                            }
                        });

                        const {awayBatter, homeBatter, awayPitcher, homePitcher} = tmpObj;
                        setHomePitcherRosterRecord(dataSettingFunc(homePitcher));
                        setAwayPitcherRosterRecord(dataSettingFunc(awayPitcher));

                        let homeBatterLineUp = _.keyBy(dataSettingFunc(homeBatter), 'batterId');
                        let awayBatterLineUp = _.keyBy(dataSettingFunc(awayBatter), 'batterId');

                        let homeBatterArr = [];
                        let awayBatterArr = [];

                        _.forEach(batterRoster, (item, idx) => {
                            if(idx === awayTeamId) {
                                item.forEach((innerItem) => {
                                    awayBatterLineUp[innerItem]['battingOrder'] = rosterInfoObj[innerItem]['battingOrder'];
                                    awayBatterLineUp[innerItem]['position'] = rosterInfoObj[innerItem]['position'];
                                    awayBatterArr.push(awayBatterLineUp[innerItem]);
                                })
                            } else if(idx === homeTeamId) {
                                item.forEach((innerItem) => {
                                    homeBatterLineUp[innerItem]['battingOrder'] = rosterInfoObj[innerItem]['battingOrder'];
                                    homeBatterLineUp[innerItem]['position'] = rosterInfoObj[innerItem]['position'];
                                    homeBatterArr.push(homeBatterLineUp[innerItem]);
                                })
                            }
                        });

                        homeBatterArr = _.sortBy(homeBatterArr, ['battingOrder']);
                        awayBatterArr = _.sortBy(awayBatterArr, ['battingOrder']);

                        setHomeBatterRosterRecord(homeBatterArr);
                        setAwayBatterRosterRecord(awayBatterArr);
                    })
                })
            } else {
                setHomePitcherRosterRecord([]);
                setHomeBatterRosterRecord([]);
                setAwayPitcherRosterRecord([]);
                setAwayBatterRosterRecord([]);
            }
        }
    }, [gameInfo]);

    const dataSettingFunc = function(data) {
        return data.map((item, idx) => {
            item = flatObject(item);
            return item;
        })
    }

    const paramSetting = function(type, teamId, rosterObj, gameInfo, returnName, seasons) {
        let playerParam = {
            gameTypeCodes: 4201,
            seasons,
            groupingColumn: 'player',
            gameDay: gameInfo['gameDay'],
            recordName: returnName
        }

        playerParam[type] = rosterObj[teamId].toString();

        return playerParam;
    }

    const rosterDivideFunc = function(data, awayTeamId, homeTeamId, type) {
        let tmpArr = {};
        tmpArr[awayTeamId] = [];
        tmpArr[homeTeamId] = [];

        let playerTypeArr = (type === 'batter') ? [5101, 5103] : [5102, 5104];

        data.forEach((item, idx) => {
            if(item['teamId'] === awayTeamId && (playerTypeArr.indexOf(item['rosterTypeCode']*1) > -1)) {
                tmpArr[awayTeamId].push(item['playerId']);
            } else if(item['teamId'] === homeTeamId && (playerTypeArr.indexOf(item['rosterTypeCode']*1) > -1)) {
                tmpArr[homeTeamId].push(item['playerId']);
            }
        });        

        return tmpArr;
    }

    return (
    <div id="lower_tab3" className="tab">
    {
    teamArr.map((team) => (
    <div className={"team_"+team} key={"team_"+team+"_2"}>
        <div className="head">
            <div className={`team_icon bg_color_${gameInfo[team+'TeamId']}`}>
                <img src={require(`/src/assets/image/common/icon_${team}.png`)} alt={team}/>
                { (team === 'away') ? '원정' : '홈'}팀
            </div>
            <div className="team_logo_wrap">
                <div className="team_logo">
                    <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfo[team+'TeamId']}_l.png`)} alt={gameInfo[team+'TeamId']}/>
                </div>
            </div>
        </div>
        {
        homePitcherRosterRecord.length === 0 && homeBatterRosterRecord.length === 0 &&
        awayPitcherRosterRecord.length === 0 && awayBatterRosterRecord.length === 0
        ?
        <div className='body'>
            <div className="no_lineup">
                라인업 발표 전 입니다
            </div>
        </div>
        :
        <div className='body'>
            <div className="table_batter_title">
                선발타자
            </div>
            <div className="table_batter">
                <table className="hiball_table">
                    <thead>
                    <tr>
                        <th>선수명</th>
                        <th>포지션</th>
                        <th>경기</th>
                        <th>선발</th>
                        <th>교체</th>
                        <th>타석</th>
                        <th>타수</th>
                        <th>안타</th>
                        <th>1루타</th>
                        <th>2루타</th>
                        <th>3루타</th>
                        <th>홈런</th>
                        <th>타점</th>
                        <th>득점</th>
                        <th>볼넷</th>
                        <th>고의4구</th>
                        <th>사구</th>
                        <th>삼진</th>
                        <th>병살타</th>
                        <th>희비</th>
                        <th>도루</th>
                        <th>도실</th>
                        <th>타율</th>
                        <th>득점권</th>
                        <th>출루율</th>
                        <th>장타율</th>
                        <th>OPS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                    _.map((team === 'home') ? homeBatterRosterRecord : awayBatterRosterRecord, (item, idx) => (
                        item 
                        ?
                        <tr key={item['batterId']+'_'+team+"_"+idx}>
                            <td>{(item['battingOrder'])+'. '+item['batterName']}</td>
                            <td>{item['position']}</td>
                            <td>{item['basicStat_gameCnt']}</td>
                            <td>{item['starter']}</td>
                            <td>{item['substitute']}</td>
                            <td>{item['basicStat_tpa']}</td>
                            <td>{item['basicStat_ab']}</td>
                            <td>{item['basicStat_hit']}</td>
                            <td>{item['basicStat_b1b']}</td>
                            <td>{item['basicStat_b2b']}</td>
                            <td>{item['basicStat_b3b']}</td>
                            <td>{item['basicStat_hr']}</td>
                            <td>{item['basicStat_rbi']}</td>
                            <td>{item['basicStat_r']}</td>
                            <td>{item['basicStat_bb']}</td>
                            <td>{item['basicStat_ibb']}</td>
                            <td>{item['basicStat_hbp']}</td>
                            <td>{item['basicStat_so']}</td>
                            <td>{item['basicStat_gdp']}</td>
                            <td>{item['basicStat_sf']}</td>
                            <td>{item['basicStat_sbSucc']}</td>
                            <td>{item['basicStat_sbFail']}</td>
                            <td>{item['avg']}</td>
                            <td>{item['scoringAvg']}</td>
                            <td>{item['obp']}</td>
                            <td>{item['slg']}</td>
                            <td>{item['ops']}</td>
                        </tr> 
                        :
                        <tr key={team+"_"+idx}>
                            <td colSpan={24}></td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
            <div className="table_pitcher_title">
                선발투수
            </div>
            <div className="table_pitcher">
                <table className="hiball_table">
                    <thead>
                    <tr>
                        <th>선수명</th>
                        <th>출장</th>
                        <th>선발</th>
                        <th>구원</th>
                        <th>승</th>
                        <th>패</th>
                        <th>세</th>
                        <th>홀</th>
                        <th>이닝</th>
                        <th>안타</th>
                        <th>홈런</th>
                        <th>삼진</th>
                        <th>볼넷</th>
                        <th>고의4구</th>
                        <th>사구</th>
                        <th>자책</th>
                        <th>희비</th>
                        <th>ERA</th>
                        <th>whip</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                    _.map((team === 'home') ? homePitcherRosterRecord : awayPitcherRosterRecord, (item, idx) => (
                        item
                        ?
                        <tr key={item['pitcherId']+'_'+team}>
                            <td>{item['pitcherName']}</td>
                            <td>{item['basicStat_gameCnt']}</td>
                            <td>{item['starter']}</td>
                            <td>{item['substitute']}</td>
                            <td>{item['win']}</td>
                            <td>{item['lose']}</td>
                            <td>{item['save']}</td>
                            <td>{item['hold']}</td>
                            <td>{item['ip']}</td>
                            <td>{item['basicStat_hit']}</td>
                            <td>{item['basicStat_hr']}</td>
                            <td>{item['basicStat_so']}</td>
                            <td>{item['basicStat_bb']}</td>
                            <td>{item['basicStat_ibb']}</td>
                            <td>{item['basicStat_hbp']}</td>
                            <td>{item['basicStat_er']}</td>
                            <td>{item['basicStat_sf']}</td>
                            <td>{item['era']}</td>
                            <td>{item['whip']}</td>
                        </tr>
                        :
                        <tr key={team+"_"+idx}>
                            <td colSpan={19}></td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
        }
    </div>
    ))
    }
    </div>
    )
}

export default TodayLineUpBox