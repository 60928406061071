import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGameList, fetchScoreBoardInfo } from '../../../utils/api';

function ScoreBoardBox() {
    const params = useParams();
    const [gameScore, setGameScore] = useState([]);
    const [hitResult, setHitResult] = useState([]);
    const [errorInfo, setErrorInfo] = useState([]);
    const [gameInfo, setGameInfo] = useState({});

    useEffect(() => {
        const gameInfoId = params['gameInfoId'];

        const gameParam = {
            gameInfoIds: gameInfoId,
            gameTypeCodes: 4201
        }

        const scoreInfoPromise = fetchScoreBoardInfo(gameParam);
        const gameInfoPromise = fetchGameList(gameParam);

        Promise.all([
            scoreInfoPromise, gameInfoPromise
        ]).then((response) => {
            setHitResult(response[0]['data']['HITRESULT']);
            setGameScore(_.keyBy(response[0]['data']['SCORE'], 'inning'));
            setErrorInfo(_.keyBy(response[0]['data']['ERROR'], 'HOME_OR_AWAY'));
            setGameInfo(response[1]['data']['RECORD'][0]);
        })
    }, [])

    const InningComponent = (props) => {
        const gameScore = props['gameScore'];
        const idx = props['idx'];
        let inningArr = [];
        for(let num=1; num <= 12; num++) {
            inningArr.push(
                <td key={'inning_'+num}>
                    {gameScore[num] ? gameScore[num][(idx == 0) ? 'awayScore' : 'homeScore'] : '-'}
                </td>
            )
        }

        return inningArr;
    }

    const sumDataCalc = function(data, type) {
        let homeOrAway = (type == 'AWAY') ? 'awayScore' : 'homeScore';
        let sumScore = 0;
        _.forEach(data, (item, idx) => {
            sumScore += item[homeOrAway];
        });

        return sumScore;
    }

    return (
    <>
        <div id="score_board_title">
            {gameInfo['gameDay']} / {gameInfo['gamesStime']}
        </div>
        <table id="score_board_table">
            <thead>
            <tr>
                <th>팀</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
                <th>12</th>
                <th>R</th>
                <th>H</th>
                <th>E</th>
                <th>B</th>
            </tr>
            </thead>
            <tbody>
            {
            _.map(hitResult, (item, idx) => (
                <tr key={'score_'+idx}>
                    <td>{item['batterTeamName']}</td>
                    <InningComponent 
                    gameScore={gameScore}
                    idx={idx}
                    />
                    <td>{sumDataCalc(gameScore, item['homeOrAway'])}</td>
                    <td>{item['hit']}</td>
                    <td>{errorInfo[item['homeOrAway']] ? errorInfo[item['homeOrAway']]['error'] : 0}</td>
                    <td>{item['bb']}</td>
                </tr>
                ))
            }
            </tbody>
        </table>
    </>
    )
}

export default ScoreBoardBox;