import React, { useEffect, useState } from 'react';
import { fetchTeamRanking } from '../../../utils/api';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

function HomeTeamRanking(props) {
    const [teamRanking, setTeamRanking] = useState([]);
    const gameDay = props['lastGameDay'];
    const gameDaySeason = moment(gameDay).format('YYYY');
    const navigate = useNavigate();

    useEffect(() => {
        if(gameDay) {
            const param = {
                seasons: gameDaySeason,
                gameTypeCodes: 4201,
                gameDay
            };

            fetchTeamRanking(param)
            .then((response) => {
                setTeamRanking(Object.values(response['data']['RECORD'])[0]);
            })
        }
    }, [gameDay]);

    const teamInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const teamId = teamObj.split("/")[1];
        const season = moment(teamObj.split("/")[0]).format('YYYY');
        navigate(`/team/${teamId}?season=${season}`)
    }

    if(teamRanking.length === 0) {
        return <div>데이터가 없습니다.</div>
    }

    return (
        <>
        <div className="title">TEAM <span>RANKING</span></div>
        <div className="table">
            <div className="name_wrap">
                <div className="name">
                    <span>KBO 팀순위</span>
                </div>
                <Link to="/ranking/team" className='more'>
                    MORE +
                </Link>
            </div>
            <div className="content">
                <table>
                    <thead>
                    <tr>
                        <th>순위</th>
                        <th colSpan="2">팀</th>
                        <th>경기</th>
                        <th>승</th>
                        <th>무</th>
                        <th>패</th>
                        <th>승률</th>
                        <th>게임차</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                    teamRanking.map((item) => (
                        <tr key={item['teamId']}>
                            <td>{item['ranking']}</td>
                            <td>
                                <img
                                src={require(`/src/assets/image/common/team_logo/logo_${item['teamId']}.png`)}
                                alt={item['teamName']}
                                />
                            </td>
                            <td onClick={teamInfoHandler} id={item['gameDay']+"/"+item['teamId']}
                            style={{cursor: 'pointer'}}
                            >
                                {item['teamName']}
                            </td>
                            <td>{item['gameCnt']}</td>
                            <td>{item['win']}</td>
                            <td>{item['draw']}</td>
                            <td>{item['lose']}</td>
                            <td>{String(item['winRate']).slice(0,5)}</td>
                            <td>{item['winGap'] || '0.0'}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}

export default HomeTeamRanking;