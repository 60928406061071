import React, { useState, useEffect } from "react";
import axios from 'axios';
import { privateApi } from "../../interceptor/axios-interceptor";
import { useParams, useNavigate } from 'react-router-dom';
import EditorBox from "../../components/board/EditorBox";

const BoardPostModify = () => {
    const [postData, setPostData] = useState();

    const [contentKeyWord, setContentKeyWord] = useState('');
    const [contentTitle, setContentTitle] = useState('');
    const [content, setContent] = useState('');
    const [ipAddress, setIpAddress] = useState('');

    const navigate = useNavigate();
    const params = useParams();
    const boardId = params['boardId'];
    const contentId = params['contentId'];

    const modifyPost = () => {
        updatePost().then((res) => {
            if(!res.errCode){
                navigate('/board/' + boardId + '/' + contentId);
            } else {
                alert("수정 권한이 없습니다.");
            }
        });
    };

    const saveContentKeyWord = event => {
        setContentKeyWord(event.target.value);
    };

    const saveContentTitle = event => {
        setContentTitle(event.target.value);
    };

    const saveContent = (value) => {
        setContent(value);
    };

    const cancleModify = () => {
        navigate('/board/' + boardId + '/' + contentId);
    };

    useEffect(() => {
        const fetchData = async () => {
            const post = await getPost();
            if(!post.errCode){
                let data = post.RECORD[0];

                setPostData(data);
                setContentKeyWord(data.contentKeyWord);
                setContentTitle(data.contentTitle);
                setContent(data.content);
            } else {
                setPostData("error");
            }
        };
        fetchData();

        axios.get('https://geolocation-db.com/json/')
        .then((res) => {
            setIpAddress(res.data.IPv4);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPost = () =>
        new Promise((resolve) => {
            const params = {
                contentId: contentId
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/selectPost`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    const updatePost = () =>
    new Promise((resolve) => {
        const params = {
            contentId: contentId,
            contentTitle: contentTitle,
            contentKeyWord: contentKeyWord,
            content: content,
            ipAddress: ipAddress
        };

        privateApi.post(`${process.env.REACT_APP_API_URL}/board/updatePost`, params).then((Response)=>{
            // console.log(Response.data);

            resolve(Response.data);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    return (
        <>
            <div id="postModifyWrap">
                <div id="postWrap">
                    {
                        postData &&
                        (
                            postData !== "error"
                            ?
                            <>
                                <div className="top">
                                    <select name="contentKeyWord" defaultValue={postData.contentKeyWord} onChange={saveContentKeyWord}>
                                        <option value="공지사항">공지사항</option>
                                        <option value="한화 2024">한화 2024</option>
                                    </select>
                                    <input type="text" name="contentTitle" placeholder="제목" defaultValue={postData.contentTitle} onChange={saveContentTitle} />
                                </div>
                                <div className="bottom">
                                    <EditorBox value={postData.content} onChange={saveContent}></EditorBox>
                                </div>
                            </>
                            :
                            <>
                                <div id="contentWrap">
                                    <div>
                                        조회 권한이 없습니다.
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>

                <div id="funcBtnWrap">
                    {
                    postData &&
                    postData !== "error" &&
                    <button className="funcBtn" id="modifyPostBtn" onClick={modifyPost}>
                    수정
                    </button>
                    }
                    <button className="funcBtn" id="listBtn" onClick={cancleModify}>
                    취소
                    </button>
                </div>
            </div>
        </>
    );
};

export default BoardPostModify;