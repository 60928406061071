import { Checkbox, FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: '1', name: '1번'},
    {id: '2', name: '2번'},
    {id: '3', name: '3번'},
    {id: '4', name: '4번'},
    {id: '5', name: '5번'},
    {id: '6', name: '6번'},
    {id: '7', name: '7번'},
    {id: '8', name: '8번'},
    {id: '9', name: '9번'}
];

function BattingOrderCheckboxItem(props) {
    const {value, setValue} = props;
    const [checkIdArr, setCheckIdArr] = useState(value || []);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setValue((prev) => {
            return {...prev, battingOrders: checkIdArr}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="batting-order-checkbox-label"
        sx={{fontSize: 12}}
        >타순</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="batting-order-checkbox-label"
        id="batting-order-checkbox"
        multiple
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        input={<OutlinedInput label="타순" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(rows, (item, idx) => selected.indexOf(item['id']) > -1);
            const selectedName = _.map(selectedArr, 'name');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"battingOrders_"+item['id']} value={item['id']}>
                <Checkbox checked={checkIdArr.indexOf(item['id']) > -1}/>
                <ListItemText primary={item['name']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default BattingOrderCheckboxItem;