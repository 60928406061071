import React, { useState, useEffect } from "react";
import { privateApi } from "../../interceptor/axios-interceptor";
import { useNavigate } from 'react-router-dom';
import { decodeToken } from "../../utils/decodeToken";
import _ from 'lodash';
import RadioFavTeam from '../../components/user/RadioFavTeam';
import user_icon from '../../assets/image/common/user_icon_default.png';

const ChangeUserInfoPage = () => {
    const [memberName, setMemberName] = useState('');
    const [nickName, setNickName] = useState('');
    const [birthYmd, setBirthYmd] = useState('');
    const [gender, setGender] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    // const [mobilePhone, setMobilePhone] = useState('');
    const [favTeamCode, setFavTeamCode] = useState("9999");

    // validation 관리용 state
    const [validMemberName, setValidMemberName] = useState(false);
    const [validNickName, setValidNickName] = useState(false);
    const [validBirthYmd, setValidBirthYmd] = useState(false);
    const [validGender, setValidGender] = useState(false);
    const [validEmailAddress, setValidEmailAddress] = useState(false);
    // const [validMobilePhone, setValidMobilePhone] = useState(false);

    const [messageMemberName, setMessageMemberName] = useState("");
    const [messageNickName, setMessageNickName] = useState("");
    const [messageBirthYmd, setMessageBirthYmd] = useState("");
    const [messageGender, setMessageGender] = useState("");
    const [messageEmailAddress, setMessageEmailAddress] = useState("");
    // const [messageMobilePhone, setMessageMobilePhone] = useState("");

    const favTeams = [
        {teamCode: '1001', teamName: "롯데"},
        {teamCode: '2001', teamName: "한화"},
        {teamCode: '3001', teamName: "LG"},
        {teamCode: '4001', teamName: "NC"},
        {teamCode: '5001', teamName: "SSG"},
        {teamCode: '6001', teamName: "기아"},
        {teamCode: '7001', teamName: "키움"},
        {teamCode: '8001', teamName: "두산"},
        {teamCode: '9001', teamName: "삼성"},
        {teamCode: '10001', teamName: "KT"}
    ];

    let decodedToken = decodeToken();

    const navigate = useNavigate();

    const moveToProfile = () => {
        navigate('/profile');
    }

    const saveMemberName = event => {
        setMemberName(event.target.value.trim());
    };

    const saveNickName = event => {
        setNickName(event.target.value.trim());
    };

    const saveBirthYmd = event => {
        setBirthYmd(event.target.value.trim());
    };

    const saveGender = event => {
        setGender(event.target.value);
    };

    const saveEmailAddress = event => {
        setEmailAddress(event.target.value.trim());
    };

    // const saveMobilePhone = event => {
    //     setMobilePhone(event.target.value.trim());
    // };

    const saveFavTeamCode = event => {
        setFavTeamCode(event.target.value);
    };

    const checkSpacebar = event => {
        if(event.keyCode === 32){
            event.preventDefault();
        }
    }

    const validationReset = () => {
        setValidMemberName(false);
        setValidNickName(false);
        setValidBirthYmd(false);
        setValidGender(false);
        setValidEmailAddress(false);
        // setValidMobilePhone(false);

        setMessageMemberName("");
        setMessageNickName("");
        setMessageBirthYmd("");
        setMessageGender("");
        setMessageEmailAddress("");
        // setMessageMobilePhone("");
    }

    const validationCheck = () => {
        validationReset();
        let checkResult = true;

        if(memberName === ""){
            setValidMemberName(true);
            setMessageMemberName("∙ 이름: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 이름: 2~12자의 한글, 영문 소문자
            const nameRegex = /^[a-z가-힣]{2,12}$/;

            if(!nameRegex.test(memberName)){
                setValidMemberName(true);
                setMessageMemberName("∙ 이름: 2~12자의 한글, 영문 소문자 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(nickName === ""){
            setValidNickName(true);
            setMessageNickName("∙ 닉네임: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 닉네임: 2~12자의 한글, 영문 소문자, 숫자
            const nickNameRegex = /^[a-z0-9가-힣]{2,12}$/;

            if(!nickNameRegex.test(nickName)){
                setValidNickName(true);
                setMessageNickName("∙ 닉네임: 2~12자의 한글, 영문 소문자, 숫자 조합으로 입력해주세요.");
                checkResult = false;
            }
        }

        if(birthYmd === ""){
            setValidBirthYmd(true);
            setMessageBirthYmd("∙ 생년월일: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 생년월일: 날짜 형식 체크
            const dateRegex = /^(19[0-9][0-9]|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

            if(!dateRegex.test(birthYmd)){
                setValidBirthYmd(true);
                setMessageBirthYmd("∙ 생년월일: 올바른 생년월일인지 확인해주세요.");
                checkResult = false;
            }

            // 생년월일: 8자의 숫자
            const birthRegex = /^[0-9]{8}$/;

            if(!birthRegex.test(birthYmd)){
                setValidBirthYmd(true);
                setMessageBirthYmd("∙ 생년월일: 8자의 숫자로 입력해주세요.");
                checkResult = false;
            }
        }

        if(gender === ""){
            setValidGender(true);
            setMessageGender("∙ 성별: 필수 정보입니다.");
            checkResult = false;
        }

        if(emailAddress === ""){
            setValidEmailAddress(true);
            setMessageEmailAddress("∙ 이메일: 필수 정보입니다.");
            checkResult = false;
        } else {
            // 이메일: 이메일 형식 체크
            const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

            if(!emailRegex.test(emailAddress)){
                setValidEmailAddress(true);
                setMessageEmailAddress("∙ 이메일: 올바른 이메일인지 확인해주세요.");
                checkResult = false;
            }
        }

        // if(mobilePhone === ""){
        //     setValidMobilePhone(true);
        //     setMessageMobilePhone("∙ 휴대폰: 필수 정보입니다.");
        //     checkResult = false;
        // } else {
        //     // 휴대폰: 휴대폰 번호 형식 체크
        //     const mobileRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        //     if(!mobileRegex.test(mobilePhone)){
        //         setValidMobilePhone(true);
        //         setMessageMobilePhone("∙ 휴대폰: 올바른 휴대폰 번호인지 확인해주세요.");
        //         checkResult = false;
        //     }
        // }

        return checkResult;
    }

    const onChangeUserInfo = () => {
        if(validationCheck()){
            updateUser()
            .then((res) => {
                let result = res.updateUser;

                if(result > 0){
                    navigate('/profile');
                    alert("회원정보를 변경했습니다.");
                } else {
                    navigate('/error');
                }
            });
        }
    };

    const updateUser = () =>
        new Promise((resolve) => {
            let paramFavTeamCode = favTeamCode;
            if(paramFavTeamCode === "9999") paramFavTeamCode = 0;

            // let paramMobilePhone = mobilePhone.replace(/-/g,'');

            const params = {
                memberName: memberName,
                nickName: nickName,
                birthYmd: birthYmd,
                sex: gender,
                emailAddress: emailAddress,
                // mobilePhone: paramMobilePhone,
                favTeamCode: paramFavTeamCode
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/member/updateUser`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
        });

    useEffect(() => {
        const fetchData = async () => {
            const userData = await selectUser();
            if(userData.selectUser){
                let userInfo = userData.selectUser;
                let inputTagFavTeamCode = userInfo.favTeamCode;
                if(userInfo.favTeamCode === 0) inputTagFavTeamCode = "9999";

                setMemberName(userInfo.memberName);
                setNickName(userInfo.nickName);
                setBirthYmd(userInfo.birthYmd);
                setGender(userInfo.sex);
                setEmailAddress(userInfo.emailAddress);
                // setMobilePhone(userInfo.mobilePhone);
                setFavTeamCode(inputTagFavTeamCode);
            } else {
                navigate('/error');
            }
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectUser = () =>
        new Promise((resolve) => {
            const params = {
                memberNo: decodedToken.memberNo
            };

            privateApi.post(`${process.env.REACT_APP_API_URL}/member/selectUser`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    return (
        <div className="container">
            <div id="joinBox">
                <div id="topBtnWrap">
                    <button className="funcBtn" id="cancleBtn" onClick={moveToProfile}>취소</button>
                </div>
                <form>
                    <div>
                        <div className="label">
                            이름
                        </div>
                        <input type="text" className={validMemberName ? "warning" : ""}
                            name="memberName" value={memberName} maxLength="12" onChange={saveMemberName} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            닉네임
                        </div>
                        <input type="text" className={validNickName ? "warning" : ""}
                            name="nickName" value={nickName} maxLength="12" onChange={saveNickName} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            생년월일
                        </div>
                        <input type="text" className={validBirthYmd ? "warning" : ""}
                            name="birthYmd" placeholder="생년월일 8자리" value={birthYmd} maxLength="8" onChange={saveBirthYmd} onKeyDown={checkSpacebar} />
                    </div>
                    <div>
                        <div className="label">
                            성별
                        </div>
                        <div id="radioWrap" className={validGender ? "warning" : ""}>
                            <label>
                                <input type="radio" name="gender" value="남" onChange={saveGender} checked={gender !== undefined ? gender === "남" : undefined} />
                                남
                            </label>
                            <label>
                                <input type="radio" name="gender" value="여" onChange={saveGender} checked={gender !== undefined ? gender === "여" : undefined} />
                                여
                            </label>
                            <label>
                                <input type="radio" name="gender" value="선택안함" onChange={saveGender} checked={gender !== undefined ? gender === "선택안함" : undefined} />
                                선택안함
                            </label>
                        </div>
                    </div>
                    <div>
                        <div className="label">
                            이메일
                        </div>
                        <input type="text" className={validEmailAddress ? "warning" : ""}
                            name="emailAddress" value={emailAddress} onChange={saveEmailAddress} onKeyDown={checkSpacebar} />
                    </div>
                    {/* <div>
                        <div className="label">
                            휴대폰
                        </div>
                        <input type="text" className={validMobilePhone ? "warning" : ""}
                            name="mobilePhone" value={mobilePhone} onChange={saveMobilePhone} onKeyDown={checkSpacebar} />
                    </div> */}
                    <div id="warningMessage">
                        <div>
                            {messageMemberName}
                        </div>
                        <div>
                            {messageNickName}
                        </div>
                        <div>
                            {messageBirthYmd}
                        </div>
                        <div>
                            {messageGender}
                        </div>
                        <div>
                            {messageEmailAddress}
                        </div>
                        {/* <div>
                            {messageMobilePhone}
                        </div> */}
                    </div>
                    <div id="favTeamLabel">
                        <div className="label">
                            응원팀
                        </div>
                        <div id="favTeamWrap">
                            <div id="favTeamNone">
                                <div>
                                    <input type="radio" name="favTeamCode" value="9999" onChange={saveFavTeamCode} checked={favTeamCode !== undefined ? favTeamCode * 1 === 9999 : undefined} />
                                    없음
                                </div>
                                <div>
                                    <img src={user_icon} alt="userIcon" id="uesrIcon" />
                                </div>
                            </div>
                            <div id="favTeamList">
                                {
                                _.map(favTeams, (item, idx) => (
                                    <RadioFavTeam key={idx} teamCode={item.teamCode} teamName={item.teamName} action={saveFavTeamCode} favTeamCode={favTeamCode} />
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </form>

                <button id="joinBtn" onClick={onChangeUserInfo}>
                    변경
                </button>
            </div>
        </div>
    );
};

export default ChangeUserInfoPage;