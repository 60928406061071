import React, { useState, useEffect } from "react";
import axios from 'axios';
import { privateApi } from "../../interceptor/axios-interceptor";
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import EditorBox from "../../components/board/EditorBox";

const BoardPostWrite = () => {
    const {decodedToken}  = useOutletContext();
    const [contentKeyWord, setContentKeyWord] = useState('공지사항');
    const [contentTitle, setContentTitle] = useState('');
    const [content, setContent] = useState('');
    const [ipAddress, setIpAddress] = useState('');

    const navigate = useNavigate();
    const params = useParams();
    const boardId = params['boardId'];

    const moveToList = () => {
      navigate('/board/' + boardId);
    };

    useEffect(() => {
        axios.get('https://geolocation-db.com/json/')
        .then((res) => {
            setIpAddress(res.data.IPv4);
        });
    }, []);

    const writePost = () => {
        insertPost().then((res) => {
            if(!res.errCode){
                navigate('/board/' + boardId);
            } else {
                alert("작성 권한이 없습니다.");
            }
        });
    };

    const saveContentKeyWord = event => {
        setContentKeyWord(event.target.value);
    };

    const saveContentTitle = event => {
        setContentTitle(event.target.value);
    };

    const saveContent = (value) => {
        setContent(value);
    };

    const insertPost = () =>
    new Promise((resolve) => {
        const params = {
            boardId: boardId,
            pContentId: "0",
            rContentId: "0",
            contentTitle: contentTitle,
            contentKeyWord: contentKeyWord,
            content: content,
            writeMemberNo: decodedToken.memberNo,
            writeNickName: decodedToken.nickname,
            ipAddress: ipAddress
        };

        privateApi.post(`${process.env.REACT_APP_API_URL}/board/insertPost`, params).then((Response)=>{
            // console.log(Response.data);

            resolve(Response.data);
        }).catch((Error)=>{
            console.log(Error);
        })
    });

    return (
        <>
            <div id="postWriteWrap">
                <div id="postWrap">
                    <div className="top">
                        <select name="contentKeyWord" value={contentKeyWord} onChange={saveContentKeyWord}>
                            <option value="공지사항">공지사항</option>
                            <option value="한화 2024">한화 2024</option>
                        </select>
                        <input type="text" name="contentTitle" placeholder="제목" value={contentTitle} onChange={saveContentTitle} />
                    </div>
                    <div className="bottom">
                        <EditorBox value={content} onChange={saveContent}></EditorBox>
                    </div>
                </div>

                <div id="funcBtnWrap">
                    <button className="funcBtn" id="writePostBtn" onClick={writePost}>
                    등록
                    </button>
                    <button className="funcBtn" id="listBtn" onClick={moveToList}>
                    취소
                    </button>
                </div>
            </div>
        </>
    );
};

export default BoardPostWrite;