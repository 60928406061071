import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchTeamWinResult } from '../../utils/api';
import _ from 'lodash';
import styled from 'styled-components';
const TdStyled = styled.td`
padding: 6px !important;
`;

function TeamVersusBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [teamRecord, setTeamRecord] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const teamId = params['teamId'];
        const teamParam = {
            seasons: season,
            gameTypeCodes: 4201,
            teamIds: teamId,
        }

        const teamRankingPromise = fetchTeamWinResult(teamParam);

        teamRankingPromise.then((response) => {
            const teamVsRecord = _.sortBy(response['data']['RECORD'], ['winRate']).reverse();
            setTeamRecord(teamVsRecord);
        })
    }, [])

    const teamClickHandler = (event) => {
        const teamId = event.currentTarget.id;
        window.location.href = `/team/${teamId}?season=${season}`;
    }

    return (
    <div>
    <div className="trackmanArea">
        <div className="teamTitle">{'팀 상대전적'}</div>
    </div>
    <div style={{padding: '0px 5px'}}>
        <table className='hiball_table' style={{tableLayout:'fixed'}}>
            <thead>
                <tr>
                    <th>팀명</th>
                    <th>승-무-패</th>
                    <th>승률</th>
                </tr>
            </thead>
            <tbody>
            {
            _.map(teamRecord, (item, idx) => (
                <tr key={item['oppTeamId']}>
                    <TdStyled>
                        <span
                        id={item['oppTeamId']}
                        className="link"
                        onClick={teamClickHandler}
                        >
                        {item['oppTeamName']}
                        </span>
                    </TdStyled>
                    <TdStyled>{item['win']+"승-"+item['draw']+"무-"+item['lose']+"패"}</TdStyled>
                    <TdStyled>{(item['winRate']*1).toFixed(3)}</TdStyled>
                </tr>
            ))
            }
            </tbody>
        </table>
    </div>
    </div>
    )
}

export default TeamVersusBox;