import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchGameList, fetchTeamRanking } from '../../../utils/api';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import weekDaysCode from '../../../utils/code/weekDaysCode';
const weekDaysArr = weekDaysCode();

function GamePlanList(props) {
    const teamId = props['teamCode'];
    const {dateType} = props;
    const gameDay = moment(props['gameDay']).format('YYYY-MM-DD');
    const [totalGameSchedule, setTotalGameSchedule] = useState([]);
    const [gameSchedule, setGameSchedule] = useState({});
    const [days, setDays] = useState([]);
    let seasonMonth = moment(props['gameDay']).format('YYYY-MM');
    const navigate = useNavigate();

    useEffect(() => {
        const dateSeason = moment(gameDay).format('YYYY');
        const dateMonth = moment(gameDay).format('M');

        const fullDate = new Date(dateSeason, dateMonth, 0);
        const dayString = fullDate.getDate();
        const dayArr = [];

        for(let i=1; i <= dayString; i++ ) {
            if((i+'').length == 1) {
                dayArr.push('0'+i);
            } else {
                dayArr.push(i);
            }
        }
        
        if(dateType === 'sort_desc') {
            dayArr.reverse();
        }

        setDays(dayArr);

        const param = {
            gameTypeCodes: 4201,
            seasons: moment(gameDay).format('YYYY'),
            months: moment(gameDay).format('MM')
        }

        fetchGameList(param)
        .then((response) => {
            let gameListData = response['data']['RECORD'];
            setTotalGameSchedule(gameListData);
        })
    }, [gameDay, dateType])

    useEffect(() => {
        let filterData = totalGameSchedule;
        if(teamId !== 'all') {
            filterData = _.filter(filterData, (item) => (item['awayTeamId'] === teamId || item['homeTeamId'] === teamId));
        } 

        const gameDayValue = _.groupBy(_.sortBy(filterData, 'gameDay'), 'gameDay');
        setGameSchedule(gameDayValue);
    }, [totalGameSchedule, teamId])

    const gameInfoIdClickHandler = (e) => {
        const id = e.currentTarget.id;
        navigate(`/gameschedule/result/${id.split('_')[1]}`);
    }
    
    function weekDayCalc(weekDay) {
        return weekDaysArr.find((item) => item['weekDay'] === weekDay*1 )['weekDayName'];
    }

    function pitcherResultRender(data, key) {
        if(data['awayPitcherResult'] && data['homePitcherResult']) {
            const awayPitcherResult = JSON.parse(data['awayPitcherResult']);
            const homePitcherResult = JSON.parse(data['homePitcherResult']);

            let pitcherName = "";
            if(awayPitcherResult[key] === '-' && homePitcherResult[key] === '-') {
                pitcherName = "";
            } else {                
                pitcherName = (awayPitcherResult[key] !== '-') ? awayPitcherResult[key] : homePitcherResult[key];
                if(key.indexOf('win') > -1) {
                    pitcherName = <><div className="win_div">승</div>{pitcherName}</>;
                } else if(key.indexOf('lose') > -1) {
                    pitcherName = <><div className="lose_div">패</div>{pitcherName}</>;
                } else if(key.indexOf('save') > -1) {
                    pitcherName = <><div className="save_div">세</div>{pitcherName}</>;
                }
            }

            return pitcherName;
        } else {
            return ''
        }
    }

    function scoreCalc(item) {
        const awayTeamScore = item['awayTeamScore']*1;
        const homeTeamScore = item['homeTeamScore']*1;

        if(awayTeamScore > homeTeamScore) {
            return <><span>{awayTeamScore}</span> : <span style={{opacity: 0.5}}>{homeTeamScore}</span></>
        } else if(awayTeamScore < homeTeamScore) {
            return <><span style={{opacity: 0.5}}>{awayTeamScore}</span> : <span>{homeTeamScore}</span></>
        } else if(item['gameStatus']*1 === 4199){
            return '-';
        } else {   
            return <><span>{awayTeamScore}</span> : <span>{homeTeamScore}</span></>
        }
    }

    function startPitcherResultRender(item, type) {
        const awayTeamScore = item['awayTeamScore']*1;
        const homeTeamScore = item['homeTeamScore']*1;
        const {ingStatus} = item;

        if(type === 'away') {
            let flag = false;
            if(awayTeamScore < homeTeamScore && ingStatus === 'DONE') {
                flag = true;
            }
            return (
                <>
                <td className={flag ? 'lose_td' : ''}>{item['awayStartPitcherName']}</td>
                <td className={flag ? 'lose_td' : ''}>{item['awayTeamName']}</td>
                <td className={flag ? 'lose_td' : ''}>
                    <img
                    src={require(`/src/assets/image/common/team_logo/logo_${item['awayTeamId']}.png`)}
                    alt={item['awayTeamName']}
                    />
                </td>
                </>
            )
        } else {
            let flag = false;
            if(awayTeamScore > homeTeamScore && ingStatus === 'DONE') {
                flag = true;
            }
            return (
                <>
                <td className={flag ? 'lose_td' : ''}>
                    <img
                    src={require(`/src/assets/image/common/team_logo/logo_${item['homeTeamId']}.png`)}
                    alt={item['homeTeamName']}
                    />
                </td>
                <td className={flag ? 'lose_td' : ''}>{item['homeTeamName']}</td>
                <td className={flag ? 'lose_td' : ''}>{item['homeStartPitcherName']}</td>
                </>
            )
        }
    }

    return (
        <>
        <table id="game_calendar_table">
            <colgroup>
                <col width="12%"></col>
                <col></col>
                <col></col>
                <col width="7%"></col>
                <col width="4%"></col>
                <col width="3%"></col>
                <col width="7%"></col>
                <col width="3%"></col>
                <col width="4%"></col>
                <col width="7%"></col>
                <col width="10%"></col>
                <col width="10%"></col>
                <col width="10%"></col>
                <col></col>
            </colgroup>
            <thead>
                <tr>
                    <th>날짜</th>
                    <th>시간</th>
                    <th>구장</th>
                    <th colSpan={3}>원정선발투수</th>
                    <th>스코어</th>
                    <th colSpan={3}>홈선발투수</th>
                    <th>승리투수</th>
                    <th>패전투수</th>
                    <th>세이브</th>
                    <th>결과/분석</th>
                </tr>
            </thead>
            {
            days.map((day, dayIdx) => (
                gameSchedule[seasonMonth+"-"+day] ?
                <tbody key={"gameTr_"+day}>
                {
                gameSchedule[seasonMonth+"-"+day].map((item, idx) => (
                    <tr key={"gameDay_"+day+"_"+item['gameInfoId']}>
                    {
                    idx === 0 && 
                    <td 
                    id={item['gameDay']}
                    rowSpan={`${gameSchedule[seasonMonth+"-"+day]['length']}`}>
                        {item['gameDay']} ({weekDayCalc(item['weekDay'])})
                    </td>
                    }
                    {
                    item['gameStatus']*1 === 4199
                    ?
                    <td colSpan={2} className="color_red">우천취소</td>
                    :
                    <>
                    <td key={"gameDay_"+dayIdx+"_"+idx}>
                        {item['gamesStime'] ? item['gamesStime'].slice(0, 5) : '00:00'}
                    </td>
                    {
                    item['doubleHeaderYn'] !== "N" ?
                    <td>{item['stadium']+"(DH"+item['doubleHeaderYn']+")"}</td>
                    :
                    <td>{item['stadium']}</td>
                    }
                    </>
                    }
                    {
                    startPitcherResultRender(item, 'away')
                    }
                    <td>{scoreCalc(item)}</td>
                    {
                    startPitcherResultRender(item, 'home')
                    }
                    <td>{pitcherResultRender(item, 'win_pitcher_name')}</td>
                    <td>{pitcherResultRender(item, 'lose_pitcher_name')}</td>
                    <td>{pitcherResultRender(item, 'save_pitcher_name')}</td>
                    <td>
                    {
                        item['ingStatus'] === 'DONE' ?
                        <div id={"result_"+item['gameInfoId']}
                        className="result_btn"
                        onClick={gameInfoIdClickHandler}>
                            경기결과
                        </div> :
                        <></>
                    }
                    </td>
                    </tr>
                ))
                }
                </tbody>
                :
                <tbody key={'gameTr_'+day}>
                <tr key={'gameDay_'+day}>
                    <td id={seasonMonth+"-"+day}
                    >{seasonMonth+"-"+day} ({moment(seasonMonth+"-"+day).format('dd')})</td>
                    <td colSpan={13}>프로야구 경기가 없습니다</td>
                </tr>
                </tbody>
            ))
            }
        </table>
        </>
    )
}

export default GamePlanList;