function batterTooltip() {
    let tooltipObj = {};

    tooltipObj.tpa = '타석 = 타수 + 볼넷 + 사구 + 희생플라이 + 희생번트';
    tooltipObj.pa = '타석 = 타수 + 볼넷 + 사구 + 희생플라이 + 희생번트';
    tooltipObj.ab = '타수';
    tooltipObj.b1b = '1루타';
    tooltipObj.b2b = '2루타';
    tooltipObj.b3b = '3루타';
    tooltipObj.hr = '홈런';
    tooltipObj.hit = '안타';
    tooltipObj.so = '삼진';
    tooltipObj.bb = '볼넷';
    tooltipObj.bbAddIbb  = "볼넷 (고의4구 포함)"
    tooltipObj.ibb = '고의4구';
    tooltipObj.hbp = '몸 맞는 볼';
    tooltipObj.rbi = '타점';
    tooltipObj.r = '득점';
    tooltipObj.sf = '희생 플라이';
    tooltipObj.stealCnt = '도루 성공';
    tooltipObj.failStealCnt = '도루 실패';
    tooltipObj.avg = '타율 = 안타 / 타수';
    tooltipObj.slg = '장타율 = {1루타 + (2*2루타) + (3*3루타) + (4*홈런)} / 타수';
    tooltipObj.obp = '출루율 = (안타 + 볼넷 + 사구) / (타수 + 볼넷 + 사구 + 희생 플라이)';
    tooltipObj.ops = 'OPS = 출루율 + 장타율';
    tooltipObj.leftPitcherAvg = '좌투수 상대 타율';
    tooltipObj.rightPitcherAvg = '우투수 상대 타율';
    tooltipObj.leftPitcherXh = '좌투수 상대 장타';
    tooltipObj.rightPitcherXh = '우투수 상대 장타';
    tooltipObj.kRate = '삼진비율 = 삼진 / 타석';
    tooltipObj.bbRate = '볼넷비율 = 볼넷 / 타석';
    tooltipObj.swingRate = '스윙율 = 스윙 / 투구수';
    tooltipObj.contactRate = '컨택율 = 컨택 / 스윙';
    tooltipObj.standRate = '스탠딩율 = 스탠드/ 투구수';
    tooltipObj.rc = '득점창출력';
    tooltipObj.rc27 = '득점창출력/27';
    tooltipObj.wrc = '조정 득점 창출력';
    tooltipObj.spd = 'spd';
    tooltipObj.iso = '순수 장타율';
    tooltipObj.woba = 'woba';
    tooltipObj.xr = `추정득점`;
    tooltipObj.gdp = '병살';
    tooltipObj.lookingSoRate = '루킹 삼진율 = 루킹 삼진 / 삼진';
    tooltipObj.swingSoRate = '스윙 삼진율 = 스윙 삼진 / 삼진';
    tooltipObj.lookingSo = '루킹 삼진';
    tooltipObj.swingSo= '스윙 삼진';
    /***타자 상세*******/
    tooltipObj.gameCnt = "출전"
    tooltipObj.pitchingCnt = "총 투구"
    tooltipObj.tpaDivPitch = "타석 당 투구수 = 투구수 / 타석 "
    tooltipObj.strikeRate = "전체 투구 중 스트라이크 비율 =  스트라이크 판정 투구 / 전체 투구"
    tooltipObj.ballRate = "전체 투구 중 볼 비율 = 볼 판정 투구 / 전체 투구"
    tooltipObj.zoneStrikeRate = "투구 중 스트라이크존 투구 비율 =  스트라이크존 투구 / 전체 투구"
    tooltipObj.zoneBallRate = "투구중 볼존 비율 = 볼존 투구 / 전체 투구"
    tooltipObj.isoD = "순수 출루율 = 출루율 - 타율"
    tooltipObj.isoP = "순수 장타율 = 장타율 - 타율"
    tooltipObj.bbRate = "타석에서 볼넷 비율 = (볼넷+고의4구) / 타석"
    tooltipObj.kRate = "타석에서 삼진 비율 = 삼진 / 타석"
    tooltipObj.bbKRate = "BB/K = (볼넷+고의4구) / 삼진"
    tooltipObj.hrRate = "타석에서 홈런 비율 = 홈런 / 타석"
    tooltipObj.babip = "인플레이 타구 비율 = (안타-홈런)/(타수-삼진-홈런+희비) "
    tooltipObj.spd = "Spd "

    /******  타자 타석 *******/
    tooltipObj.strikeStandRate = "스트라이크 판정 투구 중 스탠딩 비율 = 스트라이크 스탠딩 / 스트라이크 판정 투구"
    tooltipObj.strikeSwingRate = "스트라이크 판정 투구 중 스윙 비율 = 스트라이크 스윙 / 스트라이크 판정 투구"
    tooltipObj.strikeFoulRate = "스트라이크 판정 투구 중 파울 비율 = 스트라이크 파울 / 스트라이크 판정 투구"
    tooltipObj.strikeInplayRate = "스트라이크 판정 투구 중 인플레이 비율 = 스트라이크 인플레이 / 스트라이크 판정 투구"
    tooltipObj.whiffRate = "전체 스윙 중 헛스윙 비율 = 헛스윙 / 전체 스윙"
    tooltipObj.swStr = "전체 투구 중 헛스윙 비율 = 헛스윙 / 전체 투구"
    tooltipObj.oStandRate = "아웃존 투구 중 스탠딩 비율 = 아웃존 스탠딩 / 아웃존 투구"
    tooltipObj.zStandRate = "스트라이크존 투구 중 스탠딩 비율 = 스트라이크존 스탠딩 / 스트라이크존 투구"
    tooltipObj.standRate = "전체 투구 중 스탠딩 비율 = 스탠딩 / 전체 투구"
    tooltipObj.oSwingRate = "아웃존 투구 중 스윙 비율 = 아웃존 스윙 / 아웃존 투구"
    tooltipObj.zSwingRate = "스트라이크존 투구 중 스윙 비율 = 스트라이크존 스윙 / 스트라이크존 투구"
    tooltipObj.swingRate = "전체 투구 중 스윙 비율 = 스윙 / 전체 투구"
    tooltipObj.oContactRate = "아웃존 스윙 중 컨택 비율 = 아웃존 컨택 / 아웃존 스윙"
    tooltipObj.zContactRate = "스트라이크존 스윙 중 컨택 비율 = 스트라이크존 컨택 / 스트라이크존 스윙"
    tooltipObj.contactRate = "전체 스윙 중 컨택 비율 = 컨택 / 전체 스윙"
    tooltipObj.zoneRate = "스트라이크존 투구율 = 스트라이크존 투구 / 전체 투구"
    tooltipObj.firstStrikeRate = "초구 스트라이크 비율 = 스트라이크 판정 초구/ 전체 초구"

    /******  타자 타구 *******/
    tooltipObj.go = "땅볼 아웃"
    tooltipObj.fo = "플라이 아웃 + 라인드라이브 아웃"
    tooltipObj.groundBall = "땅볼 타구"
    tooltipObj.flyBall = "플라이 타구"
    tooltipObj.lineDriveBall = "라인드라이 타구"
    tooltipObj.gdDivFly = "땅볼 플라이 비율 = 땅볼 / 뜬공"
    tooltipObj.gdRate = "타구 중 땅볼 비율 = 땅볼 / (땅볼 + 뜬공 + 라인드라이브)"
    tooltipObj.flyRate = "타구 중 뜬공 비율 = 뜬공 / (땅볼 + 뜬공 + 라인드라이브)"
    tooltipObj.lineRate = "타구 중 라인드라이브 비율 = 라인드라이브 / (땅볼 + 뜬공 + 라인드라이브)"
    tooltipObj.rankArate = "랭크 타구 중 A타구 비율 =  랭크 A / (랭크A + 랭크B + 랭크C)"
    tooltipObj.rankBrate = "랭크 타구 중 B타구 비율 =  랭크 B / (랭크A + 랭크B + 랭크C)"
    tooltipObj.rankCrate = "랭크 타구 중 C타구 비율 =  랭크 C / (랭크A + 랭크B + 랭크C)"


        /******  타자 클러치 *******/
    tooltipObj.scoringPosTpa = "득점권 타석"
    tooltipObj.scoringPosAb = "득점권 타수"
    tooltipObj.scoringPosHit = "득점권 안타"
    tooltipObj.scoringPaRate = "득점권 타석 비율 = 구권 타석 / 타석"
    tooltipObj.scoringAvg = "득점권 타율 = 특점권 안타 / 득점권 타수"
    tooltipObj.pureRbi = "순수 타점 = 타점 - 홈런"
    tooltipObj.beforeRunner = "타석에서 총 주자 수"
    tooltipObj.scoringHitRate = "득점권 안타 비율 = 득점권 안타 / 득점권 타석"
    tooltipObj.clutchHit = "클러치 안타 = 득점권 안타 비율 - 안타 비율"
    tooltipObj.scoringHrRate = "득점권 홈런 비율 = 득점권 홈런 / 득점권 타석"
    tooltipObj.clutchHr = "클러치 홈런 = 득점권 홈런 비율 - 홈런 비율"
    tooltipObj.scoringSoRate = "득점권 삼진 비율 = 득점권 삼진 / 득점권 타석"
    tooltipObj.clutchK = "클러치 삼진 = 득점권 삼진 비율 - 삼진 비율"
    tooltipObj.scoringBbRate = "득점권 볼넷 비율 = 득점권 볼넷 / 득점권 타석"
    tooltipObj.clutchBb = "클러치 볼넷 = 득점권 볼넷 비율 - 볼넷 비율"
        
    /******  타자 WAR *******/        


    tooltipObj.woba = '(wBB*uBB + wHBP*HBP + w1H*1B + w2H*2B + w3H*3B + wHR*HR) / (AB + BB - IBB + SF + HBP)';
    tooltipObj.wraa = '((리그 wOBA - wOBA)/wOBA Scale)*PA';
    tooltipObj.wrcPlus = '(((wRAA/PA)/(리그 득점/리그 PA))+1)*100';
    tooltipObj.parkOWAR = '파크팩터를 적용한 oPWAR : (wRAA*파크팩터)+ 대체 RUN + wSB)/RunPerWin(1승당 필요한 득점)';
    tooltipObj.oWAR = '(wRAA+ 대체 RUN+ wSB)/RunPerWin(1승당 필요한 득점)';
    tooltipObj.parkWARBAT = '파크팩터를 적용한 WAR_BAT : ((wRAA*파크팩터)+ 대체 RUN)/RunPerWin(1승당 필요한 득점)';
    tooltipObj.warbat = '(wRAA+ 대체 RUN)/RunPerWin(1승당 필요한 득점)';
    tooltipObj.wSb = ' (도루 * runSB) + (도실 * runCS) – (리그 SB * (1B + BB + HBP – IBB)), 리그 SB : (리그 도루 * runSB + 리그 도실 * runCS) / (리그 1B + 리그 BB + 리그 HBP – 리그 IBB)';
    tooltipObj.replaceRun = '대체 RUN : (20 * 타석) / 600';

    /******  타자 득점 생산력 *******/        
    tooltipObj.re24Bat = '타격 이벤트에서 발생한 RE24 합';
    tooltipObj.wpa = '타격 이벤트에서 발생한 WE 합';
    tooltipObj.wpaPlus = '타격 이벤트에서 발생한 WE 플러스 합';
    tooltipObj.wpaMinus = '타격 이벤트에서 발생한 WE 마이너스 합';
    tooltipObj.pLi = '타석당 평균 Leverage Index'
    tooltipObj.phLi = '대타 평균 Leverage Index'
    tooltipObj.ph = '대타'
    tooltipObj.wpaLi = "매타석 WPA/LI 합"
    tooltipObj.clutch = "전체 WPA/pLI - WPA/LI값"


        /******  타자 팀배팅 *******/
    tooltipObj.remainBaseCnt = "잔루"
    tooltipObj.remainBasePerPa = "타석 당 잔루율  = 잔루 / 타석"
    tooltipObj.sb  = "희생 번트"    
    tooltipObj.b1bBase12Rate = '1루타 시 1루에서 2루 진루율';
    tooltipObj.b1bBase13Rate = '1루타 시 1루에서 3루 진루율';
    tooltipObj.b1bBase1hRate = '1루타 시 1루에서 홈 진루율';
    tooltipObj.b1bBase23Rate = '1루타 시 2루에서 3루 진루율';
    tooltipObj.b1bBase2hRate = '1루타 시 2루에서 홈 진루율';
    tooltipObj.b2bBase13Rate = '2루타 시 1루에서 3루 진루율';
    tooltipObj.b2bBase1hRate = '2루타 시 1루에서 홈 진루율';
    tooltipObj.b2bBase2hRate = '2루타 시 2루에서 홈 진루율';
    tooltipObj.goBase12Rate = '땅볼아웃 시 1루에서 2루 진루율';
    tooltipObj.goBase13Rate = '땅볼아웃 시 1루에서 3루 진루율';
    tooltipObj.goBase23Rate = '땅볼아웃 시 2루에서 3루 진루율';
    tooltipObj.goBase2hRate = '땅볼아웃 시 2루에서 홈 진루율';
    tooltipObj.goBase3hRate = '땅볼아웃 시 3루에서 홈 진루율';
    tooltipObj.foBase12Rate = '플라이아웃 시 1루에서 2루 진루율';
    tooltipObj.foBase13Rate = '플라이아웃 시 1루에서 3루 진루율';
    tooltipObj.foBase23Rate = '플라이아웃 시 2루에서 3루 진루율';
    tooltipObj.foBase2hRate = '플라이아웃 시 2루에서 홈 진루율';
    tooltipObj.foBase3hRate = '플라이아웃 시 3루에서 홈 진루율';

    tooltipObj.b1bBase12 = '1루타 시 1루에서 2루 진루 횟수';
    tooltipObj.b1bBase13 = '1루타 시 1루에서 3루 진루 횟수';
    tooltipObj.b1bBase1h = '1루타 시 1루에서 홈 진루 횟수';
    tooltipObj.b1bBase23 = '1루타 시 2루에서 3루 진루 횟수';
    tooltipObj.b1bBase2h = '1루타 시 2루에서 홈 진루 횟수';
    tooltipObj.b2bBase13 = '2루타 시 1루에서 3루 진루 횟수';
    tooltipObj.b2bBase1h = '2루타 시 1루에서 홈 진루 횟수';
    tooltipObj.b2bBase2h = '2루타 시 2루에서 홈 진루 횟수';
    tooltipObj.goBase12 = '땅볼아웃 시 1루에서 2루 진루 횟수';
    tooltipObj.goBase13 = '땅볼아웃 시 1루에서 3루 진루 횟수';
    tooltipObj.goBase23 = '땅볼아웃 시 2루에서 3루 진루 횟수';
    tooltipObj.goBase2h = '땅볼아웃 시 2루에서 홈 진루 횟수';
    tooltipObj.goBase3h = '땅볼아웃 시 3루에서 홈 진루 횟수';
    tooltipObj.foBase12 = '플라이아웃 시 1루에서 2루 진루 횟수';
    tooltipObj.foBase13 = '플라이아웃 시 1루에서 3루 진루 횟수';
    tooltipObj.foBase23 = '플라이아웃 시 2루에서 3루 진루 횟수';
    tooltipObj.foBase2h = '플라이아웃 시 2루에서 홈 진루 횟수';
    tooltipObj.foBase3h = '플라이아웃 시 3루에서 홈 진루 횟수';

    /******  타자 주루 *******/
    tooltipObj.stealCnt = "도루"
    tooltipObj.failStealCnt = "도실"
    tooltipObj.stealRate = "도루 성공율 = 도루 / (도루 + 도실)"
    tooltipObj.runCount = "1루에서 도루 기회"
    tooltipObj.runRate = "도루 기회 중 도루 시도율  = (1루에서의 도루 + 1루에서의 도실) / 1루에서의 도루 기회"
    tooltipObj.leftHandStealCnt = "좌완 투수 상대 도루"
    tooltipObj.leftHandFailStealCnt = "좌완 투수 상대 도실"
    tooltipObj.leftHandStealRate = "좌완 투수 상대 도루 성공율 = 좌완 투수 상대 도루 /(좌완 투수 상대 도루 + 좌완 투수 상대 도실)"
    tooltipObj.base1StealCnt = "2루 도루"
    tooltipObj.base1FailStealCnt = "2루 도실"
    tooltipObj.base1StealRate = "2루 도루 성공율 = 2루 도루 / (2루 도루 + 2루 도실)"
    tooltipObj.base2StealCnt = "3루 도루"
    tooltipObj.base2FailStealCnt = "3루 도실"
    tooltipObj.base2StealRate = "3루 도루 성공율 = 3루 도루 / (3루 도루 + 3루 도실)"
    tooltipObj.base3StealCnt = "홈 도루"
    tooltipObj.base3FailStealCnt = "홈 도실"
    tooltipObj.base3StealRate = "홈 도루 성공율 = 홈 도루 / (홈 도루 + 홈 도실)"
    tooltipObj.checkOutCnt = "견제사"
    tooltipObj.runnerOutCnt = "주루사";
    tooltipObj.wildPitchBaseMoveCnt = "폭투 진루"
    tooltipObj.passedBallBaseMoveCnt = "폭일 진루"

    tooltipObj.addRunner = '추가 진루 성공(1H 1루 -> 3루) + (1H 2루 -> 홈) + (2H 1루 -> 홈)';
    tooltipObj.addRunnerCnt = '추가 진루 기회 (1H 1루 + 1H 2루 + 2H 1루)';
    tooltipObj.addRunnerRate = '추가 진루율 (추가 진루 성공 / 추가 진루 기회)';

    /******  타자 구종 *******/
    tooltipObj.totalPitch = "총 투구수"
    tooltipObj['fastBallValue'] = '직구 구종가치'; //
    tooltipObj['fastBallValueDivOneHundred'] = '직구 구종가치/100';
    tooltipObj['fastBallPitchRate'] = '직구 투구%';
    tooltipObj['fastBallCnt'] = '직구 투구수';
    tooltipObj['fastBallAvgSpeed'] = '직구 평균구속';
    tooltipObj['fastBallMaxSpeed'] = '직구 최고구속';

    tooltipObj['twoSeamValue'] = '투심 구종가치';
    tooltipObj['twoSeamValueDivOneHundred'] = '투심 구종가치/100';
    tooltipObj['twoSeamPitchRate'] = '투심 투구%';
    tooltipObj['twoSeamCnt'] = '투심 투구수';
    tooltipObj['twoSeamAvgSpeed'] = '투심 평균구속';
    tooltipObj['twoSeamMaxSpeed'] = '투심 최고구속';

    tooltipObj['cutterValue'] = '커터 구종가치';
    tooltipObj['cutterValueDivOneHundred'] = '커터 구종가치/100';
    tooltipObj['cutterPitchRate'] = '커터 투구%';
    tooltipObj['cutterCnt'] = '커터 투구수';
    tooltipObj['cutterAvgSpeed'] = '커터 평균구속';
    tooltipObj['cutterMaxSpeed'] = '커터 최고구속';

    tooltipObj['curveValue'] = '커브 구종가치';
    tooltipObj['curveValueDivOneHundred'] = '커브 구종가치/100';
    tooltipObj['curvePitchRate'] = '커브 투구%';
    tooltipObj['curveCnt'] = '커브 투구수';
    tooltipObj['curveAvgSpeed'] = '커브 평균구속';
    tooltipObj['curveMaxSpeed'] = '커브 최고구속';

    tooltipObj['sliderValue'] = '슬라이더 구종가치';
    tooltipObj['sliderValueDivOneHundred'] = '슬라이더 구종가치/100';
    tooltipObj['sliderPitchRate'] = '슬라이더 투구%';
    tooltipObj['sliderCnt'] = '슬라이더 투구수';
    tooltipObj['sliderAvgSpeed'] = '슬라이더 평균구속';
    tooltipObj['sliderMaxSpeed'] = '슬라이더 최고구속';

    tooltipObj['changeUpValue'] = '체인지업 구종가치';
    tooltipObj['changeUpValueDivOneHundred'] = '체인지업 구종가치/100';
    tooltipObj['changeUpPitchRate'] = '체인지업 투구%';
    tooltipObj['changeUpCnt'] = '체인지업 투구수';
    tooltipObj['changeUpAvgSpeed'] = '체인지업 평균구속';
    tooltipObj['changeUpMaxSpeed'] = '체인지업 최고구속';

    tooltipObj['folkValue'] = '포크 구종가치';
    tooltipObj['folkValueDivOneHundred'] = '포크 구종가치/100';
    tooltipObj['folkPitchRate'] = '포크 투구%';
    tooltipObj['folkCnt'] = '포크 투구수';
    tooltipObj['folkAvgSpeed'] = '포크 평균구속';
    tooltipObj['folkMaxSpeed'] = '포크 최고구속';

    tooltipObj['nuckleValue'] = '너클볼 구종가치';
    tooltipObj['nuckleValueDivOneHundred'] = '너클볼 구종가치/100';
    tooltipObj['nucklePitchRate'] = '너클볼 투구%';
    tooltipObj['nuckleCnt'] = '너클볼 투구수';
    tooltipObj['nuckleAvgSpeed'] = '너클볼 평균구속';
    tooltipObj['nuckleMaxSpeed'] = '너클볼 최고구속';

    tooltipObj['sinkerValue'] = '싱커 구종가치';
    tooltipObj['sinkerValueDivOneHundred'] = '싱커 구종가치/100';
    tooltipObj['sinkerPitchRate'] = '싱커 투구%';
    tooltipObj['sinkerCnt'] = '싱커 투구수';
    tooltipObj['sinkerAvgSpeed'] = '싱커 평균구속';
    tooltipObj['sinkerMaxSpeed'] = '싱커 최고구속';

    tooltipObj['sluveValue'] = '슬러브 구종가치';
    tooltipObj['sluveValueDivOneHundred'] = '슬러브 구종가치/100';
    tooltipObj['sluvePitchRate'] = '슬러브 투구%';
    tooltipObj['sluveCnt'] = '슬러브 투구수';
    tooltipObj['sluveAvgSpeed']= '슬러브 평균구속';
    tooltipObj['sluveMaxSpeed'] = '슬러브 최고구속';

    tooltipObj['palmballValue'] = '팜볼 구종가치';
    tooltipObj['palmballValueDivOneHundred'] = '팜볼 구종가치/100';
    tooltipObj['palmballPitchRate'] = '팜볼 투구%';
    tooltipObj['palmballCnt'] = '팜볼 투구수';
    tooltipObj['palmballAvgSpeed'] = '팜볼 평균구속';
    tooltipObj['palmballMaxSpeed'] = '팜볼 최고구속';
    
    tooltipObj.특수_tab1 = '특수 구종가치';
    tooltipObj.특수_tab2 = '특수 구종가치/100';
    tooltipObj.특수_tab3 = '특수 투구%';
    tooltipObj.특수_tab4 = '특수 투구수';
    tooltipObj.특수_tab5 = '특수 평균구속 [최고구속]';
    
    tooltipObj['etcValue'] = '기타 구종가치';
    tooltipObj['etcValueOneHundred'] = '기타 구종가치/100';
    tooltipObj['etcPitchRate'] = '기타 투구%';
    tooltipObj['etcCnt'] = '기타 투구수';
    tooltipObj['etcAvgSpeed'] = '기타 평균구속';
    tooltipObj['etcMaxSpeed'] = '기타 최고구속';


        /******  타자 수비 *******/

    tooltipObj.starter = '선발';
    tooltipObj.ip = '출전 이닝';
    tooltipObj.fieldingCnt = '자살 + 보살 + 실책';
    tooltipObj.poCnt = '자살';
    tooltipObj.aoCnt = '보살';  
    tooltipObj.error = "실책";
    tooltipObj.defenseRate = "수비율 = (자살 + 보살)/(자살 + 보살 + 실책)"
    tooltipObj.rf9 = "((자살 + 보살)*9)/수비이닝"     
    tooltipObj.der = "1 - ((안타 + 실책 - 홈런) / (타석 - 볼넷 - 삼진 - 사구 - 홈런))";

    /******  타자 수비 포지션 *******/

    tooltipObj.dhPa = '지명타자 타석';
    tooltipObj.mainPosition = '주요 수비 포지션';
    tooltipObj.outCnt = '총 수비 이닝';
    tooltipObj.outCnt2 = '포수 수비 이닝';
    tooltipObj.outCnt3 = '1루수 수비 이닝';
    tooltipObj.outCnt4 = '2루수 수비 이닝';
    tooltipObj.outCnt5 = '3루수 수비 이닝';
    tooltipObj.outCnt6 = '유격수 수비 이닝';
    tooltipObj.outCnt7 = '좌익수 수비 이닝';
    tooltipObj.outCnt8 = '중견수 수비 이닝';
    tooltipObj.outCnt9 = '우익수 수비 이닝';
    tooltipObj.outCnt10 = '지명타자 수비 이닝';


    /******  내/외야 수비 *******/

    tooltipObj.infielderCnt = '내야 타구 처리 기회';
    tooltipObj.fielderChoice = '야수 선택';
    tooltipObj.infielderHit = '내야 안타';
    tooltipObj.outfielderCnt = '외야 타구 처리 기회'
    tooltipObj.outfielderOut = '뜬공 아웃 (뜬공 + 라인드라이브)'
    tooltipObj.outFieldingRate = '아웃 / 외야 타구 처리 기회'
    tooltipObj.fieldingRate = '아웃 / 처리 기회 (아웃 + 내안 + 실책 + 야선)';  
    tooltipObj.buntCnt = "번트 기회";
    tooltipObj.buntOut = "번트 처리"
    tooltipObj.buntRate = "번트 처리 / 번트 기회"
    tooltipObj.gdpCnt = "병살 기회";
    tooltipObj.gdpOut = "병살 처리"
    tooltipObj.gdpRate = "병살 처리 / 병살 기회"     
    tooltipObj.outfielderError = "외야 실책"
    tooltipObj.infielderError = "내야 실책"

    
    /****** 포수 수비 *******/
    tooltipObj.substitute = '교체';
    tooltipObj.er = '자책';
    tooltipObj.cera = '포수 평균 자책점 = (포수 자책점*9) / 수비 이닝';
    tooltipObj.fip = "FIP = ((13 * 홈런) + (3 * (볼넷 + 몸에 맞는볼))-( 2 * 삼진)) / 이닝 + FIP constant"
    tooltipObj.wildPitch = '폭투';
    tooltipObj.passedBall = '폭일';
    tooltipObj.pass9 = '(폭투 + 포일)*9/ 수비 이닝';
    tooltipObj.passBall9 = '포일*9/ 수비 이닝';
    tooltipObj.wildPitch9 = '폭투*9/ 수비 이닝';
    tooltipObj.csRate = "도루자 / (도루 + 도루자)"
        
    
    /****** 타자 홈런 *******/
    tooltipObj.xh = "장타"
    tooltipObj.xhPerPa = "장타/타석 (%)"
    tooltipObj.xhPerAb = "장타/타수 (%)"
    tooltipObj.xhPerHit = "장타/안타 (%)"
    tooltipObj.paPerHr = "타석/홈런"
    tooltipObj.abPerHr = "타수/홈런"
    tooltipObj.hrPerFly = "홈런/뜬공"
    tooltipObj.hrPerFlyOut = "홈런/뜬공 아웃"
    tooltipObj.oneRbiHr = "1타점 홈런"
    tooltipObj.twoRbiHr = "2타점 홈런"
    tooltipObj.threeRbiHr = "3타점 홈런"
    tooltipObj.fourRbiHr = "4타점 홈런"
    tooltipObj.rightPitcherHr = "우투수 상대 홈런"
    tooltipObj.leftPitcherHr = "좌투수 상대 홈런"
    tooltipObj.fastBallHr = "직구 홈런"
    tooltipObj.curveBallHr = "변화구 홈런"
    tooltipObj.lHr = "좌익수뒤 홈런"
    tooltipObj.lcHr = "좌중간 홈런"
    tooltipObj.cHr = "중견수뒤 홈런"
    tooltipObj.rcHr = "우중간 홈런"
    tooltipObj.rHr = "우익수뒤 홈런"
    tooltipObj.underHr = "-4점차 이상 열세시 홈런"
    tooltipObj.threeUnderHr = "-3점차 열세시 홈런"
    tooltipObj.twoUnderHr = "-2점차 열세시 홈런"
    tooltipObj.oneUnderHr = "-1점차 열세시 홈런"
    tooltipObj.tieHr = "동점시 홈런"
    tooltipObj.oneUpHr = "+1점 우세시 홈런"
    tooltipObj.twoUpHr = "+2점 우세시 홈런"
    tooltipObj.threeUpHr = "+3점 우세시 홈런"
    tooltipObj.upperHr = "+4점차 이상 우세시 홈런"
    tooltipObj.firstPitchHr = "초구 홈런"
    tooltipObj.firstUpperPitchHr = "초구 이상 홈런"

    /******** Digest Batter **********/ 

    tooltipObj['hardHitRate'] = '전체 타구 중 153km 이상 타구 비율';
    tooltipObj['barrelRate'] = '전체 타구에서 장타나 홈런이 되는 타구 비율';
    tooltipObj['vaildHitRate'] = '전체 타구에서 배럴 타구 이외 가장 이상적인 타구 비율';
    tooltipObj['chaseRate'] = '스트라이크 존 밖 투구에  헛스윙한 비율';
    tooltipObj['zoneContactRate'] = '스트라이크 존에 투구된 공을 맞춘 비율'
    tooltipObj['firstPitchSwingRate'] = '전체 초구 중 스윙(컨택+헛스윙)한 비율';
    tooltipObj['firstPitchContactRate'] = '전체 초구 중 공을 맞춘 비율';
    tooltipObj['twoStrikePitchSwingRate'] = '2스트라이크 이후 스윙(컨택+헛스윙)한 비율';
    tooltipObj['twoStrikePitchChaseRate'] = '2S체이스% => 2스트라이크 이후 스트라이크 존 밖 투구에 헛스윙한 비율';
    tooltipObj['twoStrikePitchContactRate'] = '2스트라이크 이후 공을 맞춘 비율';
    tooltipObj['barrelHit'] = '전체 타구에서 장타나 홈런이 되는 타구';
    tooltipObj['barrelRate'] = '전체 타구에서 장타나 홈런이 되는 타구 비율';
    tooltipObj['raw_power_speed'] = '로파워지표(T90) | T90: 개인 인플레이 타구속도 중 상위 10%값 (Top 10%)';
    tooltipObj['xBa'] = '트랙맨 의 발사각/속도 데이터를 기반으로 산출한 예상 타율';
    tooltipObj['xSlg'] = '트랙맨 의 발사각/속도 데이터를 기반으로 산출한 예상 장타율';
    tooltipObj['popUpRate'] = '내야수의 수비위치에서 높이 떠오른 비율';
    tooltipObj['hangRate'] = '당겨친 타구 비율';
    tooltipObj['centerRate'] = '가운데 방향 타구 비율';
    tooltipObj['pushRate'] = '밀어친 타구 비율';
    tooltipObj['solidContact'] = '안타로 이어질 확률이 높지만 장타가 되기는 어려운 타구';
    tooltipObj['poorlyUnder'] = '빗맞은 땅볼 타구';
    tooltipObj['poorlyTopped'] = '빗맞은 뜬공 타구';
    tooltipObj['poorlyUnderRate'] = '인플레이 타구 중 빗맞은 땅볼 타구 비율';
    tooltipObj['poorlyToppedRate'] = '인플레이 타구 중 빗맞은 뜬공 타구 비율';
    tooltipObj['poorlyWeakRate'] = '빗맞은 공 비율';

    return tooltipObj;
}

export default batterTooltip;