import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import LoaderSpinner from '../../components/oauth/LoaderSpinner';

const OAuth2RedirectHandlerGoogle = () => {
    const {setUserInfo, setPlatform, setPolicyId, setPolicyAgreeYn}  = useOutletContext();

    let params = new URL(document.URL).searchParams;
    let code = params.get('code');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const callAuthUrl = async () => {
            await oauthLogin();
        }

        callAuthUrl();
    // eslint-disable-next-line
    }, []);

    const saveToken = (jwtToken) => {
        localStorage.setItem("accessToken", JSON.stringify(jwtToken));
    }

    const checkPolicyAgree = () => {
        let policyId = sessionStorage.getItem("policyId");
        let policyAgreeYn = sessionStorage.getItem("policyAgreeYn");

        if(policyAgreeYn === "Y"){
            setPolicyId(policyId);
            setPolicyAgreeYn(policyAgreeYn);

            sessionStorage.removeItem("policyId");
            sessionStorage.removeItem("policyAgreeYn");

            navigate('/oauth/join');
        } else {
            navigate('/oauth/policy');
        }
    }

    const loginHandler = (data) => {
        if(data){
            if(data.result === "Duplicate"){
                alert("탈퇴 후 6개월간 동일한 이메일로 재가입이 불가능합니다.\n(관리자에게 문의 바랍니다.)");
                navigate('/login');
            } else {
                let token = data.token;
                let policyAgreeYn = data.policyAgreeYn;
                let encParam = data.encParam;

                if(policyAgreeYn !== "N"){
                    if(token !== null){
                        saveToken(token);
                        const origin = location.state?.from?.pathname || '/';
                        navigate(origin);
                    } else {
                        navigate('/error');
                    }
                } else {
                    setUserInfo(encParam);
                    setPlatform("google");

                    checkPolicyAgree();
                }
            }
        } else {
            navigate('/error');
        }
    }

    const oauthLogin = () =>
    new Promise((resolve) => {
        const params = {
            code: code
        };

        axios.get(`${process.env.REACT_APP_API_URL}/google/googleInfo`, {params}).then((response) => {
            // console.log(response);
            // console.log(response.data);

            loginHandler(response.data);

            resolve("resolved");
        })
        .catch((error) => {
            console.log('Google Login Failed');
            navigate('/error');
        });
    })

    return (
        <LoaderSpinner />
    )
};

export default OAuth2RedirectHandlerGoogle;