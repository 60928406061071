import React, { useEffect, useState } from 'react';
import { fetchLastGameDay, fetchPitcherBallType, fetchPitcherRecordDetail, fetchPitcherRecordTpa, fetchStraightPitch, fetchTeamRanking } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Oval } from 'react-loader-spinner';
import PlayerPitchingBasicRecord from './pitching/player-pitching-basic-record';
import PlayerPitchingDetailRecord from './pitching/player-pitching-detail-record';
import PlayerPitchingTpaRecord from './pitching/player-pitching-tpa-record';
import PlayerPitchingHitRecord from './pitching/player-pitching-hit-record';
import PlayerPitchingClutchRecord from './pitching/player-pitching-clutch-record';
import PlayerPitchingStarterRecord from './pitching/player-pitching-starter-record';
import PlayerPitchingReliefRecord from './pitching/player-pitching-relief-record';
import PlayerPitchingBallSpeedRecord from './pitching/player-pitching-ballspeed-record';
import moment from 'moment';
import { useAtom } from 'jotai';
import { player_pitching_filter_atom } from '../../../assets/model/detail/store';
import PlayerPitchingFilter from '../../../components/filter/player-pitching-filter';
import FilterToggleButton from '../../../components/button/FilterToggleButton';
import { Collapse } from '@mui/material';

function PlayerPitchingRecord() {
    const navigate = useNavigate();
    const [filterObj, setFilterObj] = useAtom(player_pitching_filter_atom);
    const [record, setRecord] = useState([]);

    const [open, setOpen] = useState(false);
    const params = useParams();
    const tabId = params['tabId'];
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {        
        if(!filterObj['seasons']) {
            const todaySeason = moment(new Date()).format('YYYY');
            fetchLastGameDay({
                seasons: [todaySeason, todaySeason-1].toString(),
                gameTypeCodes: 4201
            }).then((response) => {
                const {lastGameDay} = response['data']['RECORD'][0];
                const lastSeason = moment(lastGameDay).format('YYYY')*1;

                setFilterObj((prev) => {
                    return {...prev, seasons: lastSeason, regularInning: '1'}
                })
            })
        } 
    }, [])

    useEffect(() => {
        if(filterObj['seasons']) {
            setOpen(true);
            const {seasons} = filterObj;
            const recordParam = {
                ...filterObj,
                gameTypeCodes: 4201,
                groupingColumn: 'seasonPlayer'
            }

            _.map(recordParam, (item, idx) => {
                if(Array.isArray(item)) {
                    return recordParam[idx] = item.toString();
                }
            });

            if(!recordParam['toDate'] && !recordParam['fromDate']) {
                recordParam['fromDate'] = moment(new Date(seasons, 0, 1)).format('YYYY-MM-DD');
                recordParam['toDate'] = moment(new Date(seasons, 11, 31)).format('YYYY-MM-DD');
            }

            if(tabId === 'starter') {
                recordParam['pitcherPositionTypeCodes'] = 5102;
            } else if(tabId === 'relief') {
                recordParam['pitcherPositionTypeCodes'] = '5101, 5103, 5104';
            }

            if(tabId === 'ballspeed') {
                fetchPitcherBallType(recordParam)
                .then((response) => {
                    const pitchingRecord = loopFlatObject(response['data']['RECORD']);
                    const pitcherGroupData = _.groupBy(pitchingRecord, (item, idx) => {
                        return item['pitcher_pitcherId'];
                    });

                    const ballCodeGroup = _.groupBy(pitchingRecord, (item) => item['ballCode']);
                    const ballCodeArr = [];

                    _.forEach(ballCodeGroup, (item, idx) => {
                        const ballCode = item[0]['ballCode'];
                        const ballCodeName = item[0]['ballCodeName'];

                        ballCodeArr.push({ballCode, ballCodeName});
                    });

                    const pitcherBallRecordArr = [];
                    _.forEach(pitcherGroupData, (item, idx) => {
                        const playerObj = item[0];

                        let keyByData = _.keyBy(item, 'ballCode');
                        let pitcherObj = {
                            playerId: idx, season: playerObj['season'],
                            playerName: playerObj['pitcher_pitcherName'],
                            playerTeamName: playerObj['pitcher_pitcherTeamName'],
                            totalCnt: 0
                        };

                        _.forEach(ballCodeArr, (innerItem, innerIdx) => {
                            const innerBallCode = innerItem['ballCode'];
                            if(keyByData[innerBallCode]) { //exist ballcode
                                const ballObj = keyByData[innerBallCode];
                                pitcherObj[innerBallCode+"_cnt"] = ballObj['pitch'];
                                pitcherObj[innerBallCode+"_name"] = ballObj['ballCodeName'];
                                pitcherObj[innerBallCode+"_rate"] = (ballObj['pitchRate']*100).toFixed(1);
                                pitcherObj['totalCnt'] += ballObj['pitch']*1;
                            } else {
                                pitcherObj[innerBallCode+"_cnt"] = '-';
                                pitcherObj[innerBallCode+"_name"] = innerItem['ballCodeName'];
                                pitcherObj[innerBallCode+"_rate"] = '-';
                            }
                        });

                        pitcherBallRecordArr.push(pitcherObj);
                    });

                    setRecord(pitcherBallRecordArr);
                    setOpen(false);
                })
            } else if(tabId === 'relief') {
                let promiseArr = [];

                if(!filterObj['regularInning'] || filterObj['regularInning'] === 'all') {
                    promiseArr.push(fetchPitcherRecordDetail(recordParam));
                    promiseArr.push(fetchStraightPitch({...recordParam, groupingColumn: 'player'}));
                } else {
                    promiseArr.push(fetchPitcherRecordDetail(recordParam));
                    promiseArr.push(fetchStraightPitch({...recordParam, groupingColumn: 'player'}));
                    
                    const teamRankingPromise = fetchTeamRanking({ seasons, gameTypeCodes: 4201 });
                    promiseArr.push(teamRankingPromise);
                }

                Promise.all(
                    promiseArr
                ).then((response) => {
                    const pitchingRecord = loopFlatObject(response[0]['data']['RECORD']);
                    const straightData = response[1]['data']['RECORD'];

                    let pitchDay1Total = 0;
                    let pitchDay2Total = 0;
                    let pitchDay3Total = 0;
                    _.each(pitchingRecord, function(item, idx){
                        item["straightPitchDay1"] = 0;
                        item["straightPitchDay2"] = 0;
                        item["straightPitchDay3"] = 0;
                        _.each(straightData, function(InnerItem, InnerIdx){
                            if(item['pitcherId'] == InnerItem['pitcherId']){
                                if(InnerItem["straightPitchDay"]*1 === 1){
                                    pitchDay1Total = pitchDay1Total+InnerItem["straightPitchDayCount"]*1
                                    item["straightPitchDay1"] = InnerItem["straightPitchDayCount"]*1
                                }else if(InnerItem["straightPitchDay"]*1 === 2){
                                    pitchDay2Total = pitchDay2Total+InnerItem["straightPitchDayCount"]*1
                                    item["straightPitchDay2"] = InnerItem["straightPitchDayCount"]*1
                                }else{
                                    pitchDay3Total = pitchDay3Total+InnerItem["straightPitchDayCount"]*1
                                    var pitchDayTotal = 0;
                                    item["straightPitchDay3"] = pitchDayTotal+(InnerItem["straightPitchDayCount"]*1)
                                }
                            }
                        })
                    });

                    if(response.length > 2) {
                        const teamRanking = _.values(response[2]['data']['RECORD'])[0];
                        const teamIdx = _.keyBy(teamRanking, 'teamId');

                        const filterRecord = _.filter(pitchingRecord, (item, idx) => {
                            const teamId = item['pitcherTeamId'];
                            const teamInfo = teamIdx[teamId];
                            const regularTpaNum = teamInfo['gameCnt']*filterObj['regularInning']*3;

                            return regularTpaNum <= item['stat_totalOutCount'];
                        });

                        setRecord(filterRecord);
                    } else {
                        setRecord(pitchingRecord);                        
                    }
                    setOpen(false); 
                });
                
                if(!filterObj['regularInning'] || filterObj['regularInning'] === 'all') {
                    const recordDetailPromise = fetchPitcherRecordDetail(recordParam);
                    const reliefPromise = fetchStraightPitch({...recordParam, groupingColumn: 'player'});

                    Promise.all([
                        recordDetailPromise,
                        reliefPromise
                    ]).then((response) => {
                        const pitchingRecord = loopFlatObject(response[0]['data']['RECORD']);
                        const straightData = response[1]['data']['RECORD'];

                        let pitchDay1Total = 0;
                        let pitchDay2Total = 0;
                        let pitchDay3Total = 0;
                        _.each(pitchingRecord, function(item, idx){
                            item["straightPitchDay1"] = 0;
                            item["straightPitchDay2"] = 0;
                            item["straightPitchDay3"] = 0;
                            _.each(straightData, function(InnerItem, InnerIdx){
                                if(item['pitcherId'] == InnerItem['pitcherId']){
                                    if(InnerItem["straightPitchDay"]*1 === 1){
                                        pitchDay1Total = pitchDay1Total+InnerItem["straightPitchDayCount"]*1
                                        item["straightPitchDay1"] = InnerItem["straightPitchDayCount"]*1
                                    }else if(InnerItem["straightPitchDay"]*1 === 2){
                                        pitchDay2Total = pitchDay2Total+InnerItem["straightPitchDayCount"]*1
                                        item["straightPitchDay2"] = InnerItem["straightPitchDayCount"]*1
                                    }else{
                                        pitchDay3Total = pitchDay3Total+InnerItem["straightPitchDayCount"]*1
                                        var pitchDayTotal = 0;
                                        item["straightPitchDay3"] = pitchDayTotal+(InnerItem["straightPitchDayCount"]*1)
                                    }
                                }
                            })
                        });
                        
                        setRecord(pitchingRecord);
                        setOpen(false);
                    })
                } else {
                    
                }


                
            } else {
                let promiseArr = [];

                if(!filterObj['regularInning'] || filterObj['regularInning'] === 'all') {
                    promiseArr.push(fetchPitcherRecordDetail(recordParam));
                } else {
                    if(tabId === 'tpa') {
                        promiseArr.push(fetchPitcherRecordTpa(recordParam));
                    } else {
                        promiseArr.push(fetchPitcherRecordDetail(recordParam));
                    }

                    const teamRankingPromise = fetchTeamRanking({seasons, gameTypeCodes: 4201});
                    promiseArr.push(teamRankingPromise);
                }

                Promise.all(
                    promiseArr
                ).then((response) => {
                    if(response.length > 1) {
                        const pitchingRecord = loopFlatObject(response[0]['data']['RECORD']);
                        const teamRanking = _.values(response[1]['data']['RECORD'])[0];

                        const teamIdx = _.keyBy(teamRanking, 'teamId');

                        const filterRecord = _.filter(pitchingRecord, (item, idx) => {
                            const teamId = item['pitcherTeamId'];
                            const teamInfo = teamIdx[teamId];
                            const regularTpaNum = teamInfo['gameCnt']*filterObj['regularInning']*3;

                            return regularTpaNum <= item['stat_totalOutCount'];
                        });

                        setRecord(filterRecord);
                        setOpen(false);
                    } else {
                        const pitchingRecord = loopFlatObject(response[0]['data']['RECORD']);
                        setRecord(pitchingRecord);
                        setOpen(false);
                    }
                });
            }
        }
    }, [filterObj])

    const PitchingRecordContent = () => {
        switch(tabId) {
            case 'basic':
                return <PlayerPitchingBasicRecord data={record} />
            case 'detail':
                return <PlayerPitchingDetailRecord data={record} />;
            case 'tpa':
                return <PlayerPitchingTpaRecord data={record} />
            case 'hit':
                return <PlayerPitchingHitRecord data={record} />;
            case 'ballspeed':
                return <PlayerPitchingBallSpeedRecord data={record} />;
            case 'clutch':
                return <PlayerPitchingClutchRecord data={record} />;
            case 'starter':
                return <PlayerPitchingStarterRecord data={record} />;
            case 'relief':
                return <PlayerPitchingReliefRecord data={record} />;
            default:
                return <></>;
        }
    }

    const filterToggleEventHandler = () => {
        setFilterOpen(!filterOpen);
    }
    
    return (
    <>
    <div id="record_sub_tab_container">
        <div id="record_sub_tab_area">
            <div className={"btn "+((tabId === "basic") && "active")}
            onClick={() => navigate('/record/player/pitching/basic')}
            >
            기본
            </div>
            <div className={"btn "+((tabId === "detail") && "active")}
            onClick={() => navigate('/record/player/pitching/detail')}
            >
            상세
            </div>
            <div className={"btn "+((tabId === "tpa") && "active")}
            onClick={() => navigate('/record/player/pitching/tpa')}
            >
            타석
            </div>
            <div className={"btn "+((tabId === "hit") && "active")}
            onClick={() => navigate('/record/player/pitching/hit')}
            >
            안타
            </div>
            <div className={"btn "+((tabId === "ballspeed") && "active")}
            onClick={() => navigate('/record/player/pitching/ballspeed')}
            >
            구질
            </div>
            <div className={"btn "+((tabId === "clutch") && "active")}
            onClick={() => navigate('/record/player/pitching/clutch')}
            >
            클러치
            </div>
            <div className={"btn "+((tabId === "starter") && "active")}
            onClick={() => navigate('/record/player/pitching/starter')}
            >
            선발
            </div>
            <div className={"btn "+((tabId === "relief") && "active")}
            onClick={() => navigate('/record/player/pitching/relief')}
            >
            구원
            </div>
        </div>
        <div>
            <FilterToggleButton
            onClick={filterToggleEventHandler}
            >
                필터
            </FilterToggleButton>
        </div>
    </div>
    <div id="record_sub_container">
        <Collapse in={filterOpen} timeout={'auto'}>
            <PlayerPitchingFilter/>
        </Collapse>        
        {
            record.length === 0
            ?
            <></>
            :
            <PitchingRecordContent/>
        }
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
            <Oval
            height="80" width="80"
            radius="9" color="#00bfff"
            secondaryColor="#fff"
            ariaLabel="three-dots-loading"
            />
        </Backdrop>
    </div>
    </>
    )
}

export default PlayerPitchingRecord;