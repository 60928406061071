import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchBatterSituationRecord } from '../../../utils/api';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';
import moment from 'moment';
import weekDaysCode from '../../../utils/code/weekDaysCode';
const weekDaysArr = weekDaysCode();

function BatterSituation2RecordBox() {
    const statArr = [
        {key:'day', name: '요일'},
        {key:'homeOrAway', name: '홈/어웨이'},
        {key:'stadium', name: '구장'},
        {key:'battingOrder', name: '타순'},
        {key:'position', name: '포지션'}];
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get("season");
    const [recordObj, setRecordObj] = useState({
        day: [],
        homeOrAway: [],
        stadium: [],
        battingOrder: [],
        position: []
    });
    
    useEffect(() => {
        const playerId = params['playerId'];
        const commonParam = {
            seasons: season,
            gameTypeCodes: 4201,
            fromDate: moment(new Date(season, 0, 1)).format('YYYY-MM-DD'),
            toDate: moment(new Date(season, 11, 31)).format('YYYY-MM-DD'),
            batterIds: playerId
        }

        const weekdayRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'weekDay'});
        const homeOrAwayRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'homeOrAway'});
        const stadiumRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'stadium'});
        const battingOrderRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'battingOrder'});
        const positionRecordPromise = fetchBatterSituationRecord({...commonParam, groupingColumn: 'positionTypeCode'})

        Promise.all([
            weekdayRecordPromise, homeOrAwayRecordPromise,
            stadiumRecordPromise, battingOrderRecordPromise,
            positionRecordPromise
        ]).then((response) => {
            const weekdayRecord = loopFlatObject(response[0]['data']['RECORD']);
            const homeOrAwayRecord = loopFlatObject(response[1]['data']['RECORD']);
            const stadiumRecord = loopFlatObject(response[2]['data']['RECORD']);
            const battingOrderRecord = _.sortBy(loopFlatObject(response[3]['data']['RECORD']), ['situation_battingOrder']);
            const positionRecord = loopFlatObject(response[4]['data']['RECORD'])

            setRecordObj((prevObj) => {
                return {
                    ...prevObj,
                    day: weekdayRecord,
                    homeOrAway: homeOrAwayRecord,
                    stadium: stadiumRecord,
                    battingOrder: battingOrderRecord,
                    position: positionRecord
                }
            });
        })
    }, [])

    const SituationColumn = (props) => {
        const {data} = props;
        switch(props['type']) {
            case 'day':
                const findData = _.find(weekDaysArr, item => item['weekDay'] === data['situation_weekDay']);
                return  <td>
                    {findData['weekDayName']}
                </td>
            case 'homeOrAway':
                return <td>
                    {data['situation_homeOrAway']}
                </td>
            case 'stadium':
                return <td>
                    {data['situation_stadium']}
                </td>
            case 'battingOrder':
                return <td>
                    {data['situation_battingOrder']}
                </td>
            case 'position':
                return <td>
                    {data['situation_position']}
                </td>
            default:
                return <td></td>
        }
    }

    return (
    <div id="situation_basic_container">
    {
    _.map(statArr, (item, idx) => (
        <Fragment key={item['name']}>
        <div className="table_title">{item['name']}</div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>타석</th>
                        <th>타수</th>
                        <th>안타</th>
                        <th>1루타</th>
                        <th>2루타</th>
                        <th>3루타</th>
                        <th>홈런</th>
                        <th>삼진</th>
                        <th>볼넷</th>
                        <th>고의4구</th>
                        <th>사구</th>
                        <th>병살타</th>
                        <th>희비</th>
                        <th>타점</th>
                        <th>득점</th>
                        <th>타율</th>
                        <th>출루율</th>
                        <th>장타율</th>
                        <th>OPS</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(recordObj[item['key']], (data, dataIdx) => (
                    <tr key={item['key']+dataIdx}>
                        <SituationColumn type={item['key']} data={data}/>
                        <td>{data['statCacled_stat_tpa']}</td>
                        <td>{data['statCacled_stat_ab']}</td>
                        <td>{data['statCacled_stat_hit']}</td>
                        <td>{data['statCacled_stat_b1b']}</td>
                        <td>{data['statCacled_stat_b2b']}</td>
                        <td>{data['statCacled_stat_b3b']}</td>
                        <td>{data['statCacled_stat_hr']}</td>
                        <td>{data['statCacled_stat_so']}</td>
                        <td>{data['statCacled_stat_bb']}</td>
                        <td>{data['statCacled_stat_ibb']}</td>
                        <td>{data['statCacled_stat_hbp']}</td>
                        <td>{data['statCacled_stat_gdp']}</td>
                        <td>{data['statCacled_stat_sf']}</td>
                        <td>{data['statCacled_stat_rbi']}</td>
                        <td>{data['statCacled_stat_r']}</td>
                        <td>{data['statCacled_avg']}</td>
                        <td>{data['statCacled_obp']}</td>
                        <td>{data['statCacled_slg']}</td>
                        <td>{data['statCacled_ops']}</td>
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
        </Fragment>
    ))
    }
    </div>
    )
}

export default BatterSituation2RecordBox;