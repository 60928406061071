import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchBatterRecord, fetchPlayerInfo } from '../../../utils/api';
import _ from 'lodash';
import { flatObject, loopFlatObject } from '../../../utils/flat';

function BatterBasicRecordBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [batterRecord, setBatterRecord] = useState([]);
    const [totalRecord, setTotalRecord] = useState({});

    useEffect(() => {
        const playerId = params['playerId'];
        const recordParam = {
            batterIds: playerId,
            gameTypeCodes: 4201,
            groupingColumn: 'seasonPlayer'
        }

        const playerParam = {
            membersId: playerId,
            seasons: season
        }

        const totalParam = {
            batterIds: playerId,
            gameTypeCodes: 4201
        }

        const playerInfoPromise = fetchPlayerInfo(playerParam);
        const batterRecordPromise = fetchBatterRecord(recordParam);
        const batterTotalPromise = fetchBatterRecord(totalParam);

        Promise.all([
            batterRecordPromise,
            batterTotalPromise,
            playerInfoPromise
        ]).then((response) => {
            const batterData = loopFlatObject(response[0]['data']['RECORD']);

            _.map(batterData, (item) => item['birthDate'] = response[2]['data']['RECORD'][0]['birthDate']);
            setBatterRecord(batterData);
            setTotalRecord(loopFlatObject(response[1]['data']['RECORD']));
        })
    }, []);

    const ageRenderer = (item) => {
        const {season, birthDate} = item;
        if(birthDate) {
            let birthdateYear = birthDate.split('/');
            let age = (season*1-birthdateYear[0]*1)-1;

            return age;
        } else {
            return 0
        }
    }

    return (
    <div id="player_basic_container">
        <div className="table_title">
            시즌 기록
        </div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>시즌</th>
                        <th>나이</th>
                        <th>팀</th>
                        <th>경기</th>
                        <th>타석</th>
                        <th>타수</th>
                        <th>안타</th>
                        <th>2루타</th>
                        <th>3루타</th>
                        <th>홈런</th>
                        <th>득점</th>
                        <th>타점</th>
                        <th>사사구</th>
                        <th>삼진</th>
                        <th>도루</th>
                        <th>타율</th>
                        <th>출루율</th>
                        <th>장타율</th>
                        <th>OPS</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(batterRecord, (item, idx) => (
                    <tr key={item['season']+item['batterTeamId']}>
                        <td>{item['season']}</td>
                        <td>{ageRenderer(item)}</td>
                        <td>{item['batterTeamName']}</td>
                        <td>{item['basicStat_gameCnt']}</td>
                        <td>{item['basicStat_tpa']}</td>
                        <td>{item['basicStat_ab']}</td>
                        <td>{item['basicStat_hit']}</td>
                        <td>{item['basicStat_b2b']}</td>
                        <td>{item['basicStat_b3b']}</td>
                        <td>{item['basicStat_hr']}</td>
                        <td>{item['basicStat_r']}</td>
                        <td>{item['basicStat_rbi']}</td>
                        <td>{item['basicStat_bb']}</td>
                        <td>{item['basicStat_so']}</td>
                        <td>{item['basicStat_sbSucc']}</td>
                        <td>{item['avg']}</td>
                        <td>{item['obp']}</td>
                        <td>{item['slg']}</td>
                        <td>{item['ops']}</td>
                    </tr>
                ))}    
                </tbody>
            </table>
        </div>
        <div className="table_title">
            통산 기록
        </div>
        <div className="table_body">
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>팀</th>
                        <th>경기</th>
                        <th>타석</th>
                        <th>타수</th>
                        <th>안타</th>
                        <th>2루타</th>
                        <th>3루타</th>
                        <th>홈런</th>
                        <th>득점</th>
                        <th>타점</th>
                        <th>사사구</th>
                        <th>삼진</th>
                        <th>도루</th>
                        <th>타율</th>
                        <th>출루율</th>
                        <th>장타율</th>
                        <th>OPS</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(totalRecord, (item, idx) => (
                    <tr key={item['season']}>
                        <td>{item['batterTeamName']}</td>
                        <td>{item['basicStat_gameCnt']}</td>
                        <td>{item['basicStat_tpa']}</td>
                        <td>{item['basicStat_ab']}</td>
                        <td>{item['basicStat_hit']}</td>
                        <td>{item['basicStat_b2b']}</td>
                        <td>{item['basicStat_b3b']}</td>
                        <td>{item['basicStat_hr']}</td>
                        <td>{item['basicStat_r']}</td>
                        <td>{item['basicStat_rbi']}</td>
                        <td>{item['basicStat_bb']}</td>
                        <td>{item['basicStat_so']}</td>
                        <td>{item['basicStat_sbSucc']}</td>
                        <td>{item['avg']}</td>
                        <td>{item['obp']}</td>
                        <td>{item['slg']}</td>
                        <td>{item['ops']}</td>
                    </tr>
                ))}    
                </tbody>
            </table>
        </div>
        <div className="table_title">
            상세 기록
        </div>
        <div>
            playerId, season을 이용해서 보여줄 수 있는 상세기록 필요
        </div>
    </div>
    )
}

export default BatterBasicRecordBox;