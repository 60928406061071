import React, { useEffect, useState } from 'react';
import _ from 'lodash';

function VsMatrixTableComponent(props) {
    const gameInfo = props['gameInfo'];
    const pitcherTeamId = props['gameInfo'][props['pitcherTeam']+"TeamId"];
    const batterTeamId = props['gameInfo'][props['batterTeam']+"TeamId"];
    const teamRecord = props['teamRecord'];
    const batterRoster = props['batterRoster'];
    const pitcherRoster = props['pitcherRoster'];
    const [groupData, setGroupData] = useState({});
    const [pitcherIds, setPitcherIds] = useState([]);
    const [batterIds, setBatterIds] = useState([]);
    const [pitcherColumnLength, setPitcherColumnLength] = useState(0);

    useEffect(() => {
        setGroupData( 
            _.keyBy(teamRecord, (item, idx) => item['batter_batterId']+"_"+item['pitcher_pitcherId']) 
        );

        const batterRosterSet = _.map(batterRoster[batterTeamId], (item, idx) => {
            return item['playerId']+"_"+item['playerName'];
        });

        const pitcherRosterSet = _.map(pitcherRoster[pitcherTeamId], (item, idx) => {
            return item['playerId']+"_"+item['playerName'];
        })

        setPitcherIds(pitcherRosterSet);
        setBatterIds(batterRosterSet);

        setPitcherColumnLength(pitcherRosterSet['length']);
    }, [teamRecord])

    const playerDataCalc = function(pitcherData, batterData, data, type) {
        let pitcherId = pitcherData.split('_')[0];
        let batterId = batterData.split('_')[0];

        let playerData = data[batterId+"_"+pitcherId];
        let cellData = '';

        if(playerData) {
            if(type == 'first') {
                cellData += playerData['basicStat_hit']+'-'+playerData['basicStat_ab'];
                cellData += ' '+playerData['avg'];
            } else if(type == 'second') {
                if(playerData['basicStat_hr'] != 0) {
                    cellData += playerData['basicStat_hr']+"hr";

                    if(playerData['basicStat_bb'] != 0) {
                        cellData += playerData['basicStat_bb']+'bb';
                    }
                } else {
                    if(playerData['basicStat_bb'] != 0) {
                        cellData += playerData['basicStat_bb']+'bb';
                    }
                }
            }
        }

        return cellData;
    }

    const ForloopContent = (props) => {
        let contentArr = [];
        contentArr.push(<col key={'99_'} width="1%"/>);
        contentArr.push(<col key={'100_'} width="2%"/>);
        contentArr.push(<col key={'101_'} width="7%"/>);

        const width = 90/props['width'];

        for(let i=0; i < props['width']; i++) {
            contentArr.push(<col key={i+"_"+width} width={width+"%"}/>)
        }

        return contentArr;
    }

    return (
    <>
    <table>
        <colgroup>
            <ForloopContent width={pitcherColumnLength}/>
        </colgroup>
        <tbody>
        <tr>
            <th colSpan={pitcherIds['length']+3} className={'border_pitcher_team bg_color_'+pitcherTeamId}></th>
        </tr>
        <tr>
            <th rowSpan={batterIds['length']+2} className={'border_batter_team bg_color_'+batterTeamId}></th>
            <th className={'header_pitcher_team font_color_'+pitcherTeamId}></th>
            <th className={'header_pitcher_team font_color_'+pitcherTeamId}></th>
            <th className={'header_pitcher_team font_color_'+pitcherTeamId} colSpan={pitcherIds['length']}>
                {gameInfo[props['pitcherTeam']+'TeamName']}투수
            </th>
        </tr>
        <tr>
            <th width="2%"></th>
            <th width="6%">MATCH<br/>UP</th>
            {
            _.map(pitcherIds, (item, idx) => (
                <th key={item} className={`header_pitcher bg_color_${pitcherTeamId}`}>
                    {item.split('_')[1]}
                </th>
            ))
            }
        </tr>
        {
        _.map(batterIds, (item, idx) => (
            <tr key={item}>
                {
                idx === 0 
                &&
                <th className={`header_batter_team font_color_${batterTeamId}`} rowSpan={batterIds['length']}>
                    <div>{gameInfo[props['batterTeam']+'TeamName']}타자</div>
                </th>
                }
                <th className={'header_batter bg_color_'+batterTeamId}>{item.split('_')[1]}</th>
                {
                _.map(pitcherIds, (innerItem) => (
                    <td key={innerItem}>
                        {playerDataCalc(innerItem, item, groupData, 'first')}
                        <br/>
                        {playerDataCalc(innerItem, item, groupData, 'second')}
                    </td>
                ))
                }
            </tr>
        ))
        }
        </tbody>
    </table>
    </>
    )
}

export default VsMatrixTableComponent;