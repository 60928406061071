import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fetchPitchByPitch } from '../../utils/api';
import _ from 'lodash';
import LocationChart from '../../components/chart/LocationChart';
import { toPng } from 'html-to-image';
import moment from 'moment';
import ExportPngButton from '../../components/button/ExportPngButton';

function PlayerZoneBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [pbpData, setPbpData] = useState([]);
    const pathname = useLocation()['pathname'];
    const playerType = (pathname.indexOf('batter') > -1) ? 'batter' : 'pitcher'

    useEffect(() => {
        const playerId = params['playerId'];
        const pbpParam = {
            seasons: season,
            gameTypeCodes: 4201
        }

        if(pathname.indexOf('batter') > -1) {
            pbpParam['batterIds'] = playerId;
        } else { //pitcher
            pbpParam['pitcherIds'] = playerId;
        }

        fetchPitchByPitch(pbpParam)
        .then((response) => {
            const pitchData = response['data']['RECORD'];
            setPbpData(pitchData);
        })
    }, []);

    const exportClickHandler = (event) => {
        const firstData = pbpData[0];
        
        let title = moment(firstData['gameDay']).format('YYYY')+" ";
        if(playerType === 'batter') {
            title += firstData['batterTeamName']+ " "+firstData['batterName'] + " 투구분포"
        } else {
            title += firstData['pitcherTeamName']+ " "+firstData['pitcherName'] + " 투구분포";
        }

        let node = document.getElementById('player_info_zone_svg');

        toPng(node)
        .then(function (dataUrl) {
            const link = document.createElement('a')
            link.download = title+'.png';
            link.href = dataUrl
            link.click()
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    }

    return (
    <>
    <div style={{textAlign: 'right', padding:'2px 2px 0px 0px'}}>
        <span>
        <ExportPngButton
        onClick={exportClickHandler}
        >
        PNG
        </ExportPngButton>
        </span>
    </div>
    <div style={{textAlign:'center', backgroundColor: '#fff'}} id="player_info_zone_svg">
        <LocationChart data={pbpData} playerType={playerType} />
    </div>
    </>
    )
}

export default PlayerZoneBox;