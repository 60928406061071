import React from "react";
import { useParams, useNavigate } from 'react-router-dom';

const BoardNav = (props) => {
    const boardList = props.boardList;

    const navigate = useNavigate();
    const params = useParams();
    const boardId = params['boardId'] ? params['boardId'] : props.firstBoardId;

    const setBoard = (id) => {
        navigate('/board/' + id);
        props.setCurrentPage('1');
        props.setInputKeyword('');
        props.setSearchKeyword('');
    };

    return (
        <div id="navWrap">
            {boardList &&
            boardList.map((item, idx) => (
                boardId === item.boardId ? (
                    <button key={idx} className="navBtn active" onClick={() => setBoard(item.boardId)}>
                        {item.boardName}
                    </button>
                ) : (
                    <button key={idx} className="navBtn" onClick={() => setBoard(item.boardId)}>
                        {item.boardName}
                    </button>
                )
            ))
            }
        </div>
    );
};

export default BoardNav;