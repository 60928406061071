export default function weekDaysCode() {
    const weekDays = [
        {weekDay: 1, weekDayName: '일'},
        {weekDay: 2, weekDayName: '월'},
        {weekDay: 3, weekDayName: '화'},
        {weekDay: 4, weekDayName: '수'},
        {weekDay: 5, weekDayName: '목'},
        {weekDay: 6, weekDayName: '금'},
        {weekDay: 7, weekDayName: '토'}
    ];

    return weekDays;
}