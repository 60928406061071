import React, { useEffect, useState } from 'react';
import { fetchBatterRecord } from '../../../utils/api';
import moment from 'moment';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';
import IndicatorChart from '../../../components/chart/IndicatorChart';

function WeeklyTeamBattingRecord(props) {
    const {fromDate, toDate, teamId} = props;
    const [teamRecord, setTeamRecord] = useState({});

    const statObj = [
        {stat: 'avg', sort: 'asc', name: '타율'},
        {stat: 'obp', sort: 'asc', name: '출루율'},
        {stat: 'slg', sort: 'asc', name: '장타율'},
        {stat: 'ops', sort: 'asc', name: 'OPS'},
        {stat: 'bbRate', sort: 'asc', name: '볼넷%'},
        {stat: 'kRate', sort: 'desc', name: '삼진%'},
        {stat: 'basicStat_hr', sort: 'asc', name: '홈런'},
        {stat: 'basicStat_rbi', sort: 'asc', name: '타점'},
        {stat: 'basicStat_gdp', sort: 'desc', name: '병살'},
        {stat: 'rPerGame', sort: 'asc', name: '경기당 득점'}
    ];

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const teamParam = {
            seasons,
            gameTypeCodes: 4201,
            fromDate, toDate,
            groupingColumn: 'team'
        }

        const teamBattingPromise = fetchBatterRecord(teamParam);
        teamBattingPromise.then((response) => {
            const teamBattingRecord = loopFlatObject(response['data']['RECORD']);
            const calcedRecord = rankingCalc(teamId, teamBattingRecord);
            const idxData = _.keyBy(calcedRecord, 'stat');
            setTeamRecord(idxData)
        })
    }, [fromDate, teamId])

    function rankingCalc(teamId, digestRecord) {
        let tmpArr = [];
        _.forEach(statObj, (item, idx) => {
            const {stat} = item;

            let sortedData = _.sortBy(digestRecord, [(o) => {return o[stat]*1 }]);
            if(item['sort'] === 'asc') {
                sortedData.reverse();
            }

            const statRanking = _.findIndex(sortedData, (data) => data['batterTeamId'] === teamId);
            const statObj = _.find(sortedData, (data) => data['batterTeamId'] === teamId);

            tmpArr.push({stat: stat, value: statObj[stat], rank: statRanking+1, name: item['name']});
        })

        return tmpArr;
    }

    return (
    <>
    {
    Object.keys(teamRecord).length === 0
    ?
    <></>
    :
    <>
    <div className="title">{'주간 팀 타격 그래프'}</div>
    <div className="trackmanArea">
        <div style={{paddingTop: '5px'}}>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['avg']}/></div>
                <div><IndicatorChart data={teamRecord['obp']}/></div>
                <div><IndicatorChart data={teamRecord['slg']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['ops']}/></div>
                <div><IndicatorChart data={teamRecord['bbRate']}/></div>
                <div><IndicatorChart data={teamRecord['kRate']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['basicStat_hr']}/></div>
                <div><IndicatorChart data={teamRecord['basicStat_rbi']}/></div>
                <div><IndicatorChart data={teamRecord['basicStat_gdp']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['rPerGame']}/></div>
                <div><IndicatorChart /></div>
                <div><IndicatorChart /></div>
            </div>
        </div>
    </div>
    </>
    }
    </>
    )
}

export default WeeklyTeamBattingRecord;