import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import battingColumnCalc from '../../../../utils/table/battingColumnCalc';
import { useBatterRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function PlayerBattingHitRecord(props) {
    const statType = "hit";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCalced_stat_hit",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL,
        {key: 'season', name: '시즌', frozen: true, sortable: false},
        {key: 'batterTeamName', name: '팀', frozen: true, sortable: false, renderCell: useBatterRender},
        {key: 'batterName', width:75, name: '선수', frozen: true, sortable: false, renderCell: useBatterRender},
        {key: 'statCalced_stat_tpa', width: 55, name: '타석', renderHeaderCell: tooltipRender, tooltip: tooltip['tpa']},
        {key: 'statCalced_stat_hit', width: 55, name: '안타', renderHeaderCell: tooltipRender, tooltip: tooltip['hit']},
        {key: 'statCalced_avg', width: 55, name: '타율', renderHeaderCell: tooltipRender, tooltip: tooltip['avg']},
        {key: 'statCalced_obp', width: 63, name: '출루율', renderHeaderCell: tooltipRender, tooltip: tooltip['obp']},
        {key: 'statCalced_slg', width: 63, name: '장타율', renderHeaderCell: tooltipRender, tooltip: tooltip['slg']},
        {key: 'statCalced_ops', width: 63, name: 'OPS', renderHeaderCell: tooltipRender, tooltip: tooltip['ops']},
        {key: 'statCalced_babip', width: 68, name: 'BABIP', renderHeaderCell: tooltipRender, tooltip: tooltip['babip']},
        {key: 'statCalced_stat_groundBall', width: 50, name: 'GB', renderHeaderCell: tooltipRender, tooltip: tooltip['groundBall']},
        {key: 'statCalced_stat_flyBall', width: 50, name: 'FB', renderHeaderCell: tooltipRender, tooltip: tooltip['flyBall']},
        {key: 'statCalced_stat_lineDriveBall', width: 50, name: 'LB', renderHeaderCell: tooltipRender, tooltip: tooltip['lineDriveBall']},
        {key: 'statCalced_gdDivFly', width: 68, name: 'GB/FB', renderHeaderCell: tooltipRender, tooltip: tooltip['gdDivFly']},
        {key: 'statCalced_gdRate', width: 55, name: 'GB%', renderHeaderCell: tooltipRender, tooltip: tooltip['gdRate']},
        {key: 'statCalced_flyRate', width: 55, name: 'FB%', renderHeaderCell: tooltipRender, tooltip: tooltip['flyRate']},
        {key: 'statCalced_lineRate', width: 55, name: 'LD%', renderHeaderCell: tooltipRender, tooltip: tooltip['lineRate']},
        {key: 'statCalced_stat_hr', width: 55, name: '홈런', renderHeaderCell: tooltipRender, tooltip: tooltip['hr']},
        { name: "XH", width: 55, key: 'statCalced_stat_xh', renderHeaderCell: tooltipRender, tooltip: tooltip['xh']},
        { name: "XH/PA", width: 68, key: 'statCalced_xhPerPa', renderHeaderCell: tooltipRender, tooltip: tooltip['xhPerPa']},
        { name: "XH/AB", width: 68, key: 'statCalced_xhPerAb', renderHeaderCell: tooltipRender, tooltip: tooltip['xhPerAb']},
        { name: "XH/H", width: 68, key: 'statCalced_xhPerHit', renderHeaderCell: tooltipRender, tooltip: tooltip['xhPerHit']},
        { name: "PA/HR", width: 68, key: 'statCalced_paPerHr', renderHeaderCell: tooltipRender, tooltip: tooltip['paPerHr']},
        { name: "AB/HR", width: 68, key: 'statCalced_abPerHr', renderHeaderCell: tooltipRender, tooltip: tooltip['abPerHr']},
        { name: "HR/FLY", width: 68, key: 'statCalced_hrPerFly', renderHeaderCell: tooltipRender, tooltip: tooltip['hrPerFly']},
        { name: "HR/FO", width: 68, key: 'statCalced_hrPerFlyOut', renderHeaderCell: tooltipRender, tooltip: tooltip['hrPerFlyOut']},
        { name: "HR_1", width: 60, key: 'statCalced_stat_oneRbiHr', renderHeaderCell: tooltipRender, tooltip: tooltip['oneRbiHr']},
        { name: "HR_2", width: 60, key: 'statCalced_stat_twoRbiHr', renderHeaderCell: tooltipRender, tooltip: tooltip['twoRbiHr']},
        { name: "HR_3", width: 60, key: 'statCalced_stat_threeRbiHr', renderHeaderCell: tooltipRender, tooltip: tooltip['threeRbiHr']},
        { name: "HR_4", width: 60, key: 'statCalced_stat_fourRbiHr', renderHeaderCell: tooltipRender, tooltip: tooltip['fourRbiHr']},
        { name: "HR_Rp", width: 68, key: 'statCalced_stat_rightPitcherHr', renderHeaderCell: tooltipRender, tooltip: tooltip['rightPitcherHr']},
        { name: "HR_Lp", width: 68, key: 'statCalced_stat_leftPitcherHr', renderHeaderCell: tooltipRender, tooltip: tooltip['leftPitcherHr']}
    ];

    const rows = props['data'];

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = battingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function rowKeyGetter(row) {
        return row['batterId'];
    }

    const gridElement = (
        <DataGrid
        defaultColumnOptions={{
            sortable: true
        }}
        className="record_table_height"
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 타격 안타기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerBattingHitRecord;