import React, { useEffect, useState } from 'react';
import teamCode from '../../utils/team/teamCode';
import Calendar from 'react-calendar';
import moment from 'moment';
import _ from 'lodash';
import { fetchGameList, fetchLastGameDay } from '../../utils/api';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import INIT_SEASON from '../../utils/initSeason';
import Backdrop from '@mui/material/Backdrop';
import { Oval } from 'react-loader-spinner';
import WeeklyBatterRecord from '../../layout/weekly/player/weekly-batter-record';
import WeeklyPitcherRecord from '../../layout/weekly/player/weekly-pitcher-record';
import WeeklyBestMoment from '../../layout/weekly/moment/weekly-best-moment';
import WeeklyRotationInfo from '../../layout/weekly/player/weekly-rotation-info';
import WeeklyLineupInfo from '../../layout/weekly/player/weekly-lineup-info';
import WeeklyTeamRecord from '../../layout/weekly/team/weekly-team-record';
import WeeklyTeamBattingRecord from '../../layout/weekly/team/weekly-team-batting-record';
import WeeklyTeamPitchingRecord from '../../layout/weekly/team/weekly-team-pitching-record';
import WeeklyTeamDepth from '../../layout/weekly/team/weekly-team-depth';
import WeeklyReliefPitcherRecord from '../../layout/weekly/player/weekly-relief-pitcher-record';

const teams = teamCode();

function WeeklyMain() {
    const [season, setSeason] = useState('');
    const seasonArr = _.range(moment().format('YYYY')*1, INIT_SEASON-1);

    const [activeTeam, setActiveTeam] = useState('4001');

    const [open, setOpen] = useState(false);
    const [weekScheduleArr, setWeekScheduleArr] = useState([]);
    const [weekDate, setWeekDate] = useState(null);
    const todaySeason = moment().format('YYYY');

    useEffect(() => {
        fetchLastGameDay({
            seasons: [todaySeason, todaySeason-1].toString(),
            gameTypeCodes: 4201
        }).then((response) => {
            const {lastGameDay} = response['data']['RECORD'][0];
            const lastSeason = moment(lastGameDay).format('YYYY');
            setSeason(lastSeason);
        })
    }, [])

    useEffect(() => {
        if(season) {
            setOpen(true);
            const scheduleParam = {
                gameTypeCodes: 4201,
                seasons: season
            }
    
            fetchGameList(scheduleParam)
            .then((response) => {
                const gameList = response['data']['RECORD'];
    
                const minDate = _.minBy(gameList, 'gameDay')['gameDay'];
                const maxDate = _.maxBy(gameList, 'gameDay')['gameDay'];
                const today = moment().format('YYYY-MM-DD');

                if(maxDate > moment().format('YYYY-MM-DD') && todaySeason === moment(maxDate).format('YYYY')) {
                    const beforeToday = moment(today).add(-7, 'd').format('YYYY-MM-DD');
                    setWeekDate(getWeekObj(beforeToday));

                    const lastDate = getWeekObj(today);
                    const tmpArr = getWeekArr(lastDate, minDate, maxDate);
                    console.log(tmpArr);
                    setWeekScheduleArr(tmpArr);
                } else {
                    const maxFromDate = getWeekObj(maxDate);
                    setWeekDate(maxFromDate);

                    const lastDate = moment(maxFromDate).add(7, 'd').format('YYYY-MM-DD');
                    const tmpArr = getWeekArr(lastDate, minDate, maxDate);
                    setWeekScheduleArr(tmpArr);
                }
                
                setOpen(false);
            })
        }
    }, [season]);

    const handleChange = (e) => setSeason(e.target.value);

    const weekHandleChange = (e) => setWeekDate(e.target.value);

    const teamChangeEventHandler = (e) => setActiveTeam(e.target.value);

    const getWeekArr = (date, minDate, maxDate) => {
        let startDate = minDate;
        let endDate = date;

        let startFromDate = getWeekObj(startDate);
        let endFromDate = getWeekObj(endDate);

        return weekArrCalc(startFromDate, endFromDate);
    }

    const weekArrCalc = (startFromDate, endFromDate) => {
        let cloneStartFromDate = moment(startFromDate).clone().format('YYYY-MM-DD');

        let tmpArr = [];
        let num = 1;
        while(!moment(cloneStartFromDate).isSame(moment(endFromDate))) {
            const weekName = weekNumOfMonth(new Date(cloneStartFromDate));

            tmpArr.push({
                fromDate: cloneStartFromDate,
                toDate: moment(cloneStartFromDate).add(6, 'd').format('YYYY-MM-DD'),
                seasonWeekName: "시즌 "+num+"주차",
                weekName
            });

            cloneStartFromDate = moment(cloneStartFromDate).add(7, 'd').format('YYYY-MM-DD');

            num++;
        }

        return _.sortBy(tmpArr, 'fromDate').reverse();
    }

    const minusDayArr = [6, 0, 1, 2, 3, 4, 5];

    const getWeekObj = (date) => {
        const dateStr = new Date(date);

        const day = moment(date).format('e');
        const fromDate = moment(dateStr).add(-minusDayArr[day], 'd').format('YYYY-MM-DD');

        return fromDate;
    }

    const getToDate = (date) => {
        return moment(date).add(6, 'd').format('YYYY-MM-DD')
    }

    const weekNumOfMonth = (date) => {
        const WEEK_KOR = ['1주차', '2주차', '3주차', '4주차', '5주차'];
        const STANDARD_NUM = 1;

        let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
        let firstDayOfWeek = firstDate.getDay();

        let firstTuesday = 1 + STANDARD_NUM - firstDayOfWeek;
        if(firstTuesday <= 0){
            firstTuesday = firstTuesday + 7;
        }
        let untilDateOfFirstWeek = firstTuesday-7+3;	// 월요일기준으로 계산 (월요일부터 한주의 시작)
        let weekNum = Math.ceil((date.getDate()-untilDateOfFirstWeek) / 7) - 1;

        if(weekNum < 0){	// 첫째주 이하일 경우 전월 마지막주 계산
            let lastDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 0);
            let result = Date.weekNumOfMonth(lastDateOfMonth);
            return result;
        }

        return (date.getMonth()+1)+'월'+" "+WEEK_KOR[weekNum];
    }

    return (
    <div className="container" id="weekly_main_container">
        <div style={{display:'flex', margin: '15px 0px'}}>
            <div>
                <FormControl size={'small'}>
                    <Select
                    style={{backgroundColor: '#fff'}}
                    value={season}
                    onChange={handleChange}
                    >
                    {
                        _.map(seasonArr, (item) => (
                            <MenuItem value={item} key={item}>{item}</MenuItem>
                        ))
                    }
                    </Select>
                </FormControl>
            </div>
            <div style={{marginLeft: '10px'}}>
            {
                weekScheduleArr.length !== 0
                ?
                <FormControl size={'small'}>
                    <Select
                    style={{backgroundColor: '#fff'}}
                    value={weekDate}
                    onChange={weekHandleChange}
                    >
                    {
                        _.map(weekScheduleArr, (item) => (
                            <MenuItem value={item['fromDate']} key={item['fromDate']}>
                                {item['fromDate'].slice(5)}~{item['toDate'].slice(5)} ({item['weekName']})
                            </MenuItem>
                        ))
                    }
                    </Select>
                </FormControl>
                :
                <></>
            }
            </div>
            <div style={{marginLeft: '10px'}}>
                <FormControl size={'small'}>
                    <Select
                    value={activeTeam}
                    onChange={teamChangeEventHandler}
                    >
                    {
                    teams.map((team, idx) => (
                        <MenuItem value={team['teamCode']} key={team['teamCode']}>
                            {team['teamName']}
                        </MenuItem>
                    ))
                    }
                    </Select>
                </FormControl>
            </div>
        </div>
        <div>

        </div>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <Oval
        height="80" width="80"
        radius="9" color="#00bfff"
        secondaryColor="#fff"
        ariaLabel="three-dots-loading"
        />
        </Backdrop>
        {
        weekDate
        ?
        <>
        <div style={{display: 'flex'}}>
            <div className='half_div'>
                <div className='title'>
                    주간 포지션 소화도
                </div>
                <div id="weekly_depth">
                    <WeeklyTeamDepth fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
                </div>
            </div>
            <div className='half_div'>
                <WeeklyTeamRecord fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
                <WeeklyTeamBattingRecord fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
                <WeeklyTeamPitchingRecord fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{width: '50%', padding:'0px 5px'}}>
                <WeeklyBatterRecord fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
            </div>
            <div style={{width: '50%', padding:'0px 5px'}}>
                <WeeklyPitcherRecord fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <div style={{width: '50%'}}>
                <WeeklyLineupInfo fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
            </div>
            <div style={{width: '50%'}}>
                <WeeklyRotationInfo fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
            </div>
        </div>
        <div>
            <WeeklyBestMoment fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
        </div>
        <div>
            <WeeklyReliefPitcherRecord fromDate={weekDate} toDate={getToDate(weekDate)} teamId={activeTeam}/>
        </div>
        </>
        :
        <></>
        }
        
    </div>
    );
}

export default WeeklyMain;