import React, { useEffect, useState } from 'react';
import { fetchPitcherRecord } from '../../../utils/api';
import moment from 'moment';
import { loopFlatObject } from '../../../utils/flat';
import _ from 'lodash';
import IndicatorChart from '../../../components/chart/IndicatorChart';

function WeeklyTeamPitchingRecord(props) {
    const {fromDate, toDate, teamId} = props;
    const [teamRecord, setTeamRecord] = useState({});

    const statObj = [
        {stat: 'era', sort: 'desc', name: 'ERA'},
        {stat: 'whip', sort: 'desc', name: 'WHIP'},
        {stat: 'avg', sort: 'desc', name: '피안타율'},
        {stat: 'obp', sort: 'desc', name: 'OBP'},
        {stat: 'slg', sort: 'desc', name: 'SLG'},
        {stat: 'ops', sort: 'desc', name: 'OPS'},
        {stat: 'hr9', sort: 'desc', name: 'HR/9'},
        {stat: 'bb9', sort: 'desc', name: 'BB/9'},
        {stat: 'k9', sort: 'asc', name: 'K/9'},
        {stat: 'qs', sort: 'asc', name: '퀄리티스타트'},
        {stat: 'qsPlus', sort: 'asc', name: '퀄리티스타트+'},
        {stat: 'erPerGame', sort: 'desc', name: '경기당 자책점'},
    ];

    useEffect(() => {
        const seasons = moment(fromDate).format('YYYY');

        const teamParam = {
            seasons,
            gameTypeCodes: 4201,
            fromDate, toDate,
            groupingColumn: 'team'
        }

        const teamPitchingPromise = fetchPitcherRecord(teamParam);
        teamPitchingPromise.then((response) => {
            const teamPitchingRecord = loopFlatObject(response['data']['RECORD']);
            const calcedRecord = rankingCalc(teamId, teamPitchingRecord);
            const idxData = _.keyBy(calcedRecord, 'stat');
            setTeamRecord(idxData);
        })
    }, [fromDate, teamId]);

    function rankingCalc(teamId, digestRecord) {
        let tmpArr = [];
        _.forEach(statObj, (item, idx) => {
            const {stat} = item;

            let sortedData = _.sortBy(digestRecord, [(o) => {return o[stat]*1 }]);
            if(item['sort'] === 'asc') {
                sortedData.reverse();
            }

            const statRanking = _.findIndex(sortedData, (data) => data['pitcherTeamId'] === teamId);
            const statObj = _.find(sortedData, (data) => data['pitcherTeamId'] === teamId);

            tmpArr.push({stat: stat, value: statObj[stat], rank: statRanking+1, name: item['name']});
        })

        return tmpArr;
    }

    return (
    <>
    {
    Object.keys(teamRecord).length === 0
    ?
    <></>
    :
    <>
    <div className="title">{'주간 팀 투구 그래프'}</div>
    <div className="trackmanArea">
        <div style={{paddingTop: '5px'}}>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['era']}/></div>
                <div><IndicatorChart data={teamRecord['whip']}/></div>
                <div><IndicatorChart data={teamRecord['avg']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['obp']}/></div>
                <div><IndicatorChart data={teamRecord['slg']}/></div>
                <div><IndicatorChart data={teamRecord['ops']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['hr9']}/></div>
                <div><IndicatorChart data={teamRecord['bb9']}/></div>
                <div><IndicatorChart data={teamRecord['k9']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamRecord['qs']}/></div>
                <div><IndicatorChart data={teamRecord['qsPlus']}/></div>
                <div><IndicatorChart data={teamRecord['erPerGame']}/></div>
            </div>
        </div>
    </div>
    </>
    }
    </>
    )
}

export default WeeklyTeamPitchingRecord;