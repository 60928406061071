import React from "react";
import { Oval } from 'react-loader-spinner';

function LoaderSpinner() {
    return (
        <div id="OAuthLoader">
            <h3>로그인 중입니다. 잠시만 기다려주세요</h3>

            <Oval height="120" width="120" radius="9" color="#00bfff" ariaLabel="three-dots-loading" />
        </div>
    );
}

export default LoaderSpinner;