export function flatObject(obj) {
    const flatObject = {};
    const path = []; // current path

    function dig(obj) {
        if (obj !== Object(obj))
            /*is primitive, end of path*/
            return flatObject[path.join('_')] = obj; /*<- value*/ 
    
        //no? so this is an object with keys. go deeper on each key down
        for (let key in obj) {
            path.push(key);
            dig(obj[key]);
            path.pop();
        }
    }

    dig(obj);
    return flatObject;
}

export function loopFlatObject(data) {
    return data.map((item, idx) => {
        item = flatObject(item);
        return item;
    });
}