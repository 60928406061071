import React, { useEffect, useState } from 'react';
import { fetchGameBatterTableResultInfo, fetchGamePitcherResultInfo } from '../../../../utils/api';
import { useAtomValue } from 'jotai';
import { game_info_obj_atom } from '../../../../assets/model/detail/store';
import _ from 'lodash';
import { flatObject } from '../../../../utils/flat';
import { useNavigate } from 'react-router-dom';

function ResultRecord(props) {
    const gameInfoObj = useAtomValue(game_info_obj_atom);
    const {homeOrAway} = props;
    const [batterRecord, setBatterRecord] = useState([]);
    const [pitcherRecord, setPitcherRecord] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if(!_.isEmpty(gameInfoObj)) {
            const {awayTeamId, homeTeamId} = gameInfoObj;

            const gameParam = {
                gameInfoIds: gameInfoObj['gameInfoId'],
                gameTypeCodes: 4201
            }

            const batterInfoPromise = fetchGameBatterTableResultInfo(gameParam);
            const pitcherInfoPromise = fetchGamePitcherResultInfo(gameParam);

            Promise.all([
                batterInfoPromise, pitcherInfoPromise
            ]).then((response) => {
                const batterInfoRecord = _.groupBy(dataSettingFunc(response[0]['data']['RECORD']), 'batter_batterTeamId');
                const pitcherInfoRecord = _.groupBy(dataSettingFunc(response[1]['data']['RECORD']), 'pitcherTeamId');

                if(homeOrAway === 'away') {
                    setBatterRecord(batterInfoRecord[awayTeamId]);
                    setPitcherRecord(pitcherInfoRecord[awayTeamId]);
                } else {
                    setBatterRecord(batterInfoRecord[homeTeamId]);
                    setPitcherRecord(pitcherInfoRecord[homeTeamId]);
                }
            });
        }
    }, [gameInfoObj]);

    const dataSettingFunc = function(data) {
        return data.map((item, idx) => {
            item = flatObject(item);
            return item;
        })
    }

    function pitcherResultHandler(result) {
        if(result === 'WIN') {
            return <div className='win_div'>승</div>;
        }else if(result === 'LOSE') {
            return <div className='lose_div'>패</div>;
        }else if(result === 'HOLD') {
            return <div className='hold_div'>홀</div>;
        }else if(result === 'SAVE') {
            return <div className='save_div'>세</div>;
        }else{
            return '';
        }
    }

    function batterInfoHandler(e) {
        const playerId = e.currentTarget.id;
        const {season} = gameInfoObj;

        navigate(`/player/batter/${playerId}?season=${season}`);
    }

    function pitcherInfoHandler(e) {
        const playerId = e.currentTarget.id;
        const {season} = gameInfoObj;

        navigate(`/player/pitcher/${playerId}?season=${season}`);
    }

    return (
    <div id="result_record_container">
        <div id="result_record_wrap">
            <div className="batter_title">
                <span className={"font_color_"+(homeOrAway === 'home' ? gameInfoObj['homeTeamId'] : gameInfoObj['awayTeamId'])}>
                    {homeOrAway === 'home' ? gameInfoObj['homeTeamName'] : gameInfoObj['awayTeamName']}
                </span> 타자기록
            </div>
            <table className="hiball_table">
                <thead>
                    <tr>
                        <th>타순별</th>
                        <th width="50">수비위치</th>
                        <th width="80">이름</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>10</th>
                        <th>11</th>
                        <th>12</th>
                        <th>타수</th>
                        <th>안타</th>
                        <th>타점</th>
                        <th>득점</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(batterRecord, (item, idx) => (
                <tr key={item['batter_batterId']}>
                    <td className='first'>{item['battingOrder']}</td>
                    <td>{item['batterPositionName']}</td>
                    <td className='link' id={item['batter_batterId']}
                    onClick={batterInfoHandler}
                    >{item['batter_batterName']}</td>
                    <td>{item['inn1']}</td>
                    <td>{item['inn2']}</td>
                    <td>{item['inn3']}</td>
                    <td>{item['inn4']}</td>
                    <td>{item['inn5']}</td>
                    <td>{item['inn6']}</td>
                    <td>{item['inn7']}</td>
                    <td>{item['inn8']}</td>
                    <td>{item['inn9']}</td>
                    <td>{item['inn10']}</td>
                    <td>{item['inn11']}</td>
                    <td>{item['inn12']}</td>
                    <td>{item['ab']}</td>
                    <td>{item['hit']}</td>
                    <td>{item['rbi']}</td>
                    <td>{item['r']}</td>
                </tr>
                ))
                }
                </tbody>
            </table>
            <div className="pitcher_title">
                <span className={"font_color_"+(homeOrAway === 'home' ? gameInfoObj['homeTeamId'] : gameInfoObj['awayTeamId'])}>
                    {homeOrAway === 'home' ? gameInfoObj['homeTeamName'] : gameInfoObj['awayTeamName']}
                </span> 투수기록
            </div>
            <table className="hiball_table">
                <thead>
                <tr>
                    <th>구분</th>
                    <th>이름</th>
                    <th>유형</th>
                    <th>결과</th>
                    <th>이닝</th>
                    <th>타자</th>
                    <th>투구수</th>
                    <th>타수</th>
                    <th>안타</th>
                    <th>4사구</th>
                    <th>홈런</th>
                    <th>삼진</th>
                    <th>실점</th>
                    <th>자책</th>
                    <th>평균자책점</th>
                </tr>
                </thead>
                <tbody>
                {
                _.map(pitcherRecord, (item, idx) => (
                <tr key={item['pitcherId']}>
                    <td className='first'>{item['startYn'] === 'START' && '선발'}</td>
                    <td className='link' id={item['pitcherId']}
                    onClick={pitcherInfoHandler}
                    >{item['pitcherName']}</td>
                    <td>
                        {item['pitchingHand'] === 'L' ? '좌완' : '우완'}
                    </td>
                    <td>{pitcherResultHandler(item['pitcherResult'])}</td>
                    <td>{item['statCalced_ip']}</td>
                    <td>{item['stat_tpa']}</td>
                    <td>{item['stat_pitchingCnt']}</td>
                    <td>{item['stat_ab']}</td>
                    <td>{item['stat_hit']}</td>
                    <td>{item['stat_totalBb']}</td>
                    <td>{item['stat_hr']}</td>
                    <td>{item['stat_so']}</td>
                    <td>{item['stat_r']}</td>
                    <td>{item['stat_er']}</td>
                    <td>{item['statCalced_era']}</td>
                </tr>
                ))
                }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default ResultRecord;