import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fetchPlayerInfo } from '../../utils/api';
import _ from 'lodash';
import teamCode from '../../utils/team/teamCode';
import { Skeleton } from '@mui/material';
const teams = teamCode();

function PlayerInfoBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [playerInfo, setPlayerInfo] = useState({});
    const [draftArr, setDraftArr] = useState([]);
    const teamCodeArr = _.map(teams, 'teamCode');
    const playerId = params['playerId'];

    useEffect(() => {
        const playerParam = {
            membersId: playerId,
            seasons: season
        }

        fetchPlayerInfo(playerParam)
        .then((response) => {
            setPlayerInfo(response['data']['RECORD'][0]);

            firstJoinOrderCalc(response['data']['RECORD'][0]['firstJoinOrder'])
        })
    }, [playerId])

    function firstJoinOrderCalc(joinOrder) {
        if(joinOrder && joinOrder !== "") {
            const splitJoinOrder = joinOrder.split(" ");
            console.log(splitJoinOrder);

            const draftStrArr = [];
            let tempStr = "";
            let separateFlag = false;

            _.forEach(splitJoinOrder, (item, idx) => {
                if(item.indexOf("차") > -1 || item.indexOf("라운드") > -1 || item.indexOf("자유선발") > -1){
                    separateFlag = true;
                }

                if(separateFlag){
                    draftStrArr.push(tempStr);
                    tempStr = "";
                }

                if(idx === 0){
                    tempStr += item;
                } else {
                    tempStr += " " + item;
                }

                if(idx === splitJoinOrder.length - 1){
                    draftStrArr.push(tempStr);
                }
            });

            console.log(draftStrArr);

            setDraftArr(draftStrArr);
        }
    }

    const draftHandler = (event) => {
        let value = event.currentTarget.innerHTML;

        if(value.indexOf("라운드") > 0 || value.indexOf("순위") > 0){
            window.location.href = "/player/draft/ " + value.trim();
        } else {
            window.location.href = "/player/draft/" + value.trim();
        }
    }

    return (
    <div className="body">
        <div className="top">
            <div className="backnum">
                <div className="backnum_wrap">
                {
                _.isEmpty(playerInfo) ?
                <div></div>
                :
                (
                teamCodeArr.indexOf(playerInfo['team']) > -1
                ?
                <img src={require(`/src/assets/image/common/team_logo/logo_${playerInfo['team']}_profile.png`)} />
                :
                <Skeleton variant="rectangular" width={110} height={138} animation={false}/>
                )
                }
                <div className="backnum_text">
                    {playerInfo['lastBackNumber']}
                </div>
                </div>
            </div>
            <div className="profile">
                <div className="profile_title">
                    {playerInfo['lastBackNumber']}. {playerInfo['name']}
                </div>
                <div className="profile_content">
                    <table>
                        <tbody>
                        <tr>
                            <td>입단: </td>
                            
                            {
                            draftArr.length > 0
                            ?
                            <td>
                            {
                                draftArr.map((item, idx) => (
                                    <span key={idx}>
                                        {idx !== 0 && " "}
                                        <span className="playerListLink" onClick={draftHandler}>
                                            {item}
                                        </span>
                                    </span>
                                ))
                            }
                            </td>
                            :
                            <td></td>
                            }
                        </tr>
                        <tr>
                            <td>소속: </td><td>{playerInfo['teamName']}</td>
                        </tr>
                        <tr>
                            <td>투타: </td><td>{playerInfo['pitchingHand']}/{playerInfo['battingHand']}</td>
                        </tr>
                        <tr>
                            <td>출생: </td><td>{playerInfo['birthDate']}</td>
                        </tr>
                        <tr>
                            <td>신체: </td><td>{playerInfo['height']}cm / {playerInfo['weight']}kg</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    )
}

export default PlayerInfoBox;