export default function teamCode() {
    const teams = [
        {teamCode: 'all', teamName: "전체"},
        {teamCode: '1001', teamName: "롯데"},
        {teamCode: '2001', teamName: "한화"},
        {teamCode: '3001', teamName: "LG"},
        {teamCode: '4001', teamName: "NC"},
        {teamCode: '5001', teamName: "SSG"},
        {teamCode: '6001', teamName: "기아"},
        {teamCode: '7001', teamName: "키움"},
        {teamCode: '8001', teamName: "두산"},
        {teamCode: '9001', teamName: "삼성"},
        {teamCode: '10001', teamName: "KT"}
    ];

    return teams;
}