import { useAtom } from "jotai";
import styled from "styled-components";
import { player_batting_filter_atom } from "../../assets/model/detail/store";
import { useEffect, useState } from "react";
import moment from "moment";
import { fetchLastGameDay } from "../../utils/api";
import Grid from '@mui/material/Grid';
import BoxBasic from "./box/box-basic";
import SeasonSelectItem from "./item/select/season-select-item";
import BatterTeamSelectItem from './item/select/batter-team-select-item';
import BatterPlayerSelectItem from './item/select/batter-player-select-item';
import PitcherTeamSelectItem from './item/select/pitcher-team-select-item';
import PitcherPlayerSelectItem from './item/select/pitcher-player-select-item';
import MonthCheckboxItem from './item/checkbox/month-checkbox-item';
import StadiumCheckboxItem from './item/checkbox/stadium-checkbox-item';
import DayCheckboxItem from './item/checkbox/day-checkbox-item';
import HitTypeCheckboxItem from './item/checkbox/hit-type-checkbox-item';
import BattedTypeCheckboxItem from './item/checkbox/batted-type-checkbox-item';
import BattingHandSelectItem from './item/select/batting-hand-select-item';
import PitchingFormSelectItem from './item/select/pitching-form-select-item';
import PitchingHandSelectItem from './item/select/pitching-hand-select-item';
import PitchingZoneCheckboxItem from './item/checkbox/pitching-zone-checkbox-item';
import OutCountCheckboxItem from './item/checkbox/out-count-checkbox-item';
import BsCountCheckboxItem from './item/checkbox/bs-count-checkbox-item';
import BattingOrderCheckboxItem from './item/checkbox/batting-order-checkbox-item';
import RunnerStateCheckboxItem from './item/checkbox/runner-state-checkbox-item';
import InningCheckboxItem from './item/checkbox/inning-checkbox-item';
import HitResultCheckboxItem from './item/checkbox/hit-result-checkbox-item';
import BallCodeCheckboxItem from './item/checkbox/ball-code-checkbox-item';
import ScoreGapCheckboxItem from './item/checkbox/score-gap-checkbox-item';
import { Collapse } from '@mui/material';

import _ from "lodash";
import { Button } from '@mui/material';
import FilterBoxDiv from './box/filter-box-div';
import RegularTpaSelectItem from "./item/select/regular-tpa-select-item";

const FilterBox = FilterBoxDiv();

function PlayerBattingFilter() {
    const [filterObj, setFilterObj] = useAtom(player_batting_filter_atom);
    const [season, setSeason] = useState();
    const [defaultSeason, setDefaultSeason] = useState();

    const [innerFilterObj, setInnerFilterObj] = useState(filterObj);
    const [subFilterOpen, setSubFilterOpen] = useState(false);

    useEffect(() => {
        console.log(filterObj);
        const todaySeason = moment(new Date()).format('YYYY');
        fetchLastGameDay({
            seasons: [todaySeason, todaySeason-1].toString(),
            gameTypeCodes: 4201
        }).then((response) => {
            const {lastGameDay} = response['data']['RECORD'][0];
            const lastSeason = moment(lastGameDay).format('YYYY')*1;
            setDefaultSeason(lastSeason);
            if(filterObj['seasons']) {
                setSeason(filterObj['seasons']);
            } else {
                setSeason(lastSeason);
            }
        })

        console.log(innerFilterObj);
    }, []);

    if(!defaultSeason) {
        return <></>;
    }

    const buttonClickHandler = (event) => {
        const dataParam = {
            seasons: season,
            fromDate: moment(new Date(season, 0, 1)).format('YYYY-MM-DD'),
            toDate: moment(new Date(season, 11, 31)).format('YYYY-MM-DD'),
        }

        _.forEach(innerFilterObj, (value, name) => {
            if(name !== 'seasons' && name !== 'fromDate' && name !== 'toDate'){
                if(name === 'batterTeamId' || name === 'batterIds' 
                || name === 'pitcherTeamId' || name === 'pitcherIds') {
                    if(value !== 'all') {
                        dataParam[name] = value;
                    }
                } else {
                    if(value.length !== 0) {
                        dataParam[name] = value;
                    }
                }
            }
        });

        setFilterObj(dataParam);
    }

    const subFilterClickHandler = (event) => {
        setSubFilterOpen(!subFilterOpen);
    }

    return (
    <FilterBox>
    <Grid container spacing={0.5}>
        <Grid item xs={1}>
            <BoxBasic>시즌</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <SeasonSelectItem defaultSeason={defaultSeason}
            season={season} setSeason={setSeason} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>월</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <MonthCheckboxItem value={filterObj['months']}
            setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>요일</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <DayCheckboxItem value={filterObj['weekDays']}
            setValue={setInnerFilterObj} />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>구장</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <StadiumCheckboxItem value={filterObj['stadiums']}
            setValue={setInnerFilterObj} />
        </Grid>

        <Grid item xs={1}>
            <BoxBasic>타자팀</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BatterTeamSelectItem value={filterObj['batterTeamId']}
            setValue={setInnerFilterObj}/>
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>타자</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <BatterPlayerSelectItem season={season}
            value={innerFilterObj} setValue={setInnerFilterObj}
            />
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투수팀</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitcherTeamSelectItem value={filterObj['pitcherTeamId']}
            setValue={setInnerFilterObj}/>
        </Grid>
        <Grid item xs={1}>
            <BoxBasic>투수</BoxBasic>
        </Grid>
        <Grid item xs={2}>
            <PitcherPlayerSelectItem season={season} 
            value={innerFilterObj} setValue={setInnerFilterObj}
            />
        </Grid>
    </Grid>
    <Collapse in={subFilterOpen} timeout={'auto'}>
        <Grid container spacing={0.5}>
            <Grid item xs={12} style={{
                textAlign: 'center', fontWeight: "bold", fontSize: '18px'
            }}>
                확장
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>규정타석</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <RegularTpaSelectItem value={filterObj['regularTpa']} 
                setValue={setInnerFilterObj}
                />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>타격유형</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <HitTypeCheckboxItem value={filterObj['hitTypeCodes']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>타구유형</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <BattedTypeCheckboxItem value={filterObj['battedBallTypeCode']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>타격좌우</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <BattingHandSelectItem value={filterObj['battingHands']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>투구폼</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <PitchingFormSelectItem value={filterObj['pitchingForms']}
                setValue={setInnerFilterObj} />
            </Grid>

            <Grid item xs={1}>
                <BoxBasic>투구좌우</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <PitchingHandSelectItem value={filterObj['pitchingHands']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>투구존</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <PitchingZoneCheckboxItem value={filterObj['zoneStrikeYns']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>아웃카운트</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <OutCountCheckboxItem value={filterObj['beforeOutCounts']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>B-S</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <BsCountCheckboxItem value={filterObj['beforeBallStrikeCnts']}
                setValue={setInnerFilterObj} />
            </Grid>

            <Grid item xs={1}>
                <BoxBasic>타순</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <BattingOrderCheckboxItem value={filterObj['battingOrders']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>주자상황</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <RunnerStateCheckboxItem value={filterObj['beforeRunnerStates']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>이닝</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <InningCheckboxItem value={filterObj['innings']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>타격결과</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <HitResultCheckboxItem value={filterObj['hitResultCodes']}
                setValue={setInnerFilterObj} />
            </Grid>

            <Grid item xs={1}>
                <BoxBasic>구종</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <BallCodeCheckboxItem value={filterObj['ballCodes']}
                setValue={setInnerFilterObj} />
            </Grid>
            <Grid item xs={1}>
                <BoxBasic>득점차</BoxBasic>
            </Grid>
            <Grid item xs={2}>
                <ScoreGapCheckboxItem value={filterObj['scoreGap']}
                setValue={setInnerFilterObj} />
            </Grid>
        </Grid>
    </Collapse>
    <div onClick={subFilterClickHandler}
    style={{textAlign: 'center', border: '1px solid #e2e1e6', cursor: 'pointer'}}
    >
        필터 확장
    </div>
    <div style={{textAlign: 'center'}}>
        <Button variant='outlined' onClick={buttonClickHandler}>조회</Button>
    </div>
    </FilterBox>
    )
}

export default PlayerBattingFilter;