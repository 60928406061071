import React, { useEffect } from "react";
import axios from 'axios';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import LoaderSpinner from '../../components/oauth/LoaderSpinner';

const OAuthJoinPage = () => {
    const {userInfo, platform, policyId, policyAgreeYn}  = useOutletContext();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const callRegisterApi = async () => {
            await registerApi();
        }

        callRegisterApi();
    // eslint-disable-next-line
    }, []);

    const saveToken = (jwtToken) => {
        localStorage.setItem("accessToken", JSON.stringify(jwtToken));
    }

    const loginHandler = (uesrInfo) => {
        if(uesrInfo){
            let jwtToken = uesrInfo.token;

            if(jwtToken !== null){
                saveToken(jwtToken);
                const origin = location.state?.from?.pathname || '/';
                navigate(origin);
            } else {
                navigate('/error');
            }
        } else {
            navigate('/error');
        }
    }

    const registerApi = () =>
    new Promise((resolve) => {
        const params = {
            encParam: userInfo,
            policyId: policyId,
            policyAgreeYn: policyAgreeYn
        };

        let apiUrl = null;

        if(platform === "google"){
            apiUrl = `${process.env.REACT_APP_API_URL}/google/registerGoogleUser`;
        } else if(platform === "kakao") {
            apiUrl = `${process.env.REACT_APP_API_URL}/kakao/registerKakaoUser`;
        } else if(platform === "naver") {
            apiUrl = `${process.env.REACT_APP_API_URL}/naver/registerNaverUser`;
        } else {
            navigate('/error');
        }

        axios.post(apiUrl, params).then((response) => {
            // console.log(response);
            // console.log(response.data.GOOGLEINFO.token);

            let userInfo = null;

            if(platform === "google"){
                userInfo = response.data.GOOGLEINFO;
            } else if(platform === "kakao") {
                userInfo = response.data.KAKAOINFO;
            } else if(platform === "naver") {
                userInfo = response.data.NAVERINFO;
            } else {
                navigate('/error');
            }

            loginHandler(userInfo);

            resolve("resolved");
        })
        .catch((error) => {
            console.log('OAuth Login Failed');
            navigate('/error');
        });
    })

    return (
        <LoaderSpinner />
    )
};

export default OAuthJoinPage;