import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function DepthChart(props) {
    const playerData = props['playerData'];
    const limit = props['limit'];
    const season = props['season'] || null;
    const navigate = useNavigate();

    function playerClickHandler(event) {
        const playerObj = event.currentTarget.id;
        const playerId = playerObj.split('/')[0];
        const positionCode = playerObj.split('/')[1];

        if(positionCode == '1' || positionCode == '11') {
            navigate(`/player/pitcher/${playerId}?season=${season}`);
        } else {
            navigate(`/player/batter/${playerId}?season=${season}`);
        }
    }

    return (
        <>
        <table>
        <tbody>
        {
            _.map(playerData, (item, idx) => (
                idx < limit
                &&
                <tr key={item['membersId']}>
                    <td>
                        <span
                        id={item['membersId']+"/"+item['positionCode']}
                        onClick={season ? playerClickHandler : undefined}
                        style={{color: '#1b4397', cursor: 'pointer'}}
                        >
                        {item['name'].slice(0, 4)}
                        </span>
                    </td>
                    <td>{(item['fieldingRate']*100).toFixed(0)}%</td>
                </tr>
            ))
        }
        </tbody>
        </table>
        </>
    )
}

export default DepthChart;