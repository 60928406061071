import React, { Fragment, useEffect, useState } from 'react';
import { fetchTeamRecentLineupInfo } from '../../../utils/api';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

function TeamRecentLineupBox() {
    const params = useParams();
    const [lineupRecord, setLineupRecord] = useState([]);
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const navigate = useNavigate();

    useEffect(() => {
        const teamId = params['teamId'];
        const recordParam = {
            teamIds: teamId,
            gameTypeCodes: 4201,
            seasons: season
        }

        const teamRecentLineupPromise = fetchTeamRecentLineupInfo(recordParam);

        Promise.all([
            teamRecentLineupPromise
        ]).then((response) => {
            const groupedData = _.groupBy(response[0]['data']['RECORD'], (item, idx) => {
                return item['gameDay']+"_"+item['gameInfoId'];
            });

            const valuesData = _.values(groupedData);
            setLineupRecord(valuesData);
        })
    }, [])

    function playerClickHandler(event) {
        const playerObj = event.currentTarget.id;
        const playerId = playerObj.split('/')[0];
        const season = playerObj.split('/')[1];
        const positionTypeCode = playerObj.split('/')[2];
        if(positionTypeCode*1 === 5201) {
            navigate(`/player/pitcher/${playerId}?season=${season}`);
        } else {
            navigate(`/player/batter/${playerId}?season=${season}`);
        }
        
    }

    function gameInfoIdClickHandler(event) {
        const gameInfoId = event.currentTarget.id;
        navigate(`/gameschedule/result/${gameInfoId}`);
    }

    return (
    <div id="team_basic_container">
        <div className="table_title">라인업</div>
        <div className="table_body">
            <table className="hiball_table">
                <colgroup>
                    <col width="10%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                    <col width="9%"></col>
                </colgroup>
                <thead>
                    <tr>
                        <th>날짜</th>
                        <th>선발</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                    </tr>
                </thead>
                <tbody>
                {
                _.map(lineupRecord, (item, idx) => (
                    <tr key={item[0]['gameInfoId']}>
                    {
                    _.map(item, (innerItem, innerIdx) => (
                        <Fragment key={innerItem['battingOrder']+"_"+innerItem['gameInfoId']}>
                        {
                        innerIdx === 0 ?
                        <>
                        <td>
                            <span
                            id={innerItem['gameInfoId']}
                            onClick={gameInfoIdClickHandler}
                            style={{color: '#1b4397', cursor: 'pointer'}}
                            >
                            {innerItem['gameDay']}</span>
                        </td>
                        <td>
                            <span
                            id={innerItem['playerId']+"/"+innerItem['gameDay'].split('-')[0]+"/"+innerItem['positionTypeCode']}
                            onClick={playerClickHandler}
                            style={{color: '#1b4397', cursor: 'pointer'}}
                            >{innerItem['playerName']}</span>
                        </td>
                        </>
                        :
                        <td>
                            <span
                            id={innerItem['playerId']+"/"+innerItem['gameDay'].split('-')[0]+"/"+innerItem['positionTypeCode']}
                            onClick={playerClickHandler}
                            style={{color: '#1b4397', cursor: 'pointer'}}
                            >{innerItem['playerName']}</span>
                        </td>
                        }
                        </Fragment>
                    ))
                    }
                    </tr>
                ))
                }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default TeamRecentLineupBox;