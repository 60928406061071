export function useBatterTeamRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const teamId = row['batterTeamId'];
    const season = row['season'];

    const teamPlayerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const teamId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/team/${teamId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+teamId}
        onClick={teamPlayerInfoHandler}
        >{cellData}</span>
    )
}

export function useBatterRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const playerId = row['batterId'];
    const season = row['season'];

    const playerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const playerId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/player/batter/${playerId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+playerId}
        onClick={playerInfoHandler}
        >{cellData}</span>
    )
}

export function useRunnerTeamRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const teamId = row['runnerTeamId'];
    const season = row['season'];

    const teamPlayerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const teamId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/team/${teamId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+teamId}
        onClick={teamPlayerInfoHandler}
        >{cellData}</span>
    )
}

export function useRunnerRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const playerId = row['runnerId'];
    const season = row['season'];

    const playerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const playerId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/player/batter/${playerId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+playerId}
        onClick={playerInfoHandler}
        >{cellData}</span>
    )
}

export function usePitcherTeamRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const teamId = row['pitcherTeamId'];
    const season = row['season'];

    const teamPlayerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const teamId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/team/${teamId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+teamId}
        onClick={teamPlayerInfoHandler}
        >{cellData}</span>
    )
}

export function usePitcherRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const playerId = row['pitcherId'];
    const season = row['season'];

    const playerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const playerId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/player/pitcher/${playerId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+playerId}
        onClick={playerInfoHandler}
        >{cellData}</span>
    )
}

export function useFielderTeamRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const teamId = row['fielderTeamId'];
    const season = row['season'];

    const teamPlayerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const teamId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/team/${teamId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+teamId}
        onClick={teamPlayerInfoHandler}
        >{cellData}</span>
    )
}

export function useFielderRender({column, row, index}) {
    const {key} = column;
    const cellData = row[key];
    const playerId = row['fielderId'];
    const season = row['season'];

    const playerInfoHandler = (e) => {
        const teamObj = e.currentTarget.id;
        const playerId = teamObj.split("/")[1];
        const season = teamObj.split("/")[0];
        window.location.href = `/player/batter/${playerId}?season=${season}`;
    }

    return (
        <span className='link'
        id={season+"/"+playerId}
        onClick={playerInfoHandler}
        >{cellData}</span>
    )
}