import React, { useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import fieldingColumnCalc from '../../../../utils/table/fieldingColumnCalc';
import { useFielderRender, useFielderTeamRender } from '../../../../utils/cell/tableCellRender';
import { exportToCSV } from '../../../../utils/export/exportUtils';
import ExportCsvButton from '../../../../components/button/ExportCsvButton';
import { tooltipRender } from '../../../../utils/cell/tableTooltipRender';
import batterTooltip from '../../../../utils/tooltip/batterTooltip';
import RANKING_CELL from '../../../../utils/cell/rankingCellRender';
const tooltip = batterTooltip();

function PlayerFieldingInFielderRecord(props) {
    const statType = "infielder";
    const [sortColumns, setSortColumns] = useState([{
        columnKey: "statCacled_defenseInning",
        direction: "DESC"
    }]);

    const columns = [
        RANKING_CELL, 
        { name: '시즌', key: 'season', frozen: true, sortable: false},
        { name: '팀', key: 'fielderTeamName', frozen: true, sortable: false, renderCell: useFielderTeamRender},
        { name: '선수', width: 75, key: 'fielderName', frozen: true, sortable: false, renderCell: useFielderRender},
        { name: '출전', width: 55, key: 'stat_gameCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gameCnt']},
        { name: "선발", width: 55, key: 'stat_starter', renderHeaderCell: tooltipRender, tooltip: tooltip['starter']},
        { name: '수비이닝', width: 72, key: 'statCacled_defenseInning', renderHeaderCell: tooltipRender, tooltip: tooltip['ip']},
        { name: '자살', width: 55, key: 'stat_poCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['poCnt']},
        { name: '보살', width: 55, key: 'stat_aoCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['aoCnt']},
        { name: '실책', width: 55, key: 'stat_error', renderHeaderCell: tooltipRender, tooltip: tooltip['error']},
        { name: "야수선택", width: 72, key: 'stat_fielderChoice', renderHeaderCell: tooltipRender, tooltip: tooltip['fielderChoice']},
        { name: "내야안타", width: 72, key: 'stat_infielderHit', renderHeaderCell: tooltipRender, tooltip: tooltip['infielderHit']},
        { name: "수비기회", width: 72, key: 'stat_fieldingCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['fieldingCnt']},
        { name: "수비율", width: 63, key: 'statCacled_defenseRate', renderHeaderCell: tooltipRender, tooltip: tooltip['defenseRate']},
        { name: "처리기회", width: 72, key: 'stat_infielderCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['infielderCnt']},
        { name: "처리율", width: 63, key: 'statCacled_fieldingRate', renderHeaderCell: tooltipRender, tooltip: tooltip['fieldingRate']},
        { name: "번트기회", width: 72, key: 'statCacled_stat_buntCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['buntCnt']},
        { name: "번트처리", width: 72, key: 'statCacled_stat_buntOut', renderHeaderCell: tooltipRender, tooltip: tooltip['buntOut']},
        { name: "번트%", width: 63, key: 'statCacled_buntRate', renderHeaderCell: tooltipRender, tooltip: tooltip['buntRate']},
        { name: "병살기회", width: 72, key: 'stat_gdpCnt', renderHeaderCell: tooltipRender, tooltip: tooltip['gdpCnt']},
        { name: "병살처리", width: 72, key: 'stat_gdpOut', renderHeaderCell: tooltipRender, tooltip: tooltip['gdpOut']},
        { name: "병살%", width: 63, key: 'statCacled_gdpRate', renderHeaderCell: tooltipRender, tooltip: tooltip['gdpRate']}
    ];

    const rows = props['data'];

    function rowKeyGetter(row) {
        return row['fielderId'];
    }

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;
    
        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {

                const comparator = fieldingColumnCalc(statType, sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    const gridElement = (
        <DataGrid
        className="record_table_height"
        defaultColumnOptions={{
            sortable: true
        }}
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        />
    )

    function exportClickHandler(event) {
        exportToCSV(gridElement, '선수 수비 내야기록.csv');
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div>
        {gridElement}
    </div>
    </>
    )
}

export default PlayerFieldingInFielderRecord;