import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPlayerAllInfo } from '../../utils/api';
import _ from 'lodash';
import SearchDetailList from '../../components/search/SearchDetailList';

function PlayerListMain() {
    let [playerArr, setPlayerArr] = useState([]);

    const {searchColumn, searchStr} = useParams();

    useEffect(() => {
        let season = new Date().getFullYear();
        console.log(season);
        let params = {
            seasons: season
        }

        if(searchColumn === "career"){
            params['careerSummary'] = searchStr;
        } else if(searchColumn === "draft"){
            params['firstJoinOrder'] = searchStr;
        } else if(searchColumn === "join"){
            params['firstJoinYear'] = searchStr;
            params['groupingColumn'] = "pro";
        }

        fetchPlayerAllInfo(params)
        .then((response) => {
            console.log(response);

            let sortedProfiles = _.sortBy(response['data']['RECORD'], ['name']);
            setPlayerArr(sortedProfiles);
        })

    }, [searchColumn, searchStr]);

    return (
        <div className="container">
            <div id="playerListTitle">
            {
            searchColumn === "draft" &&
            "신인드래프트 " + searchStr
            }
            {
            searchColumn === "join" &&
            searchStr + "년 입단"
            }
            {
            (searchColumn !== "draft" && searchColumn !== "join") &&
            searchStr
            }
            </div>
            <div id="playerListWrap" className={playerArr.length > 4 ? "overflowItem" : ""}>
                { 
                playerArr.length !== 0 && 
                <SearchDetailList data={playerArr} />
                }
            </div>
        </div>
    );
}

export default PlayerListMain;