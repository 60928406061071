import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { game_today_atom, game_info_obj_atom } from '../../../../assets/model/detail/store';
import { fetchTeamDepth } from '../../../../utils/api';
import bg_stadium from '../../../../assets/image/common/bg_stadium.png';
import DepthChart from '../../../../components/chart/DepthChart';
import _ from 'lodash';
import moment from 'moment';
import { loopFlatObject } from '../../../../utils/flat';

function TodayDepthBox() {
    const gameDay = useAtomValue(game_today_atom);
    const teamArr = ['away','home'];
    const gameInfo = useAtomValue(game_info_obj_atom);
    const [teamDepth, setTeamDepth] = useState({awayDepth: {},homeDepth: {}});

    useEffect(() => {
        if(!_.isEmpty(gameInfo)) {
            const season = moment(gameDay).format('YYYY');
            const {awayTeamId, homeTeamId} = gameInfo;
            const commonParam = {
                seasons: season,
                gameTypeCodes: 4201,
                gameDay: moment(gameDay).format('YYYY-MM-DD')
            }

            const awayTeamParam = {
                teamIds: awayTeamId,
                ...commonParam
            };

            const homeTeamParam = {
                teamIds: homeTeamId,
                ...commonParam
            };

            const awayTeamDepth = fetchTeamDepth(awayTeamParam);
            const homeTeamDepth = fetchTeamDepth(homeTeamParam);

            Promise.all([
                awayTeamDepth, homeTeamDepth
            ]).then((response) => {
                const awayTeamData = _.groupBy(loopFlatObject(response[0]['data']['RECORD']), (item, idx) => {
                    return item['teamId']+"_"+item['positionCode'];
                });

                const homeTeamData = _.groupBy(loopFlatObject(response[1]['data']['RECORD']), (item, idx) => {
                    return item['teamId']+"_"+item['positionCode'];
                });

                setTeamDepth((prev) => {
                    return {
                        ...prev,
                        awayDepth: awayTeamData,
                        homeDepth: homeTeamData
                    }
                });
            })
        }
        

    }, [gameInfo])

    return (
    <div id="lower_tab4">
    {
    teamArr.map((team) => (
    <div className={"team_"+team} key={"team_"+team+"_2"}>
        <div className="head">
            <div className={`team_icon bg_color_${gameInfo[team+'TeamId']}`}>
                <img src={require(`/src/assets/image/common/icon_${team}.png`)} alt="icon_away"/>
                { (team == 'away') ? '원정' : '홈'}팀
            </div>
            <div className="team_logo_wrap">
                <div className="team_logo">
                    <img src={require(`/src/assets/image/common/team_logo/logo_${gameInfo[team+'TeamId']}_l.png`)} />
                </div>
            </div>
        </div>
        <div className="body">
            <div className="stadium_bg">
                <img src={bg_stadium} alt="bg_stadium"/>
            </div>
            <div className="depth_row1">
                <div className="depth_list team_lf">
                    <div className="position_name">
                        좌익수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_7']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_cf">
                    <div className="position_name">
                        중견수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_8']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_rf">
                    <div className="position_name">
                        우익수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_9']}
                        limit={3}/>
                    </div>
                </div>
            </div>
            <div className="depth_row2">
                <div className="depth_list team_ss">
                    <div className="position_name">
                        유격수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_6']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_2b">
                    <div className="position_name">
                        2루수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_4']}
                        limit={3}/>
                    </div>
                </div>
            </div>
            <div className="depth_row3">
                <div className="depth_list team_rp">
                    <div className="position_name">
                        구원
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_11']}
                        limit={9}/>
                    </div>
                </div>
                <div className="depth_list team_3b">
                    <div className="position_name">
                        3루수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_5']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_sp">
                    <div className="position_name">
                        선발
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_1']}
                        limit={5}/>
                    </div>
                </div>
                <div className="depth_list team_1b">
                    <div className="position_name">
                        1루수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_3']}
                        limit={3}/>
                    </div>
                </div>
                <div className="depth_list team_dh">
                    <div className="position_name">
                        지명타자
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_10']}
                        limit={3}/>
                    </div>
                </div>
            </div>
            <div className="depth_row4">
                <div className="depth_list team_c">
                    <div className="position_name">
                        포수
                    </div>
                    <div className="depth_content">
                        <DepthChart
                        season={gameInfo['season']}
                        playerData={teamDepth[team+'Depth'][gameInfo[team+'TeamId']+'_2']}
                        limit={3}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    ))
    }
    </div>
    )
}

export default TodayDepthBox;