import { useAtomValue } from 'jotai';
import React from 'react';
import { game_info_obj_atom } from '../../../assets/model/detail/store';
import TeamVsRecordBox from './team-vs-record-box';
import _ from 'lodash';

function HomeScheduleRecordBox() {
    let statTypeArr = ['season', 'match'];
    const gameInfoObj = useAtomValue(game_info_obj_atom);

    return (
        <>
            {
                statTypeArr.map((item, idx) => (
                    <TeamVsRecordBox statType={item} key={idx} gameInfoObj={gameInfoObj}/>
                ))
            }
        </>
    )
}

export default HomeScheduleRecordBox;