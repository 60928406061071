import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { fetchTeamRanking } from '../../../utils/api';

function ActiveTeamRecord(props) {
    const [teamRecord, setTeamRecord] = useState({});
    const gameDay = moment(props['gameDay']).format('YYYY-MM-DD');
    const teamId = props['teamCode'];

    useEffect(() => {
        if(teamId !== 'all') {
            const seasons = moment(gameDay).format('YYYY');
            const month = moment(gameDay).format('MM')*1;

            const teamParam = {
                seasons: moment(gameDay).format('YYYY'),
                fromDate: moment([seasons, 0, 1]).month(month-1).format('YYYY-MM-DD'),
                toDate: moment([seasons, 0, 31]).month(month-1).format('YYYY-MM-DD'),
                gameTypeCodes: 4201,
                gameDay: gameDay
            }

            fetchTeamRanking(teamParam)
            .then((response) => {
                const teamRecordByKey = _.keyBy(_.values(response['data']['RECORD'])[0], 'teamId');
                setTeamRecord(teamRecordByKey[teamId]);
            })
        } else {
            setTeamRecord({});
        }
    }, [gameDay, teamId]);

    function teamRecordRender() {
        if(teamId === 'all') {
            return <></>
        } else {
            const findTeamRecord = teamRecord;
            const {
                teamName,
                homeWin, homeDraw, homeLose,
                awayWin, awayDraw, awayLose,
                win, draw, lose, gameDay
            } = findTeamRecord;

            const month = moment(gameDay).format('MM');
            const season = moment(gameDay).format('YYYY');

            return (
            <>
            {season}년 {month}월 {teamName}성적
            &nbsp;
            {win}승 {lose}패 {draw}무
            &nbsp;
            홈: {homeWin}승 {homeLose}패 {homeDraw}무
            &nbsp;
            원정: {awayWin}승 {awayLose}패 {awayDraw}무 
            </>
            )
        }
    }

    if(_.isEmpty(teamRecord)) {
        return <></>
    }

    return (
    <>
    {teamRecordRender()}
    </>
    )
}

export default ActiveTeamRecord;