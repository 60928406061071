import React, { Fragment, useEffect } from 'react';

function DayVerticalChart(props) {
    const teamRecord = props['teamRecord'];

    const teamArr = ['away', 'home'];
    const statArr = ['day_3', 'day_4', 'day_5', 'day_6', 'day_7', 'day_1'];

    useEffect(() => {

    }, [teamRecord]);

    const winRateCalc = function(data, property, teamType) {
        const awayData = data['awayRecord'][property];
        const homeData = data['homeRecord'][property];

        if(awayData && homeData) {
            if(teamType === 'away') {
                if(awayData['winRate'] > homeData['winRate']) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if(homeData['winRate'] > awayData['winRate']) {
                    return true;
                } else {
                    return false;
                }
            }
        } else if(awayData || homeData) {
            return false;
        }
    }

    const graphWidthCalcPer = function(data) {
        const basicWidth = 40;
        const maxWidth = 207
        if(data) {
            let width = maxWidth*(data['winRate']);
            if(width > maxWidth) {
                return (basicWidth+maxWidth)+'px';
            } else {
                return (basicWidth+width)+'px';
            }
        } else {
            return '0px';
        }
    }

    return (
        teamArr.map((team, teamIdx) => (
            <Fragment key={"team_"+team}>
            <div className={"team_"+team}>
            {
            statArr.map((item, idx) => (
                <div id={"record_"+item+"_team_"+team+"_bar"+(idx+1)} key={team+"_"+item}>
                    <div className="bar_border">
                        <div className={'bar_graph '+(winRateCalc(teamRecord, item, team) ? 'active' : '')}
                        style={{width: graphWidthCalcPer(teamRecord[team+'Record'][item])}}
                        >
                            <div className="bar_value">
                            {teamRecord[team+'Record'][item] ? (teamRecord[team+'Record'][item]['winRate']*100).toFixed(1) : '0.0'}
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }
            </div>
            {
            teamIdx === 0
            &&
            <div className="bar_title">
                <div>화</div>
                <div>수</div>
                <div>목</div>
                <div>금</div>
                <div>토</div>
                <div>일</div>
            </div>
            }
            </Fragment>
        ))
        )
}

export default DayVerticalChart;