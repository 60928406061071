import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { filter_obj_atom } from '../../assets/model/detail/store';
import _ from 'lodash';
import { fetchPitchLog } from '../../utils/api';
import PitchLogsRecordTable from './sub/pitch-logs-record-table';
import Backdrop from '@mui/material/Backdrop';
import { Oval } from 'react-loader-spinner';

function PitchLogsRecord() {
    const filterObj = useAtomValue(filter_obj_atom);
    const [record, setRecord] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        console.log(filterObj);
        if(!_.isEmpty(filterObj)) {
            setOpen(true);
            const param = filterObj;
            param['gameTypeCodes'] = 4201;

            fetchPitchLog(param)
            .then((response) => {
                console.log(response);
                const logRecord = response['data']['RECORD'];
                setRecord(logRecord);
                setOpen(false);
            })
        }
    }, [filterObj])

    if(_.isEmpty(filterObj)) {
        return (
            <div style={{display:'flex', alignItems: 'center', justifyContent:'center', height: '500px'}}>
                <h3>팀과 타자를 선택하여 조회 하세요.</h3>
            </div>
        )
    }

    return (
    <>
    <PitchLogsRecordTable data={record} />
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
    >
    <Oval
    height="80" width="80"
    radius="9" color="#00bfff"
    secondaryColor="#fff"
    ariaLabel="three-dots-loading"
    />
    </Backdrop>
    </>
    )
}

export default PitchLogsRecord;