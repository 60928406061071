import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, Checkbox } from '@mui/material';
import { fetchUniqPlayerRoster } from '../../../../utils/api';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';

function PitcherPlayerSelectItem(props) {
    const {season, value:{pitcherTeamId, pitcherIds}, setValue} = props;
    const [pitcherArr, setPitcherArr] = useState([{playerId: 'all', playerName: '전체'}]);
    const [checkIdArr, setCheckIdArr] = useState(pitcherIds || []);

    useEffect(() => {
        const playerParam = {
            gameTypeCodes: 4201,
            seasons: season
        }

        if(pitcherTeamId !== 'all') {
            playerParam['teamIds'] = pitcherTeamId;
        };

        fetchUniqPlayerRoster(playerParam)
        .then((response) => {
            const filterPitcherPlayer = _.filter(response['data']['RECORD'], (item, idx) => {
                return [5102, 5104].indexOf(item['rosterTypeCode']*1) > -1;
            });

            setFilterFunc([]);
            setPitcherArr(filterPitcherPlayer);
        });
    }, [season, pitcherTeamId]);

    const handleOnChange = (event) => {
        const { target: { value }} = event;
        setCheckIdArr(
            typeof value === 'string' ? value.split(',') : value
        );
    }

    const handleOnClose = (event) => {
        setFilterFunc(checkIdArr);
    }

    const setFilterFunc = (value) => {
        return setValue((prev) => {
            return {...prev, pitcherIds: value}
        })
    }

    return (
    <>
    <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="pitcher-checkbox-label"
        sx={{fontSize: 12}}
        >투수</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="pitcher-checkbox-label"
        id="pitcher-checkbox"
        value={checkIdArr}
        onChange={handleOnChange}
        onClose={handleOnClose}
        multiple
        input={<OutlinedInput label="투수" />}
        renderValue={(selected) => {
            const selectedArr = _.filter(pitcherArr, (item, idx) => selected.indexOf(item['playerId']) > -1);
            const selectedName = _.map(selectedArr, 'playerName');
            return selectedName.join(', ');
        }}
        >
        {
        _.map(pitcherArr, (item, idx) => (
            <MenuItem key={item['playerId']} value={item['playerId']}>
                <Checkbox checked={checkIdArr.indexOf(item['playerId']) > -1}/>
                <ListItemText primary={item['playerName']} />
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default PitcherPlayerSelectItem;