import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TeamBattingRecord from '../../../layout/record/team/team-batting-record';
import TeamPitchingRecord from '../../../layout/record/team/team-pitching-record';
import TeamFieldingRecord from '../../../layout/record/team/team-fielding-record';
import { useSetAtom } from 'jotai';
import { team_batting_filter_atom, team_fielding_filter_atom, team_pitching_filter_atom } from '../../../assets/model/detail/store';

function TeamRecordMain() {
    const navigate = useNavigate();
    const params = useParams();
    const subId = params['subId'];
    const setBattingFilterObj = useSetAtom(team_batting_filter_atom);
    const setPitchingFilterObj = useSetAtom(team_pitching_filter_atom);
    const setFieldingFilterObj = useSetAtom(team_fielding_filter_atom);

    useEffect(() => {
        return () => {
            if(subId === 'batting') {
                localStorage.removeItem('teamBattingFilter');
                setBattingFilterObj({})
            } else if(subId === 'pitching'){
                localStorage.removeItem('teamPitchingFilter');
                setPitchingFilterObj({})
            } else if(subId === 'fielding') {
                localStorage.removeItem('teamFieldingFilter');
                setFieldingFilterObj({})
            }
        }
    }, [subId])

    const TeamRecordTabContent = function() {
        switch(subId) {
            case 'batting':
                return <TeamBattingRecord/>
            case 'pitching':
                return <TeamPitchingRecord/>
            case 'fielding':
                return <TeamFieldingRecord/>
            default:
                return <></>
        }
    }

    return (
    <div className="container">
        <div id="record_tab_container">
            <div id="record_tab_area">
                <div 
                className={"btn "+((subId === "batting") && "active")}
                onClick={() => navigate('/record/team/batting/basic')}
                >타격</div>
                <div
                className={"btn "+((subId === "pitching") && "active")}
                onClick={() => navigate('/record/team/pitching/basic')}
                >투구</div>
                <div
                className={"btn "+((subId === "fielding") && "active")}
                onClick={() => navigate('/record/team/fielding/basic')}
                >수비</div>
            </div>
        </div>
        <div id="record_main_container">
            <TeamRecordTabContent/>
        </div>
    </div>
    )
}

export default TeamRecordMain;