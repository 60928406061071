import React from 'react';
import { Provider } from 'jotai';
import TodayGameDetailBox from '../../layout/todaygame/detail/today-game-detail-box';

function TodayGameDetail() {
    return (
        <Provider>
            <TodayGameDetailBox />
        </Provider>
    )
}

export default TodayGameDetail;