import { FormControl, MenuItem, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import _ from 'lodash';
import React, { useState } from 'react';

const rows = [
    {id: 'N', name: '국내선수'},
    {id: 'Y', name: '해외선수'}
];

function PitcherForignerSelectItem(props) {
    const {setValue} = props;
    const [selectId, setSelectId] = useState([]);

    const handleOnChange = (event) => {
        const {target:{value}} = event
        setSelectId(value);
        setValue((prev) => {
            return {...prev, pitchingForeignerYn: value}
        })
    }

    return (
    <>
        <FormControl sx={{m:0.5, width: 150 }} size="small">
        <InputLabel 
        id="pitcher-foreigner-select-label"
        sx={{fontSize: 12}}
        >투수구분</InputLabel>
        <Select
        sx={{height: 30}}
        labelId="pitcher-foreigner-select-label"
        id="pitcher-foreigner-select"
        value={selectId}
        onChange={handleOnChange}
        input={<OutlinedInput label="투수구분" />}
        >
        <MenuItem value="">전체</MenuItem>
        {
        _.map(rows, (item, idx) => (
            <MenuItem key={"pitcherPosition"+item['id']} value={item['id']}>
                {item['name']}
            </MenuItem>
        ))
        }
        </Select>
    </FormControl>
    </>
    )
}

export default PitcherForignerSelectItem;