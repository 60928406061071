import React, { Fragment, useEffect } from 'react';

function ScoreVerticalChart(props) {
    const teamRecord = props['teamRecord'];

    const teamArr = ['away', 'home'];
    const statArr = ['score_1', 'score_2', 'score_3', 'score_4', 'score_5'];

    useEffect(() => {
        
    }, [teamRecord]);

    const winRateCalc = function(data, property, teamType) {
        const awayData = data['awayRecord'][property];
        const homeData = data['homeRecord'][property];

        if(awayData && homeData) {
            if(teamType === 'away') {
                if(awayData['winRate'] > homeData['winRate']) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if(homeData['winRate'] > awayData['winRate']) {
                    return true;
                } else {
                    return false;
                }
            }
        } else if(awayData || homeData) {
            return false;
        }
    }

    const graphWidthCalc = function(data) {
        const maxWidth = 230;
        let width = maxWidth*(data/10);

        if(width > 230) {
            return '230px';
        } else {
            return width+'px';
        }
    }

    const graphWidthCalcPer = function(data) {
        const basicWidth = 40;
        const maxWidth = 207
        if(data) {
            let width = maxWidth*(data['winRate']);
            if(width > maxWidth) {
                return (basicWidth+maxWidth)+'px';
            } else {
                return (basicWidth+width)+'px';
            }
        } else {
            return '0px';
        }
    }
    
    return (
    teamArr.map((team, teamIdx) => (
        <Fragment key={"team_"+team}>
        <div className={"team_"+team}>
        {
        statArr.map((item, idx) => (
            <div id={"record_"+item+"_team_"+team+"_bar"+(idx+1)} key={team+"_"+item}>
                <div className="bar_border">
                    <div className={'bar_graph '+(winRateCalc(teamRecord, item, team) ? 'active' : '')}
                    style={{width: graphWidthCalcPer(teamRecord[team+'Record'][item])}}
                    >
                        <div className="bar_value">
                        {teamRecord[team+'Record'][item] ? (teamRecord[team+'Record'][item]['winRate']*100).toFixed(1) : '0.0'}
                        </div>
                    </div>
                </div>
            </div>
        ))
        }
        </div>
        {
        teamIdx === 0
        &&
        <div className="bar_title">
            <div>1점차 승률</div>
            <div>2점차 승률</div>
            <div>3점차 승률</div>
            <div>4점차 승률</div>
            <div>5점차 이상</div>
        </div>
        }
        </Fragment>
    ))
    )
}

export default ScoreVerticalChart;