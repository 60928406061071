import React from 'react';
import DataGrid from 'react-data-grid';
import { exportToCSV } from '../../../utils/export/exportUtils';
import ExportCsvButton from '../../../components/button/ExportCsvButton';

function PitchLogsRecordTable(props) {
    const columns = [
        { name: "날짜", key: 'gameDay', frozen: true, width: 80},
        { name: "이닝", key: 'inning', frozen: true, width: 55, renderCell: inningRender},
        { name: "타자팀", key: 'batterTeamName', frozen: true, width: 55},
        { name: "타자", key: 'batterName', frozen: true, width: 75},
        { name: "투수팀", key: 'pitcherTeamName', width: 55},
        { name: "투수", key: 'pitcherName', width: 75},
        { name: "포수", key: 'catcherName', width: 75},
        { name: "누적투구수", key: 'accmPitchingCnt'},
        { name: "구종", key: 'ballCodeName', width: 70},
        { name: "구속", key: 'ballSpeed'},
        { name: `전B-S`, key: 'beforeDecidedCount'},
        { name: `후B-S`, key: 'afterDecidedCount'},
        { name: `전 주자`, key: 'beforeRunnerState', width: 70, renderCell: runnerStateRender},
        { name: `후 주자`, key: 'runnerState', width: 70, renderCell: runnerStateRender},
        { name: "결과", key: 'hitResultName', width: 90},
        { name: "전 out", key: 'beforeOutCount'},
        { name: "후 out", key: 'outCount'},
        { name: "S 스윙", key: 'sSwing'},
        { name: "B 스윙", key: 'bSwing'},
        { name: "S 컨택", key: 'sContact'},
        { name: "B 컨택", key: 'bContact'},
        { name: "S 스탠드", key: 'sStand'},
        { name: "B 스탠드", key: 'bStand'},
        { name: `WE`, key: 'beforeWe'},
        // { name: "타격랭크", key: 'battedBallRank'},
        { name: "li", key : 'li', width:'5%'},
        { name: "타격유형", key: 'battedBallTypeName'},
        // { name:"xBA", key: 'xba'},
        // { name:"xSLG", key: 'xslg'},
        // { name:"xwOBA", key: 'xwoba'},
    ];

    function inningRender({column, row, index}) {
        const {key} = column;
        const cellData = row[key];
        const inningTb = row['inningTb']*1;

        if(inningTb === 0) {
            return <>{cellData+"회초"}</>
        } else {
            return <>{cellData+"회말"}</>
        }
    }

    function runnerStateRender({column, row, index}) {
        const {key} = column;
        const cellData = row[key];

        return <>{runnerState(cellData*1)}</>
    }

    function runnerState(state) {
		let stateName = "";
		if(state==0) stateName='주자없음';
		else if(state==1) stateName="1루";
		else if(state==2) stateName="2루";
		else if(state==3) stateName="1,2루";
		else if(state==4) stateName="3루";
		else if(state==5) stateName="1,3루";
		else if(state==6) stateName="2,3루";
		else if(state==7) stateName="만루";
		return stateName;
	}

    const rows = props['data'];

    function rowKeyGetter(row) {
        return row['gameRecordId'];
    }

    const gridElement = (
        <DataGrid
        className="record_table_height"
        rowKeyGetter={rowKeyGetter}
        columns={columns} 
        rows={rows}
        />
    );

    function exportClickHandler(event) {
        exportToCSV(gridElement, 'PitchLogs.csv')
    }

    return (
    <>
    <div style={{textAlign:'right', padding: '5px'}}>
        <ExportCsvButton
        onClick={exportClickHandler}
        >
        CSV
        </ExportCsvButton>
    </div>
    <div id="logs_container">
    {gridElement}
    </div>
    </>
    )
}

export default PitchLogsRecordTable;