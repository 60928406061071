import React, { useState, useEffect } from "react";
import { privateApi } from "../../interceptor/axios-interceptor";
import { Outlet } from "react-router-dom";
import BoardNav from "./sub/board-nav";
import { decodeToken } from "../../utils/decodeToken";

const BoardLayout = () => {
    const [firstBoardId, setFirstBoardId] = useState();
    const [boardData, setBoardData] = useState();
    const [inputKeyword, setInputKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState('1');
    let decodedToken = decodeToken();

    useEffect(() => {
        const fetchData = async () => {
            const boardData = await getBoard();
            setBoardData(boardData.RECORD);
            setFirstBoardId(boardData.RECORD[0].boardId);
        };
        fetchData();
    }, []);

    const getBoard = () =>
        new Promise((resolve) => {
            const params = {};

            privateApi.post(`${process.env.REACT_APP_API_URL}/board/selectBoard`, params).then((Response)=>{
                // console.log(Response.data);

                resolve(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
    });

    return (
        <div className="container boardContainer">
            <div id="boardWrap">
                <BoardNav boardList={boardData} firstBoardId={firstBoardId} setCurrentPage={setCurrentPage} setInputKeyword={setInputKeyword} setSearchKeyword={setSearchKeyword} />

                <Outlet context={{boardData, firstBoardId, currentPage, setCurrentPage, inputKeyword, setInputKeyword, searchKeyword, setSearchKeyword, decodedToken}} />
            </div>

            <div id="adWrap">
                광고
            </div>
        </div>
    );
};

export default BoardLayout;