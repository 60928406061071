export default function distBallSpeedCode() {
    const distBallSpeedCodes = [
        {distBallSpeed: '110km 이하', distBallSpeedCode: 1},
        {distBallSpeed: '110km~120km 이하', distBallSpeedCode: 2},
        {distBallSpeed: '120km~130km 이하', distBallSpeedCode: 3},
        {distBallSpeed: '130km~140km 이하', distBallSpeedCode: 4},
        {distBallSpeed: '140km~145km 이하', distBallSpeedCode: 5},
        {distBallSpeed: '145km~150km 이하', distBallSpeedCode: 6},
        {distBallSpeed: '150km 초과', distBallSpeedCode: 7},
    ]

    return distBallSpeedCodes;
}