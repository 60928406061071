import { atom } from "jotai";
import { atomWithStorage } from 'jotai/utils';
import moment from "moment";

export const game_info_id_atom = atom(0);
export const game_info_obj_atom = atom({});
export const game_day_atom = atom(moment(new Date()).format('YYYY-MM-DD'));

export const game_today_atom = atom(null);

export const filter_obj_atom = atom({});

const teamBattingFilter = "teamBattingFilter";
const teamPitchingFilter = "teamPitchingFilter";
const teamFieldingFilter = "teamFieldingFilter";
const playerBattingFilter = "playerBattingFilter";
const playerPitchingFilter = "playerPitchingFilter";
const playerFieldingFilter = "playerFieldingFilter";

export const team_batting_filter_atom = atomWithStorage(
    teamBattingFilter,
    localStorage.getItem(teamBattingFilter) ? JSON.parse(localStorage.getItem(teamBattingFilter)) : {}
);

team_batting_filter_atom.onMount = (setAtom) => {
    localStorage.removeItem(teamPitchingFilter);
    localStorage.removeItem(teamFieldingFilter);
}

export const team_pitching_filter_atom = atomWithStorage(
    teamPitchingFilter,
    localStorage.getItem(teamPitchingFilter) ? JSON.parse(localStorage.getItem(teamPitchingFilter)) : {}
);

team_pitching_filter_atom.onMount = () => {
    localStorage.removeItem(teamBattingFilter);
    localStorage.removeItem(teamFieldingFilter);
}

export const team_fielding_filter_atom = atomWithStorage(
    teamFieldingFilter,
    localStorage.getItem(teamFieldingFilter) ? JSON.parse(localStorage.getItem(teamFieldingFilter)) : {}
)

team_fielding_filter_atom.onMount = () => {
    localStorage.removeItem(teamBattingFilter);
    localStorage.removeItem(teamPitchingFilter);
}

export const player_batting_filter_atom = atomWithStorage(
    playerBattingFilter,
    localStorage.getItem(playerBattingFilter) ? JSON.parse(localStorage.getItem(playerBattingFilter)) : {}
)

player_batting_filter_atom.onMount = () => {
    localStorage.removeItem(playerPitchingFilter);
    localStorage.removeItem(playerFieldingFilter);
}

export const player_pitching_filter_atom = atomWithStorage(
    playerPitchingFilter,
    localStorage.getItem(playerPitchingFilter) ? JSON.parse(localStorage.getItem(playerPitchingFilter)) : {}
)

player_pitching_filter_atom.onMount = () => {
    localStorage.removeItem(playerBattingFilter);
    localStorage.removeItem(playerFieldingFilter);
}

export const player_fielding_filter_atom = atomWithStorage(
    playerFieldingFilter,
    localStorage.getItem(playerFieldingFilter) ? JSON.parse(localStorage.getItem(playerFieldingFilter)) : {}
)

player_fielding_filter_atom.onMount = () => {
    localStorage.removeItem(playerBattingFilter);
    localStorage.removeItem(playerPitchingFilter);
}



filter_obj_atom.onMount = (setAtom) => {
    console.log('onMount');
    return () => {
        console.log('unMount');
    }
}

game_today_atom.onMount = (setAtom) => {
    console.log('game_today_atom onMount');

    return () => {
        console.log('game_today_atom unMount');
    }
}

game_day_atom.onMount = (setAtom) => {
    console.log('game_day_atom onMount');

    return () => {
        console.log('game_day_atom unMount');
    }
}