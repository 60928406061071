import React from 'react';
import TeamInfoBox from '../../layout/team/team-info-box';
import TeamRecordBox from '../../layout/team/team-record-box';
import TeamBattingDigestBox from '../../layout/team/team-batting-digest-box';
import TeamPitchingDigestBox from '../../layout/team/team-pitching-digest-box';
import ad1 from '../../assets/image/ad/ad1.png';
import TeamVersusBox from '../../layout/team/team-versus-box';
import TeamBestDepthBox from '../../layout/team/team-best-depth-box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import teamCode from '../../utils/team/teamCode';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

function TeamInfoMain() {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');

    const handleChange = (event) => {
        const teamId = event.target.value;
        window.location.href = `/team/${teamId}?season=${season}`;
    }

    return (
    <>
    <div className="container">
        {
        <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
            <Select
            defaultValue={params['teamId']}
            id="team-selectbox"
            onChange={handleChange}
            >
            {
            teamCode().map((item, idx) => (
                <MenuItem key={item['teamCode']}
                value={item['teamCode']}>{item['teamName']}</MenuItem>
            ))
            }
            </Select>
        </FormControl>
        }
    </div>
    <div className="container">
        <div id="team_info_container">
            <div className="team_info_row">
                <div className="info_wrap">
                    <TeamInfoBox />
                </div>
                <div className="info_wrap">
                    <TeamBattingDigestBox/>
                </div>
                <div className="info_wrap">
                    <TeamPitchingDigestBox/>
                </div>
            </div>
            <div className="team_info_row">
                <div className="info_wrap">
                    <img src={ad1} width='100%' height='100%' alt="ad"/>
                </div>
                <div className="info_wrap">
                    <TeamVersusBox/>
                </div>
                <div className="info_wrap">
                    <TeamBestDepthBox/>
                </div>
            </div>
        </div>
        <div id="team_record_container">
            <div id="team_tab_wrap">
                <TeamRecordBox />
            </div>
        </div>
    </div>
    </>
    )
}

export default TeamInfoMain;