import React, { useEffect, useState } from 'react';
import { fetchBatterRecord, fetchLastGameDay, fetchTeamRanking } from '../../utils/api';
import moment from 'moment';
import { loopFlatObject } from '../../utils/flat';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import INIT_SEASON from '../../utils/initSeason';

function BatterRanking() {
    const [season, setSeason] = useState('');
    const [batterRanking, setBatterRanking] = useState([]);
    const navigate = useNavigate();
    const seasonArr = _.range(moment(new Date()).format('YYYY')*1, INIT_SEASON-1);

    useEffect(() => {
        const todaySeason = moment(new Date()).format('YYYY');
        fetchLastGameDay({
            seasons: [todaySeason, todaySeason-1].toString(),
            gameTypeCodes: 4201
        }).then((response) => {
            const {lastGameDay} = response['data']['RECORD'][0];
            const lastSeason = moment(lastGameDay).format('YYYY');
            setSeason(lastSeason);
        })
    }, []);

    useEffect(() => {
        if(season) {
            const commonParam = {
                seasons: season,
                gameTypeCodes: 4201
            };

            const batterParam = {...commonParam, groupingColumn: 'seasonPlayer'};
            const teamParam = {...commonParam};

            const batterRecordPromise = fetchBatterRecord(batterParam);
            const teamRecordPromise = fetchTeamRanking(teamParam);

            Promise.all([
                batterRecordPromise,
                teamRecordPromise
            ]).then((response) => {
                const rankingData = Object.values(response[0]['data']['RECORD']);
                const teamRankingData = Object.values(response[1]['data']['RECORD'])[0];
                const indexTeamData = _.keyBy(teamRankingData, 'teamId');

                let filterData = _.filter(rankingData, (item) => {
                    let teamGameCnt = indexTeamData[item['batterTeamId']]['gameCnt'];
                    return item['basicStat']['tpa'] > teamGameCnt*3.1;
                });

                let innerData = loopFlatObject(filterData);
                innerData.sort(function (a, b) {
                    if (a['avg']*1 > b['avg']*1) {
                        return -1;
                    }
                    if (a['avg']*1 < b['avg']*1) {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                });
    
                setBatterRanking(innerData);
            });
        }
    }, [season]);

    const handleChange = (e) => setSeason(e.target.value);
    const batterPlayerInfoHandler = (e) => {
        const playerObj = e.currentTarget.id;
        const playerId = playerObj.split("/")[1];
        const season = playerObj.split("/")[0];
        navigate(`/player/batter/${playerId}?season=${season}`);
    }

    return (
    <>
    <div className="select_title">
        <div>
            <FormControl size={'small'}>
                <Select
                style={{backgroundColor: '#fff'}}
                value={season}
                onChange={handleChange}
                >
                {
                    _.map(seasonArr, (item) => (
                        <MenuItem value={item} key={item}>{item}</MenuItem>
                    ))
                }
                </Select>
            </FormControl>
        </div>
        <div className="title">
            타자순위
        </div>
    </div>
    <table className="hiball_table record_table">
        <thead>
            <tr>
                <th>순위</th>
                <th width="9%">선수</th>
                <th>팀</th>
                <th>경기</th>
                <th>타석</th>
                <th>타수</th>
                <th>안타</th>
                <th>2타</th>
                <th>3타</th>
                <th>홈런</th>
                <th>타점</th>
                <th>득점</th>
                <th>도루</th>
                <th>사사구</th>
                <th>삼진</th>
                <th>타율</th>
                <th>출루율</th>
                <th>장타율</th>
                <th>OPS</th>
            </tr>
        </thead>
        <tbody>
        {
        _.map(batterRanking, (item, idx) => (
            <tr key={item['batterId']}>
                <td className="first">{idx+1}</td>
                <td className="link">
                    <div className="flex_div"
                    onClick={batterPlayerInfoHandler}
                    id={item['season']+"/"+item['batterId']}
                    >
                        <img
                        src={require(`/src/assets/image/common/team_logo/logo_${item['batterTeamId']}.png`)}
                        alt={item['batterName']}
                        />
                        <span>{item['batterName']}</span>
                    </div>
                </td>
                <td className="text">{item['batterTeamName']}</td>
                <td className="text">{item['basicStat_gameCnt']}</td>
                <td>{item['basicStat_tpa']}</td>
                <td>{item['basicStat_ab']}</td>
                <td>{item['basicStat_hit']}</td>
                <td>{item['basicStat_b2b']}</td>
                <td>{item['basicStat_b3b']}</td>
                <td>{item['basicStat_hr']}</td>
                <td>{item['basicStat_rbi']}</td>
                <td>{item['basicStat_r']}</td>
                <td>{item['basicStat_sbSucc']}</td>
                <td>{item['basicStat_bb']}</td>
                <td>{item['basicStat_so']}</td>
                <td>{item['avg']}</td>
                <td>{item['obp']}</td>
                <td>{item['slg']}</td>
                <td>{item['ops']}</td>
            </tr>    
        ))
        }
        </tbody>
    </table>
    </>    
    )
}

export default BatterRanking;