import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchBatterRecord } from '../../utils/api';
import _ from 'lodash';
import { loopFlatObject } from '../../utils/flat';
import IndicatorChart from '../../components/chart/IndicatorChart';

function TeamBattingDigestBox() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const season = searchParams.get('season');
    const [teamBattingDigest, setTeamBattingDigest] = useState({});
    const statObj = [
        {stat: 'avg', sort: 'asc', name: '타율'},
        {stat: 'obp', sort: 'asc', name: '출루율'},
        {stat: 'slg', sort: 'asc', name: '장타율'},
        {stat: 'ops', sort: 'asc', name: 'OPS'},
        {stat: 'bbRate', sort: 'asc', name: '볼넷%'},
        {stat: 'kRate', sort: 'desc', name: '삼진%'},
        {stat: 'basicStat_hr', sort: 'asc', name: '홈런'},
        {stat: 'basicStat_rbi', sort: 'asc', name: '타점'},
        {stat: 'basicStat_gdp', sort: 'desc', name: '병살'},
        {stat: 'rPerGame', sort: 'asc', name: '경기당 득점'}
    ];

    useEffect(() => {
        const teamId = params['teamId'];
        const teamParam = {
            seasons: season,
            gameTypeCodes: 4201,
            groupingColumn: 'team',
        }

        const teamBattingPromise = fetchBatterRecord(teamParam);

        teamBattingPromise.then((response) => {
            const teamDigestRecord = loopFlatObject(response['data']['RECORD']);
            const calcedRecord = rankingCalc(teamId, teamDigestRecord);
            const idxData = _.keyBy(calcedRecord, 'stat');
            setTeamBattingDigest(idxData);
        })
    }, []);

    function rankingCalc(teamId, digestRecord) {
        let tmpArr = [];
        _.forEach(statObj, (item, idx) => {
            const {stat} = item;

            let sortedData = _.sortBy(digestRecord, [(o) => {return o[stat]*1 }]);
            if(item['sort'] === 'asc') {
                sortedData.reverse();
            }

            const statRanking = _.findIndex(sortedData, (data) => data['batterTeamId'] === teamId);
            const statObj = _.find(sortedData, (data) => data['batterTeamId'] === teamId);

            tmpArr.push({stat: stat, value: statObj[stat], rank: statRanking+1, name: item['name']});
        })

        return tmpArr;
    }

    return (
    <>
    {
    Object.keys(teamBattingDigest).length === 0
    ?
    <></>
    :
    <div className="trackmanArea">
        <div className="teamTitle">{'팀 타격 그래프'}</div>
        <div style={{paddingTop: '5px'}}>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamBattingDigest['avg']}/></div>
                <div><IndicatorChart data={teamBattingDigest['obp']}/></div>
                <div><IndicatorChart data={teamBattingDigest['slg']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamBattingDigest['ops']}/></div>
                <div><IndicatorChart data={teamBattingDigest['bbRate']}/></div>
                <div><IndicatorChart data={teamBattingDigest['kRate']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamBattingDigest['basicStat_hr']}/></div>
                <div><IndicatorChart data={teamBattingDigest['basicStat_rbi']}/></div>
                <div><IndicatorChart data={teamBattingDigest['basicStat_gdp']}/></div>
            </div>
            <div className="trackmanChart">
                <div><IndicatorChart data={teamBattingDigest['rPerGame']}/></div>
                <div><IndicatorChart /></div>
                <div><IndicatorChart /></div>
            </div>
        </div>
    </div>
    }
    </>
    )
}

export default TeamBattingDigestBox;