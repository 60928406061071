import React from 'react';
import { useLocation } from 'react-router-dom';
import PlayerInfoBox from '../../layout/player/player-info-box';
import BatterRecordBox from '../../layout/player/batter/batter-record-box';
import PlayerZoneBox from '../../layout/player/player-zone-box';
import PitcherRecordBox from '../../layout/player/pitcher/pitcher-record-box';
import PlayerSprayBox from '../../layout/player/player-spray-box';

function PlayerInfoMain() {
    const pathname = useLocation()['pathname'];

    return (
    <div className="container">
        <div id="player_info_container">
            <div className="info_wrap">
                <PlayerInfoBox />
            </div>
            <div className="info_wrap">
                <PlayerZoneBox />
            </div>
            <div className="info_wrap">
                <PlayerSprayBox />
            </div>
        </div>
        <div id="player_record_container">
            <div id="player_tab_wrap">
            {
                pathname.indexOf('batter') > -1 ?
                <BatterRecordBox />
                :
                <PitcherRecordBox />
            }
            </div>
        </div>
    </div>
    )
};

export default PlayerInfoMain;